import axios from "axios";
const prefix = "p2/ui/master-data/";

export async function getShifts(params) {
  const res = await axios.get(prefix + "shifts", { params });
  return res;
}
export async function showShift(id) {
  const res = await axios.get(prefix+'shifts/' + id);
  return res;
}
export async function createShift(params) {
  const res = await axios.post(prefix+'shifts', params);
  return res;
}
export async function updateShift(id, params) {
  const res = await axios.patch(prefix+'shifts/' + id, params);
  return res;
}
export async function deleteShift(id) {
  const res = await axios.delete(prefix+'shifts/' + id);
  return res;
}
export async function exportShift(params) {
  const res = await axios.get(prefix+'shifts/export', { params });
  return res;
}
export async function importShift(params) {
  const res = await axios.post(prefix+'shifts/import', params, { headers: { "Content-Type": "multipart/form-data" } });
  return res;
}
