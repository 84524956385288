import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Upload, message, Checkbox, Space, Modal, Spin, Popconfirm, Tabs, InputNumber } from 'antd';
import { api, baseURL, token } from '../../../config';
import React, { useState, useRef, useEffect } from 'react';
import { createProduct, deleteMultipleProduct, deleteProduct, exportProduct, getProduct, getProductById, updateProduct } from '../../../api/ui/product';
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import './MasterDataStyle.css'
import { getCustomers, getLines } from '../../../api/ui/main';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { createBom, deleteBom, getBom, updateBom } from '../../../api/ui/bom';
import { deleteProductionJourney, getProductionJourney, updateProductionJourney } from '../../../api/ui/productionJourney';
import { createMachinePriorityOrders, deleteMachinePriorityOrders, getMachinePriorityOrders, updateMachinePriorityOrders } from '../../../api/phase2/master_data/machine-priority-order';
import { getLineList, getMachineList } from '../../../api/phase2/oi/manufacture';
import { getMaterial } from '../../../api/ui/material';

const EditProduct = () => {
    const { id: productId } = useParams();
    const history = useHistory();
    const [dataProduct, setDataProduct] = useState(null);
    const [form] = Form.useForm();
    const [customers, setCustomers] = useState([]);
    const [dataBom, setDataBom] = useState([]);
    const [dataProcess, setDataProcess] = useState([]);
    const [dataPriority, setDataPriority] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tabKey, setTabKey] = useState('bom');
    const [total, setTotal] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [lines, setLines] = useState([]);
    const [machines, setMachines] = useState([]);
    const [lineOptions, setLineOptions] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [openBomMdl, setOpenBomMdl] = useState(false);
    const [openProcessMdl, setOpenProcessMdl] = useState(false);
    const [openPriorityMdl, setOpenPriorityMdl] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editRecord, setEditRecord] = useState(false);
    const [isEditProduct, setIsEditProduct] = useState(false);
    const [productRecord, setProductRecord] = useState();
    const [formBomModal] = Form.useForm();
    const [formProcessModal] = Form.useForm();
    const [formPriorityModal] = Form.useForm();

    // Columns BOM
    const columnsBom = [
        { title: 'Mã sản phẩm', dataIndex: 'product_id', align: 'center', width: 100 },
        { title: 'Tên sản phẩm', dataIndex: 'product', align: 'center', width: 180, render: (value) => value?.name ?? "" },
        { title: 'Mã NVL', dataIndex: 'material_id', align: 'center', width: 80 },
        { title: 'Tên NVL', dataIndex: 'material', align: 'center', render: (value) => value?.name ?? "" },
        { title: 'Thứ tự ưu tiên', dataIndex: 'priority', align: 'center', width: 100 },
        { title: 'Tỷ lệ (%)', dataIndex: 'ratio', align: 'center', width: 100 },
        {
            title: 'Thao tác', dataIndex: 'action', align: 'center', width: 80, render: (_, record) => (
                <Space wrap>
                    <EditOutlined className='edit-btn' onClick={() => handleOpenUpdateModal(record)} />
                    <Popconfirm title="Xoá bản ghi" description={"Bạn có chắc muốn xoá?"} onConfirm={() => handleDeleteRecord(record)} okText="Có" cancelText="Không">
                        <DeleteOutlined className='delete-btn' />
                    </Popconfirm>
                </Space>
            )
        },
    ];

    // Columns Hanh trinh san xuat
    const columnsProcess = [
        { title: 'Mã sản phẩm', dataIndex: 'id', align: 'center', width: 100, fixed: 'left' },
        { title: 'Tên sản phẩm', dataIndex: 'name', align: 'center', width: 180, fixed: 'left' },
        {
            title: 'Thao tác', dataIndex: 'action', align: 'center', width: 80, fixed: 'right', render: (_, record) => (
                <Space wrap>
                    <EditOutlined className='edit-btn' onClick={() => handleOpenUpdateModal(record)} />
                    <Popconfirm title="Xoá bản ghi" description={"Bạn có chắc muốn xoá?"} onConfirm={() => handleDeleteRecord(record)} okText="Có" cancelText="Không">
                        <DeleteOutlined className='delete-btn' />
                    </Popconfirm>
                </Space>
            )
        },
    ];
    columnsProcess.splice(-1, 0, ...lines.map(e => {
        return {
            title: e.name,
            dataIndex: e.id,
            align: 'center',
            width: 130,
            render: (_, record) => {
                const val = record?.production_journey?.find((item) => Number(item?.line_id) === Number(e?.id));
                if (val) return isNaN(val?.value) ? '' : val.value;
                return '';
            }
        }
    }));

    // Columns Thu tu uu tien may
    const columnsPriority = [
        { title: 'Thứ tự ưu tiên', dataIndex: 'priority', align: 'center', width: 120 },
        { title: 'Mã máy ', dataIndex: 'machine_id', align: 'center', fixed: 'left' },
        { title: 'Mã sản phẩm', dataIndex: 'product_id', align: 'center' },
        { title: 'Tên sản phẩm', dataIndex: 'product_name', align: 'center' },
        { title: 'Công đoạn', dataIndex: 'line_name', align: 'center' },
        {
            title: 'Thao tác', dataIndex: 'action', align: 'center', width: 80, fixed: 'right', render: (_, record) => (
                <Space wrap>
                    <EditOutlined className='edit-btn' onClick={() => handleOpenUpdateModal(record)} />
                    <Popconfirm title="Xoá bản ghi" description={"Bạn có chắc muốn xoá?"} onConfirm={() => handleDeleteRecord(record)} okText="Có" cancelText="Không">
                        <DeleteOutlined className='delete-btn' />
                    </Popconfirm>
                </Space>
            )
        }
    ];

    const loadProductById = async (id) => {
        setLoading(true);
        const res = await getProductById(id);
        if (res.success) {
            const data = res.data ?? null
            setDataProduct(data);
            form.setFieldsValue(data);
        }
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }

    const fetchCustomer = async () => {
        const res = await getCustomers();
        setCustomers(res.data.map(e => ({ ...e, value: e.id, label: e.name })));
    }

    const fetchLine = async () => {
        var res = await getLines();
        setLines(res.data);
    }

    const fetchMachines = async () => {
        const res = await getMachineList();
        setMachines(res.data.map(e => ({ ...e, value: e.code, label: e.code })));
    }

    const fetchLineOptions = async () => {
        const res = await getLineList();
        setLineOptions(res.data || []);
    }

    const fetchMaterial = async () => {
        var res = await getMaterial();
        setMaterials(res.data.data.map(e => ({ ...e, value: e.id, label: e.name })));
    }

    const onFinish = async () => {
        try {
            setLoading(true);
            const values = await form.validateFields();
            if (isEditProduct) {
                await updateProduct(values.id, values);
            } else {
                const res = await createProduct(values);
                if (res.success) {
                    setProductRecord({ product_id: values?.id });
                    setDataProduct(values);
                    setIsEditProduct(true);
                    loadTabTable({ page: 1, pageSize: 20, product_id: values?.id });
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const goBack = () => {
        history.push(`/ui/master-data/product`);
    }

    useEffect(() => {
        fetchCustomer();
        fetchLine();
    }, []);

    useEffect(() => {
        if (productId) {
            document.title = "Chỉnh sửa sản phẩm";
            setIsEditProduct(true);
            setProductRecord({ product_id: productId });
            loadProductById(productId);
        } else {
            document.title = "Thêm sản phẩm";
            setIsEditProduct(false);
        }
    }, [productId]);

    useEffect(() => {
        loadTabTable({ page: 1, pageSize: 20 });
    }, [tabKey]);

    const loadTabTable = async (params = {}) => {
        if (productId) params.product_id = productId;
        if (productRecord?.product_id) params.product_id = productRecord.product_id;
        if (params.product_id) {
            switch (tabKey) {
                case 'bom':
                    fetchMaterial();
                    loadBom(params);
                    break;
                case 'process':
                    params.id = params.product_id;
                    params.withs = 'productionJourney';
                    loadProcess(params);
                    break;
                case 'priority':
                    fetchMachines();
                    fetchLineOptions();
                    loadPriority(params);
                    break;
                default:
                    break;
            }
        }
    }

    const loadBom = async (params = {}) => {
        setLoading(true)
        if (!params?.page) params.page = 1;
        if (!params?.pageSize) params.pageSize = 20;
        const res = await getBom(params);
        setDataBom((res.data?.data || []).map(e => {
            return { ...e, key: e.id }
        }));
        setTotal(res.data?.total)
        setLoading(false);
    }

    const loadProcess = async (params = {}) => {
        setLoading(true)
        if (!params?.page) params.page = 1;
        if (!params?.pageSize) params.pageSize = 20;
        const res = await getProductionJourney(params);
        setDataProcess((res.data?.data || []).map(e => {
            return { ...e, key: e.id }
        }));
        setTotal(res.data?.total)
        setLoading(false);
    }

    const loadPriority = async (params = {}) => {
        setLoading(true)
        if (!params?.page) params.page = 1;
        if (!params?.pageSize) params.pageSize = 20;
        const res = await getMachinePriorityOrders(params);
        setDataPriority((res.data?.data || []).map(e => {
            return { ...e, key: e.id }
        }));
        setTotal(res.data?.total)
        setLoading(false);
    }

    const renderTextTab = () => {
        switch (tabKey) {
            case 'bom':
                return 'BOM'
            case 'process':
                return 'hành trình sản xuất'
            case 'priority':
                return 'thứ tự ưu tiên'
            default:
                return ''
        }
    }

    const onModalFinish = async (values) => {
        try {
            setLoading(true);
            if (isEdit) {
                switch (tabKey) {
                    case 'bom':
                        await updateBom(editRecord?.id, values);
                        break;
                    case 'process':
                        const payload = { product_id: values?.product_id };
                        const line_ids = [];
                        await Object.entries(values).map((item) => {
                            const [k, v] = item;
                            const line_id = Number(k.replace('line_id_', ''));
                            if (v && k.startsWith('line_id_') && line_id) {
                                line_ids.push({ line_id: line_id, value: v });
                            }
                        })
                        payload.line_ids = line_ids;
                        await updateProductionJourney(payload)
                        break;
                    case 'priority':
                        await updateMachinePriorityOrders(editRecord?.id, values);
                        break;
                    default:
                        break;
                }
            } else {
                switch (tabKey) {
                    case 'bom':
                        await createBom(values);
                        break;
                    case 'process':
                        //
                        break;
                    case 'priority':
                        await createMachinePriorityOrders(values);
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            message.error(error.message);
        } finally {
            formBomModal.resetFields();
            formProcessModal.resetFields();
            formPriorityModal.resetFields();
            setOpenBomMdl(false);
            setOpenProcessMdl(false);
            setOpenPriorityMdl(false);
            loadTabTable();
            setLoading(false);
            setEditRecord(null);
            // message.success('Thao tác thành công!');
        }
    }

    const handleDeleteRecord = async (values) => {
        try {
            setLoading(true);
            switch (tabKey) {
                case 'bom':
                    await deleteBom(values?.id);
                    break;
                case 'process':
                    await deleteProductionJourney(values?.id);
                    break;
                case 'priority':
                    await deleteMachinePriorityOrders(values?.id);
                    break;
                default:
                    break;
            }
        } catch (error) {
            message.error(error.message);
        } finally {
            loadTabTable();
        }
    }

    const handleOpenCreateModal = () => {
        setIsEdit(false);
        switch (tabKey) {
            case 'bom':
                formBomModal.resetFields();
                formBomModal.setFieldsValue({ product_id: dataProduct?.id });
                setOpenBomMdl(true);
                break;
            case 'process':
                formProcessModal.resetFields();
                formProcessModal.setFieldsValue({ product_id: dataProduct?.id });
                setOpenProcessMdl(true);
                break;
            case 'priority':
                formPriorityModal.resetFields();
                formPriorityModal.setFieldsValue({ product_id: dataProduct?.id });
                setOpenPriorityMdl(true);
                break;
            default:
                break;
        }
    }

    const handleOpenUpdateModal = (values) => {
        console.log(values);
        setIsEdit(true);
        setEditRecord(values);
        switch (tabKey) {
            case 'bom':
                formBomModal.resetFields();
                formBomModal.setFieldsValue({ ...values, product_id: dataProduct?.id });
                setOpenBomMdl(true);
                break;
            case 'process':
                const fields = { product_id: values?.id };
                values?.production_journey?.map((item) => {
                    fields[`line_id_${item?.line_id}`] = isNaN(item?.value) ? null : Number(item.value);
                });
                formProcessModal.resetFields();
                formProcessModal.setFieldsValue(fields);
                setOpenProcessMdl(true);
                break;
            case 'priority':
                formPriorityModal.resetFields();
                formPriorityModal.setFieldsValue({ ...values, product_id: dataProduct?.id });
                setOpenPriorityMdl(true);
                break;
            default:
                break;
        }
    }

    const items = [
        {
            key: 'bom',
            label: 'BOM',
            children: <Table size='small' bordered
                scroll={{ x: true, y: '43vh' }}
                pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                    onChange: (page, pageSize) => {
                        setPage(page);
                        setPageSize(pageSize);
                        loadTabTable({ page, pageSize });
                    }
                }}
                columns={columnsBom}
                dataSource={dataBom} />,
        },
        {
            key: 'process',
            label: 'Hành trình sản xuất',
            children: <Table size='small' bordered
                scroll={{ x: 400 + lines.length * 80, y: '43vh' }}
                pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                    onChange: (page, pageSize) => {
                        setPage(page);
                        setPageSize(pageSize);
                        loadTabTable({ page, pageSize });
                    }
                }}
                columns={columnsProcess}
                dataSource={dataProcess} />,
        },
        {
            key: 'priority',
            label: 'Thứ tự ưu tiên máy',
            children: <Table size='small' bordered
                scroll={{ x: true, y: '43vh' }}
                pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                    onChange: (page, pageSize) => {
                        setPage(page);
                        setPageSize(pageSize);
                        loadTabTable({ page, pageSize });
                    }
                }}
                columns={columnsPriority}
                dataSource={dataPriority} />,
        },
    ];

    return <>
        <Card
            style={{ minHeight: '90vh' }}
            title={
                <Space>
                    <Button icon={<ArrowLeftOutlined />} onClick={goBack} loading={loading}>Trở lại danh sách</Button>
                    <div>Chỉnh sửa sản phẩm {dataProduct?.id}</div>
                </Space>
            }
            extra={
                <Space>
                    <Button type="primary" onClick={onFinish} icon={<SaveOutlined />} loading={loading}>Lưu thông tin</Button>
                </Space>
            }
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}>
                    <Row gutter={[5, 5]}>
                        <Col span={8}>
                            <Form.Item name={'id'} className='mb-3' label={'Mã sản phẩm'} rules={[{ required: true }]}>
                                <Input placeholder="Vui lòng nhập" disabled={isEditProduct} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'name'} className='mb-3' label={'Tên sản phẩm'} rules={[{ required: true }]}>
                                <Input placeholder="Vui lòng nhập" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'customer_id'} className='mb-3' label={'Tên khách hàng'} rules={[{ required: true }]}>
                                <Select options={customers} showSearch optionFilterProp='label' />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={'ver'} className='mb-3' label={'Ver'} rules={[{ required: true }]}>
                                <Input placeholder="Vui lòng nhập" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={'his'} className='mb-3' label={'His'} rules={[{ required: true }]}>
                                <Input placeholder="Vui lòng nhập" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'weight'} className='mb-3' label={'Khối lượng sản phẩm (gam/pcs)'} rules={[{ required: true }]}>
                                <Input placeholder="Vui lòng nhập" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'paper_norm'} className='mb-3' label={'Định mức giấy sử dụng (gam/pcs)'} rules={[{ required: true }]}>
                                <Input placeholder="Vui lòng nhập" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Divider style={{ margin: '5px 0' }}>
                    <small>Thông tin về sản phẩm {dataProduct?.id}</small>
                </Divider>
                <Tabs defaultActiveKey={tabKey} onChange={setTabKey} type="card" items={items} tabBarExtraContent={
                    <Space>
                        {tabKey !== 'process' && (
                            <Button type="primary" icon={<PlusOutlined />} loading={loading} onClick={handleOpenCreateModal}>Tạo mới {renderTextTab()}</Button>
                        )}
                    </Space>
                } />
            </div>
        </Card>

        {/* Model BOM */}
        <Modal title={isEdit ? 'Cập nhật' : 'Thêm mới'} open={openBomMdl} onCancel={() => setOpenBomMdl(false)} footer={null} width={800}>
            <Form
                layout="vertical"
                form={formBomModal}
                onFinish={onModalFinish}>
                <Row gutter={[5, 5]}>
                    <Col span={12}>
                        <Form.Item name={'product_id'} className='mb-3' label={'Sản phẩm'} rules={[{ required: true }]}>
                            <Input placeholder="Mã sản phẩm" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'material_id'} className='mb-3' label={'Nguyên vật liệu'} rules={[{ required: true }]}>
                            <Select options={materials} showSearch optionFilterProp='label' placeholder="Vui lòng chọn" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'priority'} className='mb-3' label={'Thứ tự ưu tiên'} rules={[{ required: true }]}>
                            <InputNumber style={{ width: '100%' }} placeholder="Vui lòng nhập" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'ratio'} className='mb-3' label={'Tỷ lệ (%)'} rules={[{ required: true }]}>
                            <InputNumber style={{ width: '100%' }} placeholder="Vui lòng nhập" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item className='mb-0 text-end'>
                    <Button type="primary" htmlType='submit' loading={loading}>Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal>

        {/* Model Hanh trinh san xuat: Line_id, No. */}
        <Modal title={isEdit ? 'Cập nhật' : 'Thêm mới'} open={openProcessMdl} onCancel={() => setOpenProcessMdl(false)} footer={null}>
            <Form
                layout="vertical"
                form={formProcessModal}
                onFinish={onModalFinish}>
                <div className="ui-modal-content-scroll">
                    <Form.Item name={'product_id'} className='mb-3' label={'Sản phẩm'} rules={[{ required: true }]}>
                        <Input placeholder="Mã sản phẩm" disabled />
                    </Form.Item>
                    <Row gutter={5} style={{ width: '99%' }}>
                        {lines.map((item, index) => (
                            <Col span={12}>
                                <Form.Item key={index} name={`line_id_${item?.id}`} className='mb-3' label={item?.name}>
                                    <InputNumber style={{ width: '100%' }} placeholder={`Nhập ${item?.name}`} />
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>
                </div>
                <Form.Item className='mb-0 text-end'>
                    <Button type="primary" htmlType='submit'>Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal>

        {/* Model Thu tu uu tien may */}
        <Modal title={isEdit ? 'Cập nhật' : 'Thêm mới'} open={openPriorityMdl} onCancel={() => setOpenPriorityMdl(false)} footer={null} width={800}>
            <Form
                layout="vertical"
                form={formPriorityModal}
                onFinish={onModalFinish}>
                <Form.Item name="id" hidden></Form.Item>
                <Row gutter={[5, 5]}>
                    <Col span={12}>
                        <Form.Item name="product_id" label="Sản phẩm" className='mb-0' rules={[{ required: true }]}>
                            <Input placeholder="Mã sản phẩm" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="priority" label="Thứ tự ưu tiên" className='mb-0' rules={[{ required: true }]}>
                            <InputNumber className='w-100' placeholder='Nhập thứ tự ưu tiên' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="machine_id" label="Mã máy" className='mb-0' rules={[{ required: true }]}>
                            <Select options={machines} showSearch optionFilterProp='label' placeholder="Vui lòng chọn" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="line_id" label="Công đoạn" className='mb-0' rules={[{ required: true }]}>
                            <Select options={lineOptions} showSearch optionFilterProp='label' placeholder="Vui lòng chọn" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item className='mt-4'>
                    <Button type="primary" htmlType='submit' loading={loading}>Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default EditProduct;
