import axios from "axios";
const prefix = 'p2/ui/';
//LotPlans
export async function getLotPlans(params) {
    const res = await axios.get(prefix+'lot-plans', { params });
    return res;
}
export async function createLotPlans(params) {
    const res = await axios.post(prefix+'lot-plans', params);
    return res;
}
export async function updateLotPlans(id, params) {
    const res = await axios.patch(prefix+'lot-plans' + id, params);
    return res;
}
export async function deleteLotPlans(id) {
    const res = await axios.delete(prefix+'lot-plans/' + id);
    return res;
}
export async function exportLotPlans(params) {
    const res = await axios.get(prefix+'lot-plans/export', { params });
    return res;
}
