import { Card } from "antd";
import Highcharts from "highcharts";
import { useEffect } from "react";

const MaterialErrorRatio = ({ data = [] }) => {
    useEffect(() => {
        // if (data.length === 0) {
        //     return;
        // }
        const series = [
            {
                name: 'Tỷ lệ xuất hiện',
                colorByPoint: true,
                data: data.map(e=>({...e, y: e.value, name: e.error}))
            }
        ]
        const options = {
            chart: {
                type: 'pie',
                height: 200,
            },
            tooltip: {
                valueSuffix: '%'
            },
            title: false,
            series: series,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },
            exporting: false,
            credits: { enabled: false }
        };
        Highcharts.chart("material-error-ratio-chart", options);
    }, [data]);
    return (
        <Card title="Tỷ lệ lỗi nguyên vật liệu" style={{ height: '100%', padding: '0px' }} styles={{ body: { padding: 12 } }}>
            <div id={"material-error-ratio-chart"} />
        </Card>
    )
}

export default MaterialErrorRatio;