import axios from "axios";
const prefix = 'p2/ui/';
//PQC
export async function getQualityDataTable(params) {
    const res = await axios.get(prefix+'quality/pqc/data-table', {params});
    return res;
}
export async function getQualittyDataChart(params) {
    const res = await axios.get(prefix+'quality/pqc/data-chart', {params});
    return res;
}
export async function exportQualittyDataTable(params) {
    const res = await axios.get(prefix+'quality/pqc/export-data-table', {params});
    return res;
}
export async function exportTestCriteriaHistory(params) {
    const res = await axios.get(prefix+'quality/pqc/export-test-criteria-history', {params});
    return res;
}
export async function exportPQCReport(params) {
    const res = await axios.get(prefix+'quality/pqc/export-pqc-report', {params});
    return res;
}

//OQC
export async function getOQCDataTable(params) {
    const res = await axios.get(prefix+'quality/oqc/data-table', {params});
    return res;
}
export async function getOQCDataChart(params) {
    const res = await axios.get(prefix+'quality/oqc/data-chart', {params});
    return res;
}
export async function getOQCDataSummary(params) {
    const res = await axios.get(prefix+'quality/oqc/data-summary', {params});
    return res;
}
export async function exportOQCDataTable(params) {
    const res = await axios.get(prefix+'quality/oqc/export-data-table', {params});
    return res;
}