import {
  DatePicker,
  Col,
  Row,
  Card,
  Table,
  Button,
  Form,
  Select,
  Space,
  Typography,
  Divider,
  Layout,
} from "antd";
import { DualAxes } from "@ant-design/charts";
import React, { useState, useEffect } from "react";
import {
  getLines,
  getPowerConsumeDataByMonth,
  getMonthlyConsumption,
} from "../../../api/ui/main";
import dayjs from "dayjs";
import { exportThongSoMay } from "../../../api/ui/export";
import { baseURL } from "../../../config";
import { getMachine } from "../../../api";

const { Sider } = Layout;
const { RangePicker } = DatePicker;

const PowerConsumeByMonth = (props) => {
  document.title = "UI - Thông số máy";
  const [listLines, setListLines] = useState([]);
  const [machineOptions, setMachineOptions] = useState([]);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    line_id: "",
    machine_code: "",
    ca_sx: "",
    datetime: dayjs(),
  });
  const [colTable, setColTable] = useState([]);
  const [sumPowerConsume, setSumPowerConsume] = useState(0);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [loSX, setLoSX] = useState();
  const [mockData, setMockData] = useState([]);
  const [payload, setPayload] = useState();

  useEffect(() => {
    const month = [];
    for (let index = 1; index <= params.datetime.daysInMonth(); index++) {
      month.push({
        title: index.toString().padStart(2, "0"),
        key: index,
        dataIndex: index,
        align: "center",
        render: (value) => value ?? "-",
      });
    }
    const columns = [
      {
        title: " ",
        dataIndex: "col",
        rowScope: "row",
        width: "10%",
        fixed: "left",
      },
      {
        title: "Tháng " + params.datetime.format("MM"),
        children: month,
      },
    ];
    setColTable(columns);
  }, [params.datetime]);

  const btn_click = async (payload = {}) => {
    setLoading(true);
    const filter = { ...params, ...payload };
    if (filter?.datetime)
      filter.datetime = dayjs(filter.datetime).format("YYYY-MM-DD");
    const res = await getMonthlyConsumption(filter);
    if (res.success) {
      let result = { col: "Tổng điện năng (kW)" };
      res.data?.forEach((item) => {
        result[Number(dayjs(item?.date).format("DD"))] = Number(
          item?.total_consumption
        ).toFixed(2);
      });
      console.log(result);
      for (let i = 1; i <= dayjs(filter?.datetime).daysInMonth(); i++) {
        if (!result[i]) result[i] = 0;
      }
      setData([result]);
      const sumPower = Number(
        res.data?.reduce((a, b) => a + Number(b?.total_consumption), 0)
      ).toFixed(2);
      setSumPowerConsume(sumPower);
    }
    // const res = await getPowerConsumeDataByMonth(params);
    // const filledData = fillMissingDates(res.data.data, params.datetime);
    // const filledData = fakeData();
    // setData(filledData);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      // const res = await getLines();
      const res = await getMachine({ is_iot: 1 });
      // const options = res.data.map(e => ({ ...e, value: e.id, label: e.name }));
      // setListLines(options);
      // const line_in = options.find(e => e.id === 10);
      // if (line_in) setParams({ ...params, line_id: line_in.id });
      const resources = res || [];
      let machine_code = resources[0]?.code;
      if (resources?.length > 0) {
        setMachineOptions(
          resources.map(({ code }) => ({ label: code, value: code }))
        );
        setParams({ ...params, machine_code });
      }
      btn_click({ machine_code });
    })();
  }, []);

  const exportFile = async () => {
    setExportLoading(true);
    const res = await exportThongSoMay({ ...params, lo_sx: loSX });
    if (res.success) {
      window.location.href = baseURL + res.data;
    }
    setExportLoading(false);
  };

  const onClickRow = async (record) => {
    setLoSX(record.lo_sx);
  };

  // Fill missing dates
  const fillMissingDates = (data, datetime) => {
    const daysInMonth = datetime.daysInMonth();
    const existingDataMap = data.reduce((acc, item) => {
      acc[item.col] = item;
      return acc;
    }, {});

    // Initialize "Tổng điện năng (kW)" if it doesn't exist
    if (!existingDataMap["Tổng điện năng (kW)"]) {
      existingDataMap["Tổng điện năng (kW)"] = { col: "Tổng điện năng (kW)" };
    }

    // Fill missing dates with null initially
    for (let i = 1; i <= daysInMonth; i++) {
      const dateStr = i;
      if (existingDataMap["Tổng điện năng (kW)"][dateStr] === undefined) {
        existingDataMap["Tổng điện năng (kW)"][dateStr] = null;
      }
    }

    // Update null values based on their neighbors
    for (let i = 1; i <= daysInMonth; i++) {
      const dateStr = i;
      if (existingDataMap["Tổng điện năng (kW)"][dateStr] === null) {
        const prevValue =
          i > 1 ? existingDataMap["Tổng điện năng (kW)"][i - 1] : null;
        const nextValue =
          i < daysInMonth
            ? existingDataMap["Tổng điện năng (kW)"][i + 1]
            : null;
        if (
          (prevValue !== null && prevValue > 0) ||
          (nextValue !== null && nextValue > 0)
        ) {
          existingDataMap["Tổng điện năng (kW)"][dateStr] = 0;
        }
      }
    }

    return Object.values(existingDataMap);
  };

  const fakeData = () => {
    const daysInMonth = 31; // For example, assume the month has 31 days
    const data = [];
    if (
      mockData.length > 0 &&
      payload?.line_id === params.line_id &&
      dayjs(payload?.datetime).format("MM") ===
        dayjs(params.datetime).format("MM")
    )
      return mockData;

    // Generate mock data for each row (here we use just 3 rows for simplicity)
    const rows = ["Tổng điện năng (kW)", "Công đoạn 1", "Công đoạn 2"];
    rows.forEach((row) => {
      const rowData = { col: row };
      for (let i = 1; i <= daysInMonth; i++) {
        rowData[i] = (Math.random() * 100).toFixed(2); // Random kW values
      }
      data.push(rowData);
    });
    setMockData(data);
    setPayload(params);

    return data;
  };

  // Transform the data for the line and column chart
  const transformDataForChart = (data) => {
    const result = [];
    const powerConsumeData = data.find(
      (item) => item.col === "Tổng điện năng (kW)"
    );
    if (powerConsumeData) {
      Object.keys(powerConsumeData).forEach((key) => {
        if (key !== "col") {
          let val = parseFloat(powerConsumeData[key]);
          // Chuyển giá trị 0 thành null
          if (val === 0) val = null;
          result.push({ date: key, value: val });
        }
      });
    }
    return result;
  };

  const chartData = transformDataForChart(data);

  const config = {
    data: [chartData, chartData],
    xField: "date",
    yField: ["value", "value"],
    geometryOptions: [
      {
        geometry: "column",
        color: "#2462a3",
        columnWidthRatio: 0.65, // Tăng độ rộng cột
      },
      {
        geometry: "line",
        color: "#f88c24",
        lineStyle: {
          lineWidth: 2,
        },
        point: {
          size: 3,
          shape: "diamond",
        },
        connectNulls: true,
      },
    ],
    xAxis: {
      type: "cat", // Xử lý trục x như dữ liệu phân loại
      title: { text: "Ngày" },
      label: {
        formatter: (val) => val.toString().padStart(2, "0"),
      },
    },
    yAxis: {
      value: {
      
        min: 0,
        label: {
          formatter: (v) => `${v} KWH`,
        },
        sync: true, // Đồng bộ hóa trục y
      },
    },
    legend: {
      custom: true,
      position: "top",
      items: [
        {
          value: "value",
          name: "KWH",
          marker: { symbol: "square", style: { fill: "#2462a3", r: 5 } },
        },
        {
          value: "value",
          name: "KWH",
          marker: { symbol: "line", style: { stroke: "#f88c24", r: 5 } },
        },
      ],
    },
    height: "51vh",
  };

  return (
    <>
      <Row style={{ padding: "8px" }} gutter={[8, 8]} className="custom-row">
        <Col span={4} className="custom-col">
          <Card
            bodyStyle={{ padding: 0 }}
            className="custom-ant-card"
            actions={[
              <Button
                type="primary"
                style={{ width: "90%" }}
                onClick={() => btn_click()}
              >
                Truy vấn
              </Button>,
            ]}
          >
            <Divider>Điều kiện truy vấn</Divider>
            <Form style={{ margin: "0 8px" }} layout="vertical">
              <Form.Item label="Tháng" className="mb-3">
                <Space direction="vertical" style={{ width: "100%" }}>
                  <DatePicker
                    picker="month"
                    allowClear={false}
                    placeholder="Chọn tháng"
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      setParams({ ...params, datetime: value });
                      setPage(1);
                    }}
                    value={params.datetime}
                  />
                </Space>
              </Form.Item>
              <Form.Item label="Máy" className="mb-3">
                <Select
                  showSearch
                  value={params.machine_code}
                  onChange={(value) =>
                    setParams({ ...params, machine_code: value })
                  }
                  placeholder="Chọn máy"
                  options={machineOptions}
                />
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={20}>
          <Card title="Quản lý điện năng tiêu thụ">
            <div style={{ height: "51vh" }}>
              <DualAxes {...config} />
            </div>
            <Divider />
            <Table
              size="small"
              bordered
              loading={loading}
              pagination={false}
              scroll={{
                x: "150vw",
                y: "40vh",
              }}
              footer={() => (
                <Typography.Text strong>
                  {"Tổng điện năng tiêu thụ trong tháng (kW): " +
                    sumPowerConsume}
                </Typography.Text>
              )}
              columns={colTable}
              dataSource={data}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PowerConsumeByMonth;
