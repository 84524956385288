import { Card } from "antd";
import { useEffect, useRef } from "react";
import Highcharts from "highcharts";

const DowntimeChart = ({dataChart}) => {
    const chartRef = useRef(null);
    useEffect(() => {
        const initializeChart = () => {
            const categories = dataChart.map((item) => item.type);
            const series = [];
            dataChart[0]?.value.forEach((process, index) => {
                const dataSeries = {
                    name: process.name,
                    data: dataChart.map(day => day.value[index].data)  // Lấy giá trị data tương ứng mỗi ngày
                };
                series.push(dataSeries);
            });
            console.log(categories, series);


            chartRef.current = Highcharts.chart("machine-downtime-chart", {
                chart: {
                    type: 'column',
                    height: 252
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: categories, // Machines on the x-axis
                    crosshair: true,
                    title: false
                },
                yAxis: {
                    // max: 100,
                    title: false,
                    // labels: {
                    //     format: '{value}%'
                    // }
                },
                // tooltip: {
                //     format: '<b>{series.name}</b>: {y}'
                // },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                        borderRadius: 0,
                        groupPadding: 0.1, // Adjust space between groups
                        dataLabels: {
                            enabled: true,
                            // format: '{point.y:0.1f}%',
                            style: {
                                fontWeight: 'bold'
                            }
                        }
                    }
                },
                series: series,
                credits: {
                    enabled: false
                },
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                }
            });
        }
        // Hủy biểu đồ hiện tại nếu đã tồn tại
        if (chartRef.current) {
            chartRef.current.destroy();
        }

        // Khởi tạo biểu đồ mới với dữ liệu cập nhật
        if (dataChart && dataChart.length > 0) {
            initializeChart();
        }
    }, [dataChart]);

    return (
        <Card title="Dừng máy sửa chữa" style={{ padding: '0px' }}>
            <div id="machine-downtime-chart" />
        </Card>
    );
};

export default DowntimeChart;
