import React, { useEffect, useState } from 'react';
import { withRouter, Link } from "react-router-dom";
import '../style.scss';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import IQC from './IQC';
import PQC from './PQC';
import QCPhase2 from './QCPhase2';
import SelectionLineQCPhase2 from './SelectionLineQCPhase2';
import OQCPhase2 from './OQCPhase2';

const Quality = (props) => {
    // const [messageApi, contextHolder] = message.useMessage();
    document.title = "Kiểm tra chất lượng";
    const { line } = useParams();
    var node = null;
    const history = useHistory();
    useEffect(() => {
        const screen = JSON.parse(localStorage.getItem('screen'));
        if (!line) {
            if (!screen?.quality) { 
                history.push('/quality/24');
            } else { 
                history.push('/quality/'+screen?.quality);
            }
        }
    }, [line])
    switch (line) {
        case '9':
        case '10':
        case '11':
        case '12':
        case '13':
        case '15':
        case '21':
        case '22':
        case '14':
        case '20':
            node = <QCPhase2 />
            break;
        case '23':
            node = <IQC />
            break;
        case '24':
        case '25':
        case '26':
        case '27':
            node = <QCPhase2 />
            break;
        case '29':
            node = <QCPhase2 />
            break;
        case '30':
            node = <OQCPhase2 />
            break;
        default:
            break;
    }
    return (
        <React.Fragment>
            {node}
        </React.Fragment>
    );
};

export default withRouter(Quality);