import axios from "axios";
const prefix = 'p2/oi/';

export async function scanImport(params) {
    const res = await axios.get(prefix+'scan-import', {params});
    return res;
}
export async function overallImport(params) {
    const res = await axios.get(prefix+'overall-import', {params});
    return res;
}
export async function scanExport(params) {
    const res = await axios.get(prefix+'scan-export', {params});
    return res;
}
export async function overallExport(params) {
    const res = await axios.get(prefix+'overall-export', {params});
    return res;
}