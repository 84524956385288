import axios from "axios";

export async function getLines(params) {
  const res = await axios.get("ui/lines");
  return res;
}

export async function getTreeLines(params) {
  const res = await axios.get("ui/lines/tree");
  return res;
}

export async function getMachineOfLine(params) {
  const res = await axios.get("ui/line/list-machine", { params: params });
  return res;
}

export async function getAllMachine(params) {
  const res = await axios.get("ui/machines");
  return res;
}

export async function getCustomers(params) {
  const res = await axios.get("ui/customers");
  return res;
}

export async function getProducts(params) {
  const res = await axios.get("ui/products");
  return res;
}
export async function getStaffs(params) {
  const res = await axios.get("ui/staffs");
  return res;
}
export async function getLoSanXuat(params) {
  const res = await axios.get("ui/lo-san-xuat");
  return res;
}
export async function getWarehouses(params) {
  const res = await axios.get("ui/warehouses");
  return res;
}
export async function getCaSanXuats(params) {
  const res = await axios.get("ui/ca-san-xuat-s");
  return res;
}
//Lỗi QC
export async function getErrors(params) {
  const res = await axios.get("ui/errors");
  return res;
}

export async function getErrorsMachine(params) {
  const res = await axios.get("ui/errors-machine");
  return res;
}

// api - nối api của Quân
export async function getProduceHistory(params) {
  const res = await axios.get("produce/history", { params: params });
  return res;
}

export async function getPQCHistory(params) {
  const res = await axios.get("qc/history", { params: params });
  return res;
}

export async function getMachineError(params) {
  const res = await axios.get("machine/error", { params: params });
  return res;
}

export async function errorData(params) {
  const res = await axios.get("/qc/error-data", { params });
  return res;
}

export async function getThongSoMay(params) {
  const res = await axios.get("ui/thong-so-may", { params: params });
  return res;
}

export async function getKpi(params) {
  const res = await axios.get("kpi", { params });
  return res;
}

export async function getKpiPassRateChart(params) {
  const res = await axios.get("p2/ui/kpi/pass-rate-chart", { params });
  return res;
}

export async function getKpiTiLeVanHanhTB(params) {
  const res = await axios.get("p2/ui/kpi/ti-le-van-hanh-tb", { params });
  return res;
}

export async function getKpiTiLeHoanThanhKeHoach(params) {
  const res = await axios.get("p2/ui/kpi/ti-le-hoan-thanh-ke-hoach", { params });
  return res;
}

export async function getKpiTiLeLoiCongDoan(params) {
  const res = await axios.get("p2/ui/kpi/ti-le-loi-cong-doan", { params });
  return res;
}

export async function getKpiHieuSuatThietBi(params) {
  const res = await axios.get("p2/ui/kpi/hieu-suat-thiet-bi", { params });
  return res;
}

export async function getKpiSoLanDungMay(params) {
  const res = await axios.get("p2/ui/kpi/so-lan-dung-may", { params });
  return res;
}

export async function getOQC(params) {
  const res = await axios.get("oqc", { params });
  return res;
}

export async function getDataFilterUI(params) {
  const res = await axios.get("ui/data-filter", { params });
  return res;
}

export async function getDetailDataError(params) {
  const res = await axios.get("qc/detail-data-error", { params });
  return res;
}

export async function exportKPI(params) {
  const res = await axios.get("export/kpi", { params });
  return res;
}

export async function getQCErrorList(params) {
  const res = await axios.get("ui/qc-error-list", { params });
  return res;
}
export async function getIQCList(params) {
  const res = await axios.get("ui/iqc", { params });
  return res;
}
export async function getPowerConsumeDataByMonth(params) {
  const res = await axios.get("ui/equipment/power-consume-by-month", {
    params,
  });
  return res;
}
export async function getPowerConsumeDataByMonthChart(params) {
  const res = await axios.get("ui/equipment/power-consume-by-month-chart", {
    params,
  });
  return res;
}
export async function getMonthlyConsumption(params) {
  const res = await axios.get("/p2/ui/monthly-consumption", { params });
  return res;
}
export async function getPowerConsumeDataByProduct(params) {
  const res = await axios.get("ui/equipment/power-consume-by-product", {
    params,
  });
  return res;
}
export async function exportPowerConsumeDataByProduct(params) {
  const res = await axios.get("ui/export/power-consume-by-product", { params });
  return res;
}
