import axios from "axios";

//Template
export async function getTemplate(params) {
    const res = await axios.get('/template/list', { params });
    return res;
}
export async function createTemplate(params) {
    const res = await axios.post('/template/create', params);
    return res;
}
export async function updateTemplate(id, params) {
    const res = await axios.patch('/template/update/' + id, params);
    return res;
}
export async function deleteTemplate(id) {
    const res = await axios.delete('/template/delete/' + id);
    return res;
}
export async function deleteMultipleTemplate(params) {
    const res = await axios.post('/templates/delete', params);
    return res;
}
export async function exportTemplate(params) {
    const res = await axios.get('/template/export', { params });
    return res;
}