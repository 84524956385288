import { Card } from "antd";
import Highcharts from "highcharts";
import { useEffect } from "react";

const UIOQCErrorFrequency = ({ data = [] }) => {
    useEffect(() => {
        const categories = data.dates;
        const series = data.series;
        
        const options = {
            chart: {
                type: 'line',
                height: 200
            },
            title: false,
            xAxis: {
                categories: categories,
                crosshair: true
            },
            yAxis: [{
                title: false,
                labels: {
                    format: '{value}%',
                },
            }],
            plotOptions: {
                column: {
                    borderRadius: 0,
                    // depth: 40,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}%'
                    }
                }
            },
            tooltip: {
                valueSuffix: '%'
            },
            series: series,
            legend: false,
            exporting: false,
            credits: { enabled: false }
        };
        Highcharts.chart("oqc-error-frequency-chart", options);
    }, [data]);
    return (
        <Card title="Biểu đồ tỷ lệ lỗi" style={{ height: '100%', padding: '0px' }} styles={{ body: { padding: 12 } }}>
            <div id={"oqc-error-frequency-chart"} />
        </Card>
    )
}

export default UIOQCErrorFrequency;