import axios from "axios";

//Material
export async function getMaterial(params) {
    const res = await axios.get('/material/list', { params });
    return res;
}
export async function createMaterial(params) {
    const res = await axios.post('/material/create', params);
    return res;
}
export async function updateMaterial(id, params) {
    const res = await axios.patch('/material/update/' + id, params);
    return res;
}
export async function deleteMaterial(id) {
    const res = await axios.delete('/material/delete/' + id);
    return res;
}
export async function deleteMultipleMaterial(params) {
    const res = await axios.post('/materials/delete', params);
    return res;
}
export async function exportMaterial(params) {
    const res = await axios.get('/material/export', { params });
    return res;
}