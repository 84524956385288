import React, { useState, useRef, useEffect } from 'react';
import { Layout, Divider, Button, Table, Modal, Card, Checkbox, DatePicker, Form, Input, Upload, message, Select, Col, Row, Space, Radio } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Column } from '@ant-design/plots';
import "../style.scss";
import { baseURL } from '../../../config';
import { createWareHouseExport, deleteWareHouseExport, getListLot, getListWarehouseExportPlan, store, testUpdateTable, updateWareHouseExport } from '../../../api';
import UISidebar from '../components/Sidebar';
import { useHistory, useParams, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import EditableTable from '../../../components/Table/EditableTable';
import dayjs from "dayjs";
import { getCustomers, getDataFilterUI, getProducts } from '../../../api/ui/main';
import { uploadWarehouseExportPlan } from '../../../api/phase2/ui/warehouse';
import CalculateTableHeight from '../../../components/calculateTableHeight';
const { RangePicker } = DatePicker;

const { Sider } = Layout;
const WarehouseExportPlan = (props) => {
    document.title = "UI - Kế hoạch xuất kho";
    const [data, setData] = useState([]);
    const [listCheck, setListCheck] = useState([]);
    const [listCustomers, setListCustomers] = useState([]);
    const [listNameProducts, setListNameProducts] = useState([]);
    const [params, setParams] = useState({ date: [dayjs(), dayjs()] });
    const onChangeChecbox = (e) => {
        if (e.target.checked) {
            if (!listCheck.includes(e.target.value)) {
                setListCheck(oldArray => [...oldArray, e.target.value]);
            }
        } else {
            if (listCheck.includes(e.target.value)) {
                setListCheck(oldArray => oldArray.filter((datainput) => datainput !== e.target.value))
            }
        }
    }

    useEffect(() => {
        (async () => {
            var res = await getDataFilterUI({ khach_hang: params.khach_hang });
            if (res.success) {
                setListNameProducts(res.data.product.map(e => {
                    return { ...e, label: e.name, value: e.id }
                }));
                // setListLoSX(Object.values(res.data.lo_sx).map(e => {
                //         return { label: e, value: e }
                // }));
            }
        })()
    }, [params.khach_hang])

    const deleteRecord = async () => {
        if (listCheck.length > 0) {
            const res = await deleteWareHouseExport(listCheck);
            setListCheck([]);
            btn_click();
        } else {
            message.info('Chưa chọn bản ghi cần xóa')
        }
    }

    const columns = [
        {
            title: 'Chọn',
            dataIndex: 'name1',
            key: 'name1',
            align: 'center',
            fixed: 'left',
            width: 70,
            render: (value, item, index) => <Checkbox value={item.id} onChange={onChangeChecbox} checked={listCheck.includes(item.id) ? true : false} ></Checkbox>
        },
        {
            title: 'Khách hàng',
            dataIndex: 'khach_hang',
            key: 'khach_hang',
            align: 'center',
            // editable: true,
            fixed: 'left',
            width: 200,
        },
        {
            title: 'Ngày xuất hàng',
            dataIndex: 'ngay_xuat_hang',
            key: 'ngay_xuat_hang',
            align: 'center',
            // editable: true,
            fixed: 'left',
            width: 200,
        },
        {
            title: 'Số lượng nợ đơn hàng',
            align: 'center',
            children: [{
                title: 'Mã hàng',
                dataIndex: 'product_id',
                key: 'product_id',
                align: 'center',
                width: 200,
            }, {
                title: 'Tên sản phẩm',
                dataIndex: 'ten_san_pham',
                key: 'ten_san_pham',
                align: 'center',
                width: 200,
            }, {
                title: 'PO pending',
                dataIndex: 'po_pending',
                key: 'po_pendding',
                align: 'center',
                width: 200,
            }, {
                title: 'SL yêu cầu giao',
                dataIndex: 'sl_yeu_cau_giao',
                key: 'sl_yeu_cau_giao',
                align: 'center',
                width: 200,
            }],
            key: 'abc'
        },
        {
            title: 'ĐVT',
            dataIndex: 'dvt',
            key: 'dvt',
            align: 'center',
            width: 100,
        },
        {
            title: 'Tổng kg',
            dataIndex: 'tong_kg',
            key: 'tong_kg',
            align: 'center',
            width: 100,
        },
        {
            title: 'Quy cách đóng thùng/bó',
            dataIndex: 'quy_cach',
            key: 'quy_cach',
            align: 'center',
            width: 150,
        },
        {
            title: 'SL thùng chẵn',
            dataIndex: 'sl_thung_chan',
            key: 'sl_thung_chan',
            align: 'center',
            width: 100,
        },
        {
            title: 'Số lượng hàng lẻ',
            dataIndex: 'sl_hang_le',
            key: 'sl_hang_le',
            align: 'center',
            width: 100,
        },
        {
            title: 'Tồn kho',
            dataIndex: 'ton_kho',
            key: 'ton_kho',
            align: 'center',
            width: 100,
        },
        {
            title: 'Xác nhận SX',
            dataIndex: 'xac_nhan_sx',
            key: 'xac_nhan_sx',
            align: 'center',
            width: 100,
            render: (value) => value
        },
        {
            title: 'SL thực xuất',
            dataIndex: 'sl_thuc_xuat',
            key: 'sl_thuc_xuat',
            align: 'center',
            width: 100,
        },
        // {
        //     title: 'SL chênh lệch',
        //     dataIndex: 'sl_chenh_lech',
        //     key: 'sl_chenh_lech',
        // },
        {
            title: 'Cửa xuất hàng',
            dataIndex: 'cua_xuat_hang',
            key: 'cua_xuat_hang',
            align: 'center',
            // editable: true,
            width: 100,
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'dia_chi',
            key: 'dia_chi',
            align: 'center',
            width: 100,
        },
        {
            title: 'Ghi chú',
            dataIndex: 'ghi_chu',
            key: 'ghi_chu',
            align: 'center',
            width: 100,
        },
    ];
    const mergedKey = 'khach_hang';
    const mergeColumn = ['khach_hang', 'cua_xuat_hang', 'dia_chi', 'ghi_chu'];
    const mergeValue = mergeColumn.map(e => { return { key: e, set: new Set() } });
    useEffect(() => {
        (async () => {
            var res1 = await getCustomers();
            setListCustomers(res1.data.map(e => {
                return { ...e, label: e.name, value: e.id }
            }));
        })();
    }, [])
    const isEditing = (col, record) => {
        return (col.editable === true && listCheck.includes(record.id));
    };
    const customColumns = columns.map(e => {
        if (mergeColumn.includes(e.key)) {
            console.log(e);
            return {
                ...e,
                onCell: (record) => {
                    const props = {
                        record,
                        ...e,
                        editable: isEditing(e, record),
                        handleSave,
                    }
                    const set = mergeValue.find(s => s.key === e.key)?.set;
                    if (set?.has(record[mergedKey])) {
                        return { rowSpan: 0, ...props };
                    } else {
                        const rowCount = data.filter((data) => data[mergedKey] === record[mergedKey]).length;
                        set?.add(record[mergedKey]);
                        return { rowSpan: rowCount, ...props };
                    }
                },
            }
        } else {
            return {
                ...e,
                onCell: (record) => {
                    const props = {
                        record,
                        ...e,
                        editable: isEditing(e, record),
                        handleSave,
                    }
                    return props;
                },
            }
        }
    })
    const handleSave = async (row) => {
        setData(prev => prev.map(e => {
            if (e.id === row.id) {
                return row;
            } else {
                return e;
            }
        }))
    }
    const loadListTable = async () => {
        const res = await getListWarehouseExportPlan(params);
        setData(res.sort((a, b) => {
            if (a[mergedKey] < b[mergedKey]) {
                return -1;
            }
            if (a[mergedKey] > b[mergedKey]) {
                return 1;
            }
            return 0;
        }));
    }
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Upload file thành công',
        });
    };

    const error = () => {
        messageApi.open({
            type: 'error',
            content: 'Upload file lỗi',
        });
    };

    const btn_click = () => {
        loadListTable()
    }

    useEffect(() => {
        btn_click();
    }, [])

    const [titleMdlEdit, setTitleMdlEdit] = useState('Cập nhật');
    const onEdit = () => {
        if (listCheck.length > 1) {
            message.info('Chỉ chọn 1 bản ghi để chỉnh sửa');
        } else if (listCheck.length == 0) {
            message.info('Chưa chọn bản ghi cần chỉnh sửa')
        } else {
            const result = data.find((record) => record.id === listCheck[0]);
            form.setFieldsValue(result)
            setOpenMdlEdit(true);
            setTitleMdlEdit('Cập nhật');
        }
    }
    const onInsert = () => {
        setTitleMdlEdit('Thêm mới')
        form.resetFields();
        setOpenMdlEdit(true);
    }
    const [form] = Form.useForm();
    const onFinish = async (values) => {
        if (values.id) {
            const res = await updateWareHouseExport(values);
        } else {
            const res = await createWareHouseExport(values);
        }
        setOpenMdlEdit(false);
        btn_click();
    }
    const [openMdlEdit, setOpenMdlEdit] = useState(false);
    const [dateType, setDateType] = useState('date');
    const customDateFormat = (value) => {
        switch (dateType) {
            case 'week':
                return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
                break;
            case 'month':
                return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
                break;
            case 'year':
                return (`Năm ${dayjs(value).format('YYYY')}`);
                break;
            default:
                return (dayjs(value).format('DD/MM/YYYY'));
                break;
        }
    }
    useEffect(()=>{
        onChangeDate('start_date', params.date[0], dateType);
        onChangeDate('end_date', params.date[1], dateType);
    }, [dateType])
    const onChangeDate = (position, value, dateType) => {
        switch (dateType) {
            case 'week':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('isoWeek'), dayjs(params.date[1]).endOf('isoWeek')] });
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('isoWeek'), dayjs(value).endOf('isoWeek')] });
                }
                break;
            case 'month':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('month'), dayjs(params.date[1]).endOf('month')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('month'), dayjs(value).endOf('month')] });
                }
                break;
            case 'year':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('year'), dayjs(params.date[1]).endOf('year')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('year'), dayjs(value).endOf('year')] });
                }
                break;
            default:
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
                }
                break;
        }
    }
    const [uploading, setUploading] = useState(false);
    const uploadProps = {
        maxCount: 1,
        multiple: false,
        customRequest: async ({ file, onSuccess, onError }) => {
            setUploading(true);
            const formData = new FormData();
            formData.append('file', file);
            const res = await uploadWarehouseExportPlan(formData);
            if (res.success) {
                btn_click();
                success();
            } else {
                btn_click();
                error()
            }
            setUploading(false);
        }
    };
    return (
        <React.Fragment>
            <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
                <Col span={4} className='custom-col'>
                    <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                        <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                    ]}>
                        <Form style={{ margin: '0 15px' }} layout="vertical">
                            <Divider>Thời gian truy vấn</Divider>
                            <Radio.Group
                                options={[
                                    { value: 'date', label: 'Ngày', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'week', label: 'Tuần', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'month', label: 'Tháng', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'year', label: 'Năm', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                ]}
                                size='small'
                                className='d-flex justify-content-center mb-2 w-100'
                                onChange={(e) => setDateType(e.target.value)}
                                value={dateType}
                                optionType="button"
                                buttonStyle="solid"
                            />
                            <Space direction='vertical' style={{ width: '100%' }}>
                                <DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.date[0]} format={customDateFormat} picker={dateType} />
                                <DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.date[1]} format={customDateFormat} picker={dateType} />
                            </Space>
                            <Divider>Điều kiện truy vấn</Divider>
                            <Form.Item label="Khách hàng" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Chọn khách hàng"
                                    onChange={(value) => { setParams({ ...params, khach_hang: value, page: 1 }) }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    popupMatchSelectWidth={listCustomers.length ? 400 : "100%"}
                                    options={listCustomers}
                                />
                            </Form.Item>
                            <Form.Item label="Tên sản phẩm" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    onChange={(value) => {
                                        setParams({ ...params, ten_sp: value, page: 1 });
                                    }}
                                    placeholder="Nhập tên sản phẩm"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={listNameProducts}
                                />
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col span={20} className='custom-col-table'>
                    <Card style={{ height: '100%' }} title="UI kế hoạch xuất kho" extra={
                        <Space>
                            <Upload
                                {...uploadProps}
                                showUploadList={false}
                                name='file'
                                // action={baseURL + "/api/upload-ke-hoach-xuat-kho"}
                                // headers={{
                                //     authorization: 'authorization-text',
                                // }}
                                // onChange={(info) => {
                                //     setLoading(true);
                                //     if (info.file.status === 'error') {
                                //         error();
                                //         setLoading(false)
                                //     } else if (info.file.status === 'done') {
                                //         if (info.file.response.success === true) {
                                //             btn_click();
                                //             success();
                                //             setLoading(false);
                                //         } else {
                                //             btn_click();
                                //             message.error(info.file.response.message);
                                //             setLoading(false);
                                //         }
                                //     }
                                // }}
                            >
                                <Button type="primary" loading={uploading}>Upload excel</Button>
                            </Upload>
                            <Button type="primary" onClick={deleteRecord}>Delete</Button>
                            <Button type="primary" onClick={onEdit}>Edit</Button>
                            <Button type="primary" onClick={onInsert}>Insert</Button>
                        </Space>
                    }>
                        <EditableTable
                            className="table-khxk"
                            bordered
                            columns={customColumns}
                            dataSource={data}
                            scroll={
                                {
                                    x: '150vw',
                                    y: CalculateTableHeight('table-khxk')
                                }
                            }
                            pagination={false}
                            size='small'
                            setDataSource={setData}
                            onEditEnd={() => null} />
                        {/* <Table bordered columns={columns} dataSource={data} pagination={false} size='small'
                        scroll={
                            {
                                x: '150vw',
                                y: '55vh',
                            }
                        } /> */}
                    </Card>
                </Col>
                <Modal title={titleMdlEdit} open={openMdlEdit} onCancel={() => setOpenMdlEdit(false)} footer={null} width={800}>
                    <Form style={{ margin: '0 15px' }}
                        layout="vertical"
                        form={form}
                        onFinish={onFinish}>
                        <Row gutter={[16, 16]}>
                            <Col span={12} className='d-none'>
                                <Form.Item name="id" className='mb-3 d-none'>
                                    <Input></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Khách hàng" name="khach_hang" className='mb-3' rules={[{ required: true }]}>
                                    <Input placeholder='Nhập khách hàng'></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Ngày xuất hàng(YYYY-MM-DD HH:mm:ss)" name="ngay_xuat_hang" className='mb-3' rules={[{ required: true }]}>
                                    <Input placeholder='Nhập ngày xuất hàng'></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Mã hàng" name="product_id" className='mb-3' rules={[{ required: true }]}>
                                    <Input placeholder='Nhập mã hàng'></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Tên sản phẩm" name="ten_san_pham" className='mb-3' rules={[{ required: true }]}>
                                    <Input placeholder='Nhập tên sản phẩm'></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="PO pending" name="po_pending" className='mb-3' rules={[{ required: true }]}>
                                    <Input placeholder='' ></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Số lượng yêu cầu giao" name="sl_yeu_cau_giao" className='mb-3' rules={[{ required: true }]}>
                                    <Input ></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Đơn vị tính" name="dvt" className='mb-3' rules={[{ required: true }]}>
                                    <Input placeholder='Nhập đơn vị tính'></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Tổng kg" name="tong_kg" className='mb-3'>
                                    <Input placeholder='Nhập tổng kg'></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Quy cách" name="quy_cach" className='mb-3' rules={[{ required: true }]}>
                                    <Input placeholder='Nhập quy cách'></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Số lượng thùng chẵn" name="sl_thung_chan" className='mb-3' rules={[{ required: true }]}>
                                    <Input placeholder='Nhập số lượng thùng chẵn'></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Số lượng hàng lẻ" name="sl_hang_le" className='mb-3' rules={[{ required: true }]}>
                                    <Input placeholder='Nhập số lượng hàng lẻ'></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Tồn kho" name="ton_kho" className='mb-3' rules={[{ required: true }]}>
                                    <Input placeholder='Nhập tồn kho'></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Xác nhận sản xuất" name="xac_nhan_sx" className='mb-3' rules={[{ required: true }]}>
                                    <Input placeholder=''></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Số lượng chênh lệch" name="sl_chenh_lech" className='mb-3' rules={[{ required: true }]}>
                                    <Input placeholder='Nhập số lượng chênh lệch'></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Số lượng thực xuất" name="sl_thuc_xuat" className='mb-3' rules={[{ required: true }]}>
                                    <Input placeholder='Số lượng thực xuất'></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Cửa xuất hàng" name="cua_xuat_hang" className='mb-3' rules={[{ required: true }]}>
                                    <Input placeholder=''></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Địa chỉ" name="dia_chi" className='mb-3'>
                                    <Input placeholder=''></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Ghi chú" name="ghi_chu" className='mb-3'>
                                    <Input placeholder=''></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item className='mb-0'>
                            <Button type="primary" htmlType='submit' >Lưu lại</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Row>
        </React.Fragment>
    );
};

export default WarehouseExportPlan;