import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Upload, message, Checkbox, Space, Modal, Spin, Popconfirm, InputNumber, TimePicker } from 'antd';
import { baseURL } from '../../../config';
import React, { useState, useRef, useEffect } from 'react';
import { createLine, deleteLine, exportLine, getLine, updateLine } from '../../../api';
import CalculateTableHeight from '../../../components/calculateTableHeight';
import { createStamps, deleteStamps, getStamps, importStamps, updateStamps } from '../../../api/phase2/master_data/stamp';
import { createShift, deleteShift, getShifts, showShift, updateShift } from '../../../api/phase2/master_data/shift';
import { createShiftBreaks, deleteShiftBreaks, getShiftBreaks, updateShiftBreaks } from '../../../api/phase2/master_data/shift-break';
import { CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const Shifts = () => {
    document.title = "Quản lý ca làm việc";
    const [listCheck, setListCheck] = useState([]);
    const [openMdl, setOpenMdl] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm();
    const [formSearch] = Form.useForm();
    const [params, setParams] = useState({});
    const columns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            render: (_, record, index) => index + 1
        },
        {
            title: 'Tên ca',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (_, record) => (
                <Space wrap>
                    <EditOutlined onClick={() => editRecord(record)} className='edit-btn' />
                    <Popconfirm
                        title="Xoá bản ghi"
                        description={"Bạn có chắc muốn xoá?"}
                        onConfirm={() => deleteRecord(record)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <DeleteOutlined className='delete-btn' />
                    </Popconfirm>
                </Space>
            )
        },
    ];

    const onSearch = (values) => {
        loadListTable(values);
    }

    const [data, setData] = useState([]);
    const loadListTable = async (params) => {
        setLoading(true)
        const res = await getShifts(params);
        setData(res.data.map(e => {
            return { ...e, key: e.id }
        }));
        setLoading(false);
    }
    const btn_click = () => {
        loadListTable({ ...form.getFieldsValue(true) });
    }
    useEffect(() => {
        btn_click();
    }, [])

    const onFinish = async (values) => {
        values.shift_breaks = (values.shift_breaks ?? []).map(e => ({
            ...e,
            start_time: e?.start_time?.format('HH:mm:ss'),
            end_time: e?.end_time?.format('HH:mm:ss'),
            duration_minutes: e?.end_time?.diff(e?.start_time, 'minute')
        }));
        if (isEdit) {
            const res = await updateShift(values?.id, values);
            if (res) {
                form.resetFields();
                setOpenMdl(false);
                loadListTable(params);
            }
        } else {
            const res = await createShift(values);
            console.log(res);
            if (res) {
                form.resetFields();
                setOpenMdl(false);
                loadListTable(params);
            }
        }
    }
    const deleteRecord = async (record) => {
        const res = await deleteShift(record.id);
        btn_click();
    }
    const [loading, setLoading] = useState(false);
    const rowSelection = {
        // type: 'radio',
        onChange: (selectedRowKeys, selectedRows) => {
            setListCheck(selectedRowKeys)
        },
    };
    const insertRecord = () => {
        setIsEdit(false)
        form.resetFields();
        setOpenMdl(true);
    }
    const editRecord = async (record) => {
        var res = await showShift(record.id);
        if (res.success) {
            setIsEdit(true);
            const shift = res.data;
            shift.shift_breaks = (shift.shift_breaks ?? []).map(e => {
                const start_time = (e?.start_time && dayjs(e?.start_time, 'HH:mm:ss').isValid()) ? dayjs(e?.start_time, 'HH:mm:ss') : null;
                const end_time = (e?.end_time && dayjs(e?.end_time, 'HH:mm:ss').isValid()) ? dayjs(e?.end_time, 'HH:mm:ss') : null;
                return {
                    ...e,
                    start_time: start_time,
                    end_time: end_time,
                }
            });
            form.setFieldsValue(shift);
            setOpenMdl(true);
        }
    }
    return <>
        <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
            <Col span={3} className='custom-col'>
                <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => formSearch.submit()}>Truy vấn</Button>
                ]}>
                    <Divider>Tìm kiếm</Divider>
                    <Form
                        style={{ margin: '0 8px' }}
                        layout="vertical"
                        form={formSearch}
                        onFinish={onSearch}>
                        <Form.Item label="Loại ca" className='mb-3' name={'name'}>
                            <Input allowClear placeholder='Loại ca' />
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <Col span={21} className='custom-col-table'>
                <Card style={{ height: '100%' }} title="Quản lý ca làm việc" extra={
                    <Space>
                        <Button type="primary" onClick={insertRecord}>Insert</Button>
                    </Space>
                }>
                    <Table size='small' bordered
                        loading={loading}
                        className='tem-table'
                        scroll={
                            {
                                x: '100%',
                                y: CalculateTableHeight('tem-table')
                            }
                        }
                        pagination={false}
                        columns={columns}
                        dataSource={data}
                    // rowSelection={rowSelection} 
                    />
                </Card>
            </Col>
        </Row>
        <Modal title={isEdit ? 'Cập nhật' : 'Thêm mới'} open={openMdl} onCancel={() => setOpenMdl(false)} footer={null} width={700}>
            <Form style={{ margin: '0 15px' }}
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={{
                    shift_breaks: [{}]
                }}>
                <Form.Item
                    name={'id'}
                    hidden
                ><Input /></Form.Item>
                <Form.Item name={"name"} label="Ca" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.List
                    name="shift_breaks"
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.length ?
                                <Row gutter={8} style={{ marginBottom: 12 }}>
                                    <Col span={1} />
                                    <Col span={7}>
                                        <label class="ant-form-item-required" title="Tên ca">
                                            <span style={{ color: 'red', fontSize: 14, fontFamily: 'SimSun', marginInlineEnd: 4 }}>*</span>Tên ca chi tiết
                                        </label>
                                    </Col>
                                    <Col span={7}>
                                        <label class="ant-form-item-required" title="Bắt đầu ca">
                                            <span style={{ color: 'red', fontSize: 14, fontFamily: 'SimSun', marginInlineEnd: 4 }}>*</span>Thời gian bắt đầu
                                        </label>
                                    </Col>
                                    <Col span={8}>
                                        <label class="ant-form-item-required" title="Kết thúc ca">
                                            <span style={{ color: 'red', fontSize: 14, fontFamily: 'SimSun', marginInlineEnd: 4 }}>*</span>Thời gian kết thúc
                                        </label>
                                    </Col>
                                </Row>
                                :
                                null
                            }
                            {fields.map(({ key, name, ...restField }, index) => (
                                <Row gutter={8} style={{ marginBottom: 12 }}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'id']}
                                        hidden
                                    ><Input /></Form.Item>
                                    <Col span={1} style={{ display: 'flex', alignItems: 'center' }}>{index + 1 + "."}</Col>
                                    <Col span={8}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'type_break']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Hãy nhập thông tin cho trường chi tiết ca"
                                                },
                                            ]}
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Input placeholder="Nhập tên chi tiết ca" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'start_time']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Hãy nhập thông tin cho trường thời gian bắt đầu"
                                                },
                                            ]}
                                            style={{ marginBottom: 0 }}
                                        >
                                            <TimePicker placeholder="Thời gian bắt đầu" style={{ width: '100%' }} needConfirm={false} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'end_time']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Hãy nhập thông tin cho trường thời gian kết thúc"
                                                },
                                            ]}
                                            style={{ marginBottom: 0 }}
                                        >
                                            <TimePicker placeholder="Thời gian kết thúc" style={{ width: '100%' }} needConfirm={false} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1} style={{ display: 'flex', alignItems: 'center' }}>{index !== 0 && <CloseOutlined onClick={() => { remove(name); }} />}</Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Thêm ca chi tiết
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item className='mb-0'>
                    <Button type="primary" htmlType='submit' >Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default Shifts;
