import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Upload, message, Checkbox, Space, Modal, Spin, Popconfirm, InputNumber } from 'antd';
import { api, baseURL, token } from '../../../config';
import React, { useState, useRef, useEffect } from 'react';
import { createProductionJourney, deleteMultipleProductionJourney, deleteProductionJourney, exportProductionJourney, getProductionJourney, updateProductionJourney } from '../../../api/ui/productionJourney';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import './MasterDataStyle.css'
import { getLines } from '../../../api/ui/main';
import { getProduct } from '../../../api/ui/product';

const ProductionJourney = () => {
    document.title = "Quản lý hành trình sản xuất";
    const [openMdl, setOpenMdl] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm();
    const [total, setTotal] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [formSearch] = Form.useForm();
    const [editingRecord, setEditingRecord] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [lines, setLines] = useState([]);
    const [products, setProducts] = useState([]);
    const columns = [
        {
            title: 'Mã sản phẩm',
            dataIndex: 'id',
            align: 'center',
            width: 100,
            fixed: 'left'
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'name',
            align: 'center',
            width: 180,
            fixed: 'left'
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            align: 'center',
            width: 80,
            fixed: 'right',
            render: (_, record) => (
                <Space wrap>
                    <EditOutlined className='edit-btn' onClick={() => editRecord(record)} />
                    <Popconfirm
                        title="Xoá bản ghi"
                        description={"Bạn có chắc muốn xoá?"}
                        onConfirm={() => deleteRecord(record)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <DeleteOutlined className='delete-btn' />
                    </Popconfirm>
                </Space>
            )
        },
    ];
    columns.splice(-1, 0, ...lines.map(e => {
        return {
            title: e.name,
            dataIndex: e.id,
            align: 'center',
            width: 120,
            render: (_, record) => {
                const val = record?.production_journey?.find((item) => Number(item?.line_id) === Number(e?.id));
                if (val) return isNaN(val?.value) ? '' : val.value;
                return '';
            }
        }
    }));
    console.log(columns);
    function btn_click(page = 1, pageSize = 20) {
        setPage(page);
        setPageSize(pageSize);
        loadListTable({ ...formSearch.getFieldsValue(true), page, pageSize });
    }

    useEffect(() => {
        btn_click();
        fetchLine();
        fetchProduct();
    }, [])

    const [data, setData] = useState([]);
    const loadListTable = async (params) => {
        setSelectedRowKeys([]);
        setLoading(true)
        params.withs = 'productionJourney';
        const res = await getProductionJourney(params);
        setData(res.data.data.map(e => {
            return { ...e, key: e.id }
        }));
        setTotal(res.data.total)
        setLoading(false);
    }

    const fetchLine = async () => {
        var res = await getLines();
        setLines(res.data);
    }

    const fetchProduct = async () => {
        var res = await getProduct();
        setProducts(res.data.data.map(e => ({ ...e, value: e.id, label: e.name })));
    }

    const onFinish = async (values) => {
        const payload = {product_id: values?.product_id};
        const line_ids = [];
        await Object.entries(values).map((item) => {
            const [k, v] = item;
            const line_id = Number(k.replace('line_id_', ''));
            if (v && k.startsWith('line_id_') && line_id) {
                line_ids.push({line_id: line_id, value: v});
            }
        })
        payload.line_ids = line_ids;

        if (isEdit) {
            const res = await updateProductionJourney(payload);
            if (res) {
                form.resetFields();
                setOpenMdl(false);
                setEditingRecord();
            }
        } else {
            const res = await createProductionJourney(payload);
            if (res) {
                form.resetFields();
                setOpenMdl(false);
            }
        }
        btn_click();
    }

    const deleteRecord = async (record) => {
        await deleteProductionJourney(record?.id);
        btn_click();
    }
    const deleteMultiple = async () => {
        console.log(selectedRowKeys);
        const res = await deleteMultipleProductionJourney(selectedRowKeys);
        btn_click();
    }
    const editRecord = (record) => {
        setIsEdit(true);
        const fields = { product_id: record?.id };
        record?.production_journey?.map((item) => {
            fields[`line_id_${item?.line_id}`] = isNaN(item?.value) ? null : Number(item.value);
        });
        form.setFieldsValue(fields);
        setEditingRecord(fields);
        setOpenMdl(true);
    }
    const insertRecord = () => {
        setIsEdit(false)
        form.resetFields();
        setOpenMdl(true);
    }
    const [loadingExport, setLoadingExport] = useState(false);
    const [loading, setLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportProductionJourney({ ...formSearch.getFieldsValue(true) });
        if (res.success && res.data) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
        },
    };
    const onCancel = () => {
        form.resetFields();
        setOpenMdl(false);
    }
    return <>
        <Row style={{ padding: '8px', marginRight: 0 }} gutter={[8, 8]}>
            <Col span={3} className='custom-col'>
                <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => formSearch.submit()}>Truy vấn</Button>
                ]}>
                    <Form style={{ margin: '0 8px' }} layout="vertical" form={formSearch} onFinish={() => btn_click()}>
                        <Divider>Điều kiện truy vấn</Divider>
                        <Form.Item label="Mã sản phẩm" className='mb-3' name='id'>
                            <Input allowClear placeholder='Nhập mã sản phẩm' />
                        </Form.Item>
                        <Form.Item label="Tên sản phẩm" className='mb-3' name='name'>
                            <Input allowClear placeholder='Nhập tên sản phẩm' />
                        </Form.Item>
                        <button type='submit' hidden />
                    </Form>
                </Card>
            </Col>
            <Col span={21}>
                <Card style={{ height: '100%' }} title="Quản lý hành trình sản xuất" extra={
                    <Space>
                        <Upload
                            showUploadList={false}
                            name='files'
                            action={api.API_URL + "/product/import"}
                            headers={{
                                authorization: "Bearer " + JSON.parse(localStorage.getItem('authUser'))?.token,
                            }}
                            onChange={(info) => {
                                setLoadingExport(true);
                                if (info.file.status === 'error') {
                                    setLoadingExport(false);
                                    message.error('Upload file lỗi');
                                } else if (info.file.status === 'done') {
                                    if (info.file.response.success === true) {
                                        btn_click();
                                        message.success(info.file.response.message);
                                        setLoadingExport(false);
                                    } else {
                                        btn_click();
                                        message.error(info.file.response.message ?? 'Upload file lỗi');
                                        setLoadingExport(false);
                                    }
                                }
                            }}
                        >
                            <Button style={{ marginLeft: '15px' }} type="primary" loading={loadingExport}>
                                Upload Excel
                            </Button>
                        </Upload>
                        <Button type="primary" onClick={exportFile} loading={exportLoading}>Export Excel</Button>
                        {/* <Button type="primary" onClick={insertRecord}>Insert</Button> */}
                        <Popconfirm
                            title="Xoá bản ghi"
                            description={"Bạn có chắc xoá " + selectedRowKeys.length + " bản ghi đã chọn?"}
                            onConfirm={deleteMultiple}
                            okText="Có"
                            cancelText="Không"
                            placement="bottomRight"
                        >
                            <Button type="primary" disabled={selectedRowKeys.length <= 0} danger>Delete</Button>
                        </Popconfirm>
                    </Space>
                }>
                    <Spin spinning={loading}>
                        <Table size='small' bordered
                            scroll={
                                {
                                    x: 400 + lines.length * 80,
                                    y: '80vh'
                                }
                            }
                            pagination={{
                                current: page,
                                pageSize: pageSize,
                                total: total,
                                onChange: (page, pageSize) => {
                                    btn_click(page, pageSize)
                                }
                            }}
                            columns={columns}
                            dataSource={data}
                            // rowSelection={rowSelection} 
                        />
                    </Spin>
                </Card>
            </Col>
        </Row>
        <Modal title={isEdit ? 'Cập nhật' : 'Thêm mới'} open={openMdl} onCancel={onCancel} footer={null}>
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}>
                <div className="ui-modal-content-scroll">
                    <Form.Item name={'product_id'} className='mb-3' label={'Sản phẩm'} rules={[{ required: true }]}>
                        <Select options={products} showSearch optionFilterProp='label' placeholder="Chọn sản phẩm" />
                    </Form.Item>
                    <Row gutter={5} style={{width: '99%'}}>
                        {lines.map((item, index) => (
                            <Col span={12}>
                                <Form.Item key={index} name={`line_id_${item?.id}`} className='mb-3' label={item?.name}>
                                    <InputNumber style={{ width: '100%' }} placeholder={`Nhập ${item?.name}`} />
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>
                </div>
                <Form.Item className='mb-0 text-end'>
                    <Button type="primary" htmlType='submit'>Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default ProductionJourney;
