import { AppstoreOutlined, ControlOutlined, MailOutlined, SettingOutlined, StarOutlined, ToolOutlined } from '@ant-design/icons';
import { Button, Menu, Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useProfile } from '../components/hooks/UserHooks';

const Footer = () => {
    const { userProfile } = useProfile();
    const items = [
        {
            label: 'Sản xuất',
            icon: <ControlOutlined />,
            key: 'manufacture',
            permission: 'oi-sx'
        },
        {
            label: 'Chất lượng',
            icon: <StarOutlined />,
            key: 'quality',
            permission: 'oi-cl'
        },
        {
            label: 'Thiết bị',
            icon: <ToolOutlined />,
            key: 'equipment',
            permission: 'oi-tb'
        },
        {
            label: 'Kho',
            icon: <AppstoreOutlined />,
            key: 'warehouse',
            permission: 'oi-kho'
        },
    ];
    const location = useLocation();
    const [screen, setScreen] = useState('');
    useEffect(() => {
        setScreen(location.pathname.split('/')[1])
    }, [location]);
    const history = useHistory();
    const onChangeScreen = (key) => {
        const screen = JSON.parse(localStorage.getItem('screen'));
        console.log(screen);
        const params = '';
        history.push('/' + key + (params ?? ''));
    }
    return (
        <React.Fragment>
            <Layout.Footer style={{
                position: "fixed",
                bottom: "0",
                display: 'flex',
                justifyContent: 'space-around',
                padding: 8,
                backgroundColor: '#2462A3',
                color: 'white',
                width: '100%',
                borderRadius: '15px 15px 0 0',
                zIndex: 999
            }}>
                {items.map((e, i) => {
                    if ((userProfile?.permission ?? []).includes('*') || (userProfile?.permission ?? []).includes(e.permission))
                        return (
                            <a key={i} style={{color: 'white', flexDirection: 'column', alignItems: 'center', display: 'flex', cursor: 'pointer', opacity: screen === e.key ? 1 : 0.5, textDecoration: 'none', }}
                                href={'/' + e.key}>
                                {e.icon}
                                {e.label}
                            </a>
                        )
                })}
            </Layout.Footer>
        </React.Fragment>
    );
};

export default Footer;