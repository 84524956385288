import { EyeOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Upload, message, Checkbox, Space, Modal, Spin, Tree, Radio, Calendar, Typography, Image, Empty } from 'antd';
import { Pie } from '@ant-design/charts';
import { baseURL } from '../../../config';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import React, { useState, useRef, useEffect } from 'react';
import { getAllMachine, getCustomers, getDataFilterUI, getLines, getTreeLines } from '../../../api/ui/main';
import { exportKHSX, exportMaintenanceDetail, exportMaintenanceDetailStatus, getListProductPlan } from '../../../api';
import dayjs from "dayjs";
import isoWeek from 'dayjs/plugin/isoWeek';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import "./maintenance.css"
import { getMaintenancePlansDetail, getMaintenanceDetails, getMaintenancePlansList, getMaintenanceCategories, getMaintenanceItems, uploadMaintenanceLogImages, getMaintenanceLogImages, updateMaintenanceLogs, getMaintenanceSchedules, updateMaintenanceSchedules, updateMaintenanceLogImages, createMaintenanceLogImages } from '../../../api/phase2/ui/maintenance';
import { getTreeSelect } from '../../../api/phase2/ui/main';
import CalculateTableHeight from '../../../components/calculateTableHeight';
import { maintenanceQuicker } from '../../../routes/quickerCollection';
dayjs.extend(customParseFormat)
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);

const DetailMaintenance = () => {
    const [machines, setMachines] = useState([]);
    const [treeLines, setTreeLines] = useState([]);
    const [maintenanceCategories, setMaintenanceCategories] = useState([]);
    const [maintenanceItems, setMaintenanceItems] = useState([]);
    const [params, setParams] = useState({ date: [dayjs(), dayjs()] });
    const [form] = Form.useForm();
    const [dateType, setDateType] = useState('date');
    const [selectedMaintenance, setSelectedMaintenance] = useState();
    useEffect(() => {
        fetchTreeLines();
        fetchMachines();
        fetchMaintenanceCategories();
        fetchMaintenanceItems();
    }, []);

    const fetchMachines = async () => {
        const res = await getAllMachine();
        setMachines((res.data ?? []).map(e => ({ ...e, value: e.code, label: e.code })));
    }

    const fetchTreeLines = async () => {
        const res = await getTreeSelect();
        setTreeLines(res.data);
    }

    const fetchMaintenanceCategories = async () => {
        const res = await getMaintenanceCategories();
        setMaintenanceCategories((res.data ?? []).map(e => ({ ...e, value: e.id, label: e.name })));
    }

    const fetchMaintenanceItems = async () => {
        const res = await getMaintenanceItems();
        setMaintenanceItems((res.data ?? []).map(e => ({ ...e, value: e.id, label: e.name })));
    }

    function btn_click() {
        loadListTable(params)
    }

    const [data, setData] = useState([]);
    const loadListTable = async (params) => {
        setLoading(true)
        const res = await getMaintenancePlansList(params);
        console.log(res.data);
        setStatusData(res.data);
        setLoading(false);
        setSelectedMaintenance();
    }
    useEffect(() => {
        btn_click();
    }, [])

    const [messageApi, contextHolder] = message.useMessage();

    const [loading, setLoading] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const exportRecord1 = async () => {
        setLoadingExport(true);
        const res = await exportMaintenanceDetailStatus(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setLoadingExport(false);
    }
    const exportRecord2 = async () => {
        setLoadingExport(true);
        const res = await exportMaintenanceDetail(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setLoadingExport(false);
    }
    const onEdit = () => {
        if (checkedRow) {
            const record = checkData.find(e => e.id === checkedRow.id);
            if (!record) {
                messageApi.error('Không tìm thấy dữ liệu');
                return;
            }
            form.setFieldsValue({
                ...record,
                evidence: (record.images || []).map(e => ({ ...e, url: baseURL + '/' + e.image_path })),
                planning_date: dayjs(record.planning_date, 'DD/MM/YYYY').isValid() ? dayjs(record.planning_date, 'DD/MM/YYYY') : null,
                start_date: dayjs(record.start_date, 'DD/MM/YYYY').isValid() ? dayjs(record.start_date, 'DD/MM/YYYY') : null,
            });
            setIsModalOpen(true);
        }
    }
    const onCancel = () => {
        setIsModalOpen(false);
        setFileList([])
        setCheckedRow(null);
        setSelectedRowKeys([]);
        form.resetFields();
    }
    const onCheck = (checkedKeys, info) => {
        const selectKeys = [];
        if (info.node.type === 'factory') {
            if (info.checked) {
                info.node.children.map(e => {
                    selectKeys.push(e.key?.toString())
                })
            }
        } else {
            info.checkedNodes.map(e => {
                switch (e.type) {
                    case 'line':
                        selectKeys.push(e.key?.toString());
                        break;
                    case 'machine':
                        selectKeys.push(e.line_id?.toString());
                        break;
                    default:
                        break;
                }
            })
        }
        setParams({ ...params, line_id: [...new Set(selectKeys)] })
    };
    const customDateFormat = (value) => {
        switch (dateType) {
            case 'week':
                return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
                break;
            case 'month':
                return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
                break;
            case 'year':
                return (`Năm ${dayjs(value).format('YYYY')}`);
                break;
            default:
                return (dayjs(value).format('DD/MM/YYYY'));
                break;
        }
    }
    useEffect(() => {
        onChangeDate('start_date', params.date[0], dateType);
        onChangeDate('end_date', params.date[1], dateType);
    }, [dateType])
    const onChangeDate = (position, value, dateType) => {
        switch (dateType) {
            case 'week':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('isoWeek'), dayjs(params.date[1]).endOf('isoWeek')] });
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('isoWeek'), dayjs(value).endOf('isoWeek')] });
                }
                break;
            case 'month':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('month'), dayjs(params.date[1]).endOf('month')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('month'), dayjs(value).endOf('month')] });
                }
                break;
            case 'year':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('year'), dayjs(params.date[1]).endOf('year')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('year'), dayjs(value).endOf('year')] });
                }
                break;
            default:
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
                }
                break;
        }
    }

    const renderQuicker = () => {
        const className = (path) => {
            if (window.location.pathname.indexOf(path) > -1) {
                return 'active';
            } else {
                return 'inactive';
            }
        }
        return (
            <ul>
                {maintenanceQuicker.map(e => {
                    return (
                        <li><Link to={e.path} className={'quicker ' + className(e.path)}>{e.name}</Link></li>
                    )
                })}
            </ul>
        )
    }

    const [isModalOpen, setIsModalOpen] = useState(false)

    const statusColumns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            align: 'center',
            render: (_, item, index) => index + 1,
        },
        {
            title: 'Công đoạn',
            dataIndex: 'line_name',
            align: 'center',
        },
        {
            title: 'Tên máy',
            dataIndex: 'machine_code',
            align: 'center',
        },
        {
            title: 'Số hạng mục',
            dataIndex: 'item_number',
            align: 'center',
        },
        {
            title: 'Kế hoạch',
            dataIndex: 'planning_date',
            align: 'center',
        },
        {
            title: 'Ngày thực hiện',
            dataIndex: 'start_date',
            align: 'center',
        },
        {
            title: 'Người thực hiện',
            dataIndex: 'user_name',
            align: 'center',
        },
    ];

    const [statusData, setStatusData] = useState([]);

    const checkColumns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            align: 'center',
            render: (_, item, index) => index + 1,
            width: 40
        },
        {
            title: 'Công đoạn',
            dataIndex: 'line_name',
            align: 'center',
        },
        {
            title: 'Tên máy',
            dataIndex: 'machine_code',
            align: 'center',
        },
        {
            title: 'Nhóm hạng mục',
            dataIndex: 'category_name',
            align: 'center',
        },
        {
            title: 'Tên công việc',
            dataIndex: 'item_name',
            align: 'center',
            width: 150
        },
        {
            title: 'Kế hoạch',
            dataIndex: 'planning_date',
            align: 'center',
        },
        {
            title: 'Ngày thực hiện',
            dataIndex: 'start_date',
            align: 'center',
        },
        {
            title: 'Kết quả',
            dataIndex: 'result',
            align: 'center',
        },
        {
            title: 'Bằng chứng',
            dataIndex: 'evidence',
            align: 'center',
            render: (value, record) => (record.images ?? []).length > 0 ? <Button icon={<EyeOutlined />} onClick={() => onPreviewEvidence(record.images ?? [])}></Button> : null
        },
        {
            title: 'Nhận xét',
            dataIndex: 'remark',
            align: 'center',
        },
    ];

    const [checkData, setCheckedData] = useState([]);

    const [checkedRow, setCheckedRow] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys)
        setCheckedRow(selectedRows[0]);
    }
    const rowSelection = {
        type: 'radio',
        selectedRowKeys,
        columnWidth: 30,
        getCheckboxProps: (record) => ({
            disabled: record.log,
        }),
        onChange: onSelectChange,
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const onFinish = async (values) => {
        // Submit the MaintenanceLog data
        await updateMaintenanceSchedules(checkedRow.id, values)
            .then(response => {
                const maintenanceLogId = response.data.id;
                // Upload images
                const uploadPromises = fileList.map(file => {
                    const formData = new FormData();
                    formData.append('image', file.originFileObj);
                    formData.append('maintenance_log_id', maintenanceLogId);
                    return uploadMaintenanceLogImages(formData)
                        .then(response => {
                            return createMaintenanceLogImages({
                                maintenance_log_id: maintenanceLogId,
                                image_path: response.data.path,
                                result: values.result,
                                comment: values.comment,
                            });
                        });
                });

                Promise.all(uploadPromises)
                    .then(() => {
                        message.success('Maintenance log and images uploaded successfully');
                        onCancel();
                        if (selectedMaintenance) {
                            fetchMaintenanceDetails(selectedMaintenance);
                        }
                    })
                    .catch(() => {
                        message.error('Failed to upload images');
                    });
            })
            .catch((error) => {
                console.log(error);
                message.error('Failed to submit maintenance log');
            });
    }

    const [evidence, setEvidence] = useState([]);
    const [isPreviewEvidence, setIsPreviewEvidence] = useState(false);

    const onPreviewEvidence = (evidence) => {
        setEvidence(evidence || []);
        setIsPreviewEvidence(true);
    }
    const onCancelPreview = () => {
        setEvidence([]);
        setIsPreviewEvidence(false);
    }

    useEffect(() => {
        if (selectedMaintenance) {
            fetchMaintenanceDetails();
        } else {
            setCheckedData([]);
        }
    }, [selectedMaintenance])

    const [loadingDetail, setLoadingDetail] = useState(false);
    const fetchMaintenanceDetails = async () => {
        setLoadingDetail(true);
        const res = await getMaintenancePlansDetail({ ...params, ...selectedMaintenance });
        setCheckedData(res.data);
        setLoadingDetail(false);
    }
    const [fileList, setFileList] = useState([]);
    const handleChange = ({ fileList }) => setFileList(fileList);
    const tableRef = useRef();

    return <>
        {contextHolder}
        <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
            <Col span={4} className='custom-col'>
                <Card style={{ height: '100%' }} bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                ]}>
                    <div className='mb-3'>
                        <Form style={{ margin: '0 15px' }} layout="vertical">
                            <Divider>Công đoạn</Divider>
                            <Form.Item className='mb-3'>
                                {
                                    treeLines.length ?
                                        <Tree
                                            defaultExpandedKeys={[2]}
                                            style={{ maxHeight: 250, overflowY: 'auto' }}
                                            checkable
                                            selectable={false}
                                            onCheck={onCheck}
                                            treeData={treeLines}
                                        />
                                        :
                                        <LoadingOutlined />
                                }
                            </Form.Item>
                            <Divider>Quicker</Divider>
                            {renderQuicker()}
                            <Divider>Thời gian truy vấn</Divider>
                            <Radio.Group
                                size='small'
                                options={[
                                    { value: 'date', label: 'Ngày', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'week', label: 'Tuần', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'month', label: 'Tháng', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'year', label: 'Năm', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                ]}
                                className='d-flex justify-content-center mb-2 w-100'
                                onChange={(e) => setDateType(e.target.value)}
                                value={dateType}
                                optionType="button"
                                buttonStyle="solid"
                            />
                            <Space direction='vertical' style={{ width: '100%' }}>
                                <DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.date[0]} format={customDateFormat} picker={dateType} />
                                <DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.date[1]} format={customDateFormat} picker={dateType} />
                            </Space>
                            <Divider>Điều kiện truy vấn</Divider>
                            <Form.Item label="Ca sản xuất" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Chọn ca sản xuất"
                                    onChange={(value) => setParams({ ...params, ca_sx: value })}
                                    optionFilterProp="label"
                                    options={[
                                        { value: 1, label: 'Ca 1' },
                                        { value: 2, label: 'Ca 2' }
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item label="Nhân viên" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Nhập tên nhân viên"
                                    optionFilterProp="label"
                                    onChange={(value) => setParams({ ...params, user_id: value })}
                                    options={[]}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </Card>
            </Col>
            <Col span={20} className="custom-col-table">
                <div className='d-flex flex-column gap-2 h-100'>
                    <Card style={{ height: '49%' }} title="Chi tiết trạng thái" extra={
                        <Space>
                            <Button type="primary" onClick={exportRecord1} loading={loadingExport}>Excel</Button>
                        </Space>
                    }>
                        <Table className="table-top" locale={{emptyText: <Empty style={{height: '100vh'}} image={Empty.PRESENTED_IMAGE_SIMPLE}/>}} loading={loading} pagination={false} columns={statusColumns} dataSource={statusData} bordered size='small' onRow={(record, index) => {
                            return {
                                onClick: (event) => {
                                    setSelectedMaintenance(record);
                                },
                            }
                        }} scroll={{y: CalculateTableHeight('table-top')}} />
                    </Card>
                    <Card style={{ height: '50%' }} title="Chi tiết kiểm tra" extra={
                        <Space>
                            <Button type="primary" onClick={exportRecord2} loading={loadingExport}>Excel</Button>
                            <Button type="primary" onClick={onEdit} loading={loadingExport}>Nhập kết quả</Button>
                        </Space>
                    }>
                        <Table
                            ref={tableRef}
                            className='custom-table'
                            loading={loadingDetail}
                            pagination={false}
                            columns={checkColumns}
                            rowSelection={rowSelection}
                            onRow={(record, index) => {
                                return {
                                    onClick: (event) => {
                                        console.log(record);
                                        setSelectedRowKeys([record.key]);
                                        setCheckedRow(record);
                                    },
                                }
                            }}
                            dataSource={checkData.map((e, i) => ({ ...e, key: i }))}
                            bordered
                            size='small'
                            scroll={{ y: CalculateTableHeight('custom-table') }}
                        />
                    </Card>
                </div>
            </Col>
        </Row>
        <Modal open={isModalOpen} onCancel={onCancel} title="Cập nhật" okText="Lưu lại" width={600} onOk={() => form.submit()}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <Form.Item name={"machine_code"} label="Tên máy" rules={[{ required: true }]}><Select options={machines} optionFilterProp='label' showSearch /></Form.Item>
                        <Form.Item name={"category_id"} label="Nhóm hạng mục" rules={[{ required: true }]}><Select options={maintenanceCategories} optionFilterProp='label' showSearch /></Form.Item>
                        <Form.Item name={"item_id"} label="Tên công việc" rules={[{ required: true }]}><Select options={maintenanceItems} optionFilterProp='label' showSearch /></Form.Item>
                        <Form.Item name={"remark"} label="Nhận xét"><Input /></Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={"planning_date"} label="Kế hoạch" rules={[{ required: true }]}><DatePicker style={{ width: '100%' }} /></Form.Item>
                        <Form.Item name={"start_date"} label="Ngày thực hiện" rules={[{ required: true }]}><DatePicker style={{ width: '100%' }} /></Form.Item>
                        <Form.Item name={"result"} label="Kết quả"><Input /></Form.Item>
                        <Form.Item
                            rules={[{ required: true }]}
                            name="evidence"
                            label="Bằng chứng"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                        >
                            <Upload
                                listType="picture"
                                fileList={fileList}
                                onChange={handleChange}
                                beforeUpload={() => false} >
                                <Button icon={<UploadOutlined />}>Click to upload</Button>
                            </Upload>
                        </Form.Item>
                        {previewImage && (
                            <Image
                                wrapperStyle={{
                                    display: 'none',
                                }}
                                preview={{
                                    visible: previewOpen,
                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                }}
                                src={previewImage}
                            />
                        )}
                    </Col>
                </Row>
            </Form>
        </Modal>
        <Modal open={isPreviewEvidence} title="Bằng chứng" onCancel={onCancelPreview}>
            <Image.PreviewGroup
                preview={{
                    onChange: (current, prev) =>
                        console.log(`current index: ${current}, prev index: ${prev}`),
                }}
            >
                <Row gutter={[8, 8]} className='w-100'>
                    {(evidence ?? []).map(e => {
                        return (
                            <Col span={12} className='text-center'>
                                <Image
                                    width={200}
                                    src={e.image_path}
                                />
                            </Col>
                        )
                    })}
                </Row>
            </Image.PreviewGroup>
        </Modal>
    </>
}

export default DetailMaintenance;
