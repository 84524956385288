import { Card } from "antd";
import { useEffect, useRef } from "react";
import Highcharts from "highcharts";

const TyLeHoanThanhKeHoach = ({ dataChart }) => {
  const chartRef = useRef(null);
  useEffect(() => {
    const initializeChart = () => {
      const categories = dataChart.map((item) => item.type);
      const seriesData = dataChart.map((item) => ({
        y: item.value,
        color: item.color,
      }));

      chartRef.current = Highcharts.chart("ty-le-hoan-thanh", {
        chart: {
          type: "column",
          height: 252,
        },
        title: false,
        xAxis: {
          categories: categories,
          crosshair: true,
        },
        yAxis: {
          title: null,
          max: 100,
          labels: {
            format: "{value}%",
          },
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
              format: "{point.y}%",
            },
            borderRadius: 0,
          },
        },
        series: [
          {
            name: "Tỷ lệ hoàn thành kế hoạch",
            data: seriesData,
          },
          {
            name: "Mục tiêu",
            type: "line",
            data: Array(categories.length).fill(82), // Giá trị 100 cho tất cả các điểm trên trục X
            color: "orange",
            lineWidth: 3,
            marker: {
              enabled: false,
            },
          },
        ],
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
      });
    };
    if (chartRef.current) {
      chartRef.current.destroy();
    }
    if (dataChart && dataChart.length > 0) {
      initializeChart();
    }
  }, [dataChart]);

  return (
    <Card title="Tỷ lệ hoàn thành kế hoạch" style={{ padding: "0px" }}>
      <div id="ty-le-hoan-thanh" />
    </Card>
  );
};

export default TyLeHoanThanhKeHoach;
