import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Space, Spin, Radio } from 'antd';
import { Pie, Column } from '@ant-design/plots';
import React, { useState, useEffect, useRef } from "react";
import { getMachine, getErrorsMachine, getLines, getMachineOfLine, getCustomers, getProducts, getStaffs, getLoSanXuat, getWarehouses, getCaSanXuats, getMachineError } from '../../../api/ui/main';
import { exportMachineError } from '../../../api/ui/export';
import { baseURL } from '../../../config';
import dayjs from "dayjs";
import { getLineList } from '../../../api/phase2/oi/manufacture';
import OEEChart from './chart/OEEChart';
import ErrorFrequencyChart from './chart/ErrorFrequencyChart';

const { Sider } = Layout;
const { RangePicker } = DatePicker;

const Equipment1 = (props) => {
    document.title = 'UI - Thống kê lỗi'
    const [listMachines, setListMachines] = useState([]);
    const [listIdProducts, setListIdProducts] = useState([]);
    const [listLoSX, setListLoSX] = useState([]);
    const [listStaffs, setListStaffs] = useState([]);
    const [listCustomers, setListCustomers] = useState([]);
    const [listErrorsMachine, setListErrorsMachine] = useState([]);
    const [data, setData] = useState();
    const [dataTable, setDataTable] = useState();
    const [dataPieChart, setDataPieChart] = useState([]);
    const [dataColChart, setDataColChart] = useState([]);
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [option, setOption] = useState([]);
    const [params, setParams] = useState({
        page: 1,
        pageSize: 10,
        date: [dayjs(), dayjs()]
    });
    const [loading, setLoading] = useState(false);
    const oeeChartRef = useRef();
    const errorFrequencyChartRef = useRef();
    const columnTable = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (value, record, index) => ((page - 1) * pageSize) + index + 1,
            align: 'center',
            fixed: 'left',
            width: 60
        },
        {
            title: 'Ngày',
            dataIndex: 'ngay_sx',
            key: 'ngay_sx',
            align: 'center',
            width: 100
        },
        {
            title: 'Ca',
            dataIndex: 'ca_sx',
            key: 'ca_sx',
            align: 'center',
            width: 80
        },
        {
            title: 'Công đoạn',
            dataIndex: 'cong_doan',
            key: 'cong_doan',
            align: 'center',
            width: 150
        },
        {
            title: 'Máy sản xuất',
            dataIndex: 'machine_name',
            key: 'machine_name',
            align: 'center',
            width: 150
        },
        {
            title: 'Lô sx',
            dataIndex: 'lo_sx',
            key: 'lo_sx',
            align: 'center',
            width: 100,
            render: (value, record, index) => value != '' ? value : '-',
        },
        {
            title: 'Thùng/pallet',
            dataIndex: 'lot_id',
            key: 'lot_id',
            align: 'center',
            width: 100,
            render: (value, record, index) => value != '' ? value : '-',
        },
        {
            title: 'Thời gian bắt đầu dừng',
            dataIndex: 'thoi_gian_bat_dau_dung',
            key: 'thoi_gian_bat_dau_dung',
            align: 'center',
            width: 150,
            render: (value, record, index) => value != '' ? value : '-',
        },
        {
            title: 'Thời gian kết thúc dừng',
            dataIndex: 'thoi_gian_ket_thuc_dung',
            key: 'thoi_gian_ket_thuc_dung',
            align: 'center',
            width: 150,
            render: (value, record, index) => value != '' ? value : '-',
        },
        {
            title: 'Thời gian dừng',
            dataIndex: 'thoi_gian_dung',
            key: 'thoi_gian_dung',
            align: 'center',
            width: 100,
            render: (value, record, index) => value != '' ? value : '-',
        },
        {
            title: 'Mã lỗi',
            dataIndex: 'error_id',
            key: 'error_id',
            align: 'center',
            width: 100,
            render: (value, record, index) => value != '' ? value : '-',
        }, {
            title: 'Tên lỗi',
            dataIndex: 'error_name',
            key: 'error_name',
            align: 'center',
            width: 100,
            render: (value, record, index) => value != '' ? value : '-',
        }, {
            title: 'Nguyên nhân lỗi',
            dataIndex: 'nguyen_nhan',
            key: 'nguyen_nhan',
            align: 'center',
            width: 100,
            render: (value, record, index) => value != '' ? value : '-',
        }, {
            title: 'Biện pháp khắc phục lỗi',
            dataIndex: 'bien_phap',
            key: 'bien_phap',
            align: 'center',
            width: 150,
            render: (value, record, index) => value != '' ? value : '-',
        }, {
            title: 'Biện pháp phòng ngừa lỗi',
            dataIndex: 'phong_ngua',
            key: 'phong_ngua',
            align: 'center',
            width: 150,
            render: (value, record, index) => value != '' ? value : '-',
        },
        {
            title: 'Tình trạng',
            dataIndex: 'tinh_trang',
            key: 'tinh_trang',
            render: (record) => {
                return record == 1 ? <Tag style={{ wordWrap: 'break-word' }} color="#87d068">Đã hoàn thành</Tag> : <Tag color="#929292">Chưa xử lý</Tag>
            },
            align: 'center',
            width: 120,
        },
        {
            title: 'Người xử lý',
            dataIndex: 'nguoi_xl',
            key: 'nguoi_xl',
            align: 'center',
            width: 120,
            render: (value, record, index) => value != '' ? value : '-',
        },
    ]
    useEffect(() => {
        (async () => {
            setLoading(true)
            const res2 = await getProducts();
            setListIdProducts((res2.data || []).map(e => {
                return { ...e, label: e.id, value: e.id }
            }));
            const res3 = await getLoSanXuat();
            setListLoSX((res3.data || []).map(e => {
                return { ...e, label: e, value: e }
            }));
            const res4 = await getStaffs();
            setListStaffs((res4.data || []).map(e => {
                return { ...e, label: e.name, value: e.id }
            }))

            const res5 = await getCustomers();
            setListCustomers((res5.data || []).map(e => {
                return { ...e, label: e.name, value: e.id }
            }));
            const res6 = await getErrorsMachine();
            setListErrorsMachine((res6.data || []).map(e => {
                return { ...e, label: e.noi_dung, value: e.id }
            }));
            const res7 = await getMachineOfLine()
            setListMachines((res7.data || []).map(e => {
                return { ...e, label: e.name, value: e.code }
            }));
            setLoading(false)
        })()
        btn_click();
        fetchLine();
    }, [])

    const fetchLine = async () => {
        const lineList = await getLineList({ type: 'sx' });
        setOption(lineList.data || []);
        // setMachineOption([]);
    }

    const loadDataTable = async () => {
        setLoading(true);
        const res = await getMachineError(params)
        if (res?.data) {
            setData(res.data);
            setTotalPage(res.data.totalPage);
            setLoading(false);
        }
    }

    function btn_click(page = 1, pageSize = 10) {
        setPage(page);
        setPageSize(pageSize);
        loadDataTable({ ...params, page: page, pageSize: pageSize });
        oeeChartRef.current?.fetchChartData();
        errorFrequencyChartRef.current?.fetchChartData();
    }
    useEffect(() => {
        if (!data) return;
        setDataTable(data.table);
        if (data?.chart_err) {
            setDataPieChart(Object.keys(data.chart_err).map((item, i) => {
                return {
                    id: data.chart_err[item]['id'],
                    error: data.chart_err[item]['id'] + " " + data.chart_err[item]['name'],
                    value: parseInt(data.chart_err[item]['value']),
                }
            }));
        }

        setDataColChart(data.perfomance);

    }, [data]);

    useEffect(() => {
        console.log(dataColChart);
    }, [dataColChart]);

    const configPieChart = {
        appendPadding: 10,
        animation: false,
        // height:200,
        data: dataPieChart,
        angleField: 'value',
        colorField: 'error',
        radius: 0.8,
        label: {
            type: 'outer',
            content: ({ id, percent }) => `${id}` + ' ' + `${(percent * 100).toFixed(0)}%`,
        },
    };

    const [exportLoading, setExportLoading] = useState(false);
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportMachineError(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    
    const [dateType, setDateType] = useState('date');
    const customDateFormat = (value) => {
        switch (dateType) {
            case 'week':
                return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
                break;
            case 'month':
                return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
                break;
            case 'year':
                return (`Năm ${dayjs(value).format('YYYY')}`);
                break;
            default:
                return (dayjs(value).format('DD/MM/YYYY'));
                break;
        }
    }
    useEffect(() => {
        onChangeDate('start_date', params.date[0], dateType);
        onChangeDate('end_date', params.date[1], dateType);
    }, [dateType])
    const onChangeDate = (position, value, dateType) => {
        switch (dateType) {
            case 'week':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('isoWeek'), dayjs(params.date[1]).endOf('isoWeek')] });
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('isoWeek'), dayjs(value).endOf('isoWeek')] });
                }
                break;
            case 'month':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('month'), dayjs(params.date[1]).endOf('month')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('month'), dayjs(value).endOf('month')] });
                }
                break;
            case 'year':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('year'), dayjs(params.date[1]).endOf('year')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('year'), dayjs(value).endOf('year')] });
                }
                break;
            default:
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
                }
                break;
        }
    }
    return <>
        <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
            <Col span={4} className='custom-col'>
                <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                ]}>
                    <Form style={{ margin: '0 8px' }} layout="vertical">
                        <Divider>Công đoạn</Divider>
                        <Form.Item className='mb-3'>
                            <Select
                                allowClear
                                placeholder="Chọn công đoạn"
                                options={option}
                                onChange={(value) => { setParams({ ...params, line_id: value }); }}
                            />
                        </Form.Item>
                        <Divider>Thời gian truy vấn</Divider>
                        <Radio.Group
                            options={[
                                { value: 'date', label: 'Ngày', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                { value: 'week', label: 'Tuần', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                { value: 'month', label: 'Tháng', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                { value: 'year', label: 'Năm', style: { width: '25%', justifyContent: 'center', display: 'flex' } }
                            ]}
                            className='d-flex justify-content-center mb-2 w-100'
                            onChange={(e) => setDateType(e.target.value)}
                            value={dateType}
                            optionType="button"
                            buttonStyle="solid"
                        />
                        <Space direction='vertical' style={{ width: '100%' }}>
                            <DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.date[0]} format={customDateFormat} picker={dateType} />
                            <DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.date[1]} format={customDateFormat} picker={dateType} />
                        </Space>
                        <Divider>Điều kiện truy vấn</Divider>
                        <Form.Item label="Lô Sản xuất" className='mb-3'>
                            <Select
                                allowClear
                                showSearch
                                placeholder="Nhập lô sản xuất"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={(value) => { setParams({ ...params, lo_sx: value }); }}
                                options={listLoSX}
                            />
                        </Form.Item>
                        <Form.Item label="Tên lỗi" className='mb-3'>
                            <Select
                                showSearch
                                // onChange={(value)=>{
                                //     setSelectedError(value);
                                // }}
                                allowClear
                                placeholder="Chọn lỗi"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={(value) => { setParams({ ...params, machine_error: value }); }}
                                options={listErrorsMachine}
                            />
                        </Form.Item>

                        <Form.Item label="Nhân viên" className='mb-3'>
                            <Select
                                showSearch
                                allowClear
                                onChange={(value) => { setParams({ ...params, user_id: value }); }}
                                placeholder="Chọn nhân viên"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={listStaffs}
                            />
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <Col span={20} className='custom-col'>
                <Spin spinning={loading}>
                    <Row gutter={[8, 8]}>
                        <Col span={24} style={{ height: '50%' }}>
                            <Row gutter={8}>
                                <Col span={9}>
                                    <ErrorFrequencyChart ref={errorFrequencyChartRef} params={params} />
                                </Col>
                                <Col span={15}>
                                    <OEEChart ref={oeeChartRef} params={params} />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{ height: '50%' }}>
                            <Card style={{ height: '100%' }} title="Thống kê chi tiết lỗi" extra={<Button loading={exportLoading} onClick={exportFile} type='primary'>Xuất excel</Button>}>

                                <Table size='small' bordered
                                    className="table-mh40"
                                    locale={{ emptyText: '' }}
                                    pagination={{
                                        current: page,
                                        size: 'default',
                                        total: totalPage,
                                        onChange: (page, pageSize) => {
                                            btn_click(page, pageSize);
                                        }
                                    }}
                                    scroll={
                                        {
                                            x: '150vw',
                                            y: '60vh'
                                        }
                                    }
                                    columns={columnTable}
                                    dataSource={dataTable} />
                            </Card>
                        </Col>
                    </Row>
                </Spin>
            </Col>
        </Row>
    </>
}

export default Equipment1;