import axios from "axios";

//Customer
export async function getCustomer(params) {
    const res = await axios.get('/customer/list', { params });
    return res;
}
export async function createCustomer(params) {
    const res = await axios.post('/customer/create', params);
    return res;
}
export async function updateCustomer(id, params) {
    const res = await axios.patch('/customer/update/' + id, params);
    return res;
}
export async function deleteCustomer(id) {
    const res = await axios.delete('/customer/delete/' + id);
    return res;
}
export async function deleteMultipleCustomer(params) {
    const res = await axios.post('/customers/delete', params);
    return res;
}
export async function exportCustomer(params) {
    const res = await axios.get('/customer/export', { params });
    return res;
}