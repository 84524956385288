import axios from "axios";
const prefix = 'p2/ui/';

export async function getOEEData(params) {
    const res = await axios.get(prefix+'equipment/oee', params);
    return res;
}
export async function getErrorFrequencyData(params) {
    const res = await axios.get(prefix+'equipment/error-frequency', params);
    return res;
}