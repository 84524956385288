import React, { useEffect, useState } from 'react';
import { CloseOutlined, PrinterOutlined, QrcodeOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Divider, Button, Table, Modal, Select, Steps, Input, Radio, Popconfirm, Form, Checkbox, InputNumber } from 'antd';
import { withRouter, Link } from "react-router-dom";
import CardInfo from '../components/CardInfo';
import DataDetail from '../../../components/DataDetail';
import SelectButton from '../../../components/Button/SelectButton';
import '../style.scss';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getMachineParamtersData, updateMachineParamtersData } from '../../../api/oi/equipment';
import dayjs from "dayjs";
import EditableTable from '../../../components/Table/EditableTable';

const Measurement = (props) => {
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([{

    }]);

    const saveData = async (key, value) => {
        const params = {
            machine_id: props?.machine?.code,
            key: key,
            value: value,
            date: dayjs()
        }
        console.log(params);
        var res = await updateMachineParamtersData(params)
    }
    const rowClassName = (record, index) => {
        var status = '';
        if (dayjs().isBefore(dayjs(record.start_time)) || dayjs().isAfter(dayjs(record.end_time))) {
            status = 'table-row-grey';
        }
        return 'editable-row ' + status
    }
    useEffect(() => {
        retriveData();
    }, [props?.machine])

    async function retriveData() {
        if (props?.machine) {
            (async () => {
                console.log('machine_id', props?.machine?.value);
                var res = await getMachineParamtersData({ machine_id: props?.machine?.value });
                if (res.success) {
                    let columns = (res.data.columns ?? []).map(e => {
                        if (!e.is_if) {
                            return {
                                ...e,
                                align: 'center',
                                render: (value, record) => <InputNumber
                                    inputMode='numeric'
                                    defaultValue={value}
                                    onPressEnter={(event) => { saveData(e.key, event.target.value); }}
                                    disabled={dayjs().isBefore(dayjs(record.start_time)) || dayjs().isAfter(dayjs(record.end_time))}
                                />
                            }
                        }
                        return {
                            ...e,
                            align: 'center',
                            // editable: !e?.is_if && !dayjs().isAfter(dayjs(e.end_time))
                            render: (value) => <span>{!isNaN(value) ? (Math.round(value * 100) / 100) : value}</span>
                        }
                    })
                    setColumns(
                        [{
                            title: 'STT',
                            dataIndex: 'stt',
                            key: 'stt',
                            align: 'center',
                            render: (value, record, index) => index + 1
                        },
                        {
                            title: 'Mốc thời gian',
                            dataIndex: 'ca_sx',
                            key: 'ca_sx',
                            align: 'center',
                            render: (value, record, index) => {
                                return dayjs(record?.start_time).format('H') + 'h-' + dayjs(record?.end_time).format('H') + 'h'
                            }
                        },
                        ...columns]
                    );
                    setData(res.data.data ?? []);
                }

            })()
        }
    }

    return (
        <React.Fragment>
            <Row className='mt-3'>
                {props?.machine ? <Col span={24}>
                    <Table
                        scroll={{
                            x: 200,
                            y: 350,
                        }}
                        pagination={false}
                        bordered
                        rowClassName={rowClassName}
                        columns={columns}
                        dataSource={data}
                    />
                    {/* <EditableTable
                    columns={columns}
                    data={data}
                    setData={setData}
                    onEditEnd={saveData}
                    rowClassName={rowClassName}
                    /> */}
                </Col>
                    :
                    "Công đoạn không có máy!"
                }
            </Row>
        </React.Fragment>
    );
};

export default withRouter(Measurement);