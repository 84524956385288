import { Card } from "antd";
import Highcharts from "highcharts";
import { useEffect } from "react";

const ErrorRatio = ({ data = [] }) => {
    useEffect(() => {
        // Sắp xếp dữ liệu theo giá trị tỷ lệ lỗi
        // data.sort((a, b) => b.value - a.value);

        // const errors = [...new Set(data.map(item => item.name))];
        // const values = data.map(item => Number((item.value).toFixed(2)));
        // const frequencies = data.map(item => item.frequency);

        const options = {
            chart: {
                type: 'line',
                height: 200
            },
            title: false,
            xAxis: {
                categories: data?.categories,
                crosshair: true
            },
            yAxis: [{
                title: false,
                labels: {
                    format: '{value}%',
                },
                opposite: false,
            }, {
                title: false,
                labels: {
                    format: '{value}%',
                },
                opposite: true,
            }],
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true,
                        // format: '{point.y}%',
                    },
                    lineWidth: 4,
                    marker: {
                        radius: 0,
                    },
                }
            },
            tooltip: {
                shared: true
            },
            series: [
                {
                    name: 'Sản xuất',
                    data: data?.series?.sx ?? [],
                    color: 'red',
                    tooltip: {
                        valueSuffix: '%'
                    }
                },
                {
                    name: 'Chất lượng',
                    data: data?.series?.qc ?? [],
                    yAxis: 1,
                    color: 'rgb(44, 175, 254)',
                    tooltip: {
                        valueSuffix: '%'
                    }
                }
            ],
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
            },
            exporting: false,
            credits: { enabled: false }
        };

        Highcharts.chart("error-ratio-chart", options);
    }, [data]);
    return (
        <Card title="Tỷ lệ lỗi" style={{ height: '100%', padding: '0px' }} styles={{ body: { padding: 12 } }}>
            <div id={"error-ratio-chart"} />
        </Card>
    )
}

export default ErrorRatio;