import axios from "axios";

//UserInfo
export async function getUserInfo(params) {
    const res = await axios.get('/user-info/list', { params });
    return res;
}
export async function createUserInfo(params) {
    const res = await axios.post('/user-info/create', params);
    return res;
}
export async function updateUserInfo(id, params) {
    const res = await axios.patch('/user-info/update/' + id, params);
    return res;
}
export async function deleteUserInfo(id) {
    const res = await axios.delete('/user-info/delete/' + id);
    return res;
}
export async function deleteMultipleUserInfo(params) {
    const res = await axios.post('/user-infos/delete', params);
    return res;
}
export async function exportUserInfo(params) {
    const res = await axios.get('/user-info/export', { params });
    return res;
}