import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import './assets/scss/themes.scss';
import Route from './routes';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { message } from 'antd';

function App() {
  const location = useLocation();
  var timeout;
  useEffect(()=>{
    clearTimeout(timeout);
    timeout = setTimeout(()=>{
      if(location.pathname.indexOf('dashboard') > -1){
        console.log('reload');
        window.location.reload(true);
      }
      
    }, 60 * 60 * 1000);
    return ()=> clearTimeout(timeout);
  }, [location]);

  message.config({
    duration: 3,
    maxCount: 3,
  });
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
