
import { Column } from "@antv/g2plot";
import { Card } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Highcharts from "highcharts";
import { getOEEData } from "../../../../api/phase2/ui/equipment";

const OEEChart = forwardRef((params, ref) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await getOEEData(params);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };
    useImperativeHandle(ref, () => ({
        fetchChartData() {
            fetchData(params);
        },
    }));
    useEffect(() => {
        if (data.length === 0) {
            return;
        }
        const lines = [...new Set(data.map(item => item.line))];
        const A = data.map(item => item.A);
        const P = data.map(item => item.P);
        const Q = data.map(item => item.Q);
        const OEE = data.map(item => item.OEE);
        const series = [
            {
                name: "A",
                data: A,
            },
            {
                name: "P",
                data: P,
            },
            {
                name: "Q",
                data: Q,
            },
            {
                name: "OEE",
                data: OEE,
            },
        ];
        const options = {
            chart: {
                height: 250,
                type: 'column',
            },

            title: false,

            xAxis: {
                categories: lines,
                crosshair: true,
                accessibility: {
                    description: 'Máy'
                }
            },
            plotOptions: {
                column: {
                    depth: 40,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}%'
                    }
                }
            },

            yAxis: {
                title: null,
            },
            series: series,
            exporting: false,
            credits: { enabled: false }
        };

        Highcharts.chart("oee-chart", options);
    }, [data]);

    return <Card title="OEE"
        loading={loading}
        styles={{ body: { padding: '0px', height: '100%' } }}
        style={
            { padding: '0px', height: '100%' }
        }>
        <div id="oee-chart" />
    </Card>
});

export default OEEChart;