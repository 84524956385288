import {
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
  PlusOutlined,
  FileExcelOutlined,
  PrinterOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  DatePicker,
  Col,
  Row,
  Card,
  Table,
  Tag,
  Layout,
  Divider,
  Button,
  Form,
  Input,
  theme,
  Select,
  AutoComplete,
  Upload,
  message,
  Checkbox,
  Space,
  Modal,
  Spin,
  Tree,
  Radio,
} from "antd";
import { Pie } from "@ant-design/charts";
import { baseURL } from "../../../config";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import React, { useState, useRef, useEffect } from "react";
import {
  getLines,
  getMachineOfLine,
  getCustomers,
  getProducts,
  getStaffs,
  getLoSanXuat,
  getWarehouses,
  getCaSanXuats,
  getDataFilterUI,
  getTreeLines,
} from "../../../api/ui/main";
import {
  deleteRecordProductPlan,
  exportKHSX,
  getListProductPlan,
  getWarehouseExportPlan,
  storeProductPlan,
  updateProductPlan,
} from "../../../api";
import dayjs from "dayjs";
import { getTreeSelect } from "../../../api/phase2/ui/main";
import CalculateTableHeight from "../../../components/calculateTableHeight";
import { manufatureQuicker } from "../../../routes/quickerCollection";

const { Sider } = Layout;
const { RangePicker } = DatePicker;

const LenhGiaoHang = () => {
  const [listLines, setListLines] = useState([]);
  const [listNameProducts, setListNameProducts] = useState([]);
  const [listLoSX, setListLoSX] = useState([]);
  const [listCustomers, setListCustomers] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [listCheck, setListCheck] = useState([]);
  const [openMdlEdit, setOpenMdlEdit] = useState(false);
  const [titleMdlEdit, setTitleMdlEdit] = useState("Cập nhật");
  const [form] = Form.useForm();
  const [params, setParams] = useState({ date: [dayjs(), dayjs()] });
  const [dateType, setDateType] = useState("date");
  const onChangeChecbox = (e) => {
    if (e.target.checked) {
      if (!listCheck.includes(e.target.value)) {
        setListCheck((oldArray) => [...oldArray, e.target.value]);
      }
    } else {
      if (listCheck.includes(e.target.value)) {
        setListCheck((oldArray) =>
          oldArray.filter((datainput) => datainput !== e.target.value)
        );
      }
    }
  };
  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (value, record, index) => (page - 1) * pageSize + index + 1,
      align: "center",
      fixed: "left",
      width: "70px",
    },
    {
      title: "Ngày giao hàng",
      dataIndex: "ngay_xuat_hang",
      align: "center",
      width: "120px",
      render: (value) => (value ? dayjs(value).format("DD/MM/YYYY") : ""),
    },
    {
      title: "Số lượng nợ đơn hàng",
      align: "center",
      children: [
        {
          title: "Mã hàng hóa",
          dataIndex: "product_id",
          align: "center",
          width: "100px",
        },
        {
          title: "Tên hàng hóa",
          dataIndex: "ten_san_pham",
          align: "center",
          width: "200px",
        },
        {
          title: "SL yêu cầu giao",
          dataIndex: "sl_yeu_cau_giao",
          align: "center",
          width: "200px",
        },
      ],
    },
    {
      title: "ĐVT",
      dataIndex: "dvt",
      align: "center",
      width: "100px",
    },
    {
      title: "Tổng KG",
      dataIndex: "tong_kg",
      align: "center",
      width: "100px",
    },
    {
      title: "Xác nhận SX",
      dataIndex: "xac_nhan_sx",
      align: "center",
      width: "100px",
    },
    {
      title: "SL thực xuất",
      dataIndex: "sl_thuc_xuat",
      align: "center",
      width: "100px",
    },
    {
      title: "Quy cách (số thùng/bó)",
      dataIndex: "quy_cach",
      align: "center",
      width: "100px",
    },
    {
      title: "Cửa giao hàng",
      dataIndex: "cua_xuat_hang",
      align: "left",
      width: "300px",
    },
    {
      title: "Ghi chú",
      dataIndex: "ghi_chu",
      align: "left",
      width: "500px",
    },
  ];
  useEffect(() => {
    (async () => {
      const res1 = await getTreeSelect();
      setListLines(res1.data);
      const res5 = await getCustomers();
      setListCustomers(
        res5.data.map((e) => {
          return { ...e, label: e.name, value: e.id };
        })
      );
    })();
  }, []);

  function btn_click(page = 1, pageSize = 20) {
    setPage(page);
    setPageSize(pageSize);
    loadListTable({ ...params, page, pageSize });
  }

  useEffect(() => {
    (async () => {
      var res = await getDataFilterUI({ khach_hang: params.khach_hang });
      if (res.success) {
        setListNameProducts(
          res.data.product.map((e) => {
            return { ...e, label: e.name, value: e.id };
          })
        );
        setListLoSX(
          Object.values(res.data.lo_sx).map((e) => {
            return { label: e, value: e };
          })
        );
      }
    })();
  }, [params.khach_hang]);

  const [data, setData] = useState([]);
  const loadListTable = async (params) => {
    setLoading(true);
    if (params?.date && params?.date?.length === 2) {
      if (params?.date[0])
        params.start_date = dayjs(params.date[0]).format("YYYY-MM-DD");
      if (params?.date[1])
        params.end_date = dayjs(params.date[1]).format("YYYY-MM-DD");
    }
    const res = await getWarehouseExportPlan(params);
    if (res.success) {
      if (res.data?.paginate) {
        setPage(res.data.paginate.page);
        setPageSize(res.data.paginate.page_size);
        setTotalPage(res.data.paginate.total_items);
      }
      setData(res.data?.data || []);
    }
    setLoading(false);
  };
  useEffect(() => {
    (async () => {
      loadListTable(params);
    })();
  }, []);

  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Upload file thành công",
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Upload file lỗi",
    });
  };

  const onFinish = async (values) => {
    if (values.id) {
      const res = await updateProductPlan(values);
    } else {
      const res = await storeProductPlan(values);
    }
    setOpenMdlEdit(false);
    loadListTable(params);
  };

  const deleteRecord = async () => {
    if (listCheck.length > 0) {
      const res = await deleteRecordProductPlan(listCheck);
      setListCheck([]);
      loadListTable(params);
    } else {
      message.info("Chưa chọn bản ghi cần xóa");
    }
  };
  const editRecord = () => {
    setTitleMdlEdit("Cập nhật");
    if (listCheck.length > 1) {
      message.info("Chỉ chọn 1 bản ghi để chỉnh sửa");
    } else if (listCheck.length == 0) {
      message.info("Chưa chọn bản ghi cần chỉnh sửa");
    } else {
      const result = data.find((record) => record.id === listCheck[0]);
      form.setFieldsValue({
        id: listCheck[0],
        thu_tu_uu_tien: result.thu_tu_uu_tien,
        thoi_gian_bat_dau: result.thoi_gian_bat_dau,
        thoi_gian_ket_thuc: result.thoi_gian_ket_thuc,
        cong_doan_sx: result.cong_doan_sx,
        product_id: result.product_id,
        khach_hang: result.khach_hang,
        ca_sx: result.ca_sx,
        lo_sx: result.lo_sx,
        so_bat: result.so_bat,
        sl_nvl: result.sl_nvl,
        sl_thanh_pham: result.sl_thanh_pham,
        UPH: result.UPH,
        nhan_luc: result.nhan_luc,
      });
      setOpenMdlEdit(true);
    }
  };
  const insertRecord = () => {
    setTitleMdlEdit("Thêm mới");
    form.resetFields();
    setOpenMdlEdit(true);
  };
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const exportRecord = async () => {
    setLoadingExport(true);
    const res = await exportKHSX(params);
    if (res.success) {
      window.location.href = baseURL + res.data;
    }
    setLoadingExport(false);
  };
  const onCheck = (checkedKeys, info) => {
    const selectKeys = [];
    if (info.node.type === "factory") {
      if (info.checked) {
        info.node.children.map((e) => {
          selectKeys.push(e.key?.toString());
        });
      }
    } else {
      info.checkedNodes.map((e) => {
        switch (e.type) {
          case "line":
            selectKeys.push(e.key?.toString());
            break;
          case "machine":
            selectKeys.push(e.line_id?.toString());
            break;
          default:
            break;
        }
      });
    }
    setParams({ ...params, line_id: [...new Set(selectKeys)] });
  };
  const customDateFormat = (value) => {
    switch (dateType) {
      case "week":
        return `Tuần ${dayjs(value).format("WW - GGGG")}`;
        break;
      case "month":
        return `Tháng ${dayjs(value).format("MM")} - ${dayjs(value).format(
          "YYYY"
        )}`;
        break;
      case "year":
        return `Năm ${dayjs(value).format("YYYY")}`;
        break;
      default:
        return dayjs(value).format("DD/MM/YYYY");
        break;
    }
  };
  useEffect(() => {
    onChangeDate("start_date", params.date[0], dateType);
    onChangeDate("end_date", params.date[1], dateType);
  }, [dateType]);
  const onChangeDate = (position, value, dateType) => {
    switch (dateType) {
      case "week":
        if (position === "start_date") {
          setParams({
            ...params,
            date: [
              dayjs(value).startOf("isoWeek"),
              dayjs(params.date[1]).endOf("isoWeek"),
            ],
          });
        } else {
          setParams({
            ...params,
            date: [
              dayjs(params.date[0]).startOf("isoWeek"),
              dayjs(value).endOf("isoWeek"),
            ],
          });
        }
        break;
      case "month":
        if (position === "start_date") {
          setParams({
            ...params,
            date: [
              dayjs(value).startOf("month"),
              dayjs(params.date[1]).endOf("month"),
            ],
          });
        } else {
          setParams({
            ...params,
            date: [
              dayjs(params.date[0]).startOf("month"),
              dayjs(value).endOf("month"),
            ],
          });
        }
        break;
      case "year":
        if (position === "start_date") {
          setParams({
            ...params,
            date: [
              dayjs(value).startOf("year"),
              dayjs(params.date[1]).endOf("year"),
            ],
          });
        } else {
          setParams({
            ...params,
            date: [
              dayjs(params.date[0]).startOf("year"),
              dayjs(value).endOf("year"),
            ],
          });
        }
        break;
      default:
        if (position === "start_date") {
          setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] });
        } else {
          setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
        }
        break;
    }
  };
  const renderQuicker = () => {
    console.log(window.location.pathname);
    const className = (path) => {
      if (window.location.pathname.indexOf(path) > -1) {
        return "active";
      } else {
        return "inactive";
      }
    };
    return (
      <ul>
        {manufatureQuicker.map((e) => {
          return (
            <li>
              <Link to={e.path} className={"quicker " + className(e.path)}>
                {e.name}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  };
  return (
    <>
      {contextHolder}
      <Row style={{ padding: "8px" }} gutter={[8, 8]} className="custom-row">
        <Col span={4} className="custom-col">
          <Card
            bodyStyle={{ padding: 0 }}
            className="custom-ant-card"
            actions={[
              <Button
                type="primary"
                style={{ width: "90%" }}
                onClick={() => btn_click()}
              >
                Truy vấn
              </Button>,
            ]}
          >
            <Form style={{ margin: "0 8px" }} layout="vertical">
              <Divider>Quicker</Divider>
              {renderQuicker()}
              <Divider>Thời gian truy vấn</Divider>
              <Radio.Group
                options={[
                  {
                    value: "date",
                    label: "Ngày",
                    style: {
                      width: "25%",
                      justifyContent: "center",
                      display: "flex",
                    },
                  },
                  {
                    value: "week",
                    label: "Tuần",
                    style: {
                      width: "25%",
                      justifyContent: "center",
                      display: "flex",
                    },
                  },
                  {
                    value: "month",
                    label: "Tháng",
                    style: {
                      width: "25%",
                      justifyContent: "center",
                      display: "flex",
                    },
                  },
                  {
                    value: "year",
                    label: "Năm",
                    style: {
                      width: "25%",
                      justifyContent: "center",
                      display: "flex",
                    },
                  },
                ]}
                className="d-flex justify-content-center mb-2 w-100"
                onChange={(e) => setDateType(e.target.value)}
                value={dateType}
                optionType="button"
                buttonStyle="solid"
              />
              <Space direction="vertical" style={{ width: "100%" }}>
                <DatePicker
                  allowClear={false}
                  placeholder="Bắt đầu"
                  style={{ width: "100%" }}
                  onChange={(value) =>
                    onChangeDate("start_date", value, dateType)
                  }
                  value={params.date[0]}
                  format={customDateFormat}
                  picker={dateType}
                />
                <DatePicker
                  allowClear={false}
                  placeholder="Kết thúc"
                  style={{ width: "100%" }}
                  onChange={(value) =>
                    onChangeDate("end_date", value, dateType)
                  }
                  value={params.date[1]}
                  format={customDateFormat}
                  picker={dateType}
                />
              </Space>
              <Divider>Điều kiện truy vấn</Divider>
              <Form.Item label="Khách hàng" className="mb-3">
                <Select
                  allowClear
                  showSearch
                  placeholder="Chọn khách hàng"
                  onChange={(value) =>
                    setParams({ ...params, khach_hang: value })
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  popupMatchSelectWidth={listCustomers.length ? 400 : "100%"}
                  options={listCustomers}
                />
              </Form.Item>
              <Form.Item label="Tên sản phẩm" className="mb-3">
                <Select
                  allowClear
                  showSearch
                  onChange={(value) => {
                    setParams({ ...params, ten_sp: value });
                  }}
                  placeholder="Nhập tên sản phẩm"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={listNameProducts}
                />
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={20} className="custom-col-table">
          <Card
            style={{ height: "100%" }}
            title="Lệnh giao hàng"
            extra={
              <Space>
                <Upload
                  showUploadList={false}
                  name="file"
                  action={baseURL + "/api/p2/ui/warehouse-export-plans-import"}
                  headers={{
                    authorization: `Bearer ${
                      JSON.parse(localStorage.getItem("authUser"))?.token
                    }`,
                  }}
                  onChange={(info) => {
                    setLoadingUpload(true);
                    if (info.file.status === "error") {
                      setLoadingUpload(false);
                      message.error(
                        info.file?.response?.message ??
                          `${info.file.name} - Thực hiện thất bại`
                      );
                    } else if (info.file.status === "done") {
                      if (info.file.response.success === true) {
                        loadListTable(params);
                        success();
                        setLoadingUpload(false);
                      } else {
                        loadListTable(params);
                        message.error(info.file.response.message);
                        setLoadingUpload(false);
                      }
                    }
                  }}
                >
                  <Button
                    style={{ marginLeft: "15px" }}
                    type="primary"
                    loading={loadingUpload}
                  >
                    Upload Excel
                  </Button>
                </Upload>
              </Space>
            }
          >
            <Table
              size="small"
              bordered
              locale={{ emptyText: "" }}
              className="table-lgh"
              loading={loading}
              pagination={{
                current: page,
                pageSize: pageSize,
                size: "small",
                total: totalPage,
                onChange: (page, pageSize) => {
                  btn_click(page, pageSize);
                },
              }}
              scroll={{
                x: "1440px",
                y: CalculateTableHeight("table-lgh"),
              }}
              columns={columns}
              dataSource={data}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title={titleMdlEdit}
        open={openMdlEdit}
        onCancel={() => setOpenMdlEdit(false)}
        footer={null}
        width={800}
      >
        <Form
          style={{ margin: "0 15px" }}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={[16, 16]}>
            <Col span={12} className="d-none">
              <Form.Item name="id" className="mb-3 d-none">
                <Input></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Thứ tự ưu tiên"
                name="thu_tu_uu_tien"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Input placeholder="Nhập thứ tự ưu tiên"></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Ngày sản xuất (YYYY-MM-DD)"
                name="ngay_sx"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Input placeholder="Nhập ngày sản xuất"></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Ngày đặt hàng (YYYY-MM-DD)"
                name="ngay_dat_hang"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Input placeholder="Nhập ngày đặt hàng"></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Ngày giao hàng (YYYY-MM-DD)"
                name="ngay_giao_hang"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Input placeholder="Nhập ngày giao hàng"></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Thời gian bắt đầu (YYYY-MM-DD HH:mm:ss)"
                name="thoi_gian_bat_dau"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Input placeholder=""></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Thời gian kết thúc (YYYY-MM-DD HH:mm:ss)"
                name="thoi_gian_ket_thuc"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Input></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Máy"
                name="machine_id"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Input placeholder="Nhập tên máy"></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Công đoạn"
                name="cong_doan_sx"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Input placeholder="Nhập công đoạn"></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Mã sản phẩm"
                name="product_id"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Input placeholder="Nhập mã sản phẩm"></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Khách hàng"
                name="khach_hang"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Input placeholder="Nhập khách hàng"></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Ca sản xuất"
                name="ca_sx"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Input placeholder="Nhập ca sản xuất"></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Lô sản xuất"
                name="lo_sx"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Input placeholder="Nhập lô sản xuất"></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Số bát"
                name="so_bat"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Input placeholder="Nhập số bát"></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Số lượng nguyên liệu đầu vào (tờ)"
                name="sl_nvl"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Input placeholder="Nhập số lượng nguyên liệu đầu vào (tờ)"></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Kế hoạch SL thành phẩm (tờ)"
                name="sl_thanh_pham"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Input placeholder="Nhập kế hoạch SL thành phẩm (tờ)"></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="UPH"
                name="UPH"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Input placeholder="UPH"></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Nhân lực"
                name="nhan_luc"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Input placeholder="Nhân lực"></Input>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="mb-0">
            <Button type="primary" htmlType="submit">
              Lưu lại
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default LenhGiaoHang;
