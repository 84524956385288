import { Area, Column, Pie, Line, DualAxes } from '@ant-design/charts';
import { ApartmentOutlined, AppstoreOutlined, BarChartOutlined, CheckCircleOutlined, DeploymentUnitOutlined, DownloadOutlined, GlobalOutlined, GroupOutlined, InboxOutlined, InteractionOutlined, MailOutlined, PhoneOutlined, PlusOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Divider,
    Drawer,
    Form,
    Input,
    Layout,
    Modal,
    Radio,
    Row,
    Select,
    Space,
    Spin,
    Table,
    Tag,
    Upload
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
    exportKPI,
    getKpi
} from '../../../api/ui/main';
import { baseURL } from '../../../config';
import "../style.scss";
import Highcharts from "highcharts";

const { Sider } = Layout;
const { RangePicker } = DatePicker;

const VOC = (props) => {
    document.title = 'UI - Dashboard';
    const [form] = Form.useForm();
    const [listLines, setListLines] = useState([]);
    const [listMachines, setListMachines] = useState([]);
    const [listIdProducts, setListIdProducts] = useState([]);
    const [listCustomers, setListCustomers] = useState([]);
    const [selectedLine, setSelectedLine] = useState();
    const [selectedIdProduct, setSelectedIdProduct] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [params, setParams] = useState({
        start_date: dayjs().subtract(6, 'days'),
        end_date: dayjs()
    });


    const [dataChartTiLeHoanThanhKHSX, setDataChartTiLeHoanThanhKHSX] = useState([]);
    const [dataChartTiLeNgOQC, setDataChartTiLeNgOQC] = useState([]);
    const [dataChartTiLeVanHanhThietBi, setDataChartTiLeVanHanhThietBi] = useState([]);
    const [dataChartTiLeDatThang, setDataChartTiLeDatThang] = useState([]);
    const [dataChartNgayTon, setDataChartNgayTon] = useState([]);
    const [dataChartTiLeGiaoHangDungHan, setDataChartTiLeGiaoHangDungHan] = useState([]);
    const [dataChartTiLeLoiCongDoan, setDataChartTiLeLoiCongDoan] = useState([]);
    const [dataChartLeadTime, setDataChartLeadTime] = useState([]);
    const [drawerVisible, setDrawerVisible] = useState(false);

    const [dataTable, setDataTable] = useState([]);
    const [columnsDate, setColumnsDate] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
    });
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const count = params.end_date.diff(params.start_date, 'day');
        const colDate = [];
        if (count > 0) {
            for (let i = 0; i <= count; i++) {
                colDate.push({
                    title: params.start_date.add(i, 'day').format('DD-MM-YYYY'),
                    dataIndex: params.start_date.add(i, 'day').format('YYYY-MM-DD'),
                    key: params.start_date.add(i, 'day').format('YYYY-MM-DD'),
                    align: 'center'
                })
            }
        }
        setColumnsDate(colDate);
    }, [dataTable]);

    const columnTop = [
        { title: '#', dataIndex: 'type', width: '70px' },
        { title: 'W24', dataIndex: 'w24', width: '50px' },
        { title: 'W25', dataIndex: 'w25', width: '50px' },
        { title: 'W26', dataIndex: 'w26', width: '50px' },
        { title: 'W27', dataIndex: 'w27', width: '50px' },
        { title: 'W28', dataIndex: 'w28', width: '50px' },
    ]

    const dataTopFaker = [
        { type: 'Rate(A)', w24: 100, w25: 100, w26: 100, w27: 100, w28: 100 },
        { type: 'Đã nhận', w24: 100, w25: 100, w26: 100, w27: 100, w28: 100 },
        { type: 'Hoàn thành', w24: 100, w25: 100, w26: 100, w27: 100, w28: 100 },
        { type: 'Đang thực hiện', w24: 100, w25: 100, w26: 100, w27: 100, w28: 100 },
    ]

    const columnTable = [
        { title: 'STT', dataIndex: 'stt', key: 'stt', width: '50px', render: (_, record, index) => index + 1 },
        { title: 'Yêu cầu', dataIndex: 'reason', key: 'reason', width: '350px' },
        {
            title: 'Người yêu cầu',
            dataIndex: 'customer',
            key: 'customer',
            width: '200px',
            render: text => <a onClick={() => showDrawer(text)}>{text}</a>
        },
        {
            title: 'Phân loại',
            dataIndex: 'category',
            key: 'category',
            width: '200px',
            render: category => {
                let color = '#50950c';
                if (category === 'Có tính phí') color = '#1677ff';//#21852c
                else if (category === 'Không tính phí') color = '#df851b';
                return (
                    <Tag color={color} key={category}>
                        {category.toUpperCase()}
                    </Tag>
                );
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            width: '100px',
            render: status => (
                <Tag color={status === 'Đã gửi' ? '#1677ff' : '#50950c'}>
                    {status.toUpperCase()}
                </Tag>
            )
        },
        { title: 'Ngày bắt đầu', dataIndex: 'startDate', key: 'startDate', width: '100px' },
        { title: 'Ngày kết thúc', dataIndex: 'endDate', key: 'endDate', width: '200px' },
        // { title: 'Người yêu cầu', dataIndex: 'requester', key: 'requester', width: '200px' },
        { title: 'Thời gian yêu cầu', dataIndex: 'requestTime', key: 'requestTime', width: '200px' },
        { title: 'Thời gian yêu cầu hoàn thành', dataIndex: 'completionTime', key: 'completionTime', width: '200px' },
        { title: 'Thời gian cập nhật', dataIndex: 'updateTime', key: 'updateTime', width: '200px' },
        { title: 'Chi phí dự kiến', dataIndex: 'estimatedCost', key: 'estimatedCost', width: '200px' },
        { title: 'Người phụ trách sửa', dataIndex: 'repairPerson', key: 'repairPerson', width: '200px' },
        { title: 'Thời gian sửa', dataIndex: 'repairTime', key: 'repairTime', width: '200px' }
    ];
    const data = [
        { week: 'W24', value: 80, type: 'Total' },
        { week: 'W24', value: 95, type: 'Completed', rate: 101.17 },
        { week: 'W24', value: 85, type: 'Pending' },
        { week: 'W25', value: 70, type: 'Total' },
        { week: 'W25', value: 95, type: 'Completed', rate: 101.88 },
        { week: 'W25', value: 80, type: 'Pending' },
        { week: 'W26', value: 70, type: 'Total' },
        { week: 'W26', value: 100, type: 'Completed', rate: 110.37 },
        { week: 'W26', value: 80, type: 'Pending' },
        { week: 'W27', value: 79, type: 'Total' },
        { week: 'W27', value: 90, type: 'Completed', rate: 87.69 },
        { week: 'W27', value: 80, type: 'Pending' },
        { week: 'W28', value: 80, type: 'Total' },
        { week: 'W28', value: 90, type: 'Completed', rate: 106.99 },
        { week: 'W28', value: 100, type: 'Pending' },
    ];

    const lineData = [
        { week: 'W24', rate: 101.17 },
        { week: 'W25', rate: 101.88 },
        { week: 'W26', rate: 110.37 },
        { week: 'W27', rate: 87.69 },
        { week: 'W28', rate: 106.99 },
    ];

    const config = {
        data: [data, lineData],
        xField: 'week',
        yField: ['value', 'rate'],
        geometryOptions: [
            {
                geometry: 'column',
                isGroup: true,
                seriesField: 'type',
                label: null,
            },
            {
                geometry: 'line',
                yField: 'rate',
                point: {
                    size: 5,
                    shape: 'diamond',
                },
                label: {
                    position: 'middle',
                    style: {
                        fill: '#595959',
                        opacity: 0.6,
                    },
                },
                tooltip: {
                    showMarkers: false,
                },
                state: {
                    active: {
                        style: {
                            shadowBlur: 4,
                            stroke: '#000',
                            fill: 'red',
                        },
                    },
                },
            },
        ],
        yAxis: {
            left: {
                min: 0,
                max: 10,
            },
            right: {
                min: 0,
                max: 10,
            },  // Disable the right axis
        },
    };

    useEffect(() => {
        if (data.length === 0) {
            return;
        }
        const week = [...new Set(data.map(item => item.week))];
        const total = data.filter(e=>e.type === 'Total').map(item => item.value);
        const pending = data.filter(e=>e.type === 'Pending').map(item => item.value);
        const complete = data.filter(e=>e.type === 'Completed').map(item => item.value);
        const rate = data.filter(e=>e.rate).map(item => item.rate);
        console.log(total, pending, complete);
        const options = {
            chart: {
                type: 'column',
                height: 200
            },
            title: {
                text: 'Tiến độ sản xuất',
                align: 'left',
                style: {
                    fontSize: 15
                }
            },
            xAxis: [{
                categories: week,
                crosshair: true
            }],
            yAxis: [{ // Primary yAxis
                gridLineWidth: 0,
                title: false,
                labels: {
                    format: '{value}%',
                },
                opposite: false
            }],
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:0.1f}%'
                    },
                    lineWidth: 2,
                    marker: {
                        radius: 4,
                        fillColor: 'rgb(255, 87, 34)',
                    },
                    lineColor: 'rgb(255, 87, 34)'
                },
                column: {
                    borderRadius: 0,
                }
            },
            tooltip: {
                shared: true
            },
            series: [
                {
                    name: 'Total',
                    type: 'column',
                    yAxis: 0,
                    data: total,

                },
                {
                    name: 'Completed',
                    type: 'column',
                    yAxis: 0,
                    data: complete,

                },
                {
                    name: 'Pending',
                    type: 'column',
                    yAxis: 0,
                    data: pending,

                },
                {
                    name: 'Rate',
                    type: 'line',
                    yAxis: 0,
                    data: rate,
                }
            ],
            legend: {
                align: 'left',
                verticalAlign: 'top',
                layout: 'vertical',
                x: 0,
                y: 50
            },
            exporting: false,
            credits: { enabled: false }
        };
        Highcharts.chart("voc-chart", options);
    }, [data]);

    const config1 = {
        height: 230,
        data: [
            { day: '24/06', target: 30, result: 30 },
            { day: '25/06', target: 30, result: 28 },
            { day: '26/06', target: 30, result: 33 },
            { day: '27/06', target: 30, result: 29 },
            { day: '28/06', target: 30, result: 30 },
            { day: '29/06', target: 30, result: 30 },
            { day: '30/06', target: 30, result: 30 },
        ],
        xField: 'day',
        yField: 'result',
        yAxis: {
            min: 0,
        },
        label: {
            text: (d) => d.value + '%',
            textBaseline: 'bottom',
        },
        line: {
            yField: 'day',
            style: {
                stroke: '#FF6B3B',
                lineWidth: 2,
            },
        },
        tooltip: {
            channel: 'y', valueFormatter: (d) => {
                return d ?? "";
            }
        },
        legend: {
            position: 'top-left',
        },
    };

    const config2 = {
        height: 200,
        data: [
            { type: 'Đã phản hồi', value: 27 },
            { type: 'Đã giải quyết', value: 25 },
            { type: 'Đã gửi', value: 18 },
            { type: 'Đang xác nhận', value: 15 },
        ],
        angleField: 'value',
        colorField: 'type',
        radius: 0.8,
        label: {
            text: (d) => `${d.type}\n ${d.value}`,
            position: 'spider',
        },
        legend: {
            color: {
                title: false,
                position: 'right',
                rowPadding: 5,
            },
        },
    };

    const config3 = {
        height: 200,
        data: [
            { day: '24/06', plan: 30, result: 30 },
            { day: '25/06', plan: 28, result: 28 },
            { day: '26/06', plan: 33, result: 33 },
            { day: '27/06', plan: 29, result: 29 },
            { day: '28/06', plan: 30, result: 30 },
            { day: '29/06', plan: 25 },
            { day: '30/06', plan: 22 },
        ],
        legend: {
            layout: 'grid'
        },
        xField: 'day',
        yField: 'plan',
        yAxis: {
            min: 0,
        },
        style: {
            fillOpacity: 0.3,
            fill: 'linear-gradient(-90deg, white 0%, #4285f4 100%)',
            stroke: 'none',
        },
        line: {
            yField: 'result',
            style: {
                stroke: '#FF6B3B',
                lineWidth: 2,
            },
        },
        tooltip: {
            channel: 'y', valueFormatter: (d) => {
                return d ?? "";
            }
        }
    };

    const [treeData, setTreeData] = useState([]);

    useEffect(() => {
        setTreeData(listLines.map(e => {
            let child = e.machine?.map(i => {
                return { title: i.name, key: i.id }
            })
            return {
                ...e,
                title: e.name,
                key: e.id,
                children: child
            }
        }));
    }, [listLines])

    useEffect(() => {
        // Dữ liệu mẫu
        const data = [
            { key: '1', reason: 'Lỗi sản phẩm không đạt tiêu chuẩn', customer: 'Nguyễn Văn A', requester: 'Nguyễn Văn A', requestTime: '2024-01-01 08:00', completionTime: '2024-01-10 17:00', updateTime: '2024-01-05 14:00', estimatedCost: '1,000,000 VND', repairPerson: 'Kỹ sư Trần Văn B', repairTime: '2024-01-06 09:00', category: 'Có tính phí', status: 'Đã gửi', startDate: '2024-01-01', endDate: '2024-01-10' },
            { key: '2', reason: 'Thiếu phụ kiện trong bộ sản phẩm', customer: 'Trần Thị B', requester: 'Trần Thị B', requestTime: '2024-01-05 09:00', completionTime: '2024-01-15 16:00', updateTime: '2024-01-10 11:00', estimatedCost: '0 VND', repairPerson: 'Kỹ sư Lê Văn C', repairTime: '2024-01-11 10:00', category: 'Không tính phí', status: 'Đã gửi', startDate: '2024-01-05', endDate: '2024-01-15' },
            { key: '3', reason: 'Sản phẩm bị móp méo khi vận chuyển', customer: 'Lê Văn C', requester: 'Lê Văn C', requestTime: '2024-01-10 10:00', completionTime: '2024-01-20 18:00', updateTime: '2024-01-15 12:00', estimatedCost: '500,000 VND', repairPerson: 'Kỹ sư Phạm Thị D', repairTime: '2024-01-16 14:00', category: 'Có tính phí', status: 'Đã gửi', startDate: '2024-01-10', endDate: '2024-01-20' },
            { key: '4', reason: 'Sản phẩm không hoạt động', customer: 'Phạm Thị D', requester: 'Phạm Thị D', requestTime: '2024-01-15 11:00', completionTime: '2024-01-25 15:00', updateTime: '2024-01-20 13:00', estimatedCost: '0 VND', repairPerson: 'Kỹ sư Hoàng Văn E', repairTime: '2024-01-21 09:00', category: 'Không tính phí', status: 'Đã gửi', startDate: '2024-01-15', endDate: '2024-01-25' },
            { key: '5', reason: 'Màu sắc sản phẩm không đúng', customer: 'Hoàng Văn E', requester: 'Hoàng Văn E', requestTime: '2024-01-20 08:30', completionTime: '2024-01-30 17:00', updateTime: '2024-01-25 14:30', estimatedCost: '200,000 VND', repairPerson: 'Kỹ sư Ngô Thị F', repairTime: '2024-01-26 10:00', category: 'Có tính phí', status: 'Đã giải quyết', startDate: '2024-01-20', endDate: '2024-01-30' },
            { key: '6', reason: 'Sản phẩm bị lỗi phần mềm', customer: 'Ngô Thị F', requester: 'Ngô Thị F', requestTime: '2024-01-25 09:00', completionTime: '2024-02-05 16:00', updateTime: '2024-01-30 11:00', estimatedCost: '0 VND', repairPerson: 'Kỹ sư Đặng Văn G', repairTime: '2024-01-31 13:00', category: 'Không tính phí', status: 'Đã giải quyết', startDate: '2024-01-25', endDate: '2024-02-05' },
            { key: '7', reason: 'Sản phẩm giao sai kích thước', customer: 'Đặng Văn G', requester: 'Đặng Văn G', requestTime: '2024-01-30 10:00', completionTime: '2024-02-10 18:00', updateTime: '2024-02-05 12:00', estimatedCost: '300,000 VND', repairPerson: 'Kỹ sư Bùi Thị H', repairTime: '2024-02-06 09:00', category: 'Có tính phí', status: 'Đã gửi', startDate: '2024-01-30', endDate: '2024-02-10' },
            { key: '8', reason: 'Chất lượng sản phẩm kém', customer: 'Bùi Thị H', requester: 'Bùi Thị H', requestTime: '2024-02-05 09:00', completionTime: '2024-02-15 16:00', updateTime: '2024-02-10 11:00', estimatedCost: '0 VND', repairPerson: 'Kỹ sư Vũ Văn I', repairTime: '2024-02-11 10:00', category: 'Không tính phí', status: 'Đã giải quyết', startDate: '2024-02-05', endDate: '2024-02-15' },
            { key: '9', reason: 'Thiết bị không tương thích', customer: 'Vũ Văn I', requester: 'Vũ Văn I', requestTime: '2024-02-10 10:00', completionTime: '2024-02-20 18:00', updateTime: '2024-02-15 12:00', estimatedCost: '500,000 VND', repairPerson: 'Kỹ sư Phan Thị K', repairTime: '2024-02-16 14:00', category: 'Có tính phí', status: 'Đã gửi', startDate: '2024-02-10', endDate: '2024-02-20' },
            { key: '10', reason: 'Hướng dẫn sử dụng không rõ ràng', customer: 'Phan Thị K', requester: 'Phan Thị K', requestTime: '2024-02-15 11:00', completionTime: '2024-02-25 15:00', updateTime: '2024-02-20 13:00', estimatedCost: '0 VND', repairPerson: 'Kỹ sư Nguyễn Thị L', repairTime: '2024-02-21 09:00', category: 'Không tính phí', status: 'Đã giải quyết', startDate: '2024-02-15', endDate: '2024-02-25' },
            { key: '11', reason: 'Sản phẩm có vết trầy xước', customer: 'Nguyễn Thị L', requester: 'Nguyễn Thị L', requestTime: '2024-02-20 08:00', completionTime: '2024-03-01 17:00', updateTime: '2024-02-25 14:00', estimatedCost: '200,000 VND', repairPerson: 'Kỹ sư Trần Văn M', repairTime: '2024-02-26 10:00', category: 'Có tính phí', status: 'Đã gửi', startDate: '2024-02-20', endDate: '2024-03-01' },
            { key: '12', reason: 'Không nhận được sản phẩm sau khi thanh toán', customer: 'Trần Văn M', requester: 'Trần Văn M', requestTime: '2024-02-25 09:00', completionTime: '2024-03-05 16:00', updateTime: '2024-03-01 11:00', estimatedCost: '0 VND', repairPerson: 'Kỹ sư Lê Thị N', repairTime: '2024-03-02 10:00', category: 'Không tính phí', status: 'Đã gửi', startDate: '2024-02-25', endDate: '2024-03-05' },
            { key: '13', reason: 'Sản phẩm bị hỏng sau một tuần sử dụng', customer: 'Lê Thị N', requester: 'Lê Thị N', requestTime: '2024-03-01 10:00', completionTime: '2024-03-10 18:00', updateTime: '2024-03-05 12:00', estimatedCost: '1,000,000 VND', repairPerson: 'Kỹ sư Phạm Văn O', repairTime: '2024-03-06 14:00', category: 'Có tính phí', status: 'Đã gửi', startDate: '2024-03-01', endDate: '2024-03-10' },
            { key: '14', reason: 'Không kết nối được với ứng dụng', customer: 'Phạm Văn O', requester: 'Phạm Văn O', requestTime: '2024-03-05 11:00', completionTime: '2024-03-15 15:00', updateTime: '2024-03-10 13:00', estimatedCost: '0 VND', repairPerson: 'Kỹ sư Hoàng Thị P', repairTime: '2024-03-11 09:00', category: 'Không tính phí', status: 'Đã giải quyết', startDate: '2024-03-05', endDate: '2024-03-15' },
            { key: '15', reason: 'Không thể kích hoạt bảo hành', customer: 'Hoàng Thị P', requester: 'Hoàng Thị P', requestTime: '2024-03-10 08:30', completionTime: '2024-03-20 17:00', updateTime: '2024-03-15 14:30', estimatedCost: '500,000 VND', repairPerson: 'Kỹ sư Ngô Văn Q', repairTime: '2024-03-16 10:00', category: 'Có tính phí', status: 'Đã gửi', startDate: '2024-03-10', endDate: '2024-03-20' },
            { key: '16', reason: 'Không tìm thấy sản phẩm trong kho', customer: 'Ngô Văn Q', requester: 'Ngô Văn Q', requestTime: '2024-03-15 09:00', completionTime: '2024-03-25 16:00', updateTime: '2024-03-20 11:00', estimatedCost: '0 VND', repairPerson: 'Kỹ sư Đặng Thị R', repairTime: '2024-03-21 10:00', category: 'Không tính phí', status: 'Đã gửi', startDate: '2024-03-15', endDate: '2024-03-25' },
            { key: '17', reason: 'Sản phẩm không khớp với mô tả', customer: 'Đặng Thị R', requester: 'Đặng Thị R', requestTime: '2024-03-20 10:00', completionTime: '2024-03-30 18:00', updateTime: '2024-03-25 12:00', estimatedCost: '300,000 VND', repairPerson: 'Kỹ sư Bùi Văn S', repairTime: '2024-03-26 14:00', category: 'Có tính phí', status: 'Đã giải quyết', startDate: '2024-03-20', endDate: '2024-03-30' },
            { key: '18', reason: 'Phụ kiện kèm theo không đúng', customer: 'Bùi Văn S', requester: 'Bùi Văn S', requestTime: '2024-03-25 11:00', completionTime: '2024-04-05 15:00', updateTime: '2024-03-30 13:00', estimatedCost: '0 VND', repairPerson: 'Kỹ sư Vũ Thị T', repairTime: '2024-03-31 09:00', category: 'Không tính phí', status: 'Đã gửi', startDate: '2024-03-25', endDate: '2024-04-05' },
            { key: '19', reason: 'Giao hàng chậm', customer: 'Vũ Thị T', requester: 'Vũ Thị T', requestTime: '2024-03-30 08:00', completionTime: '2024-04-10 17:00', updateTime: '2024-04-05 14:00', estimatedCost: '100,000 VND', repairPerson: 'Kỹ sư Phan Văn U', repairTime: '2024-04-06 10:00', category: 'Có tính phí', status: 'Đã giải quyết', startDate: '2024-03-30', endDate: '2024-04-10' },
            { key: '20', reason: 'Không thể kích hoạt bảo hành', customer: 'Phan Văn U', requester: 'Phan Văn U', requestTime: '2024-04-05 09:00', completionTime: '2024-04-15 16:00', updateTime: '2024-04-10 11:00', estimatedCost: '0 VND', repairPerson: 'Kỹ sư Nguyễn Văn A', repairTime: '2024-04-11 10:00', category: 'Không tính phí', status: 'Đã gửi', startDate: '2024-04-05', endDate: '2024-04-15' }
        ];

        setTickets(data);
        setPagination({ ...pagination, total: data.length });
    }, []);

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    const [expandedKeys, setExpandedKeys] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const onExpand = (expandedKeysValue) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };
    const onCheck = (checkedKeysValue) => {
        setCheckedKeys(checkedKeysValue);
    };
    const onSelect = (selectedKeysValue, info) => {
        setSelectedKeys(selectedKeysValue);
    };

    useEffect(() => {
        // btn_click();
    }, [])

    async function btn_click() {
        setLoading(true);
        const res = await getKpi(params);
        if (res.success) {
            const data = [];
            Object.keys(res.data).map((key, index) => {
                data.push({
                    ...res.data[key],
                    ...res.data[key].data,
                    last_year: '-'
                })
            });
            setDataTable(data);

            let list1 = [];
            Object.keys(res.data.ti_le_sx.data ?? {}).map((key, index) => {
                list1.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: parseInt(res.data.ti_le_sx.data[key]),
                    type: "TL Hoàn thành KHSX"
                });
                list1.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: 82,
                    type: "Tỉ lệ tiêu chuẩn"
                })
            });
            setDataChartTiLeHoanThanhKHSX(list1);
            let list2 = [];
            Object.keys(res.data.ti_le_dat_thang.data ?? {}).map((key, index) => {
                list2.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: parseInt(res.data.ti_le_dat_thang.data[key]),
                    type: "TL Đạt thẳng"
                });
                list2.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: 80,
                    type: "Tỉ lệ tiêu chuẩn"
                })
            });
            setDataChartTiLeDatThang(list2);
            let list3 = [];
            Object.keys(res.data.ti_le_ng.data ?? {}).map((key, index) => {
                list3.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: parseInt(res.data.ti_le_ng.data[key]),
                    type: "TL NG"
                });
                list3.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: 8,
                    type: "Tỉ lệ tiêu chuẩn"
                })
            });
            setDataChartTiLeLoiCongDoan(list3);
            let list4 = [];
            Object.keys(res.data.ti_le_van_hanh_may.data ?? {}).map((key, index) => {
                list4.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: parseInt(res.data.ti_le_van_hanh_may.data[key]),
                    type: "TL Vận hành máy"
                });
                list4.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: 75,
                    type: "Tỉ lệ tiêu chuẩn"
                })
            });
            setDataChartTiLeVanHanhThietBi(list4);
            let list5 = [];
            Object.keys(res.data.ti_le_giao_hang_dung_han.data ?? {}).map((key, index) => {
                list5.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: parseInt(res.data.ti_le_giao_hang_dung_han.data[key]),
                    type: "TL Giao hàng đúng hạn"
                });
                list5.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: 100,
                    type: "Tỉ lệ tiêu chuẩn"
                })
            });
            setDataChartTiLeGiaoHangDungHan(list5);
            let list6 = [];
            Object.keys(res.data.ti_le_ton.data ?? {}).map((key, index) => {
                list6.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: parseInt(res.data.ti_le_ton.data[key]),
                    type: "TL ngày tồn"
                });
                list6.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: 90,
                    type: "Tỉ lệ tiêu chuẩn"
                })
            });
            setDataChartNgayTon(list6);
            let list7 = [];
            Object.keys(res.data.ti_le_ng_oqc.data ?? {}).map((key, index) => {
                list7.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: parseInt(res.data.ti_le_ng_oqc.data[key]),
                    type: "TL NG OQC"
                });
                list7.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: 1,
                    type: "Tỉ lệ tiêu chuẩn"
                })
            });
            setDataChartTiLeNgOQC(list7);
            let list8 = [];
            Object.keys(res.data.ti_le_leadtime.data ?? {}).map((key, index) => {
                list8.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: parseInt(res.data.ti_le_leadtime.data[key]),
                    type: "TL Leadtime"
                });
                list8.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: 95,
                    type: "Tỉ lệ tiêu chuẩn"
                })
            });
            setDataChartLeadTime(list8);
            setLoading(false);
        }
    }

    const [exportLoading, setExportLoading] = useState(false);
    const exportClick = async () => {
        setExportLoading(true);
        const res = await exportKPI(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const [dateType, setDateType] = useState('date');
    const customDateFormat = (value) => {
        switch (dateType) {
            case 'week':
                return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
                break;
            case 'month':
                return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
                break;
            case 'year':
                return (`Năm ${dayjs(value).format('YYYY')}`);
                break;
            default:
                return (dayjs(value).format('DD/MM/YYYY'));
                break;
        }
    }
    useEffect(()=>{
        onChangeDate('start_date', params.start_date, dateType);
        onChangeDate('end_date', params.end_date, dateType);
    }, [dateType])
    const onChangeDate = (position, value, dateType) => {
        switch (dateType) {
            case 'week':
                if (position === 'start_date') {
                    setParams({ ...params, start_date: dayjs(value).startOf('isoWeek'), end_date: dayjs(params.end_date).endOf('isoWeek') });
                } else {
                    setParams({ ...params, start_date: dayjs(value).startOf('isoWeek'), end_date: dayjs(params.end_date).endOf('isoWeek') });
                }
                break;
            case 'month':
                if (position === 'start_date') {
                    setParams({ ...params, start_date: dayjs(value).startOf('month'), end_date: dayjs(params.end_date).endOf('month') });
                } else {
                    setParams({ ...params, start_date: dayjs(value).startOf('month'), end_date: dayjs(params.end_date).endOf('month') });
                }
                break;
            case 'year':
                if (position === 'start_date') {
                    setParams({ ...params, start_date: dayjs(value).startOf('year'), end_date: dayjs(params.end_date).endOf('year') });
                } else {
                    setParams({ ...params, start_date: dayjs(value).startOf('year'), end_date: dayjs(params.end_date).endOf('year') });
                }
                break;
            default:
                setParams({ ...params, [position]: value })
                break;
        }
    }

    const showDrawer = customer => {
        const customerData = {
            name: customer,
            churnScore: 0.28,
            lifetimeValue: 36810,
            activities: [
                { type: 'email', time: '8:20 PM', description: 'Yêu cầu hoàn tiền đã được tạo' },
                { type: 'survey', time: '9:45 AM', description: 'Khảo sát mới với phản hồi tiêu cực' }
            ]
        };
        setSelectedCustomer(customerData);
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
        setSelectedCustomer(null);
    };

    const renderActivityIcon = type => {
        switch (type) {
            case 'email':
                return <MailOutlined />;
            case 'app':
                return <AppstoreOutlined />;
            case 'phone':
                return <PhoneOutlined />;
            case 'web':
                return <GlobalOutlined />;
            default:
                return <UserOutlined />;
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.validateFields().then(async (values) => {
            console.log(values);
            setIsModalOpen(false);
        }).catch((error) => { console.log(error) })
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };



    return (
        <React.Fragment>
            <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
                <Col span={4} className='custom-col'>
                    <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                        <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                    ]}>
                        <Form style={{ margin: '0 15px' }} layout="vertical">
                            <Divider>Thời gian truy vấn</Divider>
                            <Radio.Group
                                options={[
                                    { value: 'date', label: 'Ngày', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'week', label: 'Tuần', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'month', label: 'Tháng', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'year', label: 'Năm', style: { width: '25%', justifyContent: 'center', display: 'flex' } }
                                ]}
                                className='d-flex justify-content-center mb-2 w-100'
                                onChange={(e) => setDateType(e.target.value)}
                                value={dateType}
                                optionType="button"
                                buttonStyle="solid"
                            />
                            <Space direction='vertical' style={{ width: '100%' }}>
                                <DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.start_date} format={customDateFormat} picker={dateType} />
                                <DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.end_date} format={customDateFormat} picker={dateType} />
                                <div style={{ marginTop: '5px' }}>
                                    <label>Trạng thái</label>
                                    <Select placeholder="Chọn trạng thái" options={[]} style={{ width: '100%' }} />
                                </div>
                                <div style={{ marginTop: '5px' }}>
                                    <label>Phân loại</label>
                                    <Select placeholder="Phân loại" options={[]} style={{ width: '100%' }} />
                                </div>
                                <div style={{ marginTop: '5px' }}>
                                    <label>Người yêu cầu</label>
                                    <Select placeholder="Người yêu cầu" options={[]} style={{ width: '100%' }} />
                                </div>
                                <div style={{ marginTop: '5px' }}>
                                    <label>Yêu cầu</label>
                                    <Input placeholder="Yêu cầu" />
                                </div>
                            </Space>
                        </Form>
                    </Card>
                </Col>
                <Col span={20}>
                    <Spin spinning={loading}>
                        <Row gutter={
                            [8, 8]
                        }>
                            <Col span={24}>
                                <Card title="Biểu đồ yêu cầu"
                                    style={
                                        { padding: '0px' }
                                    }>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <div id={"voc-chart"} />
                                        </Col>
                                        <Col span={12}>
                                            <Table size="small" columns={columnTop} dataSource={dataTopFaker} scroll={{ x: true }} bordered pagination={false} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col span={6}>
                                <Card
                                    size="small"
                                    loading={loading}
                                    title="Sản xuất"
                                // extra={<Tag color="blue">{"item.action"}</Tag>}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <b>200</b>
                                        <ApartmentOutlined style={{ fontSize: '30px', color: '#08c' }} />
                                    </div>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card
                                    size="small"
                                    loading={loading}
                                    title="Chất lượng"
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <b>500</b>
                                        <CheckCircleOutlined style={{ fontSize: '30px', color: '#08c' }} />
                                    </div>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card
                                    size="small"
                                    loading={loading}
                                    title="Thiết bị"
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <b>100</b>
                                        <DeploymentUnitOutlined style={{ fontSize: '30px', color: '#08c' }} />
                                    </div>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card
                                    size="small"
                                    loading={loading}
                                    title="Kho"
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <b>100</b>
                                        <InboxOutlined style={{ fontSize: '30px', color: '#08c' }} />
                                    </div>
                                </Card>
                            </Col>

                            <Col span={24}>
                                <Card style={
                                    { height: '100%' }
                                }
                                    title="Bảng thông tin chi tiết"
                                    extra={
                                        <Space>
                                            <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>Tạo yêu cầu</Button>
                                            <Button
                                                icon={<DownloadOutlined />}
                                                type="primary"
                                                loading={exportLoading}
                                                onClick={exportClick}>
                                                Xuất Excel</Button>
                                        </Space>
                                    }>
                                    <Table size='small' bordered
                                        scroll={{ x: '2600px', y: '22vh' }}
                                        columns={columnTable}
                                        dataSource={tickets}
                                        pagination={pagination}
                                        onChange={handleTableChange}
                                    />

                                    {/* Drawer */}
                                    <Drawer
                                        title={selectedCustomer ? selectedCustomer.name : ''}
                                        placement="right"
                                        onClose={closeDrawer}
                                        visible={drawerVisible}
                                    >
                                        {selectedCustomer && (
                                            <div>
                                                <p>Churn score: {selectedCustomer.churnScore}</p>
                                                <p>Lifetime value: {selectedCustomer.lifetimeValue}</p>
                                                <h3>Activities</h3>
                                                <Space direction="vertical">
                                                    {selectedCustomer.activities.map(activity => (
                                                        <div key={activity.time}>
                                                            {renderActivityIcon(activity.type)} {activity.description} at {activity.time}
                                                        </div>
                                                    ))}
                                                </Space>
                                            </div>
                                        )}
                                    </Drawer>

                                    {/* Modal */}
                                    <Modal title="Tạo yêu cầu" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={800}>
                                        <Form form={form} layout="vertical">
                                            <Form.Item name="title" label="Tiêu đề" rules={[{ required: true }]}>
                                                <Input placeholder="Tiêu đề" />
                                            </Form.Item>
                                            <Row gutter={5}>
                                                <Col span={12}>
                                                    <Form.Item name="customer_id" label="Người yêu cầu" rules={[{ required: true }]}>
                                                        <Select showSearch placeholder="Chọn người yêu cầu" options={[
                                                            { label: 'Nguyễn Văn A', value: 'Nguyễn Văn A' },
                                                            { label: 'Trần Thị B', value: 'Trần Thị B' },
                                                            { label: 'Lê Văn C', value: 'Lê Văn C' },
                                                            { label: 'Phạm Thị D', value: 'Phạm Thị D' },
                                                            { label: 'Hoàng Văn E', value: 'Hoàng Văn E' },
                                                            { label: 'Ngô Thị F', value: 'Ngô Thị F' },
                                                            { label: 'Đặng Văn G', value: 'Đặng Văn G' },
                                                        ]} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item name="logo" label="Tải ảnh lên">
                                                        <Upload
                                                            showUploadList={false}
                                                            {...props}
                                                            accept='image/*'
                                                        >
                                                            <Button icon={<UploadOutlined />}>Tải ảnh lên</Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={5}>
                                                <Col span={12}>
                                                    <Form.Item name="category" label="Phân loại" rules={[{ required: true }]}>
                                                        <Select showSearch placeholder="Chọn loại" defaultValue={'Có tính phí'} options={[
                                                            { label: 'Có tính phí', value: 'Có tính phí' },
                                                            { label: 'Không tính phí', value: 'Không tính phí' },
                                                        ]} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item name="status" label="Trạng thái" rules={[{ required: true }]}>
                                                        <Select showSearch defaultValue={'Đã gửi'} placeholder="Chọn trạng thái" options={[
                                                            { label: 'Đã gửi', value: 'Đã gửi' },
                                                            { label: 'Đang xác nhận', value: 'Đang xác nhận' },
                                                            { label: 'Đã giải quyết', value: 'Đã giải quyết' },
                                                        ]} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item name="note" label="Mô tả" rules={[{ required: true }]}>
                                                        <TextArea rows={5} placeholder="Mô tả" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Modal>
                                </Card>
                            </Col>
                        </Row>
                    </Spin>
                </Col>
            </Row>

        </React.Fragment >
    );
};

export default VOC;
