import React, { useState, useEffect } from 'react';
import config from '../../../config';
import { useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const PPTist = (props) => {
    document.title = 'UI - Báo cáo powerpoint';
    const query = useQuery();
    const token = JSON.parse(localStorage.getItem("authUser"))?.token;
    let url = '';
    if (config.pptist.includes('?')) url += '&';
    else url += '?';
    if (token) url += `token=${token}`;
    if (query.has('start_date')) url += `&start_date=${query.get('start_date')}`;
    if (query.has('end_date')) url += `&end_date=${query.get('end_date')}`;
    if (query.has('dataType')) url += `&dataType=${query.get('dataType')}`;
    
    return (
        <div className="section-pptist">
            <iframe id="pptist" src={`${config.pptist}${url || ''}`} title="PPTist" width={'100%'} height={'100%'} />
        </div >
    );
};

export default PPTist;
