import { CloseOutlined } from "@ant-design/icons";
import { Button, Layout, Space } from "antd";
import { Header } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SketchfabCNCModel from "./Sketchfab/SketchfabCNCModel";
import config from "../../config";
import logolight from "../../assets/images/logo.png";
import { getMachineParameterLog } from "../../api/db/main";

const Simulator = () => {
  const history = useHistory();
  document.title = "Cảnh báo bất thường CNC";

  const layoutStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh-70px)",
    position: "relative", // Add this line
  };

  return (
    <Layout style={{ height: "99vh" }}>
      <Header
        style={{
          color: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#2462a3",
        }}
      >
        <div>
          <img src={logolight} alt="logo" />
        </div>
        <h4>Cảnh báo bất thường</h4>
        <Space>
          <Button icon={<CloseOutlined />} onClick={() => history.push("/")} />
        </Space>
      </Header>
      <Layout style={layoutStyle}>
          <SketchfabCNCModel modelUid="0bffcfb2b94b4b45b2ea255315e7925f" />
      </Layout>
    </Layout>
  );
};

export default Simulator;
