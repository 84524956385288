import React, { useEffect, useState } from "react";
import {
  CloseOutlined,
  PrinterOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Row,
  Col,
  Divider,
  Button,
  Table,
  Modal,
  Select,
  Steps,
  Input,
  Radio,
  Popconfirm,
  Form,
  Tabs,
  Card,
} from "antd";
import { withRouter, Link } from "react-router-dom";
import CardInfo from "../components/CardInfo";
import DataDetail from "../../../components/DataDetail";
import SelectButton from "../../../components/Button/SelectButton";
import "../style.scss";
import { useHistory, useParams } from "react-router-dom";
import Defect from "./Defect";
import CheckSheet from "./Checksheet";
import SelectError from "./SelectError";
import Measurement from "./Measurement";
import {
  getLine,
  getListMachineOfLine,
  getMachineOverall,
} from "../../../api/oi/equipment";
import { getLineList } from "../../../api/phase2/oi/manufacture";

const Equipment = (props) => {
  document.title = "Thiết bị";
  const { line } = useParams();
  const history = useHistory();
  const [optionsLine, setOptionsLine] = useState([]);
  // const [line, setLine] = useState();
  const [listMachine, setListMachine] = useState([]);
  const [optionsMachine, setOptionsMachine] = useState([]);
  const [machine, setMachine] = useState();
  useEffect(() => {
    (async () => {
      const list_line = await getLineList({ type: "tb" });
      setOptionsLine(list_line.success == true ? list_line.data : []);
    })();
  }, []);
  useEffect(() => {
    if (line) {
      (async () => {
        const list_machine = await getListMachineOfLine({
          line_id: line,
          is_iot: 1,
        });
        setListMachine(list_machine.success == true ? list_machine.data : []);
      })();
    }
  }, [line, optionsLine]);

  useEffect(() => {
    const screen = JSON.parse(localStorage.getItem('screen'));
    if (line) {
      localStorage.setItem('screen', JSON.stringify({ ...screen, equipment: line ?? '' }))
    } else {
      if (!screen?.equipment) {
        history.push('/equipment/24')
      } else {
        history.push('/equipment/' + screen?.equipment);
      }
    }
  }, [line])
  useEffect(() => {
    let listOption = [];
    for (let i = 0; i < listMachine.length; i++) {
      let res = {
        value: listMachine[i].code,
        label: listMachine[i].code,
        code: listMachine[i].code,
      };
      listOption.push(res);
    }
    setOptionsMachine(listOption);
    setMachine(listOption[0]);
  }, [listMachine]);

  useEffect(() => {
    if (machine) {
      (async () => {
        var res = await getMachineOverall({ machine_id: machine?.value });
        if (res.success) {
          const totalSeconds = Math.floor(
            res.data.tg_dung ? parseInt(res.data.tg_dung) : 0 / 1000
          );
          const totalMinutes = Math.floor(totalSeconds / 60);
          const totalHours = Math.floor(totalMinutes / 60);
          // return dayjs.duration(time).format('HH:mm:ss')

          setRow1([
            {
              title: "Tổng TG dừng",
              value: `${("0" + totalHours).slice(-2)}:${(
                "0" +
                (totalMinutes % 60)
              ).slice(-2)}:${("0" + (totalSeconds % 60)).slice(-2)}`,
            },
            {
              title: "Tổng số lần dừng",
              value: res.data.so_lan_dung,
            },
            {
              title: "Tổng số lỗi",
              value: res.data.so_loi,
            },
          ]);
        }
      })();
    }
  }, [machine]);

  const [row1, setRow1] = useState([
    {
      title: "Tổng TG dừng",
      value: "0",
    },
    {
      title: "Tổng số lần dừng",
      value: "0",
    },
    {
      title: "Tổng số lỗi",
      value: "0",
    },
  ]);
  const items = [
    {
      key: 1,
      label: `Check sheet`,
      children: <CheckSheet line={line} machine={machine} />,
    },
    {
      key: 2,
      label: `Chọn lỗi`,
      children: <SelectError line={line} machine={machine} />,
    },
    {
      key: 3,
      label: `Thông số đo`,
      children: <Measurement machine={machine} />,
    },
  ];
  const onChangeLine = (value) => {
    history.push("/equipment/" + value);
  };
  return (
    <React.Fragment>
      <Row className="mt-3" gutter={[8, 8]}>
        <Col span={4}>
          <SelectButton
            value={optionsLine.length > 0 && line && parseInt(line)}
            options={optionsLine}
            label="Chọn công đoạn"
            onChange={(value) => onChangeLine(value)}
          />
        </Col>
        <Col span={4}>
          <SelectButton
            value={machine}
            options={optionsMachine}
            label="Chọn máy"
            onChange={(value) => setMachine(value)}
            labelInValue={true}
          />
        </Col>
        <Col span={16}>
          <DataDetail data={row1} />
        </Col>
        <Col span={24}>
          <Card bodyStyle={{ padding: 12 }}>
            <Tabs
              defaultActiveKey={1}
              items={items}
              destroyInactiveTabPane={true}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(Equipment);
