import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Divider, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Spin, Table, Upload, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { createProductOrders, deleteMultipleProductOrders, getProductOrders, deleteProductOrders, updateProductOrders, exportProductOrders } from '../../../api/phase2/master_data/product-order';
import { api, baseURL } from '../../../config';
import dayjs from 'dayjs';
import './MasterDataStyle.css';
import { getProduct } from '../../../api/ui/product';
import { getCustomer } from '../../../api/ui/customer';

const ProductOrder = () => {
    document.title = "Quản lý đơn hàng";
    const [openMdl, setOpenMdl] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm();
    const [total, setTotal] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [formSearch] = Form.useForm();
    const [editingRecord, setEditingRecord] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'center',
            width: '50px',
        },
        {
            title: 'Số đơn hàng',
            dataIndex: 'order_number',
            align: 'center',
            width: '100px',
        },
        {
            title: 'Mã khách hàng',
            dataIndex: 'customer_id',
            align: 'center',
            width: '100px',
        },
        {
            title: 'Mã hàng',
            dataIndex: 'product_id',
            align: 'center',
            width: '100px',
        },
        {
            title: 'Ngày đặt hàng',
            dataIndex: 'order_date',
            align: 'center',
            width: '100px',
            render: (value) => dayjs(value).format('DD/MM/YYYY'),
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            align: 'center',
            width: '100px',
        },
        {
            title: 'Ngày giao',
            dataIndex: 'delivery_date',
            align: 'center',
            width: '100px',
            render: (value) => value ? dayjs(value).format('DD/MM/YYYY') : '',
        },
        {
            title: 'Ghi chú thay đổi',
            dataIndex: 'note',
            align: 'center',
            width: '200px',
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            align: 'center',
            width: '100px',
            render: (_, record) => (
                <Space wrap>
                    <EditOutlined className='edit-btn' onClick={() => editRecord(record)} />
                    <Popconfirm
                        title="Xoá bản ghi"
                        description={"Bạn có chắc muốn xoá?"}
                        onConfirm={() => deleteRecord(record)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <DeleteOutlined className='delete-btn' />
                    </Popconfirm>
                </Space>
            )
        },
    ];
    function btn_click(page = 1, pageSize = 10) {
        setPage(page);
        setPageSize(pageSize);
        loadListTable({ ...formSearch.getFieldsValue(true), page, pageSize });
    }

    useEffect(() => {
        fetchProduct();
        fetchCustomer();
        btn_click();
    }, [])

    const [data, setData] = useState([]);
    const loadListTable = async (params) => {
        setSelectedRowKeys([]);
        setLoading(true)
        const res = await getProductOrders(params);
        setData((res.data?.data ?? []).map(e => {
            return { ...e, key: e.id }
        }));
        console.log(res.data);
        setTotal(res.data.total)
        setLoading(false);
    }

    const onFinish = async (values) => {
        if (values?.order_date) values.order_date = dayjs(values.order_date).format('YYYY-MM-DD');
        if (values?.delivery_date) values.delivery_date = dayjs(values.delivery_date).format('YYYY-MM-DD');
        if (isEdit) {
            const res = await updateProductOrders(editingRecord?.id, values);
            if (res.success) {
                form.resetFields();
                setOpenMdl(false);
                setEditingRecord();
            }
        } else {
            const res = await createProductOrders(values);
            if (res.success) {
                form.resetFields();
                setOpenMdl(false);
            }
        }
        btn_click();
    }

    const deleteRecord = async (record) => {
        const res = await deleteProductOrders(record.id);
        btn_click();
    }
    const deleteMultiple = async () => {
        console.log(selectedRowKeys);
        const res = await deleteMultipleProductOrders(selectedRowKeys);
        btn_click();
    }
    const editRecord = (record) => {
        setIsEdit(true);
        if (record?.order_date) record.order_date = dayjs(record.order_date);
        if (record?.delivery_date) record.delivery_date = dayjs(record.delivery_date);
        const fields = { ...record }
        form.setFieldsValue(fields);
        setEditingRecord(fields);
        setOpenMdl(true);
    }
    const insertRecord = () => {
        setIsEdit(false)
        form.resetFields();
        form.setFieldsValue({ order_date: dayjs() })
        setOpenMdl(true);
    }
    const [loadingExport, setLoadingExport] = useState(false);
    const [loading, setLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportProductOrders({ ...formSearch.getFieldsValue(true) });
        if (res.success && res.data) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
        },
    };
    const onCancel = () => {
        form.resetFields();
        setOpenMdl(false);
    }

    const fetchProduct = async () => {
        setLoading(true);
        const response = await getProduct();
        if (response?.success) {
            setProductOptions((response.data?.data ?? []).map((item) => {
                return {
                    label: <><span style={{ fontWeight: 600 }}>{item?.id}</span><small style={{ color: 'gray' }}>({item?.name})</small></>,
                    value: item?.id,
                }
            }))
        }
        setLoading(false);
    }

    const fetchCustomer = async () => {
        setLoading(true);
        const response = await getCustomer();
        if (response?.success) {
            setCustomerOptions((response.data?.data ?? []).map((item) => {
                return {
                    label: item?.name,
                    value: item?.id,
                }
            }))
        }
        setLoading(false);
    }

    return <>
        <Row style={{ padding: '8px', marginRight: 0 }} gutter={[8, 8]}>
            <Col span={3} className='custom-col'>
                <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => formSearch.submit()}>Truy vấn</Button>
                ]}>
                    <Form style={{ margin: '0 8px' }} layout="vertical" form={formSearch} onFinish={() => btn_click()}>
                        <Divider>Điều kiện truy vấn</Divider>
                        <Form.Item label="ID đơn" className='mb-3' name='id'>
                            <Input allowClear placeholder='Nhập ID' />
                        </Form.Item>
                        <Form.Item label="Mã hàng" className='mb-3' name='product_id'>
                            <Input allowClear placeholder='Nhập mã hàng' />
                        </Form.Item>
                        <button type='submit' hidden />
                    </Form>
                </Card>
            </Col>
            <Col span={21}>
                <Card style={{ height: '100%' }} title="Quản lý đơn hàng" extra={
                    <Space>
                        <Upload
                            showUploadList={false}
                            name='file'
                            action={api.API_URL + "/product-order/import"}
                            headers={{
                                authorization: "Bearer " + JSON.parse(localStorage.getItem('authUser'))?.token,
                            }}
                            onChange={(info) => {
                                setLoadingExport(true);
                                if (info.file.status === 'error') {
                                    setLoadingExport(false);
                                    message.error('Upload file lỗi');
                                } else if (info.file.status === 'done') {
                                    if (info.file.response.success === true) {
                                        btn_click();
                                        message.success(info.file.response.message);
                                        setLoadingExport(false);
                                    } else {
                                        btn_click();
                                        message.error(info.file.response.message ?? 'Upload file lỗi');
                                        setLoadingExport(false);
                                    }
                                }
                            }}
                        >
                            <Button style={{ marginLeft: '15px' }} type="primary" loading={loadingExport}>
                                Upload Excel
                            </Button>
                        </Upload>
                        <Button type="primary" onClick={exportFile} loading={exportLoading}>Export Excel</Button>
                        <Button type="primary" onClick={insertRecord}>Insert</Button>
                        <Popconfirm
                            title="Xoá bản ghi"
                            description={"Bạn có chắc xoá " + selectedRowKeys.length + " bản ghi đã chọn?"}
                            onConfirm={deleteMultiple}
                            okText="Có"
                            cancelText="Không"
                            placement="bottomRight"
                        >
                            <Button type="primary" disabled={selectedRowKeys.length <= 0} danger>Delete</Button>
                        </Popconfirm>
                    </Space>
                }>
                    <Spin spinning={loading}>
                        <Table size='small' bordered
                            pagination={{
                                current: page,
                                pageSize: pageSize,
                                total: total,
                                onChange: (page, pageSize) => {
                                    btn_click(page, pageSize)
                                }
                            }}
                            columns={columns}
                            dataSource={data}
                            scroll={{ x: '950px' }}
                            rowSelection={rowSelection} />
                    </Spin>
                </Card>
            </Col>
        </Row>
        <Modal title={isEdit ? 'Cập nhật' : 'Thêm mới'} open={openMdl} onCancel={onCancel} footer={null} width={800}>
            <Form style={{ margin: '0 15px' }}
                layout="vertical"
                form={form}
                onFinish={onFinish}>
                <Row gutter={5}>
                    <Col span={12}>
                        <Form.Item name={'id'} className='mb-3' label={'Mã đơn'} rules={[{ required: true }]}>
                            <Input placeholder="Mã đơn hàng" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'order_number'} className='mb-3' label={'Số đơn hàng'} rules={[{ required: true }]}>
                            <Input placeholder="Số đơn hàng" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'customer_id'} className='mb-3' label={'Khách hàng'} rules={[{ required: true }]}>
                            <Select showSearch allowClear placeholder="Chọn khách hàng" options={customerOptions} optionFilterProp='label' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'product_id'} className='mb-3' label={'Mã hàng'} rules={[{ required: true }]}>
                            <Select showSearch allowClear placeholder="Chọn mã hàng" options={productOptions} optionFilterProp='value' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'quantity'} className='mb-3' label={'Số lượng'} rules={[{ required: true }]}>
                            <InputNumber min={0} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'order_date'} className='mb-3' label={'Ngày đặt hàng'} rules={[{ required: true }]}>
                            <DatePicker showTime={false} allowClear={false} placeholder="Ngày đặt" format="DD/MM/YYYY" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'delivery_date'} className='mb-3' label={'Ngày giao'} rules={[{ required: false }]}>
                            <DatePicker showTime={false} allowClear={false} placeholder="Ngày giao" format="DD/MM/YYYY" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'note'} className='mb-3' label={'Ghi chú thay đổi'} rules={[{ required: false }]}>
                            <Input placeholder="Ghi chú thay đổi" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item className='mb-0 text-end'>
                    <Button type="primary" htmlType='submit'>Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default ProductOrder;
