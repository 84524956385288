function collectionHas(a, b) {
  //helper function (see below)
  for (var i = 0, len = a.length; i < len; i++) {
    if (a[i] == b) return true;
  }
  return false;
}
function findParentBySelector(elm, selector) {
  var all = document.querySelectorAll(selector);
  var cur = elm?.parentNode;
  while (cur && !collectionHas(all, cur)) {
    //keep going up until you find a match
    cur = cur.parentNode; //go up
  }
  return cur; //will return null if not found
}
const CalculateTableHeight = (tableClassName) => {
  const table = document.querySelector("." + tableClassName);
  const card = findParentBySelector(table, ".ant-card");
  const cardHead = card?.querySelector(".ant-card-head");
  const cardBody = card?.querySelector(".ant-card-body");
  const header = table?.querySelector(".ant-table-thead");
  const pagination = table?.querySelector(".ant-table-pagination");
  const tableBody = table?.querySelector(".ant-table-body");
  const antEmtpy = table?.querySelector(".ant-empty-normal");
  var paddingTop = 0;
  var paddingBottom = 0;
  if (cardBody) {
    const style = window.getComputedStyle(cardBody);
    paddingTop = parseFloat(style.paddingTop);
    paddingBottom = parseFloat(style.paddingBottom);
  }
  const result =
    (card?.offsetHeight ?? 0) -
    (cardHead?.offsetHeight ?? 0) -
    (header?.offsetHeight ?? 0) -
    (pagination?.offsetHeight ?? 0) * 2 -
    paddingTop -
    paddingBottom -
    6;
  if (tableBody)
    tableBody?.style.setProperty("height", `${result}px`, "important");
  if (antEmtpy) antEmtpy?.style.setProperty("height", "100vh");
  return result;
};

export default CalculateTableHeight;
