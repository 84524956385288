import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Layout, Table, Col, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { getProductionSituation } from '../../api/phase2/dashboard/main';
import './THSX.css';

const colTable = [
  {
    title: 'CÔNG ĐOẠN',
    dataIndex: 'cong_doan',
    key: 'cong_doan',
    align: 'center',
  },
  {
    title: 'TÊN SẢN PHẨM',
    dataIndex: 'product',
    key: 'product',
    align: 'center',
  },
  {
    title: 'SL KẾ HOẠCH',
    dataIndex: 'sl_dau_ra_kh',
    key: 'sl_dau_ra_kh',
    align: 'center',
  },
  {
    title: 'MỤC TIÊU',
    dataIndex: 'sl_muc_tieu',
    key: 'sl_muc_tieu',
    align: 'center',
  },
  {
    title: 'SL THỰC TẾ',
    dataIndex: 'sl_thuc_te',
    key: 'sl_thuc_te',
    align: 'center',
  },
  {
    title: 'TỈ LỆ HOÀN THÀNH (%)',
    dataIndex: 'ti_le_ht',
    key: 'ti_le_ht',
    align: 'center',
  },
  {
    title: 'TỈ LỆ NG (%)',
    dataIndex: 'ti_le_ng',
    key: 'ti_le_ng',
    align: 'center',
  },
  {
    title: 'TÌNH TRẠNG',
    dataIndex: 'status',
    key: 'status',
    render: (value) => {
      const colors = {
        1: 'orange',
        2: 'blue',
        3: 'green',
        default: '#929292',
      };
      const color = colors[value] || colors.default;
      return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <div style={{
            width: '70px',
            height: '70px',
            background: color,
            borderRadius: '50%'
          }} />
        </div>
      );
    },
    align: 'center',
  },
];

const TinhHinhSanXuat = () => {
  const history = useHistory();
  const [clock, setClock] = useState(new Date());
  const [dataTable, setDataTable] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const tableBodyRef = useRef(null);

  const loadData = async () => {
    const res1 = await getProductionSituation();
    const data = res1.data.map(e => ({ ...e }));
    setDataTable(data);
  };

  useEffect(() => {
    const tick = () => setClock(new Date());
    const clockInterval = setInterval(tick, 1000);
    const dataInterval = setInterval(loadData, 5000);

    loadData();

    return () => {
      clearInterval(clockInterval);
      clearInterval(dataInterval);
    };
  }, []);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex(prevIndex => {
        const newIndex = (prevIndex + 1) % dataTable.length;
        if (tableBodyRef.current) {
          const rowHeight = tableBodyRef.current.querySelector('tr').clientHeight;
          tableBodyRef.current.style.transform = `translateY(-${newIndex * rowHeight}px)`;
        }
        return newIndex;
      });
    }, 4000); // Thay đổi slide mỗi 2 giây

    return () => clearInterval(slideInterval);
  }, [dataTable]);

  return (
    <React.Fragment>
      <Layout style={{ backgroundColor: '#e3eaf0' }}>
        <Row className='w-100' style={{ verticalAlign: 'center', justifyContent: 'space-between', padding: '10px', backgroundColor: '#2462a3', color: 'white' }}>
          <div style={{ fontSize: '2em', fontWeight: '700', lineHeight: '2.2em' }}>{clock.toLocaleString(['en-GB'], { hour12: false })}</div>
          <div style={{ fontWeight: 700, fontSize: '3em' }}>TÌNH HÌNH SẢN XUẤT</div>
          <Link to={'/screen'} style={{ margin: 'auto 0' }}>
            <CloseOutlined className='text-white' style={{ fontSize: '1.4em' }} />
          </Link>
        </Row>
        <Row style={{ padding: '15px' }} gutter={[8, 8]}>
          <Col span={24}>
            <div className="table-container">
              <Table
                columns={colTable}
                dataSource={dataTable}
                pagination={false}
                scroll={{ y: 240 }}
                components={{
                  body: {
                    wrapper: (props) => (
                      <tbody {...props} ref={tableBodyRef} className="slide-row" />
                    ),
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      </Layout>
    </React.Fragment>
  );
};

export default TinhHinhSanXuat;
