
import { Column } from "@antv/g2plot";
import { Card } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Highcharts from "highcharts";
import { getErrorFrequencyData, getOEEData } from "../../../../api/phase2/ui/equipment";

const ErrorFrequencyChart = forwardRef((params, ref) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await getErrorFrequencyData(params);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };
    useImperativeHandle(ref, () => ({
        fetchChartData() {
            fetchData(params);
        },
    }));
    useEffect(() => {
        if (data.length === 0) {
            return;
        }
        const options = {
            chart: {
                type: 'pie',
                height: 250,
            },
            title: false,
            plotOptions: {
                series: {
                    allowPointSelect: false,
                    cursor: 'pointer',
                    dataLabels: [{
                        enabled: true,
                        distance: 20
                    }, {
                        enabled: true,
                        distance: -40,
                        format: '{point.percentage:.1f}%',
                        style: {
                            fontSize: '1em',
                            opacity: 0.7
                        },
                        filter: {
                            operator: '>',
                            property: 'percentage',
                            value: 10
                        }
                    }]
                }
            },
            series: [
                {
                    name: 'Số lần xuất hiện',
                    colorByPoint: true,
                    data: data
                }
            ],
            exporting: false,
            credits: { enabled: false }
        };

        Highcharts.chart("error-frequency-chart", options);
    }, [data]);

    return <Card title="Tần suất phát sinh lỗi"
        loading={loading}
        styles={{ body: { padding: '0px', height: '100%' } }}
        style={
            { padding: '0px', height: '100%' }
        }>
        <div id="error-frequency-chart" />
    </Card>
});

export default ErrorFrequencyChart;