import React, { useState, useEffect } from "react";
import {
  DatePicker,
  Col,
  Row,
  Card,
  Divider,
  Button,
  Form,
  Space,
  Spin,
  Radio,
} from "antd";
import "../style.scss";
import {
  getKpiPassRateChart,
  getKpiTiLeVanHanhTB,
  getKpiTiLeHoanThanhKeHoach,
  getKpiTiLeLoiCongDoan,
  getKpiHieuSuatThietBi,
  getKpiSoLanDungMay,
} from "../../../api/ui/main";
import dayjs from "dayjs";
import PassRateChart from "./PassRateChart";
import QualityLineChart from "./QualityLineChart";
import TyLeVanHanhTB from "./TyLeVanHanhTB";
import TyLeHoanThanhKeHoach from "./TyLeHoanThanhKeHoach";
import TyLeLoiCongDoan from "./TyLeLoiCongDoan";
import MachineEfficiencyChart from "./MachineEfficiencyChart";
import DowntimeChart from "./DowntimeChart";
import { useHistory } from "react-router-dom";

const KPI = (props) => {
  document.title = "UI - KPI";
  const history = useHistory();
  const [listLines, setListLines] = useState([]);
  const [form] = Form.useForm();
  const dateType = Form.useWatch("dateType", form);

  const [params, setParams] = useState({
    dateType: "date",
    start_date: dayjs().subtract(6, "day"),
    end_date: dayjs(),
  });
  const [dataTiLeVanHanhTB, setDataTiLeVanHanhTB] = useState([]);
  const [dataPassRateChart, setDataPassRateChart] = useState([]);
  const [dataTiLeHoanThanhKeHoach, setDataTiLeHoanThanhKeHoach] = useState([]);
  const [dataTiLeLoiCongDoan, setDataTiLeLoiCongDoan] = useState([]);
  const [dataHieuSuatThietBi, setHieuSuatThietBi] = useState([]);
  const [dataSoLanDungMay, setSoLanDungMay] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [columnsDate, setColumnsDate] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const count = params.end_date.diff(params.start_date, "day");
    const colDate = [];
    if (count > 0) {
      for (let i = 0; i <= count; i++) {
        colDate.push({
          title: params.start_date.add(i, "day").format("DD-MM-YYYY"),
          dataIndex: params.start_date.add(i, "day").format("YYYY-MM-DD"),
          key: params.start_date.add(i, "day").format("YYYY-MM-DD"),
          align: "center",
        });
      }
    }
    setColumnsDate(colDate);
  }, [dataTable]);

  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    setTreeData(
      listLines.map((e) => {
        let child = e.machine?.map((i) => {
          return { title: i.name, key: i.id };
        });
        return {
          ...e,
          title: e.name,
          key: e.id,
          children: child,
        };
      })
    );
  }, [listLines]);

  async function btn_click() {
    const start_date = dayjs(form.getFieldValue("start_date")).format(
      "YYYY-MM-DD"
    );
    const end_date = dayjs(form.getFieldValue("end_date")).format("YYYY-MM-DD");
    const dataType = form.getFieldValue("dateType");
    const res1 = await getKpiPassRateChart({
      start_date: start_date,
      end_date: end_date,
      dateType: dataType,
    });
    setDataPassRateChart([...res1.data]);
    const res2 = await getKpiTiLeVanHanhTB({
      start_date: start_date,
      end_date: end_date,
      dateType: dataType,
    });
    setDataTiLeVanHanhTB([...res2.data]);
    const res3 = await getKpiTiLeHoanThanhKeHoach({
      start_date: start_date,
      end_date: end_date,
      dateType: dataType,
    });
    setDataTiLeHoanThanhKeHoach([...res3.data]);
    const res4 = await getKpiTiLeLoiCongDoan({
      start_date: start_date,
      end_date: end_date,
      dateType: dataType,
    });
    setDataTiLeLoiCongDoan([...res4.data]);
    const res5 = await getKpiHieuSuatThietBi({
      start_date: start_date,
      end_date: end_date,
      dateType: dataType,
    });
    setHieuSuatThietBi([...res5.data]);
    setDataTiLeLoiCongDoan([...res4.data]);
    const res6 = await getKpiSoLanDungMay({
      start_date: start_date,
      end_date: end_date,
      dateType: dataType,
    });
    setSoLanDungMay([...res6.data]);
    setParams(form.getFieldsValue());
    setParams(form.getFieldsValue());
  }

  useEffect(() => {
    btn_click();
  }, []);

  const customDateFormat = (value) => {
    switch (dateType) {
      case "week":
        return `Tuần ${dayjs(value).format("WW - GGGG")}`;
        break;
      case "month":
        return `Tháng ${dayjs(value).format("MM")} - ${dayjs(value).format(
          "YYYY"
        )}`;
        break;
      case "year":
        return `Năm ${dayjs(value).format("YYYY")}`;
        break;
      default:
        return dayjs(value).format("DD/MM/YYYY");
        break;
    }
  };
  useEffect(() => {
    onChangeDate("start_date", params.start_date, dateType);
    onChangeDate("end_date", params.end_date, dateType);
  }, [dateType]);
  const onChangeDate = (position, value, dateType) => {
    switch (dateType) {
      case "week":
        if (position === "start_date") {
          setParams({
            ...params,
            start_date: dayjs(value).startOf("isoWeek"),
            end_date: dayjs(params.end_date).endOf("isoWeek"),
          });
        } else {
          setParams({
            ...params,
            start_date: dayjs(value).startOf("isoWeek"),
            end_date: dayjs(params.end_date).endOf("isoWeek"),
          });
        }
        break;
      case "month":
        if (position === "start_date") {
          setParams({
            ...params,
            start_date: dayjs(value).startOf("month"),
            end_date: dayjs(params.end_date).endOf("month"),
          });
        } else {
          setParams({
            ...params,
            start_date: dayjs(value).startOf("month"),
            end_date: dayjs(params.end_date).endOf("month"),
          });
        }
        break;
      case "year":
        if (position === "start_date") {
          setParams({
            ...params,
            start_date: dayjs(value).startOf("year"),
            end_date: dayjs(params.end_date).endOf("year"),
          });
        } else {
          setParams({
            ...params,
            start_date: dayjs(value).startOf("year"),
            end_date: dayjs(params.end_date).endOf("year"),
          });
        }
        break;
      default:
        setParams({ ...params, [position]: value });
        break;
    }
  };

  const handleReportPPT = () => {
    const start_date = dayjs(form.getFieldValue("start_date")).format(
      "YYYY-MM-DD"
    );
    const end_date = dayjs(form.getFieldValue("end_date")).format("YYYY-MM-DD");
    const dataType = form.getFieldValue("dateType");
    if (start_date && end_date && dataType) {
      history.push(
        `/ui/pptist?start_date=${start_date}&end_date=${end_date}&dataType=${dataType}`
      );
    }
  };
  return (
    <React.Fragment>
      <Row style={{ padding: "8px" }} gutter={[8, 8]} className="custom-row">
        <Col span={4} className="custom-col">
          <Card
            bodyStyle={{ padding: 0 }}
            className="custom-ant-card"
            actions={[
              <Button
                type="primary"
                style={{ width: "90%" }}
                onClick={() => btn_click()}
              >
                Truy vấn
              </Button>,
            ]}
          >
            <Form
              style={{ margin: "0 15px" }}
              layout="vertical"
              form={form}
              onFinish={() => btn_click()}
              initialValues={params}
            >
              <Divider>Thời gian truy vấn</Divider>
              <Form.Item label="Chọn thời gian" name="dateType" noStyle>
                <Radio.Group
                  options={[
                    {
                      value: "date",
                      label: "Ngày",
                      style: {
                        width: "33%",
                        justifyContent: "center",
                        display: "flex",
                      },
                    },
                    {
                      value: "week",
                      label: "Tuần",
                      style: {
                        width: "33%",
                        justifyContent: "center",
                        display: "flex",
                      },
                    },
                    {
                      value: "month",
                      label: "Tháng",
                      style: {
                        width: "33%",
                        justifyContent: "center",
                        display: "flex",
                      },
                    },
                    {
                      value: "year",
                      label: "Năm",
                      style: {
                        width: "33%",
                        justifyContent: "center",
                        display: "flex",
                      },
                    },
                  ]}
                  size="small"
                  className="d-flex justify-content-center mb-2 w-100"
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>

              <Space direction="vertical" style={{ width: "100%" }}>
                <Form.Item label="Chọn ngày" name="start_date" noStyle>
                  <DatePicker
                    allowClear={false}
                    placeholder="Bắt đầu"
                    style={{ width: "100%" }}
                    picker={dateType}
                    format={customDateFormat}
                  />
                </Form.Item>
                <Form.Item label="Chọn ngày" name="end_date" noStyle>
                  <DatePicker
                    allowClear={false}
                    placeholder="Kết thúc"
                    style={{ width: "100%" }}
                    picker={dateType}
                    format={customDateFormat}
                  />
                </Form.Item>
              </Space>
            </Form>
          </Card>
        </Col>
        <Col span={20} className="custom-col">
          <Spin spinning={loading}>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <div
                  style={{
                    textAlign: "right",
                    padding: "10px",
                    backgroundColor: "white",
                  }}
                >
                  <Button type="primary" onClick={handleReportPPT}>
                    Báo cáo powerpoint
                  </Button>
                </div>
              </Col>
              <Col span={12}>
                <PassRateChart dataChart={dataPassRateChart} />
              </Col>
              <Col span={12}>
                <TyLeVanHanhTB dataChart={dataTiLeVanHanhTB} />
              </Col>
              <Col span={12}>
                <TyLeHoanThanhKeHoach dataChart={dataTiLeHoanThanhKeHoach} />
              </Col>
              <Col span={12}>
                <TyLeLoiCongDoan dataChart={dataTiLeLoiCongDoan} />
              </Col>
              <Col span={12}>
                <MachineEfficiencyChart dataChart={dataHieuSuatThietBi} />
              </Col>
              <Col span={12}>
                <DowntimeChart dataChart={dataSoLanDungMay} />
              </Col>
              {/* <Col span={12}>
                <QualityLineChart line="In" />
              </Col>
              <Col span={12}>
                <QualityLineChart line="Phủ" />
              </Col>
              <Col span={12}>
                <QualityLineChart line="Bế" />
              </Col>
              <Col span={12}>
                <QualityLineChart line="Bóc phôi" />
              </Col>
              <Col span={12}>
                <QualityLineChart line="Gấp dán" />
              </Col>
              <Col span={12}>
                <QualityLineChart line="Chọn" />
              </Col>
              <Col span={12}>
                <QualityLineChart line="OQC" />
              </Col> */}
            </Row>
          </Spin>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default KPI;
