import axios from "axios";
const prefix = 'p2/dasboard/';
export async function getProductionSituation(params) {
    const res = await axios.get(prefix+'produce/fmb', {params});
    return res;
}
export async function getMachinePerfomance(params) {
    const res = await axios.get(prefix+'machine-performance', {params});
    return res;
}

export async function getReportPPTX(params) {
    const res = await axios.get('create-pptx', {params});
    return res;
}
export async function getProductionSituationLineIn(params) {
    const res = await axios.get(prefix+'production-situation-line-in', {params});
    return res;
}
export async function getProductionSituationByMachine(params) {
    const res = await axios.get(prefix+'production-situation-by-machine', {params});
    return res;
}