import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Upload, message, Checkbox, Space, Modal, Spin, Popconfirm } from 'antd';
import { baseURL } from '../../../config';
import React, { useState, useRef, useEffect } from 'react';
import { createTestCriteria, deleteTestCriteria, exportTestCriteria, getTestCriteria, importTestCriteria, updateTestCriteria } from '../../../api';
import { getLineList } from '../../../api/phase2/oi/manufacture';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const TestCriteria = () => {
    document.title = "Quản lý chỉ tiêu kiểm tra";
    const [listCheck, setListCheck] = useState([]);
    const [openMdl, setOpenMdl] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm();
    const [params, setParams] = useState({});
    const [total, setTotal] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [editingRecord, setEditingRecord] = useState();
    const [frequency, setFrequency] = useState([]);
    const col_detailTable = [
        {
            title: 'Mã chỉ tiêu',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            fixed: 'left',
            width: 100
        },
        {
            title: 'Công đoạn',
            dataIndex: 'lines',
            key: 'lines',
            align: 'center',
            fixed: 'left',
            render: (value) => ((value ?? []).map(e => e?.name)).join(', ')
        },
        {
            title: 'Hạng mục',
            dataIndex: 'hang_muc',
            key: 'hang_muc',
            align: 'center',
            fixed: 'left'
        },
        {
            title: 'Chỉ tiêu',
            dataIndex: 'chi_tieu',
            key: 'chi_tieu',
            align: 'center',
            width: 100
        },

        {
            title: 'Tiêu chuẩn',
            dataIndex: 'tieu_chuan',
            key: 'tieu_chuan',
            align: 'center',
        },
        {
            title: 'Phân định',
            dataIndex: 'phan_dinh',
            key: 'phan_dinh',
            align: 'center',
            width: 100
        },
        {
            title: 'Tần suất',
            dataIndex: 'frequency',
            key: 'frequency',
            align: 'center',
            width: 200
        },
        {
            title: 'Tham chiếu TCKT công đoạn',
            dataIndex: 'ref_line',
            key: 'ref_line',
            align: 'center',
            render: (value, item, index) => value?.name,
        },
        {
            title: 'Tác vụ',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: 60,
            render: (_, record) => <Space wrap>
                <EditOutlined className='edit-btn' onClick={() => onEdit(record)} />
                <Popconfirm title="Xoá bản ghi"
                    description={"Bạn có chắc muốn xoá?"} onConfirm={() => deleteRecord(record)}>
                    <DeleteOutlined className='delete-btn' />
                </Popconfirm>

            </Space>
        },
    ]
    const [lines, setLines] = useState([]);
    const fetchLines = async () => {
        const res = await getLineList({ type: "cl" });
        setLines(res.data);
    }
    function btn_click(page = 1, pageSize = 20) {
        setPage(page);
        setPageSize(pageSize);
        loadListTable({ ...formSearch.getFieldsValue(true), page, pageSize })
    }

    const [data, setData] = useState([]);
    const loadListTable = async (params) => {
        setLoading(true)
        const res = await getTestCriteria(params);
        setData(res.data.map(e => {
            return { ...e, key: e.id }
        }));
        setFrequency((res.frequency ?? []).map(e=>({value: e, label: e})));
        setTotal(res.total)
        setLoading(false);
    }
    useEffect(() => {
        loadListTable(params);
        fetchLines();
    }, []);

    const onFinish = async (values) => {
        console.log(values);
        if (isEdit) {
            const res = await updateTestCriteria(editingRecord?.id, values);
            console.log(res);
            if (res) {
                form.resetFields();
                setOpenMdl(false);
                loadListTable(params);
            }
        } else {
            const res = await createTestCriteria(values);
            console.log(res);
            if (res) {
                form.resetFields();
                setOpenMdl(false);
                loadListTable(params);
            }
        }
        setEditingRecord();
    }

    const deleteRecord = async (record) => {
        const res = await deleteTestCriteria([record?.id]);
        setListCheck([]);
        loadListTable(params);
    }
    const onEdit = (record) => {
        setIsEdit(true);
        setEditingRecord(record);
        form.setFieldsValue({ ...record, line: record.lines.map(e => e?.id), });
        setOpenMdl(true);
    }
    const insertRecord = () => {
        setIsEdit(false)
        form.resetFields();
        setOpenMdl(true);
    }
    const [loadingExport, setLoadingExport] = useState(false);
    const [loading, setLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportTestCriteria(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setListCheck(selectedRowKeys)
        },
    };
    const [formSearch] = Form.useForm();
    return <>
        <Row style={{ padding: '8px', marginRight: 0 }} gutter={[8, 8]}>
            <Col span={3} className='custom-col'>
                <Card style={{ height: '100%' }} bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => formSearch.submit()}>Truy vấn</Button>
                ]}>
                    <Form style={{ margin: '0 8px' }} layout="vertical" form={formSearch} onFinish={() => btn_click()}>
                        <Divider>Điều kiện truy vấn</Divider>
                        <Form.Item label="Công đoạn" className='mb-3' name={"line"}>
                            <Input allowClear onChange={(e) => setParams({ ...params, line: e.target.value })} placeholder='Nhập mã' />
                        </Form.Item>
                        <Form.Item label="Hạng mục" className='mb-3' name={'hang_muc'}>
                            <Input allowClear onChange={(e) => setParams({ ...params, hang_muc: e.target.value })} placeholder='Nhập tên' />
                        </Form.Item>
                        <Form.Item label="Chỉ tiêu" className='mb-3' name={'chi_tieu'}>
                            <Input allowClear onChange={(e) => setParams({ ...params, hang_muc: e.target.value })} placeholder='Nhập tên' />
                        </Form.Item>
                        <button type='submit' hidden />
                    </Form>
                </Card>
            </Col>
            <Col span={21} className='custom-col'>
                <Card className='custom-ant-card' style={{ height: '100%' }} title="Quản lý chỉ tiêu kiểm tra" extra={
                    <Space>
                        <Upload
                            showUploadList={false}
                            customRequest={async ({ file, onSuccess, onError }) => {
                                setLoadingExport(true);
                                const formData = new FormData();
                                formData.append('file', file);
                                const res = await importTestCriteria(formData);
                                if (res.success) {
                                    loadListTable(params);
                                    setLoadingExport(false);
                                } else {
                                    loadListTable(params);
                                    setLoadingExport(false);
                                }
                            }}
                        >
                            <Button style={{ marginLeft: '15px' }} type="primary" loading={loadingExport}>
                                Upload Excel
                            </Button>
                        </Upload>
                    </Space>
                }>
                    <Table size='small' bordered
                        loading={loading}
                        scroll={
                            {
                                x: '100%',
                                y: '80vh'
                            }
                        }
                        pagination={{
                            current: page,
                            pageSize: pageSize,
                            total: total,
                            onChange: (page, pageSize) => {
                                btn_click(page, pageSize)
                            }
                        }}
                        columns={col_detailTable}
                        dataSource={data}
                        rowSelection={rowSelection} />
                </Card>
            </Col>
        </Row>
        <Modal title={isEdit ? 'Cập nhật' : 'Thêm mới'} open={openMdl} onCancel={() => setOpenMdl(false)} footer={null} width={800}>
            <Form style={{ margin: '0 15px' }}
                layout="vertical"
                form={form}
                onFinish={onFinish}>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item name={'id'} className='mb-3' label={"Mã chỉ tiêu"} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'line'} className='mb-3' label={"Công đoạn"} rules={[{ required: true }]}>
                            <Select options={lines} mode='multiple' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'hang_muc'} className='mb-3' label={"Hạng mục"} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'chi_tieu'} className='mb-3' label={"Chỉ tiêu"}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'phan_dinh'} className='mb-3' label={"Phán định"}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'reference'} className='mb-3' label={"Tham chiếu công đoạn"}>
                            <Select options={lines} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'frequency'} className='mb-3' label={"Tần suất kiểm tra"}>
                            <Select options={frequency} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item className='mb-0'>
                    <Button type="primary" htmlType='submit' >Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default TestCriteria;
