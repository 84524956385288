import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import axios from 'axios';
import * as echarts from 'echarts';
import { mockData } from './mockData';
import { getMachine } from '../../../api';

const GanttChart = () => {
    const ROOT_PATH = 'https://echarts.apache.org/examples';
    const [option, setOption] = useState(null);
    const HEIGHT_RATIO = 0.6;
    const DIM_CATEGORY_INDEX = 0;
    const DIM_TIME_ARRIVAL = 1;
    const DIM_TIME_DEPARTURE = 2;
    const DATA_ZOOM_AUTO_MOVE_THROTTLE = 30;
    const DATA_ZOOM_X_INSIDE_INDEX = 1;
    const DATA_ZOOM_Y_INSIDE_INDEX = 3;
    const DATA_ZOOM_AUTO_MOVE_SPEED = 0.2;
    const DATA_ZOOM_AUTO_MOVE_DETECT_AREA_WIDTH = 30;
    let _draggable = false;
    let _draggingEl;
    let _dropShadow;
    let _draggingCursorOffset = [0, 0];
    let _draggingTimeLength;
    let _draggingRecord;
    let _dropRecord;
    let _cartesianXBounds = [];
    let _cartesianYBounds = [];
    let _rawData;
    let _autoDataZoomAnimator;

    async function fetchData() {
        const response = await getMachine({ withs: 'plan' });
        const rawData = {
            parkingApron: {
                dimensions: ['code', 'id', 'fixed'],
                data: []
            },
            flight: {
                dimensions: [
                    "Index",
                    "Thời gian bắt đầu",
                    "Thời gian kết thúc",
                    "Flight Number",
                    "Machine",
                    "Arrival Company",
                    "Departure Company",
                    "Arrival Line",
                    "Departure Line",
                    "Report Time"
                ],
                data: []
            }
        };
        if (response) {
            response.map((machine, index) => {
                rawData.parkingApron.data.push([
                    machine.name,              // Name
                    // machine.kieu_loai,         // Type
                    '',
                    machine.is_iot === 1       // Near Bridge (giả định dựa vào is_iot)
                ]);

                // Thêm các lô sản xuất (batch) của máy vào flight.data
                machine.plan.forEach((batch) => {
                    rawData.flight.data.push([
                        index,                                  // Parking Apron Index (sử dụng index của máy)
                        new Date(batch.thoi_gian_bat_dau).getTime(), // Arrival Time
                        new Date(batch.thoi_gian_ket_thuc).getTime(), // Departure Time
                        batch.lo_sx,                            // Flight Number (sử dụng lo_sx làm mã lô)
                        machine.name,                                  // VIP (giả định là false, có thể điều chỉnh tùy theo yêu cầu)
                        "",                                     // Arrival Company (chưa có dữ liệu, đặt là chuỗi rỗng)
                        "",                                     // Departure Company (chưa có dữ liệu, đặt là chuỗi rỗng)
                        machine.line ? machine.line.name : "",  // Arrival Line (sử dụng line.name)
                        machine.line ? machine.line.name : "",  // Departure Line (sử dụng line.name)
                        new Date(batch.created_at).getTime()    // Report Time (thời gian tạo lô)
                    ]);
                });
            });
        }
        _rawData = rawData;
        setOption(makeOption());
    }

    useEffect(() => {
        fetchData();
        // _rawData = mockData();
        // setOption(makeOption());
    }, []);

    const makeOption = () => {
        return {
            tooltip: {},
            animation: false,
            toolbox: {
                left: 20,
                top: 0,
                itemSize: 20,
                feature: {
                    myDrag: {
                        show: true,
                        title: 'Make bars draggable',
                        icon: 'path://M990.55 380.08 q11.69 0 19.88 8.19 q7.02 7.01 7.02 18.71 l0 480.65 q-1.17 43.27 -29.83 71.93 q-28.65 28.65 -71.92 29.82 l-813.96 0 q-43.27 -1.17 -72.5 -30.41 q-28.07 -28.07 -29.24 -71.34 l0 -785.89 q1.17 -43.27 29.24 -72.5 q29.23 -29.24 72.5 -29.24 l522.76 0 q11.7 0 18.71 7.02 q8.19 8.18 8.19 18.71 q0 11.69 -7.6 19.29 q-7.6 7.61 -19.3 7.61 l-518.08 0 q-22.22 1.17 -37.42 16.37 q-15.2 15.2 -15.2 37.42 l0 775.37 q0 23.39 15.2 38.59 q15.2 15.2 37.42 15.2 l804.6 0 q22.22 0 37.43 -15.2 q15.2 -15.2 16.37 -38.59 l0 -474.81 q0 -11.7 7.02 -18.71 q8.18 -8.19 18.71 -8.19 l0 0 ZM493.52 723.91 l-170.74 -170.75 l509.89 -509.89 q23.39 -23.39 56.13 -21.05 q32.75 1.17 59.65 26.9 l47.94 47.95 q25.73 26.89 27.49 59.64 q1.75 32.75 -21.64 57.3 l-508.72 509.9 l0 0 ZM870.09 80.69 l-56.13 56.14 l94.72 95.9 l56.14 -57.31 q8.19 -9.35 8.19 -21.05 q-1.17 -12.86 -10.53 -22.22 l-47.95 -49.12 q-10.52 -9.35 -23.39 -9.35 q-11.69 -1.17 -21.05 7.01 l0 0 ZM867.75 272.49 l-93.56 -95.9 l-380.08 380.08 l94.73 94.73 l378.91 -378.91 l0 0 ZM322.78 553.16 l38.59 39.77 l-33.92 125.13 l125.14 -33.92 l38.59 38.6 l-191.79 52.62 q-5.85 1.17 -12.28 0 q-6.44 -1.17 -11.11 -5.84 q-4.68 -4.68 -5.85 -11.7 q-2.34 -5.85 0 -11.69 l52.63 -192.97 l0 0 Z',
                        onclick: onDragSwitchClick
                    }
                }
            },
            title: {
                text: 'Gantt of Production',
                left: 'center'
            },
            dataZoom: [
                {
                    type: 'slider',
                    xAxisIndex: 0,
                    filterMode: 'weakFilter',
                    height: 20,
                    bottom: 0,
                    start: 0,
                    end: 50,
                    handleIcon:
                        'path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%',
                    showDetail: false
                },
                {
                    type: 'inside',
                    id: 'insideX',
                    xAxisIndex: 0,
                    filterMode: 'weakFilter',
                    start: 0,
                    end: 50,
                    zoomOnMouseWheel: true,
                    moveOnMouseMove: true
                },
                {
                    type: 'slider',
                    yAxisIndex: 0,
                    zoomLock: true,
                    width: 10,
                    right: 10,
                    top: 70,
                    bottom: 20,
                    start: 5,
                    end: 100,
                    handleSize: 0,
                    showDetail: false
                },
                {
                    type: 'inside',
                    id: 'insideY',
                    yAxisIndex: 0,
                    start: 5,
                    end: 100,
                    zoomOnMouseWheel: true,
                    moveOnMouseMove: true,
                    moveOnMouseWheel: true
                }
            ],
            grid: {
                show: true,
                top: 70,
                bottom: 20,
                left: 100,
                right: 20,
                backgroundColor: '#fff',
                borderWidth: 0
            },
            xAxis: {
                type: 'time',
                position: 'top',
                // minInterval: 24 * 3600 * 1000, // Thiết lập khoảng thời gian nhỏ nhất là 1 ngày
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: ['#E9EDFF']
                    }
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    lineStyle: {
                        color: '#929ABA'
                    }
                },
                axisLabel: {
                    color: '#929ABA',
                    inside: false,
                    align: 'center',
                    // formatter: (value) => {
                    //     // Format nhãn thành ngày tháng
                    //     const date = new Date(value);
                    //     return `${date.getDate()}/${date.getMonth() + 1}`;
                    // }
                }
            },
            yAxis: {
                axisTick: { show: false },
                splitLine: { show: false },
                axisLine: { show: false },
                axisLabel: { show: false },
                min: 0,
                max: _rawData.parkingApron.data.length
            },
            series: [
                {
                    id: 'flightData',
                    type: 'custom',
                    renderItem: renderGanttItem,
                    dimensions: _rawData.flight.dimensions,
                    encode: {
                        x: [DIM_TIME_ARRIVAL, DIM_TIME_DEPARTURE],
                        y: DIM_CATEGORY_INDEX,
                        tooltip: [DIM_CATEGORY_INDEX, DIM_TIME_ARRIVAL, DIM_TIME_DEPARTURE]
                    },
                    data: _rawData.flight.data
                },
                {
                    type: 'custom',
                    renderItem: renderAxisLabelItem,
                    dimensions: _rawData.parkingApron.dimensions,
                    encode: {
                        x: -1,
                        y: 0
                    },
                    data: _rawData.parkingApron.data.map(function (item, index) {
                        return [index].concat(item);
                    })
                }
            ]
        };
    };

    const renderGanttItem = (params, api) => {
        const categoryIndex = api.value(DIM_CATEGORY_INDEX);
        const timeArrival = api.coord([api.value(DIM_TIME_ARRIVAL), categoryIndex]);
        const timeDeparture = api.coord([api.value(DIM_TIME_DEPARTURE), categoryIndex]);
        const coordSys = params.coordSys;
        _cartesianXBounds[0] = coordSys.x;
        _cartesianXBounds[1] = coordSys.x + coordSys.width;
        _cartesianYBounds[0] = coordSys.y;
        _cartesianYBounds[1] = coordSys.y + coordSys.height;
        const barLength = timeDeparture[0] - timeArrival[0];
        const barHeight = api.size([0, 1])[1] * HEIGHT_RATIO;
        const x = timeArrival[0];
        const y = timeArrival[1] - barHeight;
        const flightNumber = api.value(3) + '';
        const flightNumberWidth = echarts.format.getTextRect(flightNumber).width;
        const text =
            barLength > flightNumberWidth + 40 && x + barLength >= 180
                ? flightNumber
                : '';
        const rectNormal = clipRectByRect(params, {
            x: x,
            y: y,
            width: barLength,
            height: barHeight
        });
        const rectVIP = clipRectByRect(params, {
            x: x,
            y: y,
            width: barLength / 2,
            height: barHeight
        });
        const rectText = clipRectByRect(params, {
            x: x,
            y: y,
            width: barLength,
            height: barHeight
        });
        return {
            type: 'group',
            children: [
                {
                    type: 'rect',
                    ignore: !rectNormal,
                    shape: rectNormal,
                    style: api.style()
                },
                {
                    type: 'rect',
                    ignore: !rectVIP && !api.value(4),
                    shape: rectVIP,
                    style: api.style({ fill: '#ddb30b' })
                },
                {
                    type: 'rect',
                    ignore: !rectText,
                    shape: rectText,
                    style: api.style({
                        fill: 'transparent',
                        stroke: 'transparent',
                        text: text,
                        textFill: '#fff'
                    })
                }
            ]
        };
    };

    const renderAxisLabelItem = (params, api) => {
        const y = api.coord([0, api.value(0)])[1];
        if (y < params.coordSys.y + 5) {
            return;
        }
        return {
            type: 'group',
            position: [10, y],
            children: [
                {
                    type: 'path',
                    shape: {
                        d: 'M0,0 L0,-20 L30,-20 C42,-20 38,-1 50,-1 L70,-1 L70,0 Z',
                        x: 0,
                        y: -20,
                        width: 90,
                        height: 20,
                        layout: 'cover'
                    },
                    style: {
                        fill: '#368c6c'
                    }
                },
                {
                    type: 'text',
                    style: {
                        x: 24,
                        y: -3,
                        text: api.value(1),
                        textVerticalAlign: 'bottom',
                        textAlign: 'center',
                        textFill: '#fff'
                    }
                },
                {
                    type: 'text',
                    style: {
                        x: 75,
                        y: -2,
                        textVerticalAlign: 'bottom',
                        textAlign: 'center',
                        text: api.value(2),
                        textFill: '#000'
                    }
                }
            ]
        };
    };

    const clipRectByRect = (params, rect) => {
        return echarts.graphic.clipRectByRect(rect, {
            x: params.coordSys.x,
            y: params.coordSys.y,
            width: params.coordSys.width,
            height: params.coordSys.height
        });
    };

    const onDragSwitchClick = () => {
        _draggable = !_draggable;
    };

    const initDrag = (chartInstance) => {
        chartInstance.getZr().on('mousedown', (param) => {
            if (!_draggable || !param || param.seriesIndex == null) {
                return;
            }

            _draggingRecord = {
                dataIndex: param.dataIndex,
                categoryIndex: param.value[DIM_CATEGORY_INDEX],
                timeArrival: param.value[DIM_TIME_ARRIVAL],
                timeDeparture: param.value[DIM_TIME_DEPARTURE]
            };

            const style = {
                lineWidth: 2,
                fill: 'rgba(255,0,0,0.1)',
                stroke: 'rgba(255,0,0,0.8)',
                lineDash: [6, 3]
            };

            _draggingEl = addOrUpdateBar(_draggingEl, _draggingRecord, style, 100);
            _draggingCursorOffset = [
                _draggingEl.position[0] - param.event.offsetX,
                _draggingEl.position[1] - param.event.offsetY
            ];
            _draggingTimeLength =
                _draggingRecord.timeDeparture - _draggingRecord.timeArrival;
        });

        chartInstance.getZr().on('mousemove', (event) => {
            if (!_draggingEl) {
                return;
            }
            const cursorX = event.offsetX;
            const cursorY = event.offsetY;

            _draggingEl.attr('position', [
                _draggingCursorOffset[0] + cursorX,
                _draggingCursorOffset[1] + cursorY
            ]);
            prepareDrop();
            autoDataZoomWhenDraggingOutside(cursorX, cursorY);
        });

        chartInstance.getZr().on('mouseup', () => {
            if (_draggingEl && _dropRecord) {
                updateRawData() &&
                    chartInstance.setOption({
                        series: {
                            id: 'flightData',
                            data: _rawData.flight.data
                        }
                    });
            }
            dragRelease();
        });

        chartInstance.getZr().on('globalout', dragRelease);

        function addOrUpdateBar(el, itemData, style, z) {
            var pointArrival = chartInstance.convertToPixel('grid', [
                itemData.timeArrival,
                itemData.categoryIndex
            ]);
            var pointDeparture = chartInstance.convertToPixel('grid', [
                itemData.timeDeparture,
                itemData.categoryIndex
            ]);
            var barLength = pointDeparture[0] - pointArrival[0];
            var barHeight =
                Math.abs(
                    chartInstance.convertToPixel('grid', [0, 0])[1] -
                    chartInstance.convertToPixel('grid', [0, 1])[1]
                ) * HEIGHT_RATIO;
            if (!el) {
                el = new echarts.graphic.Rect({
                    shape: { x: 0, y: 0, width: 0, height: 0 },
                    style: style,
                    z: z
                });
                chartInstance.getZr().add(el);
            }
            el.attr({
                shape: { x: 0, y: 0, width: barLength, height: barHeight },
                position: [pointArrival[0], pointArrival[1] - barHeight]
            });
            return el;
        }

        function prepareDrop() {
            // Check droppable place.
            var xPixel = _draggingEl.shape.x + _draggingEl.position[0];
            var yPixel = _draggingEl.shape.y + _draggingEl.position[1];
            var cursorData = chartInstance.convertFromPixel('grid', [xPixel, yPixel]);
            if (cursorData) {
                // Make drop shadow and _dropRecord
                _dropRecord = {
                    categoryIndex: Math.floor(cursorData[1]),
                    timeArrival: cursorData[0],
                    timeDeparture: cursorData[0] + _draggingTimeLength
                };
                var style = { fill: 'rgba(0,0,0,0.4)' };
                _dropShadow = addOrUpdateBar(_dropShadow, _dropRecord, style, 99);
            }
        }

        function dragRelease() {
            if (_autoDataZoomAnimator && _autoDataZoomAnimator.stop) {
                _autoDataZoomAnimator.stop();
            }

            if (_draggingEl) {
                chartInstance.getZr().remove(_draggingEl);
                _draggingEl = null;
            }

            if (_dropShadow) {
                chartInstance.getZr().remove(_dropShadow);
                _dropShadow = null;
            }

            _dropRecord = _draggingRecord = null;
        };

    };

    function autoDataZoomWhenDraggingOutside(cursorX, cursorY) {
        // When cursor is outside the cartesian and being dragging,
        // auto move the dataZooms.
        var cursorDistX = getCursorCartesianDist(cursorX, _cartesianXBounds);
        var cursorDistY = getCursorCartesianDist(cursorY, _cartesianYBounds);
        if (cursorDistX !== 0 || cursorDistY !== 0) {
            _autoDataZoomAnimator.start({
                cursorDistX: cursorDistX,
                cursorDistY: cursorDistY
            });
        } else {
            _autoDataZoomAnimator.stop();
        }
    }

    function getCursorCartesianDist(cursorXY, bounds) {
        var dist0 = cursorXY - (bounds[0] + DATA_ZOOM_AUTO_MOVE_DETECT_AREA_WIDTH);
        var dist1 = cursorXY - (bounds[1] - DATA_ZOOM_AUTO_MOVE_DETECT_AREA_WIDTH);
        return dist0 * dist1 <= 0
            ? 0 // cursor is in cartesian
            : dist0 < 0
                ? dist0 // cursor is at left/top of cartesian
                : dist1; // cursor is at right/bottom of cartesian
    }

    function updateRawData() {
        var flightData = _rawData.flight.data;
        var movingItem = flightData[_draggingRecord.dataIndex];
        // Check conflict
        for (var i = 0; i < flightData.length; i++) {
            var dataItem = flightData[i];
            if (
                dataItem !== movingItem &&
                _dropRecord.categoryIndex === dataItem[DIM_CATEGORY_INDEX] &&
                _dropRecord.timeArrival < dataItem[DIM_TIME_DEPARTURE] &&
                _dropRecord.timeDeparture > dataItem[DIM_TIME_ARRIVAL]
            ) {
                alert('Conflict! Find a free space to settle the bar!');
                return;
            }
        }
        // No conflict.
        movingItem[DIM_CATEGORY_INDEX] = _dropRecord.categoryIndex;
        movingItem[DIM_TIME_ARRIVAL] = _dropRecord.timeArrival;
        movingItem[DIM_TIME_DEPARTURE] = _dropRecord.timeDeparture;
        return true;
    }

    return option ? <ReactECharts
        option={option}
        style={{ height: 600, width: '100%' }}
        onChartReady={initDrag}
    /> : <div>Gantt</div>
};

export default GanttChart;
