
import { Column } from "@antv/g2plot";
import { Card } from "antd";
import { useEffect, useRef, useState } from "react";
import Highcharts, { color } from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
import highcharts3dModule from "highcharts/modules/exporting";
import axios from "axios";
import slugify from "./slugify";

highcharts3d(Highcharts);
highcharts3dModule(Highcharts);

//radom data each items
const fakeData = {
    "in": [
        {
            ok_quantity: 58,
            ng_quantity: 3,
            ng_ratio: 4.92,
            type: "Tháng 1",
        },
        {
            ok_quantity: 52,
            ng_quantity: 3,
            ng_ratio: 5.45,
            type: "Tháng 2",
        },
        {
            ok_quantity: 55,
            ng_quantity: 3,
            ng_ratio: 5.17,
            type: "Tháng 3",
        },
        {
            ok_quantity: 51,
            ng_quantity: 3,
            ng_ratio: 5.56,
            type: "Tháng 4",
        },
        {
            ok_quantity: 53,
            ng_quantity: 3,
            ng_ratio: 5.36,
            type: "Tháng 5",
        },
        {
            ok_quantity: 54,
            ng_quantity: 3,
            ng_ratio: 5.26,
            type: "Tháng 6",
        },
        {
            ok_quantity: 57,
            ng_quantity: 3,
            ng_ratio: 5,
            type: "Tháng 7",
        },
        {
            ok_quantity: 56,
            ng_quantity: 3,
            ng_ratio: 5.08,
            type: "Tháng 8",
        },
        {
            ok_quantity: 59,
            ng_quantity: 3,
            ng_ratio: 4.84,
            type: "Tháng 9",
        },
        {
            ok_quantity: 60,
            ng_quantity: 3,
            ng_ratio: 4.76,
            type: "Tháng 10",
        },
        {
            ok_quantity: 61,
            ng_quantity: 3,
            ng_ratio: 4.69,
            type: "Tháng 11",
        },
        {
            ok_quantity: 62,
            ng_quantity: 3,
            ng_ratio: 4.62,
            type: "Tháng 12",
        },
    ],
    "in-luoi": [
        {
            ok_quantity: 56,
            ng_quantity: 3,
            ng_ratio: 5.08,
            type: "Tháng 1",
        },
        {
            ok_quantity: 54,
            ng_quantity: 3,
            ng_ratio: 5.26,
            type: "Tháng 2",
        },
        {
            ok_quantity: 57,
            ng_quantity: 3,
            ng_ratio: 5,
            type: "Tháng 3",
        },
        {
            ok_quantity: 55,
            ng_quantity: 3,
            ng_ratio: 5.17,
            type: "Tháng 4",
        },
        {
            ok_quantity: 53,
            ng_quantity: 3,
            ng_ratio: 5.36,
            type: "Tháng 5",
        },
        {
            ok_quantity: 52,
            ng_quantity: 3,
            ng_ratio: 5.45,
            type: "Tháng 6",
        },
        {
            ok_quantity: 51,
            ng_quantity: 3,
            ng_ratio: 5.56,
            type: "Tháng 7",
        },
        {
            ok_quantity: 50,
            ng_quantity: 3,
            ng_ratio: 5.66,
            type: "Tháng 8",
        },
        {
            ok_quantity: 49,
            ng_quantity: 3,
            ng_ratio: 5.77,
            type: "Tháng 9",
        },
        {
            ok_quantity: 48,
            ng_quantity: 3,
            ng_ratio: 5.88,
            type: "Tháng 10",
        },
        {
            ok_quantity: 47,
            ng_quantity: 3,
            ng_ratio: 6,
            type: "Tháng 11",
        },
        {
            ok_quantity: 46,
            ng_quantity: 3,
            ng_ratio: 6.09,
            type: "Tháng 12",
        },
    ],
    "phu": [
        {
            ok_quantity: 54,
            ng_quantity: 3,
            ng_ratio: 5.26,
            type: "Tháng 1",
        },
        {
            ok_quantity: 57,
            ng_quantity: 3,
            ng_ratio: 5,
            type: "Tháng 2",
        },
        {
            ok_quantity: 55,
            ng_quantity: 3,
            ng_ratio: 5.17,
            type: "Tháng 3",
        },
        {
            ok_quantity: 53,
            ng_quantity: 3,
            ng_ratio: 5.36,
            type: "Tháng 4",
        },
        {
            ok_quantity: 52,
            ng_quantity: 3,
            ng_ratio: 5.45,
            type: "Tháng 5",
        },
        {
            ok_quantity: 51,
            ng_quantity: 3,
            ng_ratio: 5.56,
            type: "Tháng 6",
        },
        {
            ok_quantity: 50,
            ng_quantity: 3,
            ng_ratio: 5.66,
            type: "Tháng 7",
        },
        {
            ok_quantity: 49,
            ng_quantity: 3,
            ng_ratio: 5.77,
            type: "Tháng 8",
        },
        {
            ok_quantity: 48,
            ng_quantity: 3,
            ng_ratio: 5.88,
            type: "Tháng 9",
        },
        {
            ok_quantity: 47,
            ng_quantity: 3,
            ng_ratio: 6,
            type: "Tháng 10",
        },
        {
            ok_quantity: 46,
            ng_quantity: 3,
            ng_ratio: 6.09,
            type: "Tháng 11",
        },
        {
            ok_quantity: 45,
            ng_quantity: 3,
            ng_ratio: 6.18,
            type: "Tháng 12",
        },
    ],
    "be": [
        {
            ok_quantity: 52,
            ng_quantity: 3,
            ng_ratio: 5.45,
            type: "Tháng 1",
        },
        {
            ok_quantity: 51,
            ng_quantity: 3,
            ng_ratio: 5.56,
            type: "Tháng 2",
        },
        {
            ok_quantity: 50,
            ng_quantity: 3,
            ng_ratio: 5.66,
            type: "Tháng 3",
        },
        {
            ok_quantity: 49,
            ng_quantity: 3,
            ng_ratio: 5.77,
            type: "Tháng 4",
        },
        {
            ok_quantity: 48,
            ng_quantity: 3,
            ng_ratio: 5.88,
            type: "Tháng 5",
        },
        {
            ok_quantity: 47,
            ng_quantity: 3,
            ng_ratio: 6,
            type: "Tháng 6",
        },
        {
            ok_quantity: 46,
            ng_quantity: 3,
            ng_ratio: 6.09,
            type: "Tháng 7",
        },
        {
            ok_quantity: 45,
            ng_quantity: 3,
            ng_ratio: 6.18,
            type: "Tháng 8",
        },
        {
            ok_quantity: 44,
            ng_quantity: 3,
            ng_ratio: 6.25,
            type: "Tháng 9",
        },
        {
            ok_quantity: 43,
            ng_quantity: 3,
            ng_ratio: 6.33,
            type: "Tháng 10",
        },
        {
            ok_quantity: 42,
            ng_quantity: 3,
            ng_ratio: 6.67,
            type: "Tháng 11",
        },
        {
            ok_quantity: 41,
            ng_quantity: 3,
            ng_ratio: 6.82,
            type: "Tháng 12",
        },
    ],
    "boc-phoi": [
        {
            ok_quantity: 50,
            ng_quantity: 3,
            ng_ratio: 5.66,
            type: "Tháng 1",
        },
        {
            ok_quantity: 49,
            ng_quantity: 3,
            ng_ratio: 5.77,
            type: "Tháng 2",
        },
        {
            ok_quantity: 48,
            ng_quantity: 3,
            ng_ratio: 5.88,
            type: "Tháng 3",
        },
        {
            ok_quantity: 47,
            ng_quantity: 3,
            ng_ratio: 6,
            type: "Tháng 4",
        },
        {
            ok_quantity: 46,
            ng_quantity: 3,
            ng_ratio: 6.09,
            type: "Tháng 5",
        },
        {
            ok_quantity: 45,
            ng_quantity: 3,
            ng_ratio: 6.18,
            type: "Tháng 6",
        },
        {
            ok_quantity: 44,
            ng_quantity: 3,
            ng_ratio: 6.25,
            type: "Tháng 7",
        },
        {
            ok_quantity: 43,
            ng_quantity: 3,
            ng_ratio: 6.33,
            type: "Tháng 8",
        },
        {
            ok_quantity: 42,
            ng_quantity: 3,
            ng_ratio: 6.67,
            type: "Tháng 9",
        },
        {
            ok_quantity: 41,
            ng_quantity: 3,
            ng_ratio: 6.82,
            type: "Tháng 10",
        },
        {
            ok_quantity: 40,
            ng_quantity: 3,
            ng_ratio: 7.01,
            type: "Tháng 11",
        },
        {
            ok_quantity: 39,
            ng_quantity: 3,
            ng_ratio: 7.14,
            type: "Tháng 12",
        },
    ],
    "gap-dan": [
        {
            ok_quantity: 48,
            ng_quantity: 3,
            ng_ratio: 5.88,
            type: "Tháng 1",
        },
        {
            ok_quantity: 47,
            ng_quantity: 3,
            ng_ratio: 6,
            type: "Tháng 2",
        },
        {
            ok_quantity: 46,
            ng_quantity: 3,
            ng_ratio: 6.09,
            type: "Tháng 3",
        },
        {
            ok_quantity: 45,
            ng_quantity: 3,
            ng_ratio: 6.18,
            type: "Tháng 4",
        },
        {
            ok_quantity: 44,
            ng_quantity: 3,
            ng_ratio: 6.25,
            type: "Tháng 5",
        },
        {
            ok_quantity: 43,
            ng_quantity: 3,
            ng_ratio: 6.33,
            type: "Tháng 6",
        },
        {
            ok_quantity: 42,
            ng_quantity: 3,
            ng_ratio: 6.67,
            type: "Tháng 7",
        },
        {
            ok_quantity: 41,
            ng_quantity: 3,
            ng_ratio: 6.82,
            type: "Tháng 8",
        },
        {
            ok_quantity: 40,
            ng_quantity: 3,
            ng_ratio: 7.01,
            type: "Tháng 9",
        },
        {
            ok_quantity: 39,
            ng_quantity: 3,
            ng_ratio: 7.14,
            type: "Tháng 10",
        },
        {
            ok_quantity: 38,
            ng_quantity: 3,
            ng_ratio: 7.27,
            type: "Tháng 11",
        },
        {
            ok_quantity: 37,
            ng_quantity: 3,
            ng_ratio: 7.41,
            type: "Tháng 12",
        },
    ],
    "chon": [
        {
            ok_quantity: 46,
            ng_quantity: 3,
            ng_ratio: 6.09,
            type: "Tháng 1",
        },
        {
            ok_quantity: 45,
            ng_quantity: 3,
            ng_ratio: 6.18,
            type: "Tháng 2",
        },
        {
            ok_quantity: 44,
            ng_quantity: 3,
            ng_ratio: 6.25,
            type: "Tháng 3",
        },
        {
            ok_quantity: 43,
            ng_quantity: 3,
            ng_ratio: 6.33,
            type: "Tháng 4",
        },
        {
            ok_quantity: 42,
            ng_quantity: 3,
            ng_ratio: 6.67,
            type: "Tháng 5",
        },
        {
            ok_quantity: 41,
            ng_quantity: 3,
            ng_ratio: 6.82,
            type: "Tháng 6",
        },
        {
            ok_quantity: 40,
            ng_quantity: 3,
            ng_ratio: 7.01,
            type: "Tháng 7",
        },
        {
            ok_quantity: 39,
            ng_quantity: 3,
            ng_ratio: 7.14,
            type: "Tháng 8",
        },
        {
            ok_quantity: 38,
            ng_quantity: 3,
            ng_ratio: 7.27,
            type: "Tháng 9",
        },
        {
            ok_quantity: 37,
            ng_quantity: 3,
            ng_ratio: 7.41,
            type: "Tháng 10",
        },
        {
            ok_quantity: 36,
            ng_quantity: 3,
            ng_ratio: 7.69,
            type: "Tháng 11",
        },
        {
            ok_quantity: 35,
            ng_quantity: 3,
            ng_ratio: 7.89,
            type: "Tháng 12",
        },
    ],
    "oqc": [
        {
            ok_quantity: 44,
            ng_quantity: 3,
            ng_ratio: 6.25,
            type: "Tháng 1",
        },
        {
            ok_quantity: 43,
            ng_quantity: 3,
            ng_ratio: 6.33,
            type: "Tháng 2",
        },
        {
            ok_quantity: 42,
            ng_quantity: 3,
            ng_ratio: 6.67,
            type: "Tháng 3",
        },
        {
            ok_quantity: 41,
            ng_quantity: 3,
            ng_ratio: 6.82,
            type: "Tháng 4",
        },
        {
            ok_quantity: 40,
            ng_quantity: 3,
            ng_ratio: 7.01,
            type: "Tháng 5",
        },
        {
            ok_quantity: 39,
            ng_quantity: 3,
            ng_ratio: 7.14,
            type: "Tháng 6",
        },
        {
            ok_quantity: 38,
            ng_quantity: 3,
            ng_ratio: 7.27,
            type: "Tháng 7",
        },
        {
            ok_quantity: 37,
            ng_quantity: 3,
            ng_ratio: 7.41,
            type: "Tháng 8",
        },
        {
            ok_quantity: 36,
            ng_quantity: 3,
            ng_ratio: 7.69,
            type: "Tháng 9",
        },
        {
            ok_quantity: 35,
            ng_quantity: 3,
            ng_ratio: 7.89,
            type: "Tháng 10",
        },
        {
            ok_quantity: 34,
            ng_quantity: 3,
            ng_ratio: 8.11,
            type: "Tháng 11",
        },
        {
            ok_quantity: 33,
            ng_quantity: 3,
            ng_ratio: 8.33,
            type: "Tháng 12",
        },
    ],
}
const QualityLineChart = ({ params, line = "" }) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                // var res = axios.get(baseURL+"/api/kpi/productivity", {params});
                // console.log(res);
                setData(fakeData[line.slugify()] ?? []);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, []);

    function generateRandomData(length, min, max) {
        return Array.from({ length }, () => Number((Math.random() * (max - min) + min).toFixed(1)));
    }

    useEffect(() => {
        if (data.length === 0) {
            return;
        }
        const machines = [...new Set(data.map(item => item.machine))];
        const series = [
            {
                name: "Đầu vào",
                data: machines.map(machine => {
                    const item = data.find(d => d.machine === machine && d.type === "Đầu vào");
                    return item ? item.value : 0;
                }),
                color: '#1f77b4',
            },
            {
                name: "Hàng OK",
                data: machines.map(machine => {
                    const item = data.find(d => d.machine === machine && d.type === "Hàng OK");
                    return item ? item.value : 0;
                }),
                color: '#2ca02c',
            },
            {
                name: "Tem vàng",
                data: machines.map(machine => {
                    const item = data.find(d => d.machine === machine && d.type === "Tem vàng");
                    return item ? item.value : 0;
                }),
                color: '#fff80e',
            },
            {
                name: "NG",
                data: machines.map(machine => {
                    const item = data.find(d => d.machine === machine && d.type === "NG");
                    return item ? item.value : 0;
                }),
                color: '#d62728',
            },
        ];
        // const dataFake = generateRandomData(12, 1000, 5000);
        const dataFake = generateRandomData(3, 1000, 5000);
        const sumFake = dataFake.reduce((a, b) => a + b, 0);
        const dataNGFake = generateRandomData(3, 2, 10);
        const options = {
            chart: {
                height: 300,
                type: 'column',
                // options3d: {
                //     enabled: true,
                //     alpha: 20,
                //     beta: 0,
                //     viewDistance: 100,
                //     depth: 100
                // }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    borderRadius: 0, // Set the border radius for columns
                },
            },
            title: false,
            xAxis: [{
                // categories: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
                categories: ['Tháng 8', 'Tháng 9', 'Tháng 10'],
                crosshair: true,
                labels: {
                    style: {
                        fontSize: '8px'
                    }
                }
            }],
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value}%',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                },
                title: false,
                opposite: true
            }, { // Secondary yAxis
                title: false,
                labels: {
                    format: '{value}',
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    }
                },
                // opposite: false
            }],
            series: [{
                yAxis: 1,
                name: 'Số lot OK',
                type: 'column',
                data: dataFake,
                tooltip: {
                    valueSuffix: ''
                }

            }, {
                name: 'Tỉ lệ NG',
                type: 'line',
                color: 'red',
                // data: dataFake.map(item => Number((item/sumFake*100).toFixed(2))),
                data: dataNGFake.sort((a, b) => b - a),
                tooltip: {
                    valueSuffix: ''
                }
            }],
            exporting: false,
            credits: { enabled: false }
        };

        Highcharts.chart("quality-line-" + line.slugify() + "-chart", options);
    }, [data]);

    return <Card title={"Chất lượng công đoạn" + (line ? " " + line : "")}
        styles={{ body: { padding: '0px' } }}
        style={
            { padding: '0px' }
        }>
        <div id={"quality-line-" + line.slugify() + "-chart"} />
    </Card>
};

export default QualityLineChart;