import { Card } from "antd";
import Highcharts from "highcharts";
import { useEffect } from "react";

const ErrorTrending = ({ data = [] }) => {
    useEffect(() => {
        // if (data.length === 0) {
        //     return;
        // }
        const dates = [...new Set(data.map(item => item.date))];
        const result = data.reduce((acc, item) => {
            const { error, value } = item;
            if (!acc[error]) {
                acc[error] = { name: error, data: [] };
            }
            acc[error].data.push(value);
            return acc;
        }, {});

        const series = Object.values(result);
        const options = {
            chart: {
                type: 'line',
                height: 200
            },
            title: false,

            yAxis: {
                title: {
                    enabled: false,
                    text: 'Number of Employees'
                }
            },

            xAxis: {
                categories: dates,
            },

            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },

            plotOptions: {
                line: {
                    lineWidth: 2,
                    marker: {
                        radius: 2,
                    },
                }
            },

            series: series,

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },
            exporting: false,
            credits: { enabled: false }
        };
        Highcharts.chart("error-trending-chart", options);
    }, [data]);
    return (
        <Card title="Biểu đồ xu hướng lỗi" style={{ height: '100%', padding: '0px' }} styles={{ body: { padding: 12 } }}>
            <div id={"error-trending-chart"} />
        </Card>
    )
}

export default ErrorTrending;