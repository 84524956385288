import { DeleteOutlined, EditOutlined, UploadOutlined, PlusOutlined, FileExcelOutlined, PrinterOutlined, LoadingOutlined } from '@ant-design/icons';
import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Upload, message, Checkbox, Space, Modal, Spin, Tree, Radio, Typography } from 'antd';
import { Pie } from '@ant-design/charts';
import { baseURL } from '../../../config';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import React, { useState, useRef, useEffect } from 'react';
import { getLines, getMachineOfLine, getCustomers, getProducts, getStaffs, getLoSanXuat, getWarehouses, getCaSanXuats, getDataFilterUI, getTreeLines } from '../../../api/ui/main';
import { deleteRecordProductPlan, exportKHSX, getListProductPlan, storeProductPlan, updateProductPlan } from '../../../api';
import dayjs from "dayjs";
import Highcharts from "highcharts";
import { render } from '@testing-library/react';
import { getTreeSelect } from '../../../api/phase2/ui/main';
import { manufatureQuicker } from '../../../routes/quickerCollection';

const { Sider } = Layout;
const { RangePicker } = DatePicker;
const UPH = [
    { key: 'LH1_A2', uph: [5100, 10200] },
    { key: 'LINER_01', uph: [4800, 5100, 10200] },
    { key: 'DC_1', uph: [4000, 7200] },
    { key: 'IN_2_MAU_01', uph: [4800, 5600, 11400] },
];
const loadRate = [70, 80, 85, 90, 100];
Array.prototype.random = function () {
    return this[Math.floor((Math.random() * this.length))];
}

var dataSource = [
    {
        key: '1',
        no: '1',
        congDoan: 'Gấp dán',
        line: 'LH1_A2',
        phanLoai: 'Order',
        '04/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '05/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '06/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '07/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '08/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '09/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '10/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '11/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        'W45': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12 * 7,
        'W46': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12 * 7,
        'W47': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12 * 7,
        'W48': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12 * 7,
        total: 0
    },
    {
        key: '2',
        no: '1',
        congDoan: 'Gấp dán',
        line: 'LH1_A2',
        phanLoai: 'Capa',
        '04/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '05/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '06/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '07/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '08/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '09/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '10/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '11/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        'W45': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12 * 7,
        'W46': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12 * 7,
        'W47': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12 * 7,
        'W48': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12 * 7,
        total: 0,
    },
    {
        key: '3',
        no: '1',
        congDoan: 'Gấp dán',
        line: 'LH1_A2',
        phanLoai: 'Thừa/Thiếu',
        '04/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '05/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '06/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '07/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '08/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '09/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '10/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        '11/11': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12,
        'W45': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12 * 7,
        'W46': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12 * 7,
        'W47': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12 * 7,
        'W48': (UPH.find(e => e.key === 'LH1_A2'))?.uph.random() * 12 * 7,
        total: 0,
    },
    {
        key: '4',
        no: '1',
        congDoan: 'Gấp dán',
        line: 'LH1_A2',
        phanLoai: 'Load Rate',
        '04/11': loadRate.random(),
        '05/11': loadRate.random(),
        '06/11': loadRate.random(),
        '07/11': loadRate.random(),
        '08/11': loadRate.random(),
        '09/11': loadRate.random(),
        '10/11': loadRate.random(),
        '11/11': loadRate.random(),
        'W45': loadRate.random(),
        'W46': loadRate.random(),
        'W47': loadRate.random(),
        'W48': loadRate.random(),
        total: 0,
    },
    {
        key: '5',
        no: '2',
        congDoan: 'In Flexo',
        line: 'IN_2_MAU_01',
        phanLoai: 'Order',
        '04/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '05/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '06/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '07/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '08/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '09/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '10/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '11/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        'W45': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12 * 7,
        'W46': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12 * 7,
        'W47': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12 * 7,
        'W48': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12 * 7,
        total: 0,
    },
    {
        key: '6',
        no: '2',
        congDoan: 'In Flexo',
        line: 'IN_2_MAU_01',
        phanLoai: 'Capa',
        '04/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '05/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '06/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '07/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '08/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '09/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '10/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '11/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        'W45': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12 * 7,
        'W46': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12 * 7,
        'W47': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12 * 7,
        'W48': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12 * 7,
        total: 0,
    },
    {
        key: '7',
        no: '2',
        congDoan: 'In Flexo',
        line: 'IN_2_MAU_01',
        phanLoai: 'Thừa/Thiếu',
        '04/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '05/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '06/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '07/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '08/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '09/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '10/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        '11/11': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12,
        'W45': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12 * 7,
        'W46': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12 * 7,
        'W47': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12 * 7,
        'W48': (UPH.find(e => e.key === 'IN_2_MAU_01'))?.uph.random() * 12 * 7,
        total: 0,
    },
    {
        key: '8',
        no: '2',
        congDoan: 'In Flexo',
        line: 'IN_2_MAU_01',
        phanLoai: 'Load Rate',
        '04/11': loadRate.random(),
        '05/11': loadRate.random(),
        '06/11': loadRate.random(),
        '07/11': loadRate.random(),
        '08/11': loadRate.random(),
        '09/11': loadRate.random(),
        '10/11': loadRate.random(),
        '11/11': loadRate.random(),
        'W45': loadRate.random(),
        'W46': loadRate.random(),
        'W47': loadRate.random(),
        'W48': loadRate.random(),
        total: 0,
    },
    {
        key: '9',
        no: '3',
        congDoan: 'Linner',
        line: 'LINER_01',
        phanLoai: 'Order',
        '04/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '05/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '06/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '07/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '08/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '09/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '10/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '11/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        'W45': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12 * 7,
        'W46': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12 * 7,
        'W47': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12 * 7,
        'W48': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12 * 7,
        total: 0,
    },
    {
        key: '10',
        no: '3',
        congDoan: 'Linner',
        line: 'LINER_01',
        phanLoai: 'Capa',
        '04/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '05/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '06/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '07/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '08/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '09/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '10/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '11/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        'W45': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12 * 7,
        'W46': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12 * 7,
        'W47': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12 * 7,
        'W48': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12 * 7,
        total: 0,
    },
    {
        key: '11',
        no: '3',
        congDoan: 'Linner',
        line: 'LINER_01',
        phanLoai: 'Thừa/Thiếu',
        '04/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '05/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '06/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '07/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '08/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '09/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '10/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        '11/11': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12,
        'W45': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12 * 7,
        'W46': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12 * 7,
        'W47': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12 * 7,
        'W48': (UPH.find(e => e.key === 'LINER_01'))?.uph.random() * 12 * 7,
        total: 0,
    },
    {
        key: '12',
        no: '3',
        congDoan: 'Linner',
        line: 'LINER_01',
        phanLoai: 'Load Rate',
        '04/11': loadRate.random(),
        '05/11': loadRate.random(),
        '06/11': loadRate.random(),
        '07/11': loadRate.random(),
        '08/11': loadRate.random(),
        '09/11': loadRate.random(),
        '10/11': loadRate.random(),
        '11/11': loadRate.random(),
        'W45': loadRate.random(),
        'W46': loadRate.random(),
        'W47': loadRate.random(),
        'W48': loadRate.random(),
        total: 0,
    },
    {
        key: '13',
        no: '4',
        congDoan: 'Đục cắt',
        line: 'DC_1',
        phanLoai: 'Order',
        '04/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '05/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '06/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '07/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '08/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '09/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '10/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '11/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        'W45': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12 * 7,
        'W46': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12 * 7,
        'W47': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12 * 7,
        'W48': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12 * 7,
        total: 0,
    },
    {
        key: '14',
        no: '4',
        congDoan: 'Đục cắt',
        line: 'DC_1',
        phanLoai: 'Capa',
        '04/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '05/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '06/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '07/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '08/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '09/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '10/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '11/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        'W45': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12 * 7,
        'W46': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12 * 7,
        'W47': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12 * 7,
        'W48': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12 * 7,
        total: 0,
    },
    {
        key: '15',
        no: '4',
        congDoan: 'Đục cắt',
        line: 'DC_1',
        phanLoai: 'Thừa/Thiếu',
        '04/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '05/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '06/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '07/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '08/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '09/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '10/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        '11/11': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12,
        'W45': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12 * 7,
        'W46': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12 * 7,
        'W47': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12 * 7,
        'W48': (UPH.find(e => e.key === 'DC_1'))?.uph.random() * 12 * 7,
        total: 0,
    },
    {
        key: '16',
        no: '4',
        congDoan: 'Đục cắt',
        line: 'DC_1',
        phanLoai: 'Load Rate',
        '04/11': loadRate.random(),
        '05/11': loadRate.random(),
        '06/11': loadRate.random(),
        '07/11': loadRate.random(),
        '08/11': loadRate.random(),
        '09/11': loadRate.random(),
        '10/11': loadRate.random(),
        '11/11': loadRate.random(),
        'W45': loadRate.random(),
        'W46': loadRate.random(),
        'W47': loadRate.random(),
        'W48': loadRate.random(),
        total: 0,
    },
];
const keys = ['04/11', '05/11', '06/11', '07/11', '08/11', '09/11', '10/11', '11/11', 'W45', 'W46', 'W47', 'W48'];

const dataSummary = {
    Order: { sums: {}, counts: {} },
    Capa: { sums: {}, counts: {} },
    'Thừa/Thiếu': { sums: {}, counts: {} },
    'Load Rate': { sums: {}, counts: {} }
};

Object.keys(dataSummary).forEach(type => {
    keys.forEach(key => {
        dataSummary[type].sums[key] = 0;
        dataSummary[type].counts[key] = 0;
    });
});

dataSource.forEach(record => {
    const { phanLoai } = record;
    if (dataSummary[phanLoai]) {
        keys.forEach(key => {
            if (record[key] !== undefined && typeof record[key] === 'number') {
                dataSummary[phanLoai].sums[key] += record[key];
                dataSummary[phanLoai].counts[key] += 1;
            }
        });
    }
});

const averagesByType = {};
Object.keys(dataSummary).forEach(type => {
    averagesByType[type] = keys.reduce((acc, key) => {
        const { sums, counts } = dataSummary[type];
        acc[key] = counts[key] > 0 ? sums[key] / counts[key] : 0;
        return acc;
    }, {});
});

// Tạo các hàng "SUB TOTAL" mới
const subTotalRows = [
    {
        key: '17',
        no: 'SUB TOTAL',
        congDoan: 'SUB TOTAL',
        line: '',
        phanLoai: 'Order',
        ...averagesByType.Order,
    },
    {
        key: '18',
        no: 'SUB TOTAL',
        congDoan: 'SUB TOTAL',
        line: '',
        phanLoai: 'Capa',
        ...averagesByType.Capa,
        total: Object.values(averagesByType.Capa,).reduce((sum, val) => sum + val, 0) / keys.length
    },
    {
        key: '19',
        no: 'SUB TOTAL',
        congDoan: 'SUB TOTAL',
        line: '',
        phanLoai: 'Thừa/Thiếu',
        ...averagesByType['Thừa/Thiếu'],
        total: Object.values(averagesByType['Thừa/Thiếu']).reduce((sum, val) => sum + val, 0) / keys.length
    },
    {
        key: '20',
        no: 'SUB TOTAL',
        congDoan: 'SUB TOTAL',
        line: '',
        phanLoai: 'Load Rate',
        ...averagesByType['Load Rate'],
        total: Object.values(averagesByType['Load Rate']).reduce((sum, val) => sum + val, 0) / keys.length
    }
];

// Kết quả là các hàng "SUB TOTAL" mới
dataSource = dataSource.concat(subTotalRows)
const LoadFactor = () => {
    const [listLines, setListLines] = useState([]);
    const [listNameProducts, setListNameProducts] = useState([]);
    const [listLoSX, setListLoSX] = useState([]);
    const [listCustomers, setListCustomers] = useState([]);
    const [listCheck, setListCheck] = useState([]);
    const [openMdlEdit, setOpenMdlEdit] = useState(false);
    const [titleMdlEdit, setTitleMdlEdit] = useState('Cập nhật');
    const [form] = Form.useForm();
    const [params, setParams] = useState({ date: [dayjs(), dayjs()] });
    const [dateType, setDateType] = useState('date');
    const history = useHistory();
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: 50,
            onCell: (_, index) => {
                var rowSpan = 1;
                var colSpan = 1;
                if (_.no === 'SUB TOTAL') {
                    if (index === dataSource.length - 4) {
                        rowSpan = 4;
                        colSpan = 3;
                    } else {
                        rowSpan = 0;
                    }
                } else {
                    if (index % 4 === 0) {
                        rowSpan = 4;
                    } else {
                        rowSpan = 0;
                    }
                }
                return { rowSpan, colSpan }
            }
        },
        {
            title: 'Công đoạn',
            dataIndex: 'congDoan',
            key: 'congDoan',
            onCell: (_, index) => {
                var rowSpan = 1;
                var colSpan = 1;
                if (_.no === 'SUB TOTAL') {
                    colSpan = 0;
                    if (index === dataSource.length - 4) {
                        rowSpan = 4;
                    } else {
                        rowSpan = 0;
                    }
                } else {
                    if (index % 4 === 0) {
                        rowSpan = 4;
                    } else {
                        rowSpan = 0;
                    }
                }
                return { rowSpan, colSpan }
            }
        },
        {
            title: 'Line',
            dataIndex: 'line',
            key: 'line',
            onCell: (_, index) => {
                var rowSpan = 1;
                var colSpan = 1;
                if (_.no === 'SUB TOTAL') {
                    colSpan = 0;
                    if (index === dataSource.length - 4) {
                        rowSpan = 4;
                    } else {
                        rowSpan = 0;
                    }
                } else {
                    if (index % 4 === 0) {
                        rowSpan = 4;
                    } else {
                        rowSpan = 0;
                    }
                }
                return { rowSpan, colSpan }
            }
        },
        {
            title: 'Phân loại',
            dataIndex: 'phanLoai',
            key: 'phanLoai',
        },
        {
            title: 'W44',
            children: [
                {
                    title: '04/11',
                    dataIndex: '04/11',
                    key: '04/11',
                    align: 'center',
                    render: (text, record) => <span style={{ color: (!isNaN(text) && text < 0) ? 'red' : "" }}>{(text ?? 0) + (record?.phanLoai === 'Load Rate' ? "%" : "")}</span>
                },
                {
                    title: '05/11',
                    dataIndex: '05/11',
                    key: '05/11',
                    align: 'center',
                    render: (text, record) => <span style={{ color: (!isNaN(text) && text < 0) ? 'red' : "" }}>{(text ?? 0) + (record?.phanLoai === 'Load Rate' ? "%" : "")}</span>
                },
                {
                    title: '06/11',
                    dataIndex: '06/11',
                    key: '06/11',
                    align: 'center',
                    render: (text, record) => <span style={{ color: (!isNaN(text) && text < 0) ? 'red' : "" }}>{(text ?? 0) + (record?.phanLoai === 'Load Rate' ? "%" : "")}</span>
                },
                {
                    title: '07/11',
                    dataIndex: '07/11',
                    key: '07/11',
                    align: 'center',
                    render: (text, record) => <span style={{ color: (!isNaN(text) && text < 0) ? 'red' : "" }}>{(text ?? 0) + (record?.phanLoai === 'Load Rate' ? "%" : "")}</span>
                },
                {
                    title: '08/11',
                    dataIndex: '08/11',
                    key: '08/11',
                    align: 'center',
                    render: (text, record) => <span style={{ color: (!isNaN(text) && text < 0) ? 'red' : "" }}>{(text ?? 0) + (record?.phanLoai === 'Load Rate' ? "%" : "")}</span>
                },
                {
                    title: '09/11',
                    dataIndex: '09/11',
                    key: '09/11',
                    align: 'center',
                    render: (text, record) => <span style={{ color: (!isNaN(text) && text < 0) ? 'red' : "" }}>{(text ?? 0) + (record?.phanLoai === 'Load Rate' ? "%" : "")}</span>
                },
                {
                    title: '10/11',
                    dataIndex: '10/11',
                    key: '10/11',
                    align: 'center',
                    render: (text, record) => <span style={{ color: (!isNaN(text) && text < 0) ? 'red' : "" }}>{(text ?? 0) + (record?.phanLoai === 'Load Rate' ? "%" : "")}</span>
                },
            ]
        },
        {
            title: 'W45',
            dataIndex: 'W45',
            key: 'W45',
            render: (text, record) => <span style={{ color: (!isNaN(text) && text < 0) ? 'red' : "" }}>{(text ?? 0) + (record?.phanLoai === 'Load Rate' ? "%" : "")}</span>
        },
        {
            title: 'W46',
            dataIndex: 'W46',
            key: 'W46',
            render: (text, record) => <span style={{ color: (!isNaN(text) && text < 0) ? 'red' : "" }}>{(text ?? 0) + (record?.phanLoai === 'Load Rate' ? "%" : "")}</span>
        },
        {
            title: 'W47',
            dataIndex: 'W47',
            key: 'W47',
            render: (text, record) => <span style={{ color: (!isNaN(text) && text < 0) ? 'red' : "" }}>{(text ?? 0) + (record?.phanLoai === 'Load Rate' ? "%" : "")}</span>
        },
        {
            title: 'W48',
            dataIndex: 'W48',
            key: 'W48',
            render: (text, record) => <span style={{ color: (!isNaN(text) && text < 0) ? 'red' : "" }}>{(text ?? 0) + (record?.phanLoai === 'Load Rate' ? "%" : "")}</span>
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (text, record) => {
                const { sum, count } = Object.values(record).reduce(
                    (acc, value) => {
                        if (typeof value === 'number' && !isNaN(value)) {
                            acc.sum += value;
                            acc.count += 1;
                        }
                        return acc;
                    },
                    { sum: 0, count: 0 } // Khởi tạo tổng và đếm
                );
                const average = count > 0 ? sum / count : 0;
                return <span>{Math.round(average) + (record?.phanLoai === 'Load Rate' ? "%" : "")}</span>
            }

        },
    ];

    useEffect(() => {
        (async () => {
            const res1 = await getTreeSelect();
            setListLines(res1.data);
            // const res2 = await getProducts();
            // setListIdProducts(res2.data.map(e => {
            //       return { ...e, label: e.id, value: e.id }
            // }));
            // setListNameProducts(res2.data.map(e => {
            //       return { ...e, label: e.name, value: e.id }
            // }));
            // const res3 = await getLoSanXuat();
            // setListLoSX(res3.data.map(e => {
            //       return { ...e, label: e, value: e }
            // }));
            // const res4 = await getStaffs();
            // setListStaffs(res4.data.map(e => {
            //       return { ...e, label: e.name, value: e.id }
            // }))
            const res5 = await getCustomers();
            setListCustomers(res5.data.map(e => {
                return { ...e, label: e.name, value: e.id }
            }));
        })()
    }, [])

    function btn_click() {
        loadListTable(params)
    }

    useEffect(() => {
        (async () => {
            var res = await getDataFilterUI({ khach_hang: params.khach_hang });
            if (res.success) {
                setListNameProducts(res.data.product.map(e => {
                    return { ...e, label: e.name, value: e.id }
                }));
                setListLoSX(Object.values(res.data.lo_sx).map(e => {
                    return { label: e, value: e }
                }));
            }
        })()
    }, [params.khach_hang])

    const [data, setData] = useState([]);
    const loadListTable = async (params) => {
        setLoading(true)
        const res = await getListProductPlan(params);
        setData(dataSource);
        setLoading(false)
    }
    useEffect(() => {
        btn_click();
    }, [])

    const [messageApi, contextHolder] = message.useMessage();

    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Upload file thành công',
        });
    };

    const error = () => {
        messageApi.open({
            type: 'error',
            content: 'Upload file lỗi',
        });
    };

    const onFinish = async (values) => {
        if (values.id) {
            const res = await updateProductPlan(values);
        } else {
            const res = await storeProductPlan(values);
        }
        setOpenMdlEdit(false);
        loadListTable(params);
    }

    const deleteRecord = async () => {
        if (listCheck.length > 0) {
            const res = await deleteRecordProductPlan(listCheck);
            setListCheck([]);
            loadListTable(params);
        } else {
            message.info('Chưa chọn bản ghi cần xóa')
        }
    }
    const editRecord = () => {
        setTitleMdlEdit('Cập nhật')
        if (listCheck.length > 1) {
            message.info('Chỉ chọn 1 bản ghi để chỉnh sửa');
        } else if (listCheck.length == 0) {
            message.info('Chưa chọn bản ghi cần chỉnh sửa')
        } else {
            const result = data.find((record) => record.id === listCheck[0]);
            form.setFieldsValue({
                id: listCheck[0], thu_tu_uu_tien: result.thu_tu_uu_tien, thoi_gian_bat_dau: result.thoi_gian_bat_dau, thoi_gian_ket_thuc: result.thoi_gian_ket_thuc,
                cong_doan_sx: result.cong_doan_sx, product_id: result.product_id, khach_hang: result.khach_hang, ca_sx: result.ca_sx, lo_sx: result.lo_sx, so_bat: result.so_bat, sl_nvl: result.sl_nvl,
                sl_thanh_pham: result.sl_thanh_pham, UPH: result.UPH, nhan_luc: result.nhan_luc
            })
            setOpenMdlEdit(true);
        }
    }
    const insertRecord = () => {
        setTitleMdlEdit('Thêm mới')
        form.resetFields();
        setOpenMdlEdit(true);
    }
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const exportRecord = async () => {
        setLoadingExport(true);
        const res = await exportKHSX(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setLoadingExport(false);
    }
    const onCheck = (checkedKeys, info) => {
        const selectKeys = [];
        if (info.node.type === 'factory') {
            if (info.checked) {
                info.node.children.map(e => {
                    selectKeys.push(e.key?.toString())
                })
            }
        } else {
            info.checkedNodes.map(e => {
                switch (e.type) {
                    case 'line':
                        selectKeys.push(e.key?.toString());
                        break;
                    case 'machine':
                        selectKeys.push(e.line_id?.toString());
                        break;
                    default:
                        break;
                }
            })
        }
        setParams({ ...params, line_id: [...new Set(selectKeys)] })
    };
    const customDateFormat = (value) => {
        switch (dateType) {
            case 'week':
                return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
                break;
            case 'month':
                return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
                break;
            case 'year':
                return (`Năm ${dayjs(value).format('YYYY')}`);
                break;
            default:
                return (dayjs(value).format('DD/MM/YYYY'));
                break;
        }
    }
    useEffect(() => {
        onChangeDate('start_date', params.date[0], dateType);
        onChangeDate('end_date', params.date[1], dateType);
    }, [dateType])
    const onChangeDate = (position, value, dateType) => {
        switch (dateType) {
            case 'week':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('isoWeek'), dayjs(params.date[1]).endOf('isoWeek')] });
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('isoWeek'), dayjs(value).endOf('isoWeek')] });
                }
                break;
            case 'month':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('month'), dayjs(params.date[1]).endOf('month')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('month'), dayjs(value).endOf('month')] });
                }
                break;
            case 'year':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('year'), dayjs(params.date[1]).endOf('year')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('year'), dayjs(value).endOf('year')] });
                }
                break;
            default:
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
                }
                break;
        }
    }
    const renderQuicker = () => {
        console.log(window.location.pathname);
        const className = (path) => {
            if (window.location.pathname.indexOf(path) > -1) {
                return 'active';
            } else {
                return 'inactive';
            }
        }
        return (
            <ul>
                {manufatureQuicker.map(e => {
                    return (
                        <li><Link to={e.path} className={'quicker ' + className(e.path)}>{e.name}</Link></li>
                    )
                })}
            </ul>
        )
    }

    const runLoadFactor = () => {
        history.push('/ui/manufacture/tao-ke-hoach');
    }

    const fakeRun = () => {
        history.push('/ui/manufacture/khsx-auto');
    }

    const dataChart = [
        { week: 'W44', main1: 14000, main2: 10200, main3: 16000, main4: 14500, capa: 80.7 },
        { week: 'W45', main1: 14500, main2: 11000, main3: 16000, main4: 14500, capa: 85 },
        { week: 'W46', main1: 14500, main2: 12000, main3: 16000, main4: 14500, capa: 110.4 },
        { week: 'W47', main1: 15000, main2: 11500, main3: 16000, main4: 14500, capa: 88.3 },
        { week: 'W48', main1: 12000, main2: 12000, main3: 16000, main4: 14500, capa: 100 },
    ];

    useEffect(() => {
        if (dataChart.length === 0) {
            return;
        }
        const week = [...new Set(dataChart.map(item => item.week))];
        const main1 = dataChart.map(item => item.main1);
        const main2 = dataChart.map(item => item.main2);
        const main3 = dataChart.map(item => item.main3);
        const main4 = dataChart.map(item => item.main4);
        const capa = dataChart.map(item => item.capa);
        const options = {
            chart: {
                type: 'column',
                height: 300
            },
            title: {
                text: 'Tiến độ sản xuất',
                align: 'left',
                style: {
                    fontSize: 15
                }
            },
            xAxis: [{
                categories: week,
                crosshair: true
            }],
            yAxis: [{ // Primary yAxis
                gridLineWidth: 0,
                title: false,
                labels: {
                    format: '{value}%',
                },
                min: 0,
                opposite: true
            }, {
                gridLineWidth: 0,
                title: false,
                labels: {
                    fillColor: 'white'
                }
            }],
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:0.1f}%'
                    },
                    color: 'gray',
                    // lineColor: 'gray'
                },
                column: {
                    dataLabels: {
                        enabled: true,
                        inside: true
                    }
                }
            },
            tooltip: {
                shared: true
            },
            series: [
                {
                    name: 'Gấp dán',
                    type: 'column',
                    yAxis: 1,
                    data: main1,
                    color: '#1668b5'
                },
                {
                    name: 'In flexo',
                    type: 'column',
                    yAxis: 1,
                    data: main2,
                },
                {
                    name: 'Linner',
                    type: 'column',
                    yAxis: 1,
                    data: main3,
                },
                {
                    name: 'Đục cắt',
                    type: 'column',
                    yAxis: 1,
                    data: main4,
                },
                {
                    name: 'Capa',
                    type: 'line',
                    data: capa,
                    yAxis: 0,
                }
            ],
            legend: {
                align: 'center',
                verticalAlign: 'top',
                // layout: 'vertical',
            },
            exporting: false,
            credits: { enabled: false }
        };
        Highcharts.chart("load-factor-chart", options);
    }, []);
    return <>
        {contextHolder}
        <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
            <Col span={4} className='custom-col'>
                <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                ]}>
                    <Form style={{ margin: '0 8px' }} layout="vertical">
                        <Divider>Công đoạn</Divider>
                        <Form.Item className='mb-3'>
                            {
                                listLines.length ?
                                    <Tree
                                        defaultExpandedKeys={[2]}
                                        style={{ maxHeight: 250, overflowY: 'auto' }}
                                        checkable
                                        selectable={false}
                                        onCheck={onCheck}
                                        treeData={listLines}
                                    />
                                    :
                                    <LoadingOutlined />
                            }
                        </Form.Item>
                        <Divider>Quicker</Divider>
                        {renderQuicker()}
                        <Divider>Thời gian truy vấn</Divider>
                        <Radio.Group
                            options={[
                                { value: 'date', label: 'Ngày', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                { value: 'week', label: 'Tuần', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                { value: 'month', label: 'Tháng', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                { value: 'year', label: 'Năm', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                            ]}
                            className='d-flex justify-content-center mb-2 w-100'
                            onChange={(e) => setDateType(e.target.value)}
                            value={dateType}
                            optionType="button"
                            buttonStyle="solid"
                        />
                        <Space direction='vertical' style={{ width: '100%' }}>
                            <DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.date[0]} format={customDateFormat} picker={dateType} />
                            <DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.date[1]} format={customDateFormat} picker={dateType} />
                        </Space>
                        <Divider>Điều kiện truy vấn</Divider>
                        <Form.Item label="Khách hàng" className='mb-3'>
                            <Select
                                allowClear
                                showSearch
                                placeholder="Chọn khách hàng"
                                onChange={(value) => setParams({ ...params, khach_hang: value })}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                popupMatchSelectWidth={listCustomers.length ? 400 : "100%"}
                                options={listCustomers}
                            />
                        </Form.Item>
                        <Form.Item label="Tên sản phẩm" className='mb-3'>
                            <Select
                                allowClear
                                showSearch
                                onChange={(value) => {
                                    setParams({ ...params, ten_sp: value })
                                }}
                                placeholder="Nhập tên sản phẩm"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={listNameProducts}
                            />
                        </Form.Item>
                        <Form.Item label="Lô Sản xuất" className='mb-3'>
                            <Select
                                allowClear
                                showSearch
                                placeholder="Nhập lô sản xuất"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={(value) => setParams({ ...params, lo_sx: value })}
                                options={listLoSX}
                            />
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <Col span={20}>
                <Card style={{ height: '100%' }} title="Hệ số tải/Capa" extra={
                    <Space>
                        <Upload
                            showUploadList={false}
                            name='files'
                            action={baseURL + "/api/upload-ke-hoach-san-xuat"}
                            headers={{
                                authorization: 'authorization-text',
                            }}
                            onChange={(info) => {
                                setLoadingUpload(true);
                                if (info.file.status === 'error') {
                                    setLoadingUpload(false);
                                    error()
                                } else if (info.file.status === 'done') {
                                    if (info.file.response.success === true) {
                                        loadListTable(params);
                                        success();
                                        setLoadingUpload(false);
                                    } else {
                                        loadListTable(params);
                                        message.error(info.file.response.message);
                                        setLoadingUpload(false);
                                    }
                                }
                            }}
                        >
                            <Button style={{ marginLeft: '15px' }} loading={loadingUpload}>
                                Upload Excel
                            </Button>
                        </Upload>
                        <Button onClick={exportRecord} loading={loadingExport}>Export Excel</Button>
                        <Button onClick={deleteRecord} danger>Delete</Button>
                        <Button onClick={editRecord}>Edit</Button>
                        <Button onClick={insertRecord}>Insert</Button>
                        {/* <Button type='primary' onClick={runLoadFactor}>Run</Button> */}
                        <Button type='primary' onClick={fakeRun}>Run</Button>
                    </Space>
                }>
                    <div id="load-factor-chart"></div>
                    <Table size='small' bordered
                        loading={loading}
                        pagination={false}
                        scroll={
                            {
                                x: true,
                                y: '44vh'
                            }
                        }
                        columns={columns.map(e => ({ ...e, align: 'center' }))}
                        dataSource={data}
                    />
                </Card>
            </Col>
        </Row>
        <Modal title={titleMdlEdit} open={openMdlEdit} onCancel={() => setOpenMdlEdit(false)} footer={null} width={800}>
            <Form style={{ margin: '0 15px' }}
                layout="vertical"
                form={form}
                onFinish={onFinish}>
                <Row gutter={[16, 16]}>
                    <Col span={12} className='d-none'>
                        <Form.Item name="id" className='mb-3 d-none'>
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Thứ tự ưu tiên" name="thu_tu_uu_tien" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập thứ tự ưu tiên'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Ngày sản xuất (YYYY-MM-DD)" name="ngay_sx" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập ngày sản xuất'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Ngày đặt hàng (YYYY-MM-DD)" name="ngay_dat_hang" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập ngày đặt hàng'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Ngày giao hàng (YYYY-MM-DD)" name="ngay_giao_hang" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập ngày giao hàng'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Thời gian bắt đầu (YYYY-MM-DD HH:mm:ss)" name="thoi_gian_bat_dau" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='' ></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Thời gian kết thúc (YYYY-MM-DD HH:mm:ss)" name="thoi_gian_ket_thuc" className='mb-3' rules={[{ required: true }]}>
                            <Input ></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Máy" name="machine_id" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập tên máy'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Công đoạn" name="cong_doan_sx" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập công đoạn'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Mã sản phẩm" name="product_id" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập mã sản phẩm'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Khách hàng" name="khach_hang" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập khách hàng'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Ca sản xuất" name="ca_sx" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập ca sản xuất'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Lô sản xuất" name="lo_sx" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập lô sản xuất'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Số bát" name="so_bat" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập số bát'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Số lượng nguyên liệu đầu vào (tờ)" name="sl_nvl" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập số lượng nguyên liệu đầu vào (tờ)'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Kế hoạch SL thành phẩm (tờ)" name="sl_thanh_pham" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập kế hoạch SL thành phẩm (tờ)'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="UPH" name="UPH" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='UPH'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Nhân lực" name="nhan_luc" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhân lực'></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item className='mb-0'>
                    <Button type="primary" htmlType='submit' >Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default LoadFactor;
