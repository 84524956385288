import { Card } from "antd";
import { useEffect, useRef } from "react";
import Highcharts from "highcharts";

const TyLeVanHanhTB = ({ dataChart }) => {
  console.log(dataChart);
  const chartRef = useRef(null);

  useEffect(() => {
    const initializeChart = () => {
      const categories = dataChart.map((item) => item.type);
      const seriesData = dataChart.map((item) => ({
        y: item.value,
        color: item.color,
      }));
      chartRef.current = Highcharts.chart("ty-le-van-hanh-tb", {
        chart: {
          type: "column",
          height: 252,
        },
        title: false,
        xAxis: {
          categories: categories,
          crosshair: true,
        },
        yAxis: {
          title: null,
          max: 100,
          labels: {
            format: "{value}%",
          },
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
              format: "{point.y}%",
            },
            borderRadius: 0,
          },
        },
        series: [
          {
            name: "Tỷ lệ vận hành TB",
            data: seriesData,
          },
          {
            name: "Mục tiêu",
            type: "line",
            data: Array(categories.length).fill(75),
            color: "orange",
            lineWidth: 3,
            marker: {
              enabled: false,
            },
          },
        ],
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
      });
    };
    if (chartRef.current) {
      chartRef.current.destroy();
    }
    if (dataChart && dataChart.length > 0) {
      initializeChart();
    }
  }, [dataChart]);
  return (
    <Card title="Tỷ lệ vận hành TB" style={{ padding: "0px" }}>
      <div id="ty-le-van-hanh-tb" />
    </Card>
  );
};

export default TyLeVanHanhTB;
