import axios from "axios";
const prefix = 'p2/ui/master-data/';
export async function getMachines(params) {
    const res = await axios.get(prefix+'machines', { params });
    return res;
}
export async function createMachines(params) {
    const res = await axios.post(prefix+'machines', params);
    return res;
}
export async function updateMachines(id, params) {
    const res = await axios.patch(prefix+'machines/' + id, params);
    return res;
}
export async function deleteMachines(id) {
    const res = await axios.delete(prefix+'machines/' + id);
    return res;
}
export async function exportMachines(params) {
    const res = await axios.get(prefix+'machines/export', { params });
    return res;
}
export async function importMachines(params) {
    const res = await axios.post(prefix+'machines/import', params, { headers: { "Content-Type": "multipart/form-data" } });
    return res;
}