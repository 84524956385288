import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Spin, Space, Modal, Typography, Radio, Tree } from 'antd';
import { Pie } from '@ant-design/charts';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import React, { useState, useEffect } from "react";
import { getErrorsMachine, getLines, getMachineOfLine, getCustomers, getProducts, getStaffs, getLoSanXuat, getWarehouses, getCaSanXuats, getThongSoMay, getPowerConsumeData, getDataFilterUI, getPowerConsumeDataByProduct, exportPowerConsumeDataByProduct, getTreeLines } from '../../../api/ui/main';
import { baseURL } from '../../../config';
import { exportThongSoMay } from '../../../api/ui/export';
import dayjs from "dayjs";
import { LoadingOutlined } from '@ant-design/icons';
import { getTreeSelect } from '../../../api/phase2/ui/main';

const { Sider } = Layout;
const { RangePicker } = DatePicker;


const PowerConsumeByProduct = (props) => {
    document.title = 'UI - Thông số máy'
    const [listLines, setListLines] = useState([]);
    const [listLoSX, setListLoSX] = useState([]);
    const [listCustomers, setListCustomers] = useState([]);
    const [listNameProducts, setListNameProducts] = useState([]);
    const [data, setData] = useState([]);
    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            render: (value, record, index) => ((page - 1) * pageSize) + index + 1,
            fixed: 'left',
            width: 45
        },
        {
            title: 'Ngày SX',
            dataIndex: 'ngay_sx',
            key: 'ngay_sx',
            align: 'center',
            fixed: 'left',
            width: 100
        },
        {
            title: 'Ca SX',
            dataIndex: 'ca_sx',
            key: 'ca_sx',
            align: 'center',
            width: 70
        },
        {
            title: 'Xưởng',
            dataIndex: 'xuong',
            key: 'xuong',
            align: 'center',
            width: 70
        },
        {
            title: 'Công đoạn',
            dataIndex: 'cong_doan',
            key: 'cong_doan',
            align: 'center',
            width: 100
        },
        {
            title: 'Máy sản xuất',
            dataIndex: 'machine_name',
            key: 'machine_name',
            align: 'center',
            width: 120
        },
        {
            title: 'Mã máy',
            dataIndex: 'machine_id',
            key: 'machine_id',
            align: 'center',
            width: 120
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'ten_san_pham',
            key: 'ten_san_pham',
            align: 'center',
            width: 120
        },
        {
            title: 'Khách hàng',
            dataIndex: 'khach_hang',
            key: 'khach_hang',
            align: 'center',
            width: 200
        },
        {
            title: 'Mã hàng',
            dataIndex: 'product_id',
            key: 'product_id',
            align: 'center',
            width: 70
        },
        {
            title: 'Mã nguyên vật liệu',
            dataIndex: 'material_id',
            key: 'material_id',
            align: 'center',
            width: 90
        },
        {
            title: 'Lô SX',
            dataIndex: 'lo_sx',
            key: 'lo_sx',
            align: 'center',
            width: 100
        },
        {
            title: 'Điện năng tiêu thụ',
            dataIndex: 'power_consume',
            key: 'power_consume',
            align: 'center',
            width: 100
        },
        {
            title: 'Thời gian sản xuất',
            dataIndex: 'produce_time',
            key: 'produce_time',
            align: 'center',
            width: 100
        },
        {
            title: 'TB điện năng tiêu thụ (1 giờ)',
            dataIndex: 'avg_power_consume',
            key: 'avg_power_consume',
            align: 'center',
            width: 100
        },
        {
            title: 'Thực tế',
            dataIndex: 'thuc_te',
            key: 'thuc_te',
            align: 'center',
            children: [
                {
                    title: 'Vào hàng',
                    dataIndex: 'vao_hang',
                    key: 'vao_hang',
                    align: 'center',
                    children: [
                        {
                            title: 'Thời gian bắt đầu vào hàng',
                            dataIndex: 'thoi_gian_bat_dau_vao_hang',
                            key: 'thoi_gian_bat_dau_vao_hang',
                            align: 'center',

                        },
                        {
                            title: 'Thời gian kết thúc vào hàng',
                            dataIndex: 'thoi_gian_ket_thuc_vao_hang',
                            key: 'thoi_gian_ket_thuc_vao_hang',
                            align: 'center',
                        },
                        {
                            title: 'Số lượng đầu vào vào hàng',
                            dataIndex: 'sl_dau_vao_vao_hang',
                            key: 'sl_dau_vao_vao_hang',
                            align: 'center',
                        },
                        {
                            title: 'Số lượng đầu ra vào hàng',
                            dataIndex: 'sl_dau_ra_vao_hang',
                            key: 'sl_dau_ra_vao_hang',
                            align: 'center',
                        },
                    ]
                },
                {
                    title: 'Sản xuất sản lượng',
                    dataIndex: 'sx_san_luong',
                    key: 'sx_san_luong',
                    align: 'center',
                    children: [
                        {
                            title: 'Thời gian bắt đầu sx sản lượng',
                            dataIndex: 'thoi_gian_bat_dau_sx',
                            key: 'thoi_gian_bat_dau_sx',
                            align: 'center',

                        },
                        {
                            title: 'Thời gian kết thúc sx sản lượng',
                            dataIndex: 'thoi_gian_ket_thuc_sx',
                            key: 'thoi_gian_ket_thuc_sx',
                            align: 'center',
                        },
                        {
                            title: 'Số lượng đầu vào thực tế',
                            dataIndex: 'sl_dau_vao_sx',
                            key: 'sl_dau_vao_sx',
                            align: 'center',
                        },
                        {
                            title: 'Số lượng đầu ra thực tế',
                            dataIndex: 'sl_dau_ra_sx',
                            key: 'sl_dau_ra_sx',
                            align: 'center',
                        },
                        {
                            title: 'Số lượng đầu ra OK',
                            dataIndex: 'sl_ok',
                            key: 'sl_ok',
                            align: 'center',
                        },
                        {
                            title: 'Số lượng tem vàng',
                            dataIndex: 'sl_tem_vang',
                            key: 'sl_tem_vang',
                            align: 'center',
                        },
                        {
                            title: 'Số lượng NG',
                            dataIndex: 'sl_ng',
                            key: 'sl_ng',
                            align: 'center',
                        },
                    ]
                },
            ]
        },
        {
            title: 'Chênh lệch',
            dataIndex: 'chenh_lech',
            key: 'chenh_lech',
            align: 'center',
            width: 100
        },
        {
            title: 'Tỷ lệ đạt',
            dataIndex: 'ty_le_dat',
            key: 'ty_le_dat',
            align: 'center',
            width: 100
        },
        {
            title: 'TT Thực tế (Phút)',
            dataIndex: 'tt_thuc_te',
            key: 'tt_thuc_te',
            align: 'center',
            width: 100
        },
        {
            title: 'Công nhân SX',
            dataIndex: 'cong_nhan_sx',
            key: 'cong_nhan_sx',
            align: 'center',
            width: 90
        },
    ];
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [params, setParams] = useState({
        line_id: '',
        ca_sx: '',
        start_date: dayjs(),
        end_date: dayjs(),
    });
    useEffect(() => {
        (async () => {
            var res = await getDataFilterUI({ khach_hang: params.khach_hang });
            if (res.success) {
                setListNameProducts(res.data.product.map(e => {
                    return { ...e, label: e.name, value: e.id }
                }));
                setListLoSX(Object.values(res.data.lo_sx).map(e => {
                    return { label: e, value: e }
                }));
            }
        })()
    }, [params.khach_hang])

    const [sumPowerConsume, setSumPowerConsume] = useState(0);

    const loadDataTable = async (params) => {
        setLoSX();
        setLoading(true);
        var res = await getPowerConsumeDataByProduct(params);
        setData(res.data.data);
        setTotalPage(res.data.totalPage);
        setSumPowerConsume(res.data.sum)
        setLoading(false);
    }
    async function btn_click(page = 1, pageSize = 10) {
        setPage(page);
        setPageSize(pageSize);
        loadDataTable({ ...params, page, pageSize });
    }

    useEffect(() => {
        // btn_click();
        (async () => {
            const res1 = await getTreeSelect();
            setListLines(res1.data);
            const res5 = await getCustomers();
            setListCustomers(res5.data.map(e => {
                return { ...e, label: e.name, value: e.id }
            }));
        })()
    }, [])

    useEffect(() => {
        btn_click();
    }, [])
    const [loading, setLoading] = useState(false);

    const [exportLoading, setExportLoading] = useState(false);
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportPowerConsumeDataByProduct({ ...params, lo_sx: loSX });
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const [loSX, setLoSX] = useState();
    const onClickRow = async (record) => {
        setLoSX(record.lo_sx)
    }
    const [dateType, setDateType] = useState('date');
    const onCheck = (checkedKeys, info) => {
        const selectKeys = [];
        if (info.node.type === 'factory') {
            if (info.checked) {
                info.node.children.map(e => {
                    selectKeys.push(e.key?.toString())
                })
            }
        } else {
            info.checkedNodes.map(e => {
                switch (e.type) {
                    case 'line':
                        selectKeys.push(e.key?.toString());
                        break;
                    case 'machine':
                        selectKeys.push(e.line_id?.toString());
                        break;
                    default:
                        break;
                }
            })
        }
        setParams({ ...params, line_id: [...new Set(selectKeys)] })
    };
    const customDateFormat = (value) => {
        switch (dateType) {
            case 'week':
                return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
                break;
            case 'month':
                return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
                break;
            case 'year':
                return (`Năm ${dayjs(value).format('YYYY')}`);
                break;
            default:
                return (dayjs(value).format('DD/MM/YYYY'));
                break;
        }
    }
    useEffect(()=>{
        onChangeDate('start_date', params.start_date, dateType);
        onChangeDate('end_date', params.end_date, dateType);
    }, [dateType])
    const onChangeDate = (position, value, dateType) => {
        switch (dateType) {
            case 'week':
                if (position === 'start_date') {
                    setParams({ ...params, start_date: dayjs(value).startOf('isoWeek'), end_date: dayjs(params.end_date).endOf('isoWeek') });
                } else {
                    setParams({ ...params, start_date: dayjs(value).startOf('isoWeek'), end_date: dayjs(params.end_date).endOf('isoWeek') });
                }
                break;
            case 'month':
                if (position === 'start_date') {
                    setParams({ ...params, start_date: dayjs(value).startOf('month'), end_date: dayjs(params.end_date).endOf('month') });
                } else {
                    setParams({ ...params, start_date: dayjs(value).startOf('month'), end_date: dayjs(params.end_date).endOf('month') });
                }
                break;
            case 'year':
                if (position === 'start_date') {
                    setParams({ ...params, start_date: dayjs(value).startOf('year'), end_date: dayjs(params.end_date).endOf('year') });
                } else {
                    setParams({ ...params, start_date: dayjs(value).startOf('year'), end_date: dayjs(params.end_date).endOf('year') });
                }
                break;
            default:
                setParams({ ...params, [position]: value })
                break;
        }
    }
    return <>
        <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
            <Col span={4} className='custom-col'>
                <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                ]}>
                    <Form style={{ margin: '0 8px' }} layout="vertical">
                        <Divider>Công đoạn</Divider>
                        <Form.Item className='mb-3'>
                            {
                                listLines.length ?
                                    <Tree
                                        defaultExpandedKeys={[2]}
                                        style={{ maxHeight: 250, overflowY: 'auto' }}
                                        checkable
                                        selectable={false}
                                        onCheck={onCheck}
                                        treeData={listLines}
                                    />
                                    :
                                    <LoadingOutlined />
                            }
                        </Form.Item>
                        <Divider>Thời gian truy vấn</Divider>
                        <Radio.Group
                            options={[
                                { value: 'date', label: 'Ngày', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                { value: 'week', label: 'Tuần', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                { value: 'month', label: 'Tháng', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                { value: 'year', label: 'Năm', style: { width: '25%', justifyContent: 'center', display: 'flex' } }
                            ]}
                            className='d-flex justify-content-center mb-2 w-100'
                            onChange={(e) => setDateType(e.target.value)}
                            value={dateType}
                            optionType="button"
                            buttonStyle="solid"
                        />
                        <Space direction='vertical' style={{ width: '100%' }}>
                            <DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.start_date} format={customDateFormat} picker={dateType} />
                            <DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.end_date} format={customDateFormat} picker={dateType} />
                        </Space>
                        <Divider>Điều kiện truy vấn</Divider>
                        <Form.Item label="Khách hàng" className='mb-3'>
                            <Select
                                allowClear
                                showSearch
                                placeholder="Chọn khách hàng"
                                onChange={(value) => { setParams({ ...params, khach_hang: value }); }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                popupMatchSelectWidth={listCustomers.length ? 400 : "100%"}
                                options={listCustomers}
                            />
                        </Form.Item>
                        <Form.Item label="Tên sản phẩm" className='mb-3'>
                            <Select
                                allowClear
                                showSearch
                                onChange={(value) => {
                                    setParams({ ...params, ten_sp: value });
                                }}
                                placeholder="Nhập tên sản phẩm"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={listNameProducts}
                            />
                        </Form.Item>
                        <Form.Item label="Lô Sản xuất" className='mb-3'>
                            <Select
                                allowClear
                                showSearch
                                placeholder="Nhập lô sản xuất"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={(value) => {
                                    setParams({ ...params, lo_sx: value });
                                }}
                                options={listLoSX}
                            />
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <Col span={20} className='custom-col'>
                <Card style={{ height: '100%' }} title="Quản lý điện năng tiêu thụ" extra={<Button onClick={exportFile} loading={exportLoading} type='primary'>Xuất Excel</Button>}>
                    <Table size='small' bordered
                        loading={loading}
                        pagination={{
                            current: page,
                            size: 'default',
                            total: totalPage,
                            onChange: (page, pageSize) => {
                                btn_click(page, pageSize);
                            }
                        }}
                        scroll={
                            {
                                x: '420vw',
                                y: '48vh'
                            }
                        }
                        footer={() => <Typography.Text strong>{'Tổng điện năng tiêu thụ (kW): ' + sumPowerConsume}</Typography.Text>}
                        columns={columns}
                        dataSource={data} />
                </Card>
            </Col>
        </Row>

    </>
}

export default PowerConsumeByProduct;
