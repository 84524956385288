import axios from "axios";

//Bom
export async function getBom(params) {
    const res = await axios.get('/bom/list', { params });
    return res;
}
export async function createBom(params) {
    const res = await axios.post('/bom/create', params);
    return res;
}
export async function updateBom(id, params) {
    const res = await axios.patch('/bom/update/' + id, params);
    return res;
}
export async function deleteBom(id) {
    const res = await axios.delete('/bom/delete/' + id);
    return res;
}
export async function deleteMultipleBom(params) {
    const res = await axios.post('/boms/delete', params);
    return res;
}
export async function exportBom(params) {
    const res = await axios.get('/bom/export', { params });
    return res;
}