import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Layout, Table, Tag, Col, Row } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "./THSX.css";
import { getProductionSituationLineIn } from "../../api/phase2/dashboard/main";

const colTable = [
  {
    title: "MÁY",
    dataIndex: "machine_code",
    key: "machine_code",
    align: "center",
  },
  {
    title: "TÊN SẢN PHẨM",
    dataIndex: "product",
    key: "product",
    align: "center",
  },
  {
    title: "SL KẾ HOẠCH",
    dataIndex: "sl_dau_ra_kh",
    key: "sl_dau_ra_kh",
    align: "center",
  },
  {
    title: "MỤC TIÊU",
    dataIndex: "sl_muc_tieu",
    key: "sl_muc_tieu",
    align: "center",
  },
  {
    title: "SL THỰC TẾ",
    dataIndex: "sl_thuc_te",
    key: "sl_thuc_te",
    align: "center",
  },
  {
    title: "TỈ LỆ HOÀN THÀNH (%)",
    dataIndex: "ti_le_ht",
    key: "ti_le_ht",
    align: "center",
  },
  {
    title: "TỈ LỆ NG (%)",
    dataIndex: "ti_le_ng",
    key: "ti_le_ng",
    align: "center",
  },
  {
    title: "TÌNH TRẠNG",
    dataIndex: "status",
    key: "status",
    render: (value) => {
      var color = "white";
      if (value == "1") color = "orange";
      else if (value == "2") color = "blue";
      else if (value == "3") color = "green";
      else color = "#929292";
      return (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div
            style={{
              width: "70px",
              height: "70px",
              background: color,
              borderRadius: "50%",
            }}
          ></div>
        </div>
      );
    },
    align: "center",
  },
];

const ProductionSituation1 = () => {
  const history = useHistory();
  const [isFullCreen, setIsFullScreen] = useState(false);
  const [clock, setClock] = useState(new Date());
  const noteItems = [
    { title: "Máy dừng", color: "orange" },
    { title: "Máy đang chạy", color: "green" },
    { title: "Máy hoàn thành kế hoạch", color: "blue" },
  ];
  const loadData = async () => {
    const res1 = await getProductionSituationLineIn();
    setDataTable(
      res1.data.map((e) => {
        return { ...e };
      })
    );
    return res1;
  };
  useEffect(() => {
    setInterval(() => tick(), 1000);
  }, []);
  const tick = () => {
    setClock(new Date());
  };

  useEffect(() => {
    let intervalId;
    const startFetching = async () => {
      await loadData(); // Gọi API lần đầu
      intervalId = setInterval(async () => {
        await loadData(); // Đợi API trả về kết quả trước khi gọi lần tiếp theo
      }, 3000); // 3 giây
    };

    startFetching();

    // Xóa interval khi component bị huỷ
    return () => clearInterval(intervalId);
  }, []);
  // useEffect(() => {
  //     const success = loadData();
  // }, [])
  const [dataTable, setDataTable] = useState([]);
  // let interval;
  // useEffect(() => {
  //     interval = setInterval(() => {
  //         loadData();
  //     }, 3000);
  //     return () => clearInterval(interval);
  // }, [])
  var interval1;
  // useEffect(() => {
  //     interval = setInterval(async () => {
  //       history.push('/dashboard/canh-bao-bat-thuong');
  //     }, 30000);
  //     return () => clearInterval(interval1);
  // }, []);

  return (
    <React.Fragment>
      <Layout style={{ backgroundColor: "#e3eaf0", overflowX: "hidden" }}>
        <Row
          className="w-100"
          style={{
            height: 80,
            verticalAlign: "center",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
            backgroundColor: "#2462a3",
            color: "white",
          }}
        >
          <div
            style={{
              fontSize: "1.6em",
              fontWeight: "700",
              width: "15%",
              textAlign: "center",
            }}
          >
            {clock
              .toLocaleString(["en-GB"], { hour12: false })
              .replace(",", "")}
          </div>
          <div style={{ fontWeight: 700, fontSize: "2em" }}>
            TÌNH HÌNH SẢN XUẤT
          </div>
          <Link
            to={"/screen"}
            style={{ margin: "auto 0", width: "15%", textAlign: "right" }}
          >
            <CloseOutlined
              className="text-white"
              style={{ fontSize: "1.4em" }}
            />
          </Link>
        </Row>
        <Row style={{ padding: "15px" }} gutter={[8, 8]}>
          <Col span={24}>
            <Table
              className="mt-3 table-thsx-gdlh"
              bordered
              pagination={false}
              scroll={{ x: "100%", y: "calc(100vh-120px)" }}
              columns={colTable}
              dataSource={dataTable}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "5px",
                height: "100px",
                alignItems: "center",
                paddingTop: "10px",
              }}
            >
              {noteItems.map((item, index) => (
                <div
                  key={index}
                  style={{
                    border: "1px solid gray",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                      backgroundColor: "whitesmoke",
                      padding: "0 30px",
                    }}
                  >
                    <div
                      style={{
                        width: "60px",
                        height: "60px",
                        background: item.color,
                        borderRadius: "50%",
                        border: "1px solid gray",
                      }}
                    ></div>
                    <div style={{ fontWeight: "bold", fontSize: "24px" }}>
                      {item.title}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Layout>
      {/* )}
                </ReactFullscreen> */}
    </React.Fragment>
  );
};

export default ProductionSituation1;
