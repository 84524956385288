import React, { useState, useEffect } from 'react';
import { Layout, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const Iot = () => {
    return (
        <Layout style={{ height: '100vh', backgroundColor: '#e3eaf0' }}>
            <Row className='w-100' style={{ verticalAlign: 'center', justifyContent: 'space-between', padding: '10px', backgroundColor: '#2462a3', color: 'white' }}>
                <div style={{ fontWeight: 700, fontSize: '24px' }}>IOT</div>
                <Link to={'/screen'} style={{ margin: 'auto 0' }}>
                    <CloseOutlined className='text-white' style={{ fontSize: '1.4em' }} />
                </Link>
            </Row>
            <Row style={{ padding: '15px' }} gutter={[8, 8]}>
            </Row>
        </Layout>
    );
};

export default Iot;
