import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Modal,
  Spin,
  Form,
  InputNumber,
  message,
} from "antd";
import { withRouter, Link } from "react-router-dom";
import "../style.scss";
import ScanButton from "../../../components/Button/ScanButton";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { scanError } from "../../../api/oi/quality";
import {
  getCriteriaListOfLot,
  getLotQCList,
  getLotQCCurrent,
  getQCOverall,
  printTemVang,
  scanQC,
  updateTemVangQuantity,
} from "../../../api/phase2/oi/quality";
import SelectButton from "../../../components/Button/SelectButton";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import TemVang from "./TemVang";
import { useProfile } from "../../../components/hooks/UserHooks";
import DataDetail2 from "../../../components/DataDetail2";
import {
  getLineList,
  getMachineList,
} from "../../../api/phase2/oi/manufacture";
import ChecksheetP2 from "../../../components/Popup/ChecksheetP2";
import ErrorsManagement from "../../../components/Popup/ErrorsManagement";
const columns = [
  {
    title: "Ngày sản xuất",
    dataIndex: "ngay_sx",
    key: "ngay_sx",
    align: "center",
  },
  {
    title: "Mã Pallet/cuộn",
    dataIndex: "lot_id",
    key: "lot_id",
    align: "center",
  },
  {
    title: "Mã SP",
    dataIndex: "product_id",
    key: "product_id",
    align: "center",
  },
  {
    title: "Tên SP",
    dataIndex: "ten_sp",
    key: "ten_sp",
    align: "center",
  },
  {
    title: "Lô sản xuất",
    dataIndex: "lo_sx",
    key: "lo_sx",
    align: "center",
  },
  {
    title: "Số lượng sản xuất",
    dataIndex: "sl_dau_ra_hang_loat",
    key: "sl_dau_ra_hang_loat",
    align: "center",
  },
  {
    title: "Số lượng OK",
    dataIndex: "sl_ok",
    key: "sl_ok",
    align: "center",
  },
  {
    title: "Tỉ lệ OK",
    dataIndex: "ty_le_ht",
    key: "ty_le_ht",
    align: "center",
  },
  {
    className: "yellow",
    title: "SL tem vàng",
    dataIndex: "sl_tem_vang",
    key: "sl_tem_vang",
    align: "center",
  },
  {
    title: "SL NG",
    dataIndex: "sl_ng",
    key: "sl_ng",
    align: "center",
    className: "red",
  },
];
const defaultRow1 = [
  {
    title: "Kế hoạch",
    key: "ke_hoach",
  },
  {
    title: "Mục tiêu",
    key: "muc_tieu",
  },
  {
    title: "Kết quả",
    key: "ket_qua",
  },
];
const defaultRow2 = [
  {
    title: "Mã Lot",
    key: "lot_id",
  },
  {
    title: "Lô SX",
    key: "lo_sx",
  },
  {
    title: "Mã SP đang sản xuất",
    key: "product_id",
  },
  {
    title: "SL KH",
    key: "sl_kh",
  },
  {
    title: "SL T.tế",
    key: "sl_dau_ra_hang_loat",
  },
  {
    title: "Tỷ lệ HT",
    key: "ty_le_ht",
  },
];
const QCPhase2 = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  document.title = "Kiểm tra chất lượng";
  const { line } = useParams();
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checksheet1, setChecksheet1] = useState();
  const [checksheet2, setChecksheet2] = useState();
  const [checksheet3, setChecksheet3] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [data, setData] = useState([]);
  const { userProfile } = useProfile();
  const [overallData, setOverallData] = useState();
  const [openKV, setOpenKV] = useState(false);
  const [kvValue, setKvValue] = useState();
  const [form] = Form.useForm();
  const [machineOptions, setMachineOption] = useState([]);
  const [listCheck, setListCheck] = useState([]);
  const machine =
    new URLSearchParams(window.location.search).get("machine") || "";
  // const [params, setParams] = useState({ line_id: line, machine_code: machine });
  // useEffect(() => {
  //   if (line && machine) {
  //     setParams({ line_id: line, machine_code: machine });
  //   }
  // }, [line, machine])
  const resetAll = () => {
    setSelectedRow();
    setKvValue();
    setChecksheet1();
    setChecksheet2();
    setChecksheet3();
    setSelectedRowKeys([]);
  };

  const fetchDataTable = async () => {
    setLoading(true);
    const tableData = await getLotQCList({ line_id: line, machine_code: machine });
    if (tableData.success) {
      setData(tableData.data);
      if (machine) {
        const currentLot = tableData.data.find(e => e.status === 1);
        if (currentLot) {
          setSelectedRow(currentLot);
        } else {
          setSelectedRow();
        }
      }
    }
    setLoading(false);
  };

  const fetchCurrent = async () => {
    const currentLot = await getLotQCCurrent({
      line_id: line,
      machine_code: machine,
    });
  }

  const fetchOverall = async () => {
    const overall = await getQCOverall({ line_id: line, machine_code: machine });
    setOverallData(overall.data);
  };

  const fetchError = async () => {
    const errors = await scanError();
    setErrorList(
      errors.data.map((e) => {
        return { ...e, key: e.id };
      })
    );
  };

  const fetchLine = async () => {
    const res = await getLineList({ type: "cl" });
    if (res.success) {
      setOptions(res.data);
    }
  };

  // useEffect(() => {
  //   if (!machine && machineOptions.length) {
  //     history.push({ search: "?machine=" + machineOptions[0]?.value });
  //   }
  // }, [machineOptions]);

  useEffect(() => {
    fetchLine();
    fetchError();
  }, []);

  useEffect(() => {
    if (line && line !== 'undefined') {
      fetchMachine();
      const screen = JSON.parse(localStorage.getItem("screen"));
      localStorage.setItem("screen", JSON.stringify({ ...screen, quality: line }));
    }
  }, [line]);

  const fetchMachine = async () => {
    const machineList = await getMachineList({ line });
    setMachineOption(
      machineList.data.map((e) => ({ ...e, value: e.code, label: e.code }))
    );
  };

  useEffect(() => {
    if (options.length && machineOptions.length) {
      const machineTarget = machineOptions.find(e => e.value == machine);
      if (!machineTarget) {
        history.push({ search: "?machine=" + machineOptions[0].value });
      } else {
        history.push({ search: "?machine=" + machineTarget.value });
      }
      setSelectedRow();
      resetAll();
    }
  }, [machineOptions, options])

  // useEffect(() => {
  //   setSelectedRow();
  //   if (options.length) {
  //     if (line) {
  //       const screen = JSON.parse(localStorage.getItem("screen"));
  //       localStorage.setItem(
  //         "screen",
  //         JSON.stringify({ ...screen, quality: line ? line : "" })
  //       );
  //     } else if (options.length) {
  //       history.push("/quality/" + options[0].value + (machine ? ('?machine=' + machine) : ""));
  //     }
  //   }
  //   resetAll();
  // }, [line, options]);

  const fetchCriteria = async () => {
    if (selectedRow) {
      var res = await getCriteriaListOfLot({
        line_id: line,
        lot_id: selectedRow.lot_id,
        machine_code: machine,
      });
      Object.keys(res.data ?? {}).forEach((key) => {
        switch (key) {
          case "kich-thuoc":
            setChecksheet1(res.data[key]);
            // setChiTieu1(res.data[key]['history']);
            break;
          case "dac-tinh":
            setChecksheet2(res.data[key]);
            // setChiTieu2(res.data[key]['history']);
            break;
          case "ngoai-quan":
            setChecksheet3(res.data[key]);
            // setChiTieu3(res.data[key]['history']);
            break;
          default:
            break;
        }
      });
      form.setFieldsValue({ sl_tem_vang: selectedRow.sl_ok });
    } else {
      setChecksheet1([]);
      setChecksheet2([]);
      setChecksheet3([]);
      form.resetFields();
    }
  };

  const fetchData = async () => {
    await fetchOverall();
    await fetchCurrent();
    await fetchDataTable();
  }

  useEffect(() => {
    if (line && machine) {
      fetchData();
    }
  }, [line, machine]);

  useEffect(() => {
    fetchCriteria();
  }, [selectedRow]);

  const onChangeLine = (value) => {
    history.push("/quality/" + value);
    const screen = JSON.parse(localStorage.getItem("screen"));
    localStorage.setItem("screen", JSON.stringify({ ...screen, quality: value }));
  };
  const onChangeMachine = (value) => {
    if (value) {
      history.push({ search: "?machine=" + value });
    } else {
      history.push({ search: "" });
    }
  };
  const onScan = async (result) => {
    var res = await scanQC({
      lot_id: result,
      line_id: line,
      machine_code: machine,
    });
    if (res.success) {
      fetchDataTable();
    }
  };
  useEffect(() => {
    if (!selectedRow) {
      setListCheck([]);
    }
    setSelectedRowKeys([]);
  }, [selectedRow]);

  const onFinish = async (values) => {
    if (selectedRow && values.sl_tem_vang >= 0) {
      if (selectedRowKeys.length) {
        values.seleted_errors = selectedRowKeys;
        setLoadingSubmitKV(true);
        var res = await updateTemVangQuantity({
          lot_id: selectedRow.lot_id,
          line_id: line,
          machine_code: machine,
          ...values,
        });
        if (res.success) {
          setOpenKV(false);
          fetchDataTable();
          form.resetFields();
          fetchOverall();
          setSelectedRowKeys([]);
        }
        setLoadingSubmitKV(false);
      } else {
        messageApi.info("Chọn ít nhất 1 lỗi");
      }
    }
  };

  const handlePrint = async () => {
    if (selectedRow) {
      var res = await printTemVang({
        lot_id: selectedRow.lot_id,
        line_id: line,
        sl_tem_vang: kvValue,
        machine_code: machine,
      });
      if (res.success) {
        console.log(res.data);
        setListCheck([res.data]);
        fetchDataTable();
        resetAll();
      }
    }
  };
  useEffect(() => {
    if (listCheck.length > 0) {
      print();
    }
  }, [listCheck]);

  const componentRef1 = useRef();
  const print = useReactToPrint({
    content: () => componentRef1.current,
  });

  const onSubmit = async () => {
    form.resetFields();
    setKvValue("");
    setOpenKV(false);
    fetchOverall();
    fetchDataTable();
  };
  const [errorList, setErrorList] = useState([]);
  const errorColumn = [
    {
      title: "Mã lỗi",
      key: "id",
      dataIndex: "id",
      align: "center",
    },
    {
      title: "Nội dung",
      key: "noi_dung",
      dataIndex: "noi_dung",
      align: "center",
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const qcPermission = ["pqc", "oqc", "*"].filter((value) =>
    (userProfile?.permission ?? []).includes(value)
  );
  const [loadingSubmitKV, setLoadingSubmitKV] = useState(false);

  const onFinishChecksheet = () => { };

  const rowClassName = (record, index) => {
    if (record?.lot_id === selectedRow?.lot_id) {
      return "table-row-green";
    } else if (record?.qc_status) {
      return "table-row-grey";
    } else {
      return '';
    }
  };
  return (
    <React.Fragment>
      {contextHolder}
      <Spin spinning={loading}>
        <Row gutter={12} className="mt-3">
          <Col span={4}>
            <SelectButton
              value={options.length > 0 && parseInt(line)}
              options={options}
              label="Chọn công đoạn"
              onChange={onChangeLine}
            />
          </Col>
          <Col span={4}>
            <SelectButton
              value={
                machineOptions.length > 0 &&
                  machineOptions.some((e) => e.value == machine)
                  ? machine
                  : null
              }
              options={machineOptions}
              label="Chọn thiết bị"
              onChange={onChangeMachine}
              allowClear={true}
            />
          </Col>
          <Col span={16}>
            <DataDetail2 data={defaultRow1} value={overallData} />
          </Col>
        </Row>

        <Row className="mt-3">
          <ScanButton onScan={onScan} searchData={[]} />
        </Row>

        <Row className="mt-3" style={{ justifyContent: "space-between" }}>
          <Col span={24}>
            <DataDetail2 data={defaultRow2} value={selectedRow} />
          </Col>
        </Row>

        <Row
          className="mt-3"
          style={{ justifyContent: "space-between" }}
          gutter={12}
        >
          <Col span={4}>
            <ChecksheetP2
              text="Chỉ tiêu KT1"
              checksheet={checksheet1}
              lotId={selectedRow?.lot_id}
              disabled={false}
              keyName={"kích thước"}
              onComplete={onFinishChecksheet}
            />
          </Col>
          <Col span={4}>
            <ChecksheetP2
              text="Chỉ tiêu KT2"
              checksheet={checksheet2}
              lotId={selectedRow?.lot_id}
              disabled={false}
              keyName={"đặc tính"}
              onComplete={onFinishChecksheet}
            />
          </Col>
          <Col span={4}>
            <ChecksheetP2
              text="Chỉ tiêu KT3"
              checksheet={checksheet3}
              lotId={selectedRow?.lot_id}
              disabled={false}
              keyName={"ngoại quan"}
              onComplete={onFinishChecksheet}
            />
          </Col>
          <Col span={4}>
            <ErrorsManagement
              text="Quản lý lỗi"
              lotId={selectedRow?.lot_id}
              onSubmit={onSubmit}
            />
          </Col>
          <Col span={4}>
            <Button
              className="w-100 text-wrap h-100"
              style={
                selectedRow &&
                qcPermission.length &&
                selectedRow && { backgroundColor: "#f7ac27", color: "#ffffff" }
              }
              disabled={!selectedRow || !qcPermission.length}
              size="large"
              onClick={() => setOpenKV(true)}
            >
              Khoanh vùng
            </Button>
          </Col>
          <Col span={4}>
            <Button
              className="w-100 text-wrap h-100"
              style={
                qcPermission.length && {
                  backgroundColor: "#f7ac27",
                  color: "#ffffff",
                }
              }
              size="large"
              onClick={handlePrint}
              disabled={!qcPermission.length}
            >
              In tem vàng
            </Button>
            <div className="report-history-invoice">
              <TemVang listCheck={listCheck} ref={componentRef1} />
            </div>
          </Col>
        </Row>

        <Table
          rowClassName={rowClassName}
          scroll={{ y: "50vh" }}
          pagination={false}
          bordered={true}
          className="mt-3 mb-3"
          columns={columns}
          dataSource={data}
          size="small"
        />
        <Modal
          title="Khoanh vùng"
          open={openKV}
          onCancel={() => setOpenKV(false)}
          onOk={() => form.submit()}
          okButtonProps={{
            loading: loadingSubmitKV,
          }}
        >
          <Form form={form} onFinish={onFinish}>
            <Table
              rowHoverable={false}
              columns={errorColumn}
              dataSource={errorList}
              pagination={false}
              scroll={{ x: "100%", y: 300 }}
              size="small"
              rowSelection={rowSelection}
            />
            <Form.Item
              name="sl_tem_vang"
              label="Nhập số lượng khoanh vùng"
              className="mt-3"
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </React.Fragment>
  );
};

export default withRouter(QCPhase2);
