import axios from "axios";
const prefix = 'p2/ui/master-data/';
export async function getExcelHeaders(params) {
    const res = await axios.get(prefix+'excel-headers', { params });
    return res;
}
export async function createExcelHeaders(params) {
    const res = await axios.post(prefix+'excel-headers', params);
    return res;
}
export async function updateExcelHeaders(id, params) {
    const res = await axios.patch(prefix+'excel-headers/' + id, params);
    return res;
}
export async function deleteExcelHeaders(id) {
    const res = await axios.delete(prefix+'excel-headers/' + id);
    return res;
}
export async function deleteManyExcelHeaders(ids) {
    const res = await axios.post(prefix+'excel-headers/delete', {ids});
    return res;
}
export async function exportExcelHeaders(params) {
    const res = await axios.post(prefix+'excel-headers/export', params );
    return res;
}
export async function importExcelHeaders(params) {
    const res = await axios.post(prefix+'excel-headers/import', params, { headers: { "Content-Type": "multipart/form-data" } });
    return res;
}