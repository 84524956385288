import React from "react";
import Barcode from "react-barcode";
import styled from "styled-components";
import "./style.css";
import { QRCode, Space } from "antd";

const PageBreakWrapper = styled.div`
  && {
    page-break-after: always;
  }
`;

const PrintTemplate = ({ detail }) => {
  return (
    <div>
      <div className="print-only" style={{ marginTop: "0px" }}>
        <table className="table-tem-chon">
          <thead>
            <tr>
              <th style={{ width: "28%" }}></th>
              <th style={{ width: "27%" }}></th>
              <th style={{ width: "20%" }}></th>
              <th style={{ width: "25%" }}></th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ marginTop: "0px", marginBottom: "0px" }}>
              <td colSpan={5} style={{ marginTop: "0px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "0px",
                    marginBottom: "0px",
                    padding: "0px",
                    height: "35px",
                  }}
                >
                  <Barcode
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "0px",
                      marginBottom: "0px",
                      padding: "0px",
                    }}
                    value={"GH69-43295ADOEWE12305150001004000"}
                    format={"CODE128"}
                    height={50}
                    width={1.2}
                    fontSize={16}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ border: "none" }}>
                <span
                  style={{
                    marginLeft: "15px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  PART NO
                </span>
              </td>
              <td style={{ border: "none" }} colSpan={3}>
                <span
                  style={{
                    marginLeft: "15px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  : GH49-43295A
                </span>
              </td>
            </tr>
            <tr>
              <td style={{ border: "none" }}>
                <span
                  style={{
                    marginLeft: "15px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  SPECIFICATION
                </span>
              </td>
              <td style={{ border: "none" }} colSpan={2}>
                <span
                  style={{
                    marginLeft: "15px",
                    fontWeight: "500",
                    fontSize: "12px",
                  }}
                >
                  : NONWOVEN-SET_COMMON_A156
                </span>
              </td>
              <td style={{ border: "none" }} rowSpan={4}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <QRCode
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    value={"123"}
                    bordered={false}
                    size={70}
                    type="svg"
                  />
                </div>
              </td>
            </tr>
            <tr style={{ padding: "3px" }}>
              <td style={{ border: "none", padding: "2px" }}>
                <span
                  style={{
                    marginLeft: "15px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  PO TYPE
                </span>
              </td>
              <td style={{ border: "none" }} colSpan={2}>
                <span
                  style={{
                    marginLeft: "15px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  : E1
                </span>
              </td>
            </tr>
            <tr>
              <td style={{ border: "none" }}>
                <span
                  style={{
                    marginLeft: "15px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  LOT NO
                </span>
              </td>
              <td style={{ border: "none" }} colSpan={2}>
                <span
                  style={{
                    marginLeft: "15px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  : 2405150001
                </span>
              </td>
            </tr>
            <tr>
              <td style={{ border: "none" }}>
                <span
                  style={{
                    marginLeft: "15px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  QTY
                </span>
              </td>
              <td style={{ border: "none" }} colSpan={2}>
                <span
                  style={{
                    marginLeft: "15px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  : 0040000
                </span>
              </td>
            </tr>
            <tr>
              <td style={{ border: "none" }}>
                <span
                  style={{
                    marginLeft: "15px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  VENDOR P/N
                </span>
              </td>
              <td style={{ border: "none" }} colSpan={2}>
                <span
                  style={{
                    marginLeft: "15px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  : Thang Long
                </span>
              </td>
              <td style={{ border: "none" }} rowSpan={2}>
                <div>
                  <div class="box">W20</div>
                  <div class="box1">RoHS</div>
                  <div class="box">HF</div>
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ border: "none" }}>
                <span
                  style={{
                    marginLeft: "15px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  VENDOR/ CODE
                </span>
              </td>
              <td style={{ border: "none" }}>
                <span
                  style={{
                    marginLeft: "15px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  : DOEW
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <PageBreakWrapper>&nbsp;</PageBreakWrapper>
    </div>
  );
};
export default class TemChon extends React.Component {
  render() {
    let printingPages = [];
    // const { listCheck } = this.props;
    // for (const detail of listCheck) {
    //   const tempTemplate = <PrintTemplate detail={detail} />;
    //   printingPages.push(tempTemplate);
    // }
    const tempTemplate = <PrintTemplate detail={{}} />;
    console.log(tempTemplate);
    printingPages.push(tempTemplate);
    return <div>{printingPages}</div>;
  }
}
