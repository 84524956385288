import { Button, Card, Col, DatePicker, Divider, Form, Input, Layout, Radio, Row, Select, AutoComplete, Table, Tabs, Space } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useState, useEffect } from "react";
import { Line } from '@ant-design/plots';
import { getErrors, getLines, getMachineOfLine, getCustomers, getProducts, getStaffs, getLoSanXuat, getWarehouses, getCaSanXuats, getOQC, getDataFilterUI } from '../../../api/ui/main';
import { exportOQC } from '../../../api/ui/export';
import { baseURL } from '../../../config';
import dayjs from 'dayjs';
import { exportOQCDataTable, getOQCDataChart, getOQCDataSummary, getOQCDataTable } from "../../../api/phase2/ui/quality";
import UIOQCErrorFrequency from "./chart/UIOQCErrorFrequency";

const { Sider } = Layout;
const { RangePicker } = DatePicker;


const QualityOQC = (props) => {
    document.title = "UI - OQC";
    const [listLines, setListLines] = useState([]);
    const [listMachines, setListMachines] = useState([]);
    const [listIdProducts, setListIdProducts] = useState([]);
    const [listLoSX, setListLoSX] = useState([]);
    const [listStaffs, setListStaffs] = useState([]);
    const [listCustomers, setListCustomers] = useState([]);
    const [listErrors, setListErrors] = useState([]);
    const [listNameProducts, setListNameProducts] = useState([]);
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [params, setParams] = useState({
        start_date: dayjs(), end_date: dayjs()
    });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [dataChart, setDataChart] = useState([]);
    const [dataTable1, setDataTable1] = useState([]);
    const [dataTable2, setDaTaTable2] = useState([]);

    const configChart = {
        data: dataChart,
        height: 150,
        xField: 'date',
        yField: 'value',
        seriesField: 'error',
    };
    const columns2 = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            render: (value, record, index) => ((page - 1) * pageSize) + index + 1,
        },
        {
            title: 'Ngày sx',
            dataIndex: 'ngay_sx',
            key: 'ngay_sx',
            align: 'center',
        },
        {
            title: 'Ca SX',
            dataIndex: 'ca_sx',
            key: 'ca_sx',
            align: 'center',
        },
        {
            title: 'Xưởng',
            dataIndex: 'xuong',
            key: 'xuong',
            align: 'center',
        },
        {
            title: 'Tên SP',
            dataIndex: 'ten_sp',
            key: 'ten_sp',
            align: 'center',
        },
        {
            title: 'Khách hàng',
            dataIndex: 'khach_hang',
            key: 'khach_hang',
            align: 'center',
        },
        {
            title: 'Mã hàng',
            dataIndex: 'product_id',
            key: 'product_id',
            align: 'center',
        },
        {
            title: 'Lô SX',
            dataIndex: 'lo_sx',
            key: 'lo_sx',
            align: 'center',
        },
        {
            title: 'Mã pallet/thùng',
            dataIndex: 'lot_id',
            key: 'lot_id',
            align: 'center',
        },
        {
            title: 'Số lượng SX',
            dataIndex: 'sl_sx',
            key: 'sl_sx',
            align: 'center',
        },
        {
            title: 'SL lấy mẫu',
            dataIndex: 'sl_mau_thu',
            key: 'sl_mau_thu',
            align: 'center',
        },
        {
            title: 'Số lượng NG',
            dataIndex: 'sl_ng',
            key: 'sl_ng',
            align: 'center',
        },
        {
            title: 'Loại lỗi',
            dataIndex: 'error',
            key: 'error',
            align: 'center',
        },
        {
            title: 'Kết luận',
            dataIndex: 'ket_luan',
            key: 'ket_luan',
            align: 'center',
        },
        {
            title: 'OQC',
            dataIndex: 'nguoi_oqc',
            key: 'nguoi_oqc',
            align: 'center',
        },
    ];
    const columns1 = [
        {
            title: 'Số Lot KT',
            dataIndex: 'lot_check',
            key: 'lot_check',
            align: 'center'
        },
        {
            title: 'Số Lot OK',
            dataIndex: 'lot_ok',
            key: 'lot_ok',
            align: 'center'
        },
        {
            title: 'Số Lot NG',
            dataIndex: 'lot_ng',
            key: 'lot_ng',
            align: 'center'
        },
        {
            title: 'Tỉ lệ NG',
            dataIndex: 'ng_rate',
            key: 'ng_rate',
            align: 'center'
        },
    ];
    useEffect(() => {
        (async () => {
            const res1 = await getLines();
            setListLines(res1.data.map(e => {
                return { ...e, label: e.name, value: e.id }
            }));
            const res2 = await getProducts();
            setListIdProducts(res2.data.map(e => {
                return { ...e, label: e.id, value: e.id }
            }));
            const res3 = await getLoSanXuat();
            setListLoSX(res3.data.map(e => {
                return { ...e, label: e, value: e }
            }));
            const res4 = await getStaffs();
            setListStaffs(res4.data.map(e => {
                return { ...e, label: e.name, value: e.id }
            }))

            const res5 = await getCustomers();
            setListCustomers(res5.data.map(e => {
                return { ...e, label: e.name, value: e.id }
            }));
            const res6 = await getErrors();
            setListErrors(res6.data.map(e => {
                return { ...e, label: e.name, value: e.id }
            }));

        })()
    }, [])
    useEffect(() => {
        btn_click();
    }, [])
    useEffect(() => {
        (async () => {
            var res = await getDataFilterUI({ khach_hang: params.khach_hang });
            if (res.success) {
                setListNameProducts(res.data.product.map(e => {
                    return { ...e, label: e.name, value: e.id }
                }));
                setListLoSX(Object.values(res.data.lo_sx).map(e => {
                    return { label: e, value: e }
                }));
            }
        })()
    }, [params.khach_hang])

    const loadDataTable = async (params) => {
        setLoading(true)
        const res = await getOQCDataTable(params);
        setTotalPage(res.data.totalPage);
        setDaTaTable2(res.data.data);
        setLoading(false)
    }
    const loadDataSummary = async (params) => {
        const res = await getOQCDataSummary(params);
        setDataTable1([res.data]);
    }
    const loadDataChart = async (params) => {
        const res = await getOQCDataChart(params);
        setDataChart(res.data)
    }
    async function btn_click(page = 1, pageSize = 10) {
        setPage(page);
        setPageSize(pageSize);
        loadDataTable({ ...params, page, pageSize, dateType });
        loadDataSummary({ ...params });
        loadDataChart({ ...params, dateType });
    }
    // useEffect(() => {
    //     if (!data) return;
    //     setDataTable1([{
    //         lot_check: data.tong_quan?.tong_lot_kt,
    //         lot_ok: data.tong_quan?.tong_lot_ok,
    //         lot_ng: data.tong_quan?.tong_lot_ng,
    //         ng_rate: data.tong_quan.tong_lot_kt == 0 ? 0 : data.tong_quan.tong_lot_ng / data.tong_quan.tong_lot_kt,
    //     }]);
    //     setDataChart(data.chart)
    //     setDaTaTable2(data.table);

    // }, [data]);

    const [exportLoading, setExportLoading] = useState(false);
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportOQCDataTable({...params});
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const [dateType, setDateType] = useState('date');
    const customDateFormat = (value) => {
        switch (dateType) {
            case 'week':
                return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
                break;
            case 'month':
                return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
                break;
            case 'year':
                return (`Năm ${dayjs(value).format('YYYY')}`);
                break;
            default:
                return (dayjs(value).format('DD/MM/YYYY'));
                break;
        }
    }
    useEffect(() => {
        onChangeDate('start_date', params.start_date, dateType);
        onChangeDate('end_date', params.end_date, dateType);
    }, [dateType])
    const onChangeDate = (position, value, dateType) => {
        switch (dateType) {
            case 'week':
                if (position === 'start_date') {
                    setParams({ ...params, start_date: dayjs(value).startOf('isoWeek'), end_date: dayjs(params.end_date).endOf('isoWeek') });
                } else {
                    setParams({ ...params, start_date: dayjs(params.start_date).startOf('isoWeek'), end_date: dayjs(value).endOf('isoWeek')});
                }
                break;
            case 'month':
                if (position === 'start_date') {
                    setParams({ ...params, start_date: dayjs(value).startOf('month'), end_date: dayjs(params.end_date).endOf('month') })
                } else {
                    setParams({ ...params, start_date: dayjs(params.start_date).startOf('month'), end_date: dayjs(value).endOf('month') });
                }
                break;
            case 'year':
                if (position === 'start_date') {
                    setParams({ ...params, start_date: dayjs(value).startOf('year'), end_date: dayjs(params.end_date).endOf('year') })
                } else {
                    setParams({ ...params, start_date: dayjs(params.start_date).startOf('year'), end_date: dayjs(value).endOf('year') });
                }
                break;
            default:
                if (position === 'start_date') {
                    setParams({ ...params, start_date: dayjs(value), end_date: dayjs(params.end_date) })
                } else {
                    setParams({ ...params, start_date: dayjs(params.start_date), end_date: dayjs(value) });
                }
                break;
        }
    }
    return (
        <React.Fragment>
            <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
                <Col span={4} className='custom-col'>
                    <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                        <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                    ]}>
                        <Form style={{ margin: '0 8px' }} layout="vertical">
                            <Divider>Thời gian truy vấn</Divider>
                            <Radio.Group
                                options={[
                                    { value: 'date', label: 'Ngày', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'week', label: 'Tuần', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'month', label: 'Tháng', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'year', label: 'Năm', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                ]}
                                className='d-flex justify-content-center mb-2 w-100'
                                onChange={(e) => setDateType(e.target.value)}
                                value={dateType}
                                optionType="button"
                                buttonStyle="solid"
                            />
                            <Space direction='vertical' style={{ width: '100%' }}>
                                <DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.start_date} format={customDateFormat} picker={dateType} />
                                <DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.end_date} format={customDateFormat} picker={dateType} />
                            </Space>
                            <Divider>Điều kiện truy vấn</Divider>
                            <Form.Item label="Khách hàng" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Chọn khách hàng"
                                    onChange={(value) => { setParams({ ...params, khach_hang: value }); }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    popupMatchSelectWidth={listCustomers.length ? 400 : "100%"}
                                    options={listCustomers}
                                />
                            </Form.Item>
                            <Form.Item label="Tên sản phẩm" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    onChange={(value) => {
                                        setParams({ ...params, ten_sp: value });
                                    }}
                                    placeholder="Nhập tên sản phẩm"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={listNameProducts}
                                />
                            </Form.Item>
                            <Form.Item label="Lô Sản xuất" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Nhập lô sản xuất"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={(value) => {
                                        setParams({ ...params, lo_sx: value });
                                    }}
                                    options={listLoSX}
                                />
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col span={20} className="custom-col">
                    <Row gutter={[8, 8]}>
                        <Col span={12}>
                            <UIOQCErrorFrequency data={dataChart} />
                        </Col>
                        <Col span={12}>
                            <Card title={<h6>Bảng tóm tắt</h6>} style={{ height: '35vh', padding: '0px' }} bodyStyle={{ padding: 12 }}>
                                <Table bordered pagination={false} columns={columns1} style={{ height: '30px' }}
                                    dataSource={dataTable1} size="small"
                                />
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card title="Chi tiết lỗi" style={{ padding: '0px' }} bodyStyle={{ padding: 12 }} extra={<Button type='primary' loading={exportLoading} onClick={exportFile}>Xuất excel</Button>}>
                                <Table bordered
                                    className="table-mh40"
                                    locale={{ emptyText: '' }}
                                    loading={loading}
                                    columns={columns2}
                                    dataSource={dataTable2}
                                    pagination={{
                                        current: page,
                                        size: 'default',
                                        total: totalPage,
                                        onChange: (page, pageSize) => {
                                            btn_click(page, pageSize)
                                        }
                                    }}
                                    scroll={
                                        {
                                            x: '120vw',
                                            y: '50vh'
                                        }}
                                    size="small" />
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default QualityOQC;
