import React, { useState, useRef } from 'react';
import { Button, Table, Card, Checkbox, message, Modal, Space, InputNumber, Form, Input, Col, Row, Select, DatePicker } from 'antd';
import "../style.scss";
import { exportHistoryMonitors, getDetailAbnormal, getHistoryMonitor, updateMonitor, updateScenario } from '../../../api';
import background1 from "../../../assets/images/layout8.png";
import CommentBoxUI from '../../../components/CommentBoxUI';
import { useEffect } from 'react';
import { getMonitor, getMonitorList } from '../../../api/db/main';
import dayjs from "dayjs";
import { baseURL } from '../../../config';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const img = {
    width: '100%',
    display: 'flex',
}
const { TextArea } = Input;
const Giamsat = (props) => {
    document.title = "UI - Lịch sử bất thường";
    const history = useHistory();
    const [dataTable, setDataTable] = useState([]);
    const [form] = Form.useForm();
    const [formMdl] = Form.useForm();
    const [openMdlEdit, setOpenMdlEdit] = useState(false);
    const [currentRecord, setCurrentRecord] = useState({});
    const columns = [
        {
            title: 'STT',
            dataIndex: 'name1',
            key: 'name1',
            width: 70,
            render: (value, item, index) => (index + 1)
        },
        {
            title: 'Loại cảnh báo',
            dataIndex: 'type',
            key: 'type',
            width: 150,
            render: (value, item, index) => item.type == 'sx' ? 'Sản xuất' : (item.type == 'cl' ? 'Chất lượng' : 'Thiết bị')
        },
        {
            title: 'Thời gian bắt đầu cảnh báo',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 200,
            render: (value, item, index) => dayjs(item.created_at).format('HH:mm:ss')
        },
        {
            title: 'Ngày cảnh báo',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 150,
            render: (value, item, index) => dayjs(item.created_at).format('DD/MM/YYYY')
        },
        {
            title: 'Tên máy',
            dataIndex: 'name',
            key: 'name',
            width: 100,
            render: (value, item, index) => item?.machine?.name
        },
        {
            title: 'Tên lỗi',
            dataIndex: 'content',
            key: 'content',
            width: 100,
        },
        {
            title: 'Giá trị',
            dataIndex: 'value',
            key: 'value',
            width: 100,
        },
        {
            title: 'Tình trạng xử lý',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: (value, item, index) => item.status == 0 ? 'NG' : 'OK'
        }

    ];
    const [dataList, setDataList] = useState([]);
    const hanleGetMonitorList = async () => {
        var res = await getMonitorList();
        setDataList(res.data);
    }
    const [demoMonitor, setDemoMonitor] = useState();
    const hanleGetMonitorSeparate = async () => {
        var res = await getMonitor();
        setDemoMonitor(res.data);
    }
    useEffect(() => {
        // hanleGetMonitorList();
        hanleGetMonitorSeparate();
    }, [])
    // let interval1;
    // useEffect(() => {
    //     interval1 = setInterval(async () => {
    //         hanleGetMonitorList();
    //     }, 5000)
    //     return () => clearInterval(interval1);
    // }, [])
    let interval2;
    useEffect(() => {
        interval2 = setInterval(async () => {
            hanleGetMonitorSeparate();
        }, 5000)
        return () => clearInterval(interval2);
    }, [])
    const onFinish = async (values) => {
        values.start_date = dayjs(values.start_date).format('YYYY-MM-DD');
        values.end_date = dayjs(values.end_date).format('YYYY-MM-DD');
        loadListTable(values);
    }

    const onFinishMdl = async (values) => {
        const res = await updateMonitor(values);
        history.push('/ui/abnormal/lich-su-bat-thuong');
        setOpenMdlEdit(false);
        loadListTable();
    }
    const listMC = [
        {
            code: 'GL_637CIR',
            type: 'cl',
            top: "6vh",
            left: "11.5vw",
        },
        {
            code: 'GL_637CIR',
            type: 'sx',
            top: "12vh",
            left: "2vw",
        },
        {
            code: 'GL_637CIR',
            type: 'tb',
            top: "8vh",
            left: "7vw",
        },
        {
            code: 'SN_UV',
            type: 'sx',
            top: "15vh",
            left: "13vw",
        },
        {
            code: 'SN_UV',
            type: 'tb',
            top: "12vh",
            left: "18vw",
        },
        {
            code: 'MK1060MF',
            type: 'sx',
            top: "16vh",
            left: "19vw",
        },
        {
            code: 'MK1060MF',
            type: 'cl',
            top: "28vh",
            left: "49vw",
        },
        {
            code: 'MK1060MF',
            type: 'tb',
            top: "13vh",
            left: "23vw",
        },
        {
            code: 'ACE70CS',
            type: 'sx',
            top: "24vh",
            left: "29vw",
        },
        {
            code: 'ACE70CS',
            type: 'cl',
            top: "14vh",
            left: "35vw",
        },
        {
            code: 'ACE70CS',
            type: 'tb',
            top: "18vh",
            left: "31.5vw",
        },
    ]
    const [loading, setLoading] = useState(false);
    const loadListTable = async (params) => {
        setLoading(true);
        const res = await getHistoryMonitor(params);
        setDataTable(res);
        setLoading(false);
    }
    useEffect(() => {
        loadListTable(form.getFieldsValue(true));
    }, [])
    const option_type = [{
        label: 'Thiết bị',
        value: 'tb'
    }, {
        label: 'Chất lượng',
        value: 'cl'
    }, {
        label: 'Sản xuất',
        value: 'sx'
    }]
    const option_status = [{
        label: 'OK',
        value: 1
    }, {
        label: 'NG',
        value: 0
    }]
    const option_machine = [{
        label: 'MÁY IN TỜ RỜI  KOMORI',
        value: 'GL_637CIR'
    }, {
        label: 'MÁY PHỦ UV CỤC BỘ',
        value: 'SN_UV'
    }, {
        label: 'MÁY BẾ TỜ RỜI',
        value: 'MK1060MF'
    }, {
        label: 'MÁY GẤP HỘP',
        value: 'ACE70CS'
    }]
    const [exportLoading1, setExportLoading1] = useState(false);
    const exportFile = async () => {
        setExportLoading1(true);
        const res = await exportHistoryMonitors(form.getFieldsValue(true));
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading1(false);
    }
    const onClickRow = (record) => {
        setCurrentRecord(record);
        formMdl.setFieldsValue({ id: record?.id, troubleshoot: record?.troubleshoot });
        setOpenMdlEdit(true);
    }
    const location = useLocation();
    useEffect(() => {
        (async () => {
            const queryParameters = new URLSearchParams(location.search)
            const id = queryParameters.get("id");
            if(id){
                const res = await getDetailAbnormal({ id: id });
                formMdl.setFieldsValue({ id: id, troubleshoot: res?.troubleshoot });
                setCurrentRecord(res);
                setOpenMdlEdit(true);
            }
        })()
    }, [])
    useEffect(() => {
        if (dataTable.length > 0) {
            const queryParameters = new URLSearchParams(location.search)
            const id = queryParameters.get("id");
            const target = dataTable.find(e => e.id == id);
            if (target) {
                onClickRow(target);
            }
        }
    }, [location, dataTable])
    return (
        <React.Fragment>
            <Card className='mt-3' title="Lịch sử bất thường" bodyStyle={{height: 'calc(100vh - 150px)'}}>
                <Form layout={'vertical'} form={form} onFinish={onFinish} initialValues={{
                    start_date: dayjs(),
                    end_date: dayjs()
                }}>
                    <Row gutter={[16, 16]}>
                        <Col span={4}>
                            <Form.Item label="Bắt đầu" name="start_date">
                                <DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Kết thúc" name="end_date">
                                <DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Loại cảnh báo" name="type">
                                <Select options={option_type} placeholder="Chọn loại cảnh báo" allowClear></Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Tên máy" name="machine_id">
                                <Select options={option_machine} placeholder="Chọn máy" allowClear></Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Tình trạng xử lý" name="status">
                                <Select options={option_status} placeholder="Chọn tình trạng xử lý" allowClear></Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Space>
                                <Button htmlType="submit" type="primary" style={{ marginTop: '30px' }}>Truy vấn</Button>
                                <Button type="primary" style={{ marginTop: '30px' }} onClick={exportFile} loading={exportLoading1}>Xuất Excel</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <Table
                            className="table-mh60"
                            locale={{emptyText: ''}}
                            loading={loading}
                            columns={columns.map(e=>({...e, align: 'center'}))}
                            dataSource={dataTable}
                            bordered
                            scroll={{
                                y: '60vh',
                                x: 1070
                            }}
                            pagination={false}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (event) => onClickRow(record)
                                };
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <div style={{ position: 'relative', width: '100%', backgroundColor: 'white', float: 'right' }}>
                            {demoMonitor?.length > 0 ? (
                                demoMonitor.map((value) => {
                                    return <CommentBoxUI title={value?.type?.toUpperCase()} content={value?.content} type={value?.type === 'sx' ? 'error' : (value?.type === 'cl' ? 'warning' : 'success')} color={value?.type === 'sx' ? '#90f' : (value?.type === 'cl' ? '#AA0000' : '#00f')} top={listMC.find((val) => val.code === value?.machine_id && val.type === value.type)?.top} left={listMC.find((val) => val.code == value?.machine_id && val.type === value.type)?.left} />
                                }
                                )) : <></>
                            }
                            <img style={img} src={background1} />
                        </div>
                    </Col>
                </Row>
            </Card>
            <Modal title={'Thông tin bất thường'} open={openMdlEdit} onCancel={() => setOpenMdlEdit(false)} footer={null}>
                <p>Loại cảnh báo: <b>{currentRecord?.type == 'sx' ? 'Sản xuất' : (currentRecord?.type == 'cl' ? 'Chất lượng' : 'Thiết bị')}</b></p>
                <p>Thời gian bắt đầu cảnh báo: <b>{dayjs(currentRecord?.created_at).format('HH:mm:ss')}</b></p>
                <p>Ngày cảnh báo: <b>{dayjs(currentRecord?.created_at).format('DD/MM/YYYY')}</b></p>
                <p>Tên máy: <b>{currentRecord?.machine?.name}</b></p>
                <p>Tên lỗi: <b>{currentRecord?.content}</b></p>
                <p>Giá trị: <b>{currentRecord?.value}</b></p>
                <p>Tình trạng: <b>{currentRecord?.status == 0 ? 'NG' : 'OK'}</b></p>
                <Form style={{ margin: '0' }}
                    layout="vertical"
                    form={formMdl}
                    onFinish={onFinishMdl}>
                    <Form.Item name="id" className='mb-3 d-none'>
                    </Form.Item>
                    <Form.Item label="Cách khắc phục" name="troubleshoot" className='mb-3'>
                        <TextArea rows={4} placeholder='Nhập cách khắc phục'></TextArea>
                    </Form.Item>
                    <Form.Item className='mb-0'>
                        <Button type="primary" htmlType='submit' >Lưu lại</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </React.Fragment >
    );
};

export default Giamsat;