import React, { useEffect, useRef, useState, useMemo } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Descriptions, Form, Input, Modal, Space } from "antd";
import dayjs from "dayjs";
import {
  getMachineParameterLog,
  updateMachineParameterLog,
} from "../../../api/db/main";

const SketchfabCNCModel = ({ modelUid, setIsShow3D, setIsShowTable }) => {
  const history = useHistory();
  const iframeRef = useRef(null);
  const [client, setClient] = useState(null);
  const [nodes, setNodes] = useState({});
  const [selectedNode, setSelectedNode] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenFirstModal, setIsOpenFirstModal] = useState(false);
  const [visibilityMap, setVisibilityMap] = useState({});
  const [warning, setWarning] = useState(false);
  const [dataLog, setDataLog] = useState();
  const models = useRef({
    cnc: [],
    quality: [],
    logistic: [],
    monior: [],
    machine: [],
  });
  const [form] = Form.useForm();

  const originalCameraPosition = useMemo(
    () => ({
      position: [0.7358111570048669, 0.08390532497652266, 0.0910176768420195],
      target: [0.5226255332454119, 0.15541119898803826, 0.02531807691310094],
    }),
    []
  );

  const objectCameraPosition = useMemo(
    () => ({
      position: [0.6492250220832392, 0.1060187446308546, 0.05256262526323602],
      target: [0.4934386141768933, 0.1816019132680778, 0.04255195129769821],
    }),
    []
  );
  const handleClose3DView = () => {
    setIsShow3D("none");
    setIsShowTable("block");
  };
  useEffect(() => {
    const iframe = iframeRef.current;
    const sketchfabClient = new window.Sketchfab("1.12.1", iframe);
    setWarning(false);

    sketchfabClient.init(modelUid, {
      success: (api) => {
        setClient(api);
        api.start();
        api.addEventListener("viewerready", () => {
          loadNodes(api);
          hideNodes(api);
          api.setCameraLookAt(
            originalCameraPosition.position,
            originalCameraPosition.target,
            1,
            () => {
              console.log("Camera moved");
              setWarning(true);
            }
          );
        });
      },
      error: () => {
        console.error("Viewer error");
        setWarning(true);
      },
    });
  }, [modelUid, originalCameraPosition]);

  const loadNodes = useMemo(
    () => (api) => {
      api.getNodeMap(async (err, nodes) => {
        if (!err) {
          setNodes(nodes);
          await loadModels(nodes);
          setupNodeClick(api, nodes);
          initializeVisibilityMap(nodes);
        }
      });
    },
    []
  );

  const hideNodes = useMemo(
    () => (api) => {
      api.getNodeMap((err, nodes) => {
        if (!err) {
          const hideModels = [
            ...models.current.logistic,
            ...models.current.machine,
            ...models.current.monior,
            ...models.current.quality,
          ];
          hideModels.forEach(({ instanceID }) => {
            const node = nodes[instanceID];
            if (node) api.hide(node.instanceID);
          });
        }
      });
    },
    []
  );

  const loadModels = async (nodes) => {
    const cncModels = Object.values(nodes).filter((value) =>
      String(value?.name ?? "").startsWith("CNC")
    );
    const qualityModels = Object.values(nodes).filter((value) =>
      String(value?.name ?? "").startsWith("QUALITY_CNC")
    );
    const logisticModels = Object.values(nodes).filter((value) =>
      String(value?.name ?? "").startsWith("LOGISTIC_CNC")
    );
    const moniorModels = Object.values(nodes).filter((value) =>
      String(value?.name ?? "").startsWith("monior_CNC")
    );
    const machineModels = Object.values(nodes).filter((value) =>
      String(value?.name ?? "").startsWith("MACHINE_CNC")
    );
    models.current = {
      cnc: cncModels,
      quality: qualityModels,
      logistic: logisticModels,
      monior: moniorModels,
      machine: machineModels,
    };
  };

  const setupNodeClick = (api, nodes) => {
    api.addEventListener("click", (event) => {
      const nodeId = event.instanceID;
      const node = Object.values(nodes).find(
        (node) => node.instanceID === nodeId
      );
      if (node) {
        const nodeName = node.name;
        const allModels = [
          ...models.current.cnc,
          ...models.current.logistic,
          ...models.current.machine,
          ...models.current.monior,
          ...models.current.quality,
        ];
        const getModel = allModels.find((model) => {
          if (String(nodeName).startsWith(model?.name)) return true;
          const node = model.children?.find((item) =>
            String(nodeName).startsWith(item?.name)
          );
          return !!node;
        });
        if (getModel) {
          setSelectedNode(getModel);
          // setIsOpenModal(true);
          setIsOpenFirstModal(true);
        }
      }
    });
  };

  const initializeVisibilityMap = (nodes) => {
    const visibility = {};
    const hideModels = [
      ...models.current.logistic,
      ...models.current.machine,
      ...models.current.monior,
      ...models.current.quality,
    ];
    Object.values(nodes).forEach((node) => {
      if (!hideModels.find((model) => model.instanceID === node.instanceID)) {
        visibility[node.instanceID] = true; // Default all nodes to visible
      }
    });
    setVisibilityMap(visibility);
  };

  const closePopup = () => {
    setIsOpenModal(false);
    setIsOpenFirstModal(false);
    setSelectedNode(null);
  };

  const showPopupWarning = (status = true) => {
    if (client) {
      client.getNodeMap(async (err, nodes) => {
        if (!err) {
          const hideModels = [
            ...models.current.logistic,
            ...models.current.machine,
            ...models.current.monior,
            ...models.current.quality,
          ];
          const instanceIdsToHide = hideModels.map(
            (models) => models.instanceID
          );
          instanceIdsToHide.forEach((id) => {
            const node = nodes[id];
            if (node) {
              setSelectedNode(node);
              if (status) client.show(node.instanceID);
              else client.hide(node.instanceID);
            }
          });
        }
      });
    }
  };
  const moveCameraToNode = async (api) => {
    const res = await getMachineParameterLog();
    if (res?.data) {
      setDataLog(res.data);
      const monitor = res.data?.monitor;
      if (!monitor) {
        api.setCameraLookAt(
          originalCameraPosition.position,
          originalCameraPosition.target,
          1.5,
          function (err) {
            if (!err) {
              window.console.log("Original camera moved");
            }
          }
        );
        setIsOpenModal(false);
        closePopup();
        handleClose3DView();
      }
      if (Number(monitor?.status) === 0) {
        api.setCameraLookAt(
          objectCameraPosition.position,
          objectCameraPosition.target,
          1.5,
          function (err) {
            if (!err) {
              window.console.log("Object camera moved");
            }
          }
        );
        showPopupWarning(true);
        setIsOpenFirstModal(true);
      } else if (Number(monitor?.status) === 1) {
        if (monitor?.troubleshoot) {
          // Tracking monitor.troubleshoot: nếu đã nhập xử lý thì ẩn modal
          api.setCameraLookAt(
            originalCameraPosition.position,
            originalCameraPosition.target,
            1.5,
            function (err) {
              if (!err) {
                window.console.log("Original camera moved");
              }
            }
          );
          showPopupWarning(false);
          setIsOpenModal(false);
          setIsOpenFirstModal(false);
        } else {
          api.setCameraLookAt(
            objectCameraPosition.position,
            objectCameraPosition.target,
            1.5,
            function (err) {
              if (!err) {
                window.console.log("Object camera moved");
              }
            }
          );
          setIsOpenFirstModal(false);
          showPopupWarning(false);
          setIsOpenModal(true);
        }
      }
    } else {
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (client && warning) {
        moveCameraToNode(client);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [client, warning]);

  const onFinish = async (values) => {
    if (values) {
      const res = await updateMachineParameterLog({
        parameter_id: dataLog?.monitor?.parameter_id,
        troubleshoot: values?.troubleshoot,
      });
      if (res.success) {
        form.resetFields();
        closePopup();
        if (client) {
          if (warning) moveCameraToNode(client);
        }
      }
    }
  };

  const itemsDescription = [
    {
      key: "start_time",
      label: "Thời gian bắt đầu",
      children: dataLog?.monitor?.created_at
        ? dayjs(dataLog?.monitor?.created_at).format("DD/MM/YYYY HH:mm:ss")
        : dayjs().format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      key: "end_time",
      label: "Thời gian kết thúc",
      children: dataLog?.monitor?.updated_at
        ? dayjs(dataLog?.monitor?.updated_at).format("DD/MM/YYYY HH:mm:ss")
        : dayjs().format("DD/MM/YYYY HH:mm:ss"),
    },

    {
      key: "content",
      label: "Hướng dẫn xử lý",
      span: 2,
      children: (
        <div>
          <div>[Machine] Kiểm tra đường dẫn ký nén bị lỏng</div>
          <div>[Machine] Kiểm tra van cấp khí nén bị lỏng</div>
          <div>[Machine] Kiểm tra máy cấp khí có hoạt động hay không</div>
        </div>
      ),
    },
    {
      key: "troubleshoot",
      label: "Kết quả xử lý",
      span: 2,
      children: (
        <>
          <Form.Item
            style={{ marginBottom: 0 }}
            name="troubleshoot"
            rules={[{ required: true, message: "Vui lòng nhập!" }]}
          >
            <Input.TextArea placeholder="Vui lòng nhập" rows={2} />
          </Form.Item>
        </>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: "Giám sát bất thường",
      span: 2,
      children: (
        <ul>
          <li>Hiện trạng: Áp suất giảm liên tục 3 lần liên tiếp</li>
          <li>
            Mức ảnh hưởng: Dự báo áp suất có thể vượt giới hạn kiểm soát, gây
            lỗi chất lượng
          </li>
        </ul>
      ),
    },
    {
      key: "2",
      label: "Hướng dẫn xử lý",
      span: 2,
      children: (
        <ul>
          <li>[Machine] Kiểm tra đường dẫn ký nén bị lỏng</li>
          <li>[Machine] Kiểm tra van cấp khí nén bị lỏng</li>
          <li>[Machine] Kiểm tra máy cấp khí có hoạt động hay không</li>
        </ul>
      ),
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        ref={iframeRef}
        width="100%"
        height="100%"
        title="Thanglong Layout"
        frameborder="0"
        allowfullscreen
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        allow="autoplay; fullscreen; xr-spatial-tracking"
        xr-spatial-tracking
        execution-while-out-of-viewport
        execution-while-not-rendered
        web-share
        src={`https://sketchfab.com/models/${modelUid}/embed`}
      ></iframe>
      <Modal
        className="modal-simulator"
        title={
          <div style={{ fontWeight: "bold", fontSize: "20px" }}>
            [Kết quả xử lý]
          </div>
        }
        open={isOpenModal}
        onCancel={closePopup}
        footer={null}
        width={1000}
        loading={!selectedNode}
        style={{ top: 70 }}
        mask={false}
      >
        {selectedNode && (
          <Form
            style={{ padding: "5px 10px" }}
            layout="vertical"
            form={form}
            onFinish={onFinish}
          >
            <Descriptions
              size="small"
              layout="horizontal"
              bordered
              labelStyle={{
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
                width: "200px",
              }}
              contentStyle={{ color: "black", fontSize: "18px" }}
              items={itemsDescription}
              column={2}
            />
            <Form.Item
              style={{
                marginTop: "10px",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              <Space>
                <Button type="primary" htmlType="submit">
                  Xác nhận
                </Button>
                <Button htmlType="button" onClick={closePopup}>
                  Hủy
                </Button>
              </Space>
            </Form.Item>
          </Form>
        )}
      </Modal>

      <Modal
        className="modal-simulator"
        title={
          <div style={{ fontWeight: "bold", fontSize: "20px" }}>
            [LH1-A2] Áp suất khí nén giảm (
            {dayjs(dataLog?.updated_at).format("DD/MM/YYYY HH:mm:ss")})
          </div>
        }
        open={isOpenFirstModal}
        onCancel={closePopup}
        footer={null}
        width={1000}
        loading={!selectedNode}
        style={{ top: 70 }}
        mask={false}
      >
        <div style={{ padding: "5px 10px" }}>
          {selectedNode && (
            <Descriptions
              size="small"
              layout="horizontal"
              bordered
              labelStyle={{
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
                width: "210px",
              }}
              contentStyle={{ color: "black", fontSize: "18px" }}
              items={items}
              column={2}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default SketchfabCNCModel;
