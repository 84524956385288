import axios from "axios";
const prefix = 'p2/oi/';

export async function getQCOverall(params) {
    const res = await axios.get(prefix+'qc-overall', {params});
    return res;
}
export async function getLotQCList(params) {
    const res = await axios.get(prefix+'lot-qc-list', {params});
    return res;
}
export async function getLotQCCurrent(params) {
    const res = await axios.get(prefix+'lot-qc-current', {params});
    return res;
}
export async function scanQC(params) {
    const res = await axios.post(prefix+'scan-qc', params);
    return res;
}
export async function getCriteriaListOfLot(params) {
    const res = await axios.get(prefix+'criteria-list-of-lot', {params});
    return res;
}
export async function savePQCResult(params) {
    const res = await axios.post(prefix+'save-pqc-result', params);
    return res;
}
export async function updateErrorLog(params) {
    const res = await axios.post(prefix+'update-error-log', params);
    return res;
}
export async function updateTemVangQuantity(params) {
    const res = await axios.post(prefix+'update-tem-vang-quantity', params);
    return res;
}
export async function printTemVang(params) {
    const res = await axios.post(prefix+'print-tem-vang', params);
    return res;
}
export async function scanOQC(params) {
    const res = await axios.post(prefix+'scan-oqc', params);
    return res;
}