import React, { useEffect, useState, useRef } from 'react';
import { QrcodeOutlined } from '@ant-design/icons';
import { Row, Col, Button, Table, Modal, Input, Form, InputNumber, message, Space } from 'antd';
import DataDetail from '../../../../components/DataDetail';
import '../../style.scss';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import SelectButton from '../../../../components/Button/SelectButton';
import { exportWareHouse, getDetailLot, getInfoExportWareHouse, getListCustomerExport, getProposeExportWareHouse, gopThung, prepareGT, splitBarrel } from '../../../../api';
import dayjs from "dayjs";
import { useProfile } from '../../../../components/hooks/UserHooks';
import TemThung from '../TemThung';
import { useReactToPrint } from "react-to-print";
import { overallExport } from '../../../../api/phase2/oi/warehouse';
import DataDetail2 from '../../../../components/DataDetail2';

const exportColumns = [
    {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        render: (value, item, index) => index + 1
    },
    {
        title: 'Thời gian xuất',
        dataIndex: 'thoi_gian_xuat',
        key: 'thoi_gian_xuat',
        align: 'center'
    },
    {
        title: 'Mã hàng',
        dataIndex: 'product_id',
        key: 'product_id',
        align: 'center'
    },
    {
        title: 'Tên sản phẩm',
        dataIndex: 'ten_san_pham',
        key: 'ten_san_pham',
        align: 'center'
    },
    {
        title: 'Mã thùng',
        dataIndex: 'lot_id',
        key: 'lot_id',
        align: 'center'
    },
    {
        title: 'Kế hoạch xuất',
        dataIndex: 'ke_hoach_xuat',
        key: 'lot',
        align: 'center'
    },
    {
        title: 'Thực tế xuất',
        dataIndex: 'thuc_te_xuat',
        key: 'thuc_te_xuat',
        align: 'center'
    },
    {
        title: 'Vị trí',
        dataIndex: 'vi_tri',
        key: 'vi_tri',
        align: 'center'
    },
    {
        title: 'PIC',
        dataIndex: 'pic',
        key: 'pic',
        align: 'center'
    },
];
const defaultRow1 = [
    {
        title: "Kế hoạch xuất",
        key: "number_of_plan",
    },
    {
        title: "Sản lượng",
        key: "quantity",
    },
    {
        title: "Tỷ lệ",
        key: "ratio",
    },
];
const defaultRow2 = [
    {
        title: 'Mã hàng',
        key: 'product_id',
    },
    {
        title: 'Tên SP',
        key: 'ten_san_pham',
    },
    {
        title: 'Mã thùng',
        key: 'lot_id',
    },
    {
        title: 'Số lượng',
        key: 'so_luong',
    },
    {
        title: 'Vị trí',
        key: 'vi_tri',
    }
];
const Export = (props) => {
    document.title = "Kho";
    const { line } = useParams();
    const history = useHistory();
    const [customersData, setCustomersData] = useState([]);
    const [data, setData] = useState([]);
    const [listTem, setListTem] = useState([]);

    const options = [
        {
            label: 'Nhập',
            value: 'nhap',
        },
        {
            label: 'Xuất',
            value: 'xuat',
        },
    ];
    const onChangeLine = (value) => {
        history.push('/warehouse/' + value)
    }

    const [row2, setRow2] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [currentLot, setCurrentLot] = useState();
    const [currentKhachHang, setCurrentKhachHang] = useState('');
    const [valueQR, setValueQR] = useState('');
    const [form] = Form.useForm();
    const onFinish = async (values) => {
        values.lot_id = currentLot.lot_id;
        const res = await splitBarrel(values);
        setListTem(res);
        setOpenModal(false);
        setData(prev => prev.map(e => {
            if (e.lot_id === currentLot.lot_id) {
                e.so_luong = values.remain_quanlity;
            }
            return e;
        }));
        setCurrentLot();
        setRow2([
            {
                title: 'Mã hàng',
                value: '',
            },
            {
                title: 'Tên SP',
                value: '',
            },
            {
                title: 'Mã thùng',
                value: '',
            },
            {
                title: 'Số lượng',
                value: '',
            },
            {
                title: 'Vị trí',
                value: '',
            }
        ]);
    }
    useEffect(() => {
        if (listTem.length > 0) {
            handlePrint();
            setListTem([])
        }
    }, [listTem.length])

    const onClickRow = (record) => {
        setData(prev => prev.map(e => {
            if (e.lot_id === record.lot_id && record.status !== 2) {
                return { ...e, status: 1 }
            }
            if (e.status === 2) {
                return e;
            }
            return { ...e, status: 0 };
        }));
    }
    const onChangeCustomer = async (value) => {
        setCurrentKhachHang(value);
        const res = await getProposeExportWareHouse({ khach_hang: value })
        setData(res);
    }
    const [dataOverallExport, setDataOverallExport] = useState({});
    const loadInfo = async () => {
        var res = await overallExport()
        setDataOverallExport(res.data);
    }
    const loadCustomer = async () => {
        setCustomersData(await getListCustomerExport());
    }
    useEffect(() => {
        (async () => {
            loadCustomer();
            loadInfo();
        })()
    }, [])
    const getLotCurrent = async (e) => {
        const current_lot = data.find((old_data) => old_data.lot_id === e.target.value);
        console.log(current_lot);
        if (current_lot) {
            if (current_lot.status === 2) {
                message.error('Thùng hàng đã xuất');
            } else {
                setCurrentLot(current_lot);
                setValueQR('');
            }
        } else {
            message.error('Mã thùng không có trong danh sách gợi ý');
            setValueQR('');
        }
    }
    const saveLogWareHouse = async (e) => {
        if (e.target.value === currentLot.vi_tri) {
            const res = await exportWareHouse({ lot_id: currentLot.lot_id, cell_id: e.target.value, khach_hang: currentKhachHang });
            setCurrentLot();
            const new_data = data.filter((old_data) => {
                if (old_data.product_id === currentLot.product_id) {
                    old_data.thuc_te_xuat = old_data.thuc_te_xuat + currentLot.so_luong;
                }
                if (old_data.lot_id !== currentLot.lot_id) {
                    return old_data;
                }
            })
            setData(new_data);
            loadInfo();
            setValueQR('');
        } else {
            message.error('Không đúng vị trí đề xuất');
        }
    }
    const changeRemain = (value) => {
        form.setFieldValue('remain_quanlity', currentLot.so_luong - value);
    }
    const componentRef1 = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef1.current
    });
    const [openModalGT, setOpenModalGT] = useState(false);
    const [formGT] = Form.useForm();
    const selectedGT = Form.useWatch([], formGT);
    const onFinishGT = async (values) => {
        if (values.sl_gop > values.sl_thung2) {
            message.info('Số lượng gộp không được lớn hơn thùng gộp');
            return;
        }
        if (values.sl_gop <= 0) {
            message.info('Số lượng gộp phải lớn hơn 0');
            return;
        }
        var res = await gopThung(values);
        if (res) {
            setListTem(res);
        }
        formGT.resetFields();
    }
    const getDataThung = async () => {
        var res = await prepareGT(formGT.getFieldsValue(true));
        if (res) {
            formGT.setFieldsValue(res)
        }
    }
    useEffect(()=>{
        console.log(currentLot);
    }, [currentLot])
    return (
        <React.Fragment>
            <Row className='mt-3' gutter={[12, 12]}>
                <Col span={4}>
                    <SelectButton value={line} options={options} label="Chọn công đoạn" onChange={onChangeLine} />
                </Col>
                <Col span={20}>
                    <DataDetail2 data={defaultRow1} value={dataOverallExport} />
                </Col>
                <Col span={24}>
                    <Input
                        size="large"
                        prefix={
                            <QrcodeOutlined
                                style={{ fontSize: '24px', marginRight: '10px' }}
                            />
                        }
                        placeholder={"Nhập mã QR hoặc nhập mã thùng"}
                        onPressEnter={currentLot ? saveLogWareHouse : getLotCurrent}
                        onChange={(e) => { setValueQR(e.target.value) }}
                        value={valueQR}
                        allowClear
                    />
                </Col>
                <Col span={4}>
                    <SelectButton options={customersData} onChange={onChangeCustomer} label="Khách hàng" />
                </Col>
                <Col span={15}>
                    <DataDetail2 data={defaultRow2} value={currentLot} />
                </Col>
                <Col span={5}>
                    <div className='d-flex justify-content-between h-100 w-100'>
                        <Button size='large' type='primary' style={{ height: '100%', width: '100%', marginRight: 12, whiteSpace: 'normal' }}
                            onClick={() => {
                                setOpenModalGT(true);
                                form.resetFields();
                            }}>Gộp thùng</Button>
                        <Button size='large' type='primary' style={{ height: '100%', width: '100%', whiteSpace: 'normal' }}
                            onClick={() => {
                                if (currentLot) {
                                    setOpenModal(true);
                                    form.resetFields();
                                } else {
                                    message.info('Chưa chọn thùng');
                                }
                            }}>Tách thùng</Button>
                    </div>
                </Col>
                <Col span={24}>
                    <Table
                        scroll={{
                            x: 200,
                            y: '100vh',
                        }}
                        rowClassName={(record, index) => record.status === 1 ? 'table-row-yellow' : record.status === 2 ? 'table-row-grey' : ''}
                        pagination={false}
                        bordered
                        className='mb-4'
                        columns={exportColumns}
                        dataSource={data}
                        onRow={(record, index) => {
                            return {
                                onClick: () => onClickRow(record)
                            }
                        }}
                    />
                </Col>
            </Row>
            <div className="report-history-invoice">
                <TemThung listCheck={listTem} ref={componentRef1} />
            </div>
            <Modal title="Tách thùng" open={openModal} footer={null} onCancel={() => { setOpenModal(false) }}>
                <Form form={form} onFinish={onFinish} layout='vertical'>
                    <Form.Item name={'export_quanlity'} label="Q.ty xuất">
                        <InputNumber className='w-100' inputMode='numeric' onChange={(value) => { changeRemain(value) }} />
                    </Form.Item>
                    <Form.Item name={'remain_quanlity'} label="Q.ty còn">
                        <InputNumber className='w-100' inputMode='numeric' disabled />
                    </Form.Item>
                    <Space>
                        <Button type="primary" htmlType='submit'>In tem</Button>
                        <Button onClick={() => setOpenModal(false)}>Hủy</Button>
                    </Space>
                </Form>
            </Modal>
            <Modal title="Gộp thùng" open={openModalGT} footer={null} onCancel={() => { setOpenModalGT(false) }}>
                <Form form={formGT} onFinish={onFinishGT} layout='vertical' initialValues={{
                    thung1: '',
                    thung2: '',
                    sl_thung1: 0,
                    sl_thung2: 0,
                    sl_gop: 0
                }}
                    validateMessages={{
                        required: "Trường này là bắt buộc",
                    }}>
                    <Form.Item name={'sl_thung1'} hidden><InputNumber /></Form.Item>
                    <Form.Item name={'sl_thung2'} hidden><InputNumber /></Form.Item>
                    <Form.Item name={'thung1'} label="Thùng được gộp" extra={"Số lượng: " + (selectedGT?.sl_thung1 ?? 0)} rules={[{ required: true }]}>
                        <Input
                            size="large"
                            prefix={
                                <QrcodeOutlined
                                    style={{ fontSize: '24px', marginRight: '10px' }}
                                />
                            }
                            placeholder={"Nhập mã QR hoặc nhập mã thùng"}
                            onPressEnter={getDataThung}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item name={'thung2'} label="Thùng gộp" extra={"Số lượng: " + (selectedGT?.sl_thung2 ?? 0)} rules={[{ required: true }]}>
                        <Input
                            size="large"
                            prefix={
                                <QrcodeOutlined
                                    style={{ fontSize: '24px', marginRight: '10px' }}
                                />
                            }
                            onPressEnter={getDataThung}
                            // onChange={(e) => { setSelectedGT({...selectedGT, thung2: e.target.value}) }}
                            placeholder={"Nhập mã QR hoặc nhập mã thùng"}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item name={'sl_gop'} label="Số lượng gộp" rules={[{ required: true }]}>
                        <InputNumber className='w-100' inputMode='numeric' min={0} />
                    </Form.Item>
                    <Space>
                        <Button type="primary" onClick={() => formGT.submit()}>In tem</Button>
                        <Button onClick={() => setOpenModalGT(false)}>Hủy</Button>
                    </Space>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default Export;