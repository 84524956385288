import { LoadingOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Divider, Form, Input, Layout, Modal, Radio, Row, Select, Space, Spin, Table, Tree, Upload, message } from 'antd';
import dayjs from "dayjs";
import React, { useEffect, useState } from 'react';
import { deleteRecordProductPlan, exportKHSX, getListProductPlan, storeProductPlan, updateProductPlan } from '../../../api';
import { getTreeSelect } from '../../../api/phase2/ui/main';
import { getCustomers, getDataFilterUI } from '../../../api/ui/main';
import { baseURL } from '../../../config';
import CalculateTableHeight from '../../../components/calculateTableHeight';

const { Sider } = Layout;
const { RangePicker } = DatePicker;

const dataFaker = [
	{
		key: '1',
		organization: 'TOTAL',
		productivity_item: 'Prod-Qty/Person',
		productivity_index: '89.8',
		input_item: 'Register MH',
		input_mh: '7,531,941',
		output_item: 'Total SMH',
		output_index: '11,199,360',
		output_percent: '-',
		loss_item: 'Material Shortage (Ext)',
		loss_index: '1,389',
		loss_percent: '0.02',
		rest_item: 'Day Rest',
		rest_index: '516,885',
		rest_percent: '6.86',
	},
	{
		key: '2',
		organization: 'TOTAL',
		productivity_item: 'Prod. Qty/Person Actual MH',
		productivity_index: '91.3',
		input_item: 'Rest MH',
		input_mh: '767,552',
		output_item: 'In-house SMH',
		output_index: '4,505,766',
		output_percent: '40.2',
		loss_item: 'Material Shortage (Int)',
		loss_index: '0',
		loss_percent: '0.0',
		rest_item: 'Absence',
		rest_index: '11,684',
		rest_percent: '0.16',
	},
	{
		key: '3',
		organization: 'TOTAL',
		productivity_item: 'Working MH eff.(%)',
		productivity_index: '62.2',
		input_item: 'Performance MH',
		input_mh: '6,764,389',
		output_item: 'Automation SMH',
		output_index: '5,673,617',
		output_percent: '50.7',
		loss_item: 'Material Defect',
		loss_index: '592',
		loss_percent: '0.01',
		rest_item: 'Business Trip',
		rest_index: '0',
		rest_percent: '0.0',
	},
	{
		key: '4',
		organization: 'TOTAL',
		productivity_item: 'Loss Rate(%)',
		productivity_index: '1.7%',
		input_item: 'Add MH',
		input_mh: '482,246',
		output_item: 'Design SMH',
		output_index: '253,469',
		output_percent: '2.3',
		loss_item: 'Design Loss',
		loss_index: '0',
		loss_percent: '0.0',
		rest_item: 'Education',
		rest_index: '77,216',
		rest_percent: '1.03',
	},
	{
		key: '5',
		organization: 'TOTAL',
		productivity_item: 'Actual Rate(%)',
		productivity_index: '98.3',
		input_item: 'Working MH',
		input_mh: '1,246,635',
		output_item: 'Improvement SMH',
		output_index: '766,508',
		output_percent: '6.8',
		loss_item: 'Out-Material Shortage',
		loss_index: '0',
		loss_percent: '0.0',
		rest_item: 'Half Day Off',
		rest_index: '83,649',
		rest_percent: '1.11',
	},
	{
		key: '6',
		organization: 'TOTAL',
		productivity_item: 'Actual MH eff.(%)',
		productivity_index: '61.3',
		input_item: 'Loss MH',
		input_mh: '120,457',
		output_item: 'Reduction SMH',
		output_index: '6,699,593',
		output_percent: '59.8',
		loss_item: 'Exchange',
		loss_index: '108,900',
		loss_percent: '1.5',
		rest_item: 'Support',
		rest_index: '78,114',
		rest_percent: '1.04',
	},
	{
		key: '7',
		organization: 'TOTAL',
		productivity_item: 'Value eff.(%)',
		productivity_index: '0.0',
		input_item: 'Actual MH',
		input_mh: '0',
		output_item: 'Total F/T MH',
		output_index: '0',
		output_percent: '-',
		loss_item: 'Rework',
		loss_index: '0',
		loss_percent: '0.0',
		rest_item: 'Other official rest',
		rest_index: '0',
		rest_percent: '0.0',
	},
	{
		key: '8',
		organization: 'TOTAL',
		productivity_item: 'Actual Value eff.(%)',
		productivity_index: '0.0',
		input_item: 'Working Time',
		input_mh: '346,094',
		output_item: 'Working Day',
		output_index: '45',
		output_percent: '-',
		loss_item: 'Equip. Breakdown',
		loss_index: '0',
		loss_percent: '0.0',
		rest_item: 'Sum',
		rest_index: '767,552',
		rest_percent: '10.19',
	},
	{
		key: '9',
		organization: 'TOTAL',
		productivity_item: 'ST Reduction Rate(%)',
		productivity_index: '59.8',
		input_item: 'Working Time(T/Time)',
		input_mh: '335,154',
		output_item: 'Working Day',
		output_index: '45',
		output_percent: '-',
		loss_item: 'Utility',
		loss_index: '0',
		loss_percent: '0.0',
		rest_item: 'Detail Add MH Contents',
		rest_index: '0',
		rest_percent: '-',
	},
	{
		key: '10',
		organization: 'TOTAL',
		productivity_item: 'Prod. Qty',
		productivity_index: '1,792,494',
		input_item: 'Loss time',
		input_mh: '10,325',
		output_item: 'Basic ST',
		output_index: '8,261',
		output_percent: '-',
		loss_item: 'Molding/Measuring',
		loss_index: '0',
		loss_percent: '0.0',
		rest_item: 'Off Day Work',
		rest_index: '75,516',
		rest_percent: '1.04',
	},
	{
		key: '11',
		organization: 'TOTAL',
		productivity_item: 'Prod. Qty Standard Time',
		productivity_index: '1,723,871',
		input_item: 'Loss Time (Working T/Time)',
		input_mh: '5,825',
		output_item: 'Exclude Early End of Prod',
		output_index: '0',
		output_percent: '-',
		loss_item: 'Process Unbalance',
		loss_index: '5,676',
		loss_percent: '0.08',
		rest_item: 'Support Receive',
		rest_index: '45,742',
		rest_percent: '0.63',
	},
	{
		key: '12',
		organization: 'TOTAL',
		productivity_item: 'Actual T/T(sec)',
		productivity_index: '1:10',
		input_item: 'Loss Time (Actual T/Time)',
		input_mh: '15,091',
		output_item: 'Exclude Early End of Prod',
		output_index: '0',
		output_percent: '-',
		loss_item: 'Planned Loss',
		loss_index: '0',
		loss_percent: '0.0',
		rest_item: 'Over Time',
		rest_index: '164,334',
		rest_percent: '2.18',
	},
	{
		key: '13',
		organization: 'TOTAL',
		productivity_item: 'Working T/T(sec)',
		productivity_index: '1:12',
		input_item: 'Etc Loss Time',
		input_mh: '0',
		output_item: 'Time Off',
		output_index: '0',
		output_percent: '-',
		loss_item: 'Sales Loss',
		loss_index: '0',
		loss_percent: '0.0',
		rest_item: 'Selected Overtime',
		rest_index: '69,650',
		rest_percent: '0.96',
	},
	{
		key: '14',
		organization: 'TOTAL',
		productivity_item: 'Actual Set/HR',
		productivity_index: '326.0',
		input_item: 'Register Emp',
		input_mh: '8,051',
		output_item: 'Not Registered',
		output_index: '3,900',
		output_percent: '0.03',
		loss_item: 'Add Emp',
		loss_index: '0',
		loss_percent: '0.0',
		rest_item: 'Add Support',
		rest_index: '127,004',
		rest_percent: '1.75',
	},
	{
		key: '15',
		organization: 'TOTAL',
		productivity_item: 'Working Set/HR',
		productivity_index: '320.9',
		input_item: 'Rest Emp',
		input_mh: '8,051',
		output_item: 'System Error',
		output_index: '0',
		output_percent: '0.0',
		loss_item: 'Average Working Emp',
		loss_index: '13,667',
		loss_percent: '0.02',
		rest_item: 'Sum',
		rest_index: '120,457',
		rest_percent: '1.66',
	},
	{
		key: '16',
		organization: 'SEVT',
		productivity_item: 'Prod-Qty/Person',
		productivity_index: '89.8',
		input_item: 'Register MH',
		input_mh: '7,531,941',
		output_item: 'Total SMH',
		output_index: '11,199,360',
		output_percent: '-',
		loss_item: 'Material Shortage (Ext)',
		loss_index: '1,389',
		loss_percent: '0.02',
		rest_item: 'Day Rest',
		rest_index: '516,885',
		rest_percent: '6.86',
	},
	{
		key: '17',
		organization: 'SEVT',
		productivity_item: 'Prod. Qty/Person Actual MH',
		productivity_index: '91.3',
		input_item: 'Rest MH',
		input_mh: '767,552',
		output_item: 'In-house SMH',
		output_index: '4,505,766',
		output_percent: '40.2',
		loss_item: 'Material Shortage (Int)',
		loss_index: '0',
		loss_percent: '0.0',
		rest_item: 'Absence',
		rest_index: '11,684',
		rest_percent: '0.16',
	},
	{
		key: '18',
		organization: 'SEVT',
		productivity_item: 'Working MH eff.(%)',
		productivity_index: '62.2',
		input_item: 'Performance MH',
		input_mh: '6,764,389',
		output_item: 'Automation SMH',
		output_index: '5,673,617',
		output_percent: '50.7',
		loss_item: 'Material Defect',
		loss_index: '592',
		loss_percent: '0.01',
		rest_item: 'Business Trip',
		rest_index: '0',
		rest_percent: '0.0',
	},
	{
		key: '19',
		organization: 'SEVT',
		productivity_item: 'Loss Rate(%)',
		productivity_index: '1.7%',
		input_item: 'Add MH',
		input_mh: '482,246',
		output_item: 'Design SMH',
		output_index: '253,469',
		output_percent: '2.3',
		loss_item: 'Design Loss',
		loss_index: '0',
		loss_percent: '0.0',
		rest_item: 'Education',
		rest_index: '77,216',
		rest_percent: '1.03',
	},
	{
		key: '20',
		organization: 'SEVT',
		productivity_item: 'Actual Rate(%)',
		productivity_index: '98.3',
		input_item: 'Working MH',
		input_mh: '1,246,635',
		output_item: 'Improvement SMH',
		output_index: '766,508',
		output_percent: '6.8',
		loss_item: 'Out-Material Shortage',
		loss_index: '0',
		loss_percent: '0.0',
		rest_item: 'Half Day Off',
		rest_index: '83,649',
		rest_percent: '1.11',
	},
	{
		key: '21',
		organization: 'SEVT',
		productivity_item: 'Actual MH eff.(%)',
		productivity_index: '61.3',
		input_item: 'Loss MH',
		input_mh: '120,457',
		output_item: 'Reduction SMH',
		output_index: '6,699,593',
		output_percent: '59.8',
		loss_item: 'Exchange',
		loss_index: '108,900',
		loss_percent: '1.5',
		rest_item: 'Support',
		rest_index: '78,114',
		rest_percent: '1.04',
	},
	{
		key: '22',
		organization: 'SEVT',
		productivity_item: 'Value eff.(%)',
		productivity_index: '0.0',
		input_item: 'Actual MH',
		input_mh: '0',
		output_item: 'Total F/T MH',
		output_index: '0',
		output_percent: '-',
		loss_item: 'Rework',
		loss_index: '0',
		loss_percent: '0.0',
		rest_item: 'Other official rest',
		rest_index: '0',
		rest_percent: '0.0',
	},
	{
		key: '23',
		organization: 'SEVT',
		productivity_item: 'Actual Value eff.(%)',
		productivity_index: '0.0',
		input_item: 'Working Time',
		input_mh: '346,094',
		output_item: 'Working Day',
		output_index: '45',
		output_percent: '-',
		loss_item: 'Equip. Breakdown',
		loss_index: '0',
		loss_percent: '0.0',
		rest_item: 'Sum',
		rest_index: '767,552',
		rest_percent: '10.19',
	},
	{
		key: '24',
		organization: 'SEVT',
		productivity_item: 'ST Reduction Rate(%)',
		productivity_index: '59.8',
		input_item: 'Working Time(T/Time)',
		input_mh: '335,154',
		output_item: 'Working Day',
		output_index: '45',
		output_percent: '-',
		loss_item: 'Utility',
		loss_index: '0',
		loss_percent: '0.0',
		rest_item: 'Detail Add MH Contents',
		rest_index: '0',
		rest_percent: '-',
	},
	{
		key: '25',
		organization: 'SEVT',
		productivity_item: 'Prod. Qty',
		productivity_index: '1,792,494',
		input_item: 'Loss time',
		input_mh: '10,325',
		output_item: 'Basic ST',
		output_index: '8,261',
		output_percent: '-',
		loss_item: 'Molding/Measuring',
		loss_index: '0',
		loss_percent: '0.0',
		rest_item: 'Off Day Work',
		rest_index: '75,516',
		rest_percent: '1.04',
	},
];


const NangSuat = () => {
	const [listLines, setListLines] = useState([]);
	const [listNameProducts, setListNameProducts] = useState([]);
	const [listLoSX, setListLoSX] = useState([]);
	const [listCustomers, setListCustomers] = useState([]);
	const [listCheck, setListCheck] = useState([]);
	const [openMdlEdit, setOpenMdlEdit] = useState(false);
	const [titleMdlEdit, setTitleMdlEdit] = useState('Cập nhật');
	const [form] = Form.useForm();
	const [params, setParams] = useState({ date: [dayjs(), dayjs()] });
	const [dateType, setDateType] = useState('date');
	const onChangeChecbox = (e) => {
		if (e.target.checked) {
			if (!listCheck.includes(e.target.value)) {
				setListCheck(oldArray => [...oldArray, e.target.value]);
			}
		} else {
			if (listCheck.includes(e.target.value)) {
				setListCheck(oldArray => oldArray.filter((datainput) => datainput !== e.target.value))
			}
		}
	}

	const columns = [
		{
			title: 'Organization',
			dataIndex: 'organization',
			width: 100,
			render: (text, row, index) => {
				const obj = {
					children: text,
					props: {},
				};
				if (index % 15 === 0) {
					obj.props.rowSpan = 15;
				} else {
					obj.props.rowSpan = 0;
				}
				return obj;
			},
		},
		{
			title: 'Productivity Index',
			children: [
				{
					title: 'Item',
					dataIndex: 'productivity_item',
					width: 150,
				},
				{
					title: 'Index',
					dataIndex: 'productivity_index',
					width: 100,
				},
			],
		},
		{
			title: 'Input',
			children: [
				{
					title: 'Item',
					dataIndex: 'input_item',
					width: 150,
				},
				{
					title: 'MH(min)',
					dataIndex: 'input_mh',
					width: 100,
				},
			],
		},
		{
			title: 'Output',
			children: [
				{
					title: 'Item',
					dataIndex: 'output_item',
					width: 150,
				},
				{
					title: 'MH(min)',
					dataIndex: 'output_index',
					width: 100,
				},
				{
					title: '%',
					dataIndex: 'output_percent',
					width: 100,
				},
			],
		},
		{
			title: 'Detail Loss Contents',
			children: [
				{
					title: 'Item',
					dataIndex: 'loss_item',
					width: 150,
				},
				{
					title: 'MH(min)',
					dataIndex: 'loss_index',
					width: 100,
				},
				{
					title: '%',
					dataIndex: 'loss_percent',
					width: 100,
				},
			],
		},
		{
			title: 'Detail Rest MH Contents',
			children: [
				{
					title: 'Item',
					dataIndex: 'rest_item',
					width: 150,
				},
				{
					title: 'MH(min)',
					dataIndex: 'rest_index',
					width: 100,
				},
				{
					title: '%',
					dataIndex: 'rest_percent',
					width: 100,
				},
			],
		},
	];

	useEffect(() => {
		(async () => {
			const res1 = await getTreeSelect();
			setListLines(res1.data);
			const res5 = await getCustomers();
			setListCustomers(res5.data.map(e => {
				return { ...e, label: e.name, value: e.id }
			}));
		})()
	}, [])

	function btn_click() {
		loadListTable(params)
	}

	useEffect(() => {
		(async () => {
			var res = await getDataFilterUI({ khach_hang: params.khach_hang });
			if (res.success) {
				setListNameProducts(res.data.product.map(e => {
					return { ...e, label: e.name, value: e.id }
				}));
				setListLoSX(Object.values(res.data.lo_sx).map(e => {
					return { label: e, value: e }
				}));
			}
		})()
	}, [params.khach_hang])

	const [data, setData] = useState([]);
	const loadListTable = async (params) => {
		setLoading(true)
		const res = await getListProductPlan(params);
		setData(res);
		setLoading(false)
	}
	useEffect(() => {
		(async () => {
			loadListTable(params);
		})()
	}, [])

	const [messageApi, contextHolder] = message.useMessage();

	const success = () => {
		messageApi.open({
			type: 'success',
			content: 'Upload file thành công',
		});
	};

	const error = () => {
		messageApi.open({
			type: 'error',
			content: 'Upload file lỗi',
		});
	};

	const onFinish = async (values) => {
		if (values.id) {
			const res = await updateProductPlan(values);
		} else {
			const res = await storeProductPlan(values);
		}
		setOpenMdlEdit(false);
		loadListTable(params);
	}

	const deleteRecord = async () => {
		if (listCheck.length > 0) {
			const res = await deleteRecordProductPlan(listCheck);
			setListCheck([]);
			loadListTable(params);
		} else {
			message.info('Chưa chọn bản ghi cần xóa')
		}
	}
	const editRecord = () => {
		setTitleMdlEdit('Cập nhật')
		if (listCheck.length > 1) {
			message.info('Chỉ chọn 1 bản ghi để chỉnh sửa');
		} else if (listCheck.length == 0) {
			message.info('Chưa chọn bản ghi cần chỉnh sửa')
		} else {
			const result = data.find((record) => record.id === listCheck[0]);
			form.setFieldsValue({
				id: listCheck[0], thu_tu_uu_tien: result.thu_tu_uu_tien, thoi_gian_bat_dau: result.thoi_gian_bat_dau, thoi_gian_ket_thuc: result.thoi_gian_ket_thuc,
				cong_doan_sx: result.cong_doan_sx, product_id: result.product_id, khach_hang: result.khach_hang, ca_sx: result.ca_sx, lo_sx: result.lo_sx, so_bat: result.so_bat, sl_nvl: result.sl_nvl,
				sl_thanh_pham: result.sl_thanh_pham, UPH: result.UPH, nhan_luc: result.nhan_luc
			})
			setOpenMdlEdit(true);
		}
	}
	const insertRecord = () => {
		setTitleMdlEdit('Thêm mới')
		form.resetFields();
		setOpenMdlEdit(true);
	}
	const [loadingUpload, setLoadingUpload] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingExport, setLoadingExport] = useState(false);
	const exportRecord = async () => {
		setLoadingExport(true);
		const res = await exportKHSX(params);
		if (res.success) {
			window.location.href = baseURL + res.data;
		}
		setLoadingExport(false);
	}
	const onCheck = (checkedKeys, info) => {
		const selectKeys = [];
		if (info.node.type === 'factory') {
			if (info.checked) {
				info.node.children.map(e => {
					selectKeys.push(e.key?.toString())
				})
			}
		} else {
			info.checkedNodes.map(e => {
				switch (e.type) {
					case 'line':
						selectKeys.push(e.key?.toString());
						break;
					case 'machine':
						selectKeys.push(e.line_id?.toString());
						break;
					default:
						break;
				}
			})
		}
		setParams({ ...params, line_id: [...new Set(selectKeys)] })
	};
	const customDateFormat = (value) => {
		switch (dateType) {
			case 'week':
				return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
				break;
			case 'month':
				return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
				break;
			case 'year':
				return (`Năm ${dayjs(value).format('YYYY')}`);
				break;
			default:
				return (dayjs(value).format('DD/MM/YYYY'));
				break;
		}
	}
	useEffect(() => {
		onChangeDate('start_date', params.date[0], dateType);
		onChangeDate('end_date', params.date[1], dateType);
	}, [dateType])
	const onChangeDate = (position, value, dateType) => {
		switch (dateType) {
			case 'week':
				if (position === 'start_date') {
					setParams({ ...params, date: [dayjs(value).startOf('isoWeek'), dayjs(params.date[1]).endOf('isoWeek')] });
				} else {
					setParams({ ...params, date: [dayjs(params.date[0]).startOf('isoWeek'), dayjs(value).endOf('isoWeek')] });
				}
				break;
			case 'month':
				if (position === 'start_date') {
					setParams({ ...params, date: [dayjs(value).startOf('month'), dayjs(params.date[1]).endOf('month')] })
				} else {
					setParams({ ...params, date: [dayjs(params.date[0]).startOf('month'), dayjs(value).endOf('month')] });
				}
				break;
			case 'year':
				if (position === 'start_date') {
					setParams({ ...params, date: [dayjs(value).startOf('year'), dayjs(params.date[1]).endOf('year')] })
				} else {
					setParams({ ...params, date: [dayjs(params.date[0]).startOf('year'), dayjs(value).endOf('year')] });
				}
				break;
			default:
				if (position === 'start_date') {
					setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] })
				} else {
					setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
				}
				break;
		}
	}
	return <>
		{contextHolder}
		<Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
			<Col span={4} className='custom-col'>
				<Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
					<Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
				]}>
					<Form style={{ margin: '0 8px' }} layout="vertical">
						<Divider>Công đoạn</Divider>
						<Form.Item className='mb-3'>
							{
								listLines.length ?
									<Tree
										defaultExpandedKeys={[2]}
										style={{ maxHeight: 250, overflowY: 'auto' }}
										checkable
										selectable={false}
										onCheck={onCheck}
										treeData={listLines}
									/>
									:
									<LoadingOutlined />
							}
						</Form.Item>
						<Divider>Thời gian truy vấn</Divider>
						<Radio.Group
							options={[
								{ value: 'date', label: 'Ngày', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
								{ value: 'week', label: 'Tuần', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
								{ value: 'month', label: 'Tháng', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
								{ value: 'year', label: 'Năm', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
							]}
							className='d-flex justify-content-center mb-2 w-100'
							onChange={(e) => setDateType(e.target.value)}
							value={dateType}
							optionType="button"
							buttonStyle="solid"
						/>
						<Space direction='vertical' style={{ width: '100%' }}>
							<DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.date[0]} format={customDateFormat} picker={dateType} />
							<DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.date[1]} format={customDateFormat} picker={dateType} />
						</Space>
						<Divider>Điều kiện truy vấn</Divider>
						<Form.Item label="Khách hàng" className='mb-3'>
							<Select
								allowClear
								showSearch
								placeholder="Chọn khách hàng"
								onChange={(value) => setParams({ ...params, khach_hang: value })}
								optionFilterProp="children"
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								popupMatchSelectWidth={listCustomers.length ? 400 : "100%"}
								options={listCustomers}
							/>
						</Form.Item>
						<Form.Item label="Tên sản phẩm" className='mb-3'>
							<Select
								allowClear
								showSearch
								onChange={(value) => {
									setParams({ ...params, ten_sp: value })
								}}
								placeholder="Nhập tên sản phẩm"
								optionFilterProp="children"
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								options={listNameProducts}
							/>
						</Form.Item>
						<Form.Item label="Lô Sản xuất" className='mb-3'>
							<Select
								allowClear
								showSearch
								placeholder="Nhập lô sản xuất"
								optionFilterProp="children"
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								onChange={(value) => setParams({ ...params, lo_sx: value })}
								options={listLoSX}
							/>
						</Form.Item>
					</Form>
				</Card>
			</Col>

			{/* Productivity */}
			<Col span={20} className='custom-col-table'>
				<Card style={{ height: '100%' }} title="Năng suất" extra={
					<Space>
						{/* <Upload
							showUploadList={false}
							name='files'
							action={baseURL + "/api/upload-productivity"}
							headers={{
								authorization: `Bearer ${JSON.parse(localStorage.getItem("authUser"))?.token}`,
							}}
							onChange={(info) => {
								setLoadingUpload(true);
								if (info.file.status === 'error') {
									setLoadingUpload(false);
									if (info.file?.response?.message) message.error(info.file?.response?.message);
									else error();
								} else if (info.file.status === 'done') {
									if (info.file.response.success === true) {
										loadListTable(params);
										success();
										setLoadingUpload(false);
									} else {
										loadListTable(params);
										message.error(info.file.response.message);
										setLoadingUpload(false);
									}
								}
							}}
						>
							<Button style={{ marginLeft: '15px' }} type="primary" loading={loadingUpload}>
								Upload Excel
							</Button>
						</Upload>
						<Button type="primary" onClick={exportRecord} loading={loadingExport}>Export Excel</Button>
						<Button type="primary" onClick={deleteRecord}>Delete</Button>
						<Button type="primary" onClick={editRecord}>Edit</Button>
						<Button type="primary" onClick={insertRecord}>Insert</Button> */}
					</Space>
				}>
					<Table size='small' bordered
						locale={{ emptyText: ' ' }}
						className="table-productivity"
						loading={loading}
						pagination={false}
						scroll={
							{
								x: 1700,
								y: CalculateTableHeight('table-productivity')
							}
						}
						columns={columns}
						dataSource={dataFaker} />
				</Card>
			</Col>
		</Row>
		<Modal title={titleMdlEdit} open={openMdlEdit} onCancel={() => setOpenMdlEdit(false)} footer={null} width={800}>
			<Form style={{ margin: '0 15px' }}
				layout="vertical"
				form={form}
				onFinish={onFinish}>
				<Row gutter={[16, 16]}>
					<Col span={12} className='d-none'>
						<Form.Item name="id" className='mb-3 d-none'>
							<Input></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Thứ tự ưu tiên" name="thu_tu_uu_tien" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập thứ tự ưu tiên'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Ngày sản xuất (YYYY-MM-DD)" name="ngay_sx" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập ngày sản xuất'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Ngày đặt hàng (YYYY-MM-DD)" name="ngay_dat_hang" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập ngày đặt hàng'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Ngày giao hàng (YYYY-MM-DD)" name="ngay_giao_hang" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập ngày giao hàng'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Thời gian bắt đầu (YYYY-MM-DD HH:mm:ss)" name="thoi_gian_bat_dau" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='' ></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Thời gian kết thúc (YYYY-MM-DD HH:mm:ss)" name="thoi_gian_ket_thuc" className='mb-3' rules={[{ required: true }]}>
							<Input ></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Máy" name="machine_id" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập tên máy'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Công đoạn" name="cong_doan_sx" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập công đoạn'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Mã sản phẩm" name="product_id" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập mã sản phẩm'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Khách hàng" name="khach_hang" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập khách hàng'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Ca sản xuất" name="ca_sx" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập ca sản xuất'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Lô sản xuất" name="lo_sx" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập lô sản xuất'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Số bát" name="so_bat" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập số bát'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Số lượng nguyên liệu đầu vào (tờ)" name="sl_nvl" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập số lượng nguyên liệu đầu vào (tờ)'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Kế hoạch SL thành phẩm (tờ)" name="sl_thanh_pham" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập kế hoạch SL thành phẩm (tờ)'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="UPH" name="UPH" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='UPH'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Nhân lực" name="nhan_luc" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhân lực'></Input>
						</Form.Item>
					</Col>
				</Row>
				<Form.Item className='mb-0'>
					<Button type="primary" htmlType='submit' >Lưu lại</Button>
				</Form.Item>
			</Form>
		</Modal>
	</>
}

export default NangSuat;
