import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Upload, message, Checkbox, Space, Modal, Spin, Popconfirm } from 'antd';
import { baseURL } from '../../../config';
import React, { useState, useRef, useEffect } from 'react';
import { exportMachines, getMachines, updateMachines, createMachines, deleteMachines, importMachines } from '../../../api/phase2/master_data/machine';
import dayjs from 'dayjs';

const Machine = () => {
    document.title = "Quản lý máy";
    const [listCheck, setListCheck] = useState([]);
    const [openMdl, setOpenMdl] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm();
    const [params, setParams] = useState({});
    const col_detailTable = [
        { title: 'STT', dataIndex: 'stt', key: 'stt', align: 'center', render: (value, item, index) => index + 1, width: 60, fixed: 'left' },
        { title: 'Tên ', dataIndex: 'name', key: 'name', align: 'center', fixed: 'left' },
        { title: 'Kiểu loại', dataIndex: 'kieu_loai', key: 'kieu_loai', align: 'center' },
        { title: 'Code', dataIndex: 'code', key: 'code', align: 'center' },
        { title: 'Mã số', dataIndex: 'ma_so', key: 'ma_so', align: 'center' },
        { title: 'Công đoạn', dataIndex: 'line', key: 'line', align: 'center', render: (value, item, index) => value?.name },
        { title: 'Công suất', dataIndex: 'cong_suat', key: 'cong_suat', align: 'center' },
        { title: 'Hãng sản xuất', dataIndex: 'hang_sx', key: 'hang_sx', align: 'center' },
        { title: 'Năm sử dụng', dataIndex: 'nam_sd', key: 'nam_sd', align: 'center' },
        { title: 'Đơn vị sử dụng', dataIndex: 'don_vi_sd', key: 'don_vi_sd', align: 'center' },
        { title: 'Tình trạng', dataIndex: 'tinh_trang', key: 'tinh_trang', align: 'center' },
        { title: 'Vị trí', dataIndex: 'vi_tri', key: 'vi_tri', align: 'center' },
        { title: 'IOT', dataIndex: 'is_iot', key: 'is_iot', align: 'center', width: 90, render: (value) => value ? "Có" : "Không" },
        { title: 'Thiết bị', dataIndex: 'device', key: 'device', align: 'center', render: (value) => value?.name },
        { title: 'Thời gian khả dụng', dataIndex: 'available_at', key: 'available_at', align: 'center', render: (value) => value ? dayjs(value).format('DD/MM/YYYY HH:mm') : '' }
    ];

    const formFields = [
        { key: 'id', hidden: true },
        { title: 'Tên ', key: 'name', required: true },
        { title: 'Kiểu loại', key: 'kieu_loai', required: true },
        { title: 'Code', key: 'code', required: true },
        { title: 'Mã số', key: 'ma_so', required: true },
        { title: 'Công đoạn', key: 'line', required: true },
        { title: 'Công suất', key: 'cong_suat', required: true },
        { title: 'Hãng sản xuất', key: 'hang_sx', required: true },
        { title: 'Năm sử dụng', key: 'nam_sd', required: true },
        { title: 'Đơn vị sử dụng', key: 'don_vi_sd', required: true },
        { title: 'Tình trạng', key: 'tinh_trang', required: true },
        { title: 'Vị trí', key: 'vi_tri', required: true },
        { title: 'IOT', key: 'is_iot', isTrueFalse: true, required: true }
    ];


    function btn_click() {
        loadListTable(params)
    }

    const [data, setData] = useState([]);
    const loadListTable = async (params) => {
        setLoading(true)
        const res = await getMachines(params);
        setData(res.data.map(e => {
            return { ...e, key: e.id }
        }));
        setLoading(false);
    }
    useEffect(() => {
        (async () => {
            loadListTable(params);
        })()
    }, [])

    const [messageApi, contextHolder] = message.useMessage();

    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Upload file thành công',
        });
    };

    const error = () => {
        messageApi.open({
            type: 'error',
            content: 'Upload file lỗi',
        });
    };

    const onFinish = async (values) => {
        console.log(values);
        if (values?.available_at) {
            values.available_at = dayjs(values.available_at).format('YYYY-MM-DD HH:mm:ss');
        }
        if (isEdit) {
            const res = await updateMachines(values);
            if (res) {
                form.resetFields();
                setOpenMdl(false);
                loadListTable(params);
            }
        } else {
            const res = await createMachines(values);
            if (res) {
                form.resetFields();
                setOpenMdl(false);
                loadListTable(params);
            }
        }

    }

    const deleteRecord = async () => {
        if (listCheck.length > 0) {
            const res = await deleteMachines(listCheck);
            setListCheck([]);
            loadListTable(params);
        } else {
            message.info('Chưa chọn bản ghi cần xóa')
        }
    }
    const editRecord = () => {
        setIsEdit(true)
        if (listCheck.length !== 1) {
            message.info('Chọn 1 bản ghi để chỉnh sửa');
        } else {
            const result = data.find((record) => record.id === listCheck[0]);
            let available_at = null;
            if (result?.available_at) {
                available_at = dayjs(result.available_at);
            }
            form.setFieldsValue({ ...result, line: result?.line?.name, available_at });
            setOpenMdl(true);
        }
    }
    const insertRecord = () => {
        setIsEdit(false)
        form.resetFields();
        setOpenMdl(true);
    }
    const [loadingExport, setLoadingExport] = useState(false);
    const [loading, setLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportMachines(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setListCheck(selectedRowKeys)
        },
    };
    return <>
        {contextHolder}
        <Row style={{ padding: '8px', height: '90vh' }} gutter={[8, 8]}>
            {/* <Col span={3}>
                    <Card style={{ height: '100%' }} bodyStyle={{padding:0}}>
                    <Divider>Tìm kiếm</Divider>
                    <div className='mb-3'>
                        <Form style={{ margin: '0 15px' }} layout="vertical">
                            <Form.Item label="Mã" className='mb-3'>
                                <Input allowClear onChange={(e)=>setParams({...params, code: e.target.value})} placeholder='Nhập mã'/>
                            </Form.Item>
                            <Form.Item label="Tên" className='mb-3'>
                                <Input allowClear onChange={(e)=>setParams({...params, name: e.target.value})} placeholder='Nhập tên'/>
                            </Form.Item>
                        </Form>
                    </div>

                    <div style={
                        {
                            padding: '10px',
                            textAlign: 'center'
                        }
                    }
                        layout="vertical">
                        <Button type='primary'
                            style={{ width: '80%' }} onClick={btn_click}>
                            Tìm kiếm
                        </Button>
                    </div>
                    </Card>
                </Col> */}
            <Col span={3} className='custom-col'>
                <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={btn_click}>Truy vấn</Button>
                ]}>
                    <Form style={{ margin: '0 8px' }} layout="vertical" onFinish={() => btn_click()}>
                        <Divider>Điều kiện truy vấn</Divider>
                        <Form.Item label="Mã" className='mb-3'>
                            <Input allowClear onChange={(e) => setParams({ ...params, code: e.target.value })} placeholder='Nhập mã' />
                        </Form.Item>
                        <Form.Item label="Tên" className='mb-3'>
                            <Input allowClear onChange={(e) => setParams({ ...params, name: e.target.value })} placeholder='Nhập tên' />
                        </Form.Item>
                        <button type='submit' hidden />
                    </Form>
                </Card>
            </Col>
            <Col span={21}>
                <Card style={{ height: '100%' }} title="Máy" extra={
                    <Space>
                        <Upload
                            showUploadList={false}
                            name='files'
                            customRequest={async ({ file, onSuccess, onError }) => {
                                const formData = new FormData();
                                formData.append('file', file);
                                const res = await importMachines(formData);
                                if (res.success) {
                                    loadListTable(params);
                                    setLoadingExport(false);
                                } else {
                                    loadListTable(params);
                                    setLoadingExport(false);
                                }
                            }}
                        >
                            <Button style={{ marginLeft: '15px' }} type="primary" loading={loadingExport}>
                                Upload Excel
                            </Button>
                        </Upload>
                        <Button type="primary" onClick={exportFile} loading={exportLoading}>Export Excel</Button>
                        <Button type="primary" onClick={editRecord} disabled={listCheck.length <= 0}>Edit</Button>
                        <Button type="primary" onClick={insertRecord}>Insert</Button>
                        <Popconfirm
                            title="Xoá bản ghi"
                            description={"Bạn có chắc xoá " + listCheck.length + " bản ghi đã chọn?"}
                            onConfirm={deleteRecord}
                            okText="Có"
                            cancelText="Không"
                            placement="bottomRight"
                        >
                            <Button type="primary" disabled={listCheck.length <= 0}>Delete</Button>
                        </Popconfirm>
                    </Space>
                }>
                    <Spin spinning={loading}>
                        <Table size='small' bordered
                            pagination={false}
                            scroll={
                                {
                                    x: '200vw',
                                    y: '70vh'
                                }
                            }
                            columns={col_detailTable}
                            dataSource={data}
                            rowSelection={rowSelection} />
                    </Spin>
                </Card>
            </Col>
        </Row>
        <Modal title={isEdit ? 'Cập nhật' : 'Thêm mới'} open={openMdl} onCancel={() => setOpenMdl(false)} footer={null} width={800}>
            <Form style={{ margin: '0 15px' }}
                layout="vertical"
                form={form}
                onFinish={onFinish}>
                <Row gutter={[16, 16]}>
                    {formFields.map(e => {
                        if (e.key !== 'select' && e.key !== 'stt') return <Col span={!e.hidden ? 12 : 0}>
                            <Form.Item name={e.key} label={e.title} hidden={e.hidden} rules={[{ required: e.required }]}>
                                {!e.isTrueFalse ?
                                    <Input disabled={e.disabled || (isEdit && e.key === 'id')}></Input>
                                    :
                                    <Select>
                                        <Select.Option value={1}>Có</Select.Option>
                                        <Select.Option value={0}>Không</Select.Option>
                                    </Select>
                                }
                            </Form.Item>
                        </Col>
                    })}
                    <Col span={12}>
                        <Form.Item name="device_id" label="Thiết bị">
                            <Select options={data.map((item) => ({ label: item?.name, value: item?.id }))} showSearch optionFilterProp='label' placeholder="Vui lòng chọn" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="available_at" label="Thời gian khả dụng">
                            <DatePicker showTime style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item className='mb-0'>
                    <Button type="primary" htmlType='submit' >Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default Machine;
