module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  pptist: "https://pptist.netlify.app?company=thang_long",
  api: {
    API_URL: "https://backtl.ors.vn/api",
  },
  baseURL: "https://backtl.ors.vn",
};
