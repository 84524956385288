import axios from "axios";
const prefix = 'p2/ui/master-data/';
export async function getStamps(params) {
    const res = await axios.get(prefix+'stamps', { params });
    return res;
}
export async function createStamps(params) {
    const res = await axios.post(prefix+'stamps', params);
    return res;
}
export async function updateStamps(id, params) {
    const res = await axios.patch(prefix+'stamps/' + id, params);
    return res;
}
export async function deleteStamps(id) {
    const res = await axios.delete(prefix+'stamps/' + id);
    return res;
}
export async function exportStamps(params) {
    const res = await axios.get(prefix+'stamps/export', { params });
    return res;
}
export async function importStamps(params) {
    const res = await axios.post(prefix+'stamps/import', params, { headers: { "Content-Type": "multipart/form-data" } });
    return res;
}