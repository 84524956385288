import axios from "axios";
const prefix = "p2/ui/";
//MaintenancePlans
export async function generateProductionPlan(params) {
  const res = await axios.post(prefix + "plan/generate", params);
  return res;
}
export async function createAutoProductionPlan(params) {
  const res = await axios.post(prefix + "plan/create", params);
  return res;
}
export async function printAutoProductionPlan(params) {
  const res = await axios.post(prefix + "plan/print", params);
  return res;
}
export async function uploadProductionPlan(params) {
  const res = await axios.post(prefix + "plan/upload", params, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res;
}
