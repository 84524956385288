import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Card, DatePicker, Input, Space, Table } from 'antd';
import dayjs from "dayjs";
import axios from "axios";
const columns = [
  {
    title: "Mã pallet/thùng",
    dataIndex: "lot_id",
    key: "lot_id",
    align: "left",
    fixed: "left",
    width: 120,
  },
  {
    title: "Mã máy",
    dataIndex: "machine_code",
    key: "machine_code",
    align: "left",
    fixed: "left",
    width: 120,
  },
  {
    title: "Thời gian bấm máy",
    dataIndex: "thoi_gian_bam_may",
    key: "thoi_gian_bam_may",
    align: "right",
    width: 150,
  },
  {
    title: "Thời gian kết thúc",
    dataIndex: "thoi_gian_ket_thuc",
    key: "thoi_gian_ket_thuc",
    align: "right",
    width: 150,
  },
  {
    title: "SL đầu vào khi bấm máy",
    dataIndex: "sl_dau_vao_bam_may",
    key: "sl_vao_bam_may",
    align: "right",
    width: 210,
  },
  {
    title: "SL đầu ra khi bấm máy",
    dataIndex: "sl_khi_bam_may",
    key: "sl_ra_bam_may",
    align: "right",
    width: 210,
  },
  {
    title: "Sản lượng đầu vào kết thúc",
    dataIndex: "sl_vao_ket_thuc",
    key: "sl_vao_ket_thuc",
    align: "right",
    width: 200,
  },
  {
    title: "Sản lượng đầu ra kết thúc",
    dataIndex: "sl_dau_ra_ket_thuc",
    key: "sl_dau_ra_ket_thuc",
    align: "right",
    width: 200,
  },
];
const TrackingProduction = (props) => {
    document.title = "Tracking";
    const [params, setParams] = useState({date: dayjs()});
    const [data, setData] = useState([]);
    const fetchData = async () => {
        var res = await axios.get('get-tracking-info-cong-doan', {params});
        setData(res.data);
    }
    useEffect(()=>{
        fetchData();
    }, [params]);
    return (
        <React.Fragment>
            <Card title={'Theo dõi ghi nhận IOT'} extra={<Space>
                <Input placeholder='Mã pallet' onBlur={(event)=>setParams({...params, lot_id: event.target.value})} onPressEnter={(event)=>setParams({...params, lot_id: event.target.value})}/>
                <Input placeholder='Mã máy' onBlur={(event)=>setParams({...params, machine_code: event.target.value})} onPressEnter={(event)=>setParams({...params, lot_id: event.target.value})}/>
                <DatePicker onChange={(date)=>setParams({...params, date})} value={params.date}/>
                <Button type='primary' onClick={fetchData}>Tải dữ liệu</Button>
            </Space>}>
                <Table dataSource={data} columns={columns} bordered size='small' virtual={true} />
            </Card>
        </React.Fragment>
    );
};

export default withRouter(TrackingProduction);
