import {
  Row,
  Col,
  Card,
  Table,
  Tag,
  Button,
  Form,
  Input,
  Space,
  Modal,
  Divider,
  message,
  Select,
  DatePicker,
  TreeSelect,
} from "antd";
import React, { useState, useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import dayjs from "dayjs";
import { getMachines } from "../../../api/phase2/master_data/machine";
import { getShifts } from "../../../api/phase2/master_data/shift";
import {
  getMachineShifts,
  createMachineShifts,
  updateMachineShifts,
} from "../../../api/phase2/master_data/machine-shift"; // Thêm API updateMachineShift
import { getTreeSelect } from "../../../api/phase2/ui/main";

const { RangePicker } = DatePicker;
const { Option } = Select;

const generateWeekDates = (startOfWeek) => {
  const weekDates = [];
  for (let i = 0; i < 7; i++) {
    weekDates.push(startOfWeek.add(i, "day"));
  }
  return weekDates;
};

const MachineShift = () => {
  document.title = "Quản lý phân ca máy";
  const [form] = Form.useForm();
  const [formAddShift] = Form.useForm();
  const [formEditShift] = Form.useForm(); // Form để chỉnh sửa ca
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startOfWeek, setStartOfWeek] = useState(
    dayjs().startOf("week").add(1, "day")
  );
  const [openAddShiftModal, setOpenAddShiftModal] = useState(false);
  const [openEditShiftModal, setOpenEditShiftModal] = useState(false); // State cho modal chỉnh sửa
  const componentRef1 = useRef();
  const [machines, setMachines] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [currentEditData, setCurrentEditData] = useState(null); // Lưu dữ liệu hiện tại để chỉnh sửa

  const weekDates = generateWeekDates(startOfWeek);

  const loadListTable = async (params) => {
    setLoading(true);
    try {
      const res = await getMachineShifts(params);
      if (res && res.data) {
        const machinesData = res.data;
        const tableData = machinesData.map((machine) => {
          const row = {
            key: machine.machine_id,
            machine_name: machine.machine_name,
          };

          weekDates.forEach((date) => {
            const dateKey = date.format("YYYY-MM-DD");
            row[dateKey] =
              machine.shifts.filter((shift) => shift.date === dateKey) || [];
          });

          return row;
        });

        setData(tableData);
      }
    } catch (error) {
      message.error("Lỗi khi lấy dữ liệu từ API");
    }
    setLoading(false);
  };

  useEffect(() => {
    const params = {
      startDate: startOfWeek.format("YYYY-MM-DD"),
      endDate: startOfWeek.add(6, "day").format("YYYY-MM-DD"),
    };
    loadListTable(params);
  }, [startOfWeek]);

  const onSearch = (values) => {
    values.startDate = startOfWeek.format("YYYY-MM-DD");
    values.endDate = startOfWeek.add(6, "day").format("YYYY-MM-DD");
    console.log("Tìm kiếm:", values);
    loadListTable(values);
  };

  const print = useReactToPrint({
    content: () => componentRef1.current,
  });

  // Hàm mở modal chỉnh sửa ca
  const handleEditShift = async (record, date, shifts) => {
    const shift = shifts[0]; // Giả sử chỉ có một ca trong mỗi ô
    console.log("Chỉnh sửa ca làm việc:", shifts);
    if (shift) {
      const editData = {
        machine_id: record.key, // Lấy từ record, không cho phép chỉnh sửa
        date: date.format("YYYY-MM-DD"), // Lấy từ record, không cho phép chỉnh sửa
        shifts: shifts.map((shift) => ({
          value: shift.shift_id,
          label: shift.shift_name,
        })),
      };
      setCurrentEditData(editData);
      formEditShift.setFieldsValue(editData); // Đổ dữ liệu vào form chỉnh sửa
      try {
        // Lấy danh sách ca từ API khi mở modal
        const shiftsRes = await getShifts();
        setShifts(shiftsRes.data); // Lưu danh sách ca vào state
      } catch (error) {
        message.error("Lỗi khi lấy dữ liệu ca");
      }
      setOpenEditShiftModal(true); // Mở modal chỉnh sửa
    }
  };

  const columns = [
    {
      title: "Máy",
      dataIndex: "machine_name",
      key: "machine_name",
      fixed: "left",
      width: 150,
    },
    ...weekDates.map((date) => ({
      title: dayjs(date).format("DD/MM/YYYY"),
      dataIndex: date.format("YYYY-MM-DD"),
      key: date.format("YYYY-MM-DD"),
      render: (shifts = [], record) =>
        shifts.length > 0 ? (
          <div onClick={() => handleEditShift(record, date, shifts)}>
            {shifts.map((shift) => (
              <Tag key={shift.shift_id} color="blue">
                {shift.shift_name}
              </Tag>
            ))}
          </div>
        ) : (
          <Tag color="red">Không có ca</Tag>
        ),
    })),
  ];

  const openAddShift = async () => {
    formAddShift.resetFields(); // Reset form trước khi mở modal
    try {
      // const machinesRes = await getMachines();
      const shiftsRes = await getShifts();
      fetchMachineTree();
      setShifts(shiftsRes.data);
      setOpenAddShiftModal(true);
    } catch (error) {
      message.error("Lỗi khi lấy dữ liệu");
    }
  };

  const handlePreviousWeek = () => {
    setStartOfWeek((prev) => prev.subtract(1, "week"));
  };

  const handleNextWeek = () => {
    setStartOfWeek((prev) => prev.add(1, "week"));
  };

  const onAddShiftFinish = async (values) => {
    console.log("Ca làm việc được thêm:", values);
    await createMachineShifts(values);
    setOpenAddShiftModal(false);
    loadListTable({
      startDate: startOfWeek.format("YYYY-MM-DD"),
      endDate: startOfWeek.add(6, "day").format("YYYY-MM-DD"),
    }); // Cập nhật lại dữ liệu
    // Xử lý sau khi thêm ca
  };

  // Xử lý submit form chỉnh sửa ca
  const onEditShiftFinish = async (values) => {
    const updatedData = {
      machine_id: currentEditData.machine_id, // Lấy từ record
      date: currentEditData.date, // Lấy từ record
      shift_ids: values.shifts.map((shift) => shift.value), // Chỉ lấy value (shift_id)
    };

    console.log("Dữ liệu cập nhật:", updatedData);
    try {
      const res = await updateMachineShifts(updatedData); // Gọi API cập nhật ca làm việc
      if (res.success) {
        setOpenEditShiftModal(false); // Đóng modal
        loadListTable({
          startDate: startOfWeek.format("YYYY-MM-DD"),
          endDate: startOfWeek.add(6, "day").format("YYYY-MM-DD"),
        }); // Cập nhật lại dữ liệu
      }
    } catch (error) {
      message.error("Lỗi khi cập nhật ca làm việc");
    }
  };
  const convertMachinesToTreeData = (machines) => {
    return machines.map((machine) => ({
      title: machine.code,
      value: machine.code,
      key: machine.code,
    }));
  };
  const fetchMachineTree = async () => {
    const res = await getTreeSelect();
    setMachines(res.data);
  }
  return (
    <>
      <Row gutter={[8, 8]} className="custom-row">
        <Col span={3} className="custom-col">
          <Card
            bodyStyle={{ padding: 6 }}
            className="custom-ant-card"
            actions={[
              <Button
                type="primary"
                style={{ width: "90%" }}
                onClick={() => form.submit()}
              >
                Truy vấn
              </Button>,
            ]}
          >
            <Divider>Tìm kiếm</Divider>
            <Form layout="vertical" form={form} onFinish={onSearch}>
              <Form.Item label="Mã máy" name={"machine_id"}>
                <Input allowClear placeholder="Nhập mã máy" />
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={21} className="custom-col-table">
          <Card
            title="Quản lý phân ca máy"
            extra={
              <Space>
                <Button onClick={handlePreviousWeek}>Tuần trước</Button>
                <Button onClick={handleNextWeek}>Tuần sau</Button>
                <Button type="primary" onClick={openAddShift}>
                  Phân ca
                </Button>
              </Space>
            }
          >
            <Table
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered
              pagination={false}
              scroll={{ x: "max-content", y: "64vh" }}
            />
          </Card>
        </Col>
      </Row>

      <Modal
        title="Thêm ca cho máy"
        open={openAddShiftModal}
        onCancel={() => setOpenAddShiftModal(false)}
        footer={null}
      >
        <Form form={formAddShift} onFinish={onAddShiftFinish} layout="vertical">
          <Form.Item
            name="machines"
            label="Chọn máy"
            rules={[{ required: true, message: "Vui lòng chọn máy!" }]}
          >
            {/* Sử dụng TreeSelect với checkbox để chọn nhiều máy */}
            <TreeSelect
              treeData={machines} // Chuyển dữ liệu máy thành dạng tree
              treeCheckable={true} // Hiển thị checkbox
              showCheckedStrategy={TreeSelect.SHOW_CHILD} // Chỉ hiển thị mục con được chọn
              placeholder="Chọn máy"
              style={{ width: "100%" }}
              allowClear
              multiple
              // maxTagCount={'responsive'}
              fieldNames={{label: 'title', value: 'key'}}
              treeDefaultExpandAll
            />
          </Form.Item>

          <Form.Item
            name="shifts"
            label="Chọn ca"
            rules={[{ required: true, message: "Vui lòng chọn ca!" }]}
          >
            <Select mode="multiple" placeholder="Chọn nhiều ca">
              {shifts.map((shift) => (
                <Option key={shift.id} value={shift.id}>
                  {shift.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="dateRange"
            label="Chọn thời gian"
            rules={[{ required: true, message: "Vui lòng chọn thời gian!" }]}
          >
            <RangePicker format="YYYY-MM-DD" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Lưu ca làm việc
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Modal chỉnh sửa ca */}
      <Modal
        title="Chỉnh sửa ca làm việc"
        open={openEditShiftModal}
        onCancel={() => setOpenEditShiftModal(false)}
        footer={null}
      >
        <Form
          form={formEditShift}
          onFinish={onEditShiftFinish}
          layout="vertical"
        >
          <Form.Item label="Máy">
            {/* Hiển thị máy từ record, không cho phép chỉnh sửa */}
            <Input value={currentEditData?.machine_id} disabled />
          </Form.Item>

          <Form.Item label="Ngày">
            {/* Hiển thị ngày từ record, không cho phép chỉnh sửa */}
            <Input value={currentEditData?.date} disabled />
          </Form.Item>

          <Form.Item name="shifts" label="Chọn ca">
            {/* Cho phép chỉnh sửa ca */}
            <Select
              placeholder="Chọn ca"
              value={currentEditData?.shifts}
              mode="multiple"
              labelInValue
            >
              {shifts.map((shift) => (
                <Option key={shift.id} value={shift.id}>
                  {shift.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Cập nhật ca làm việc
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default MachineShift;
