import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Upload, message, Checkbox, Space, Modal, Spin, Popconfirm, Badge } from 'antd';
import { baseURL } from '../../../config';
import React, { useState, useRef, useEffect } from 'react';
import { createUsers, deleteUsers, exportUsers, getUserRoles, getUsers, updateUsers } from '../../../api';
import { type } from '@testing-library/user-event/dist/type';
import { createUserInfo, deleteUserInfo, getUserInfo, updateUserInfo } from '../../../api/ui/userInfo';
import dayjs from 'dayjs';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const Users = () => {
    document.title = "Quản lý nhân sự";
    const [listCheck, setListCheck] = useState([]);
    const [openMdl, setOpenMdl] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm();
    const [params, setParams] = useState({});
    const [roles, setRoles] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [editingRecord, setEditingRecord] = useState();
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 20,
        total: 0,
    });
    
    const col_detailTable = [
        {
            title: 'STT',
            dataIndex: 'stt',
            key: 'stt',
            align: 'left',
            render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
            width: '60px',
        },
        {
            title: 'Mã nhân viên',
            dataIndex: 'id',
            key: 'id',
            align: 'left',
            width: '100px',
        },
        {
            title: 'Họ và tên',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            width: '190px',
        },
        {
            title: 'Ngày vào',
            dataIndex: 'date_join_company',
            align: 'left',
            width: '100px',
            render: (value) => dayjs(value).format('DD/MM/YYYY'),
        },
        {
            title: 'Ngày kết thúc thử việc',
            dataIndex: 'date_end_trial',
            align: 'left',
            width: '150px',
            render: (value) => dayjs(value).format('DD/MM/YYYY'),
        },
        {
            title: 'Phân loại 1',
            dataIndex: 'category_1',
            key: 'category_1',
            align: 'left',
            width: '200px',
        },
        {
            title: 'Phân loại 2',
            dataIndex: 'category_2',
            key: 'category_2',
            align: 'left',
            width: '120px',
        },
        {
            title: 'Phân loại 3',
            dataIndex: 'category_3',
            key: 'category_3',
            align: 'left',
            width: '120px',
        },
        {
            title: 'Phân loại 4',
            dataIndex: 'category_4',
            key: 'category_4',
            align: 'left',
            width: '120px',
        },
        {
            title: 'Phân loại 5',
            dataIndex: 'category_5',
            key: 'category_5',
            align: 'left',
            width: '120px',
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            key: 'note',
            align: 'left',
            width: '120px',
        },
        {
            title: 'Kinh ngiệm',
            dataIndex: 'experience',
            key: 'experience',
            align: 'left',
            width: '100px',
            render: (_, record) => Number((dayjs().diff(dayjs(record?.date_end_trial), 'day') / 365).toFixed(2))
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            align: 'center',
            width: '100px',
            fixed: 'right',
            render: (_, record) => (
                <Space wrap>
                    <EditOutlined className='edit-btn' onClick={() => editRecord(record)} />
                    <Popconfirm
                        title="Xoá bản ghi"
                        description={"Bạn có chắc muốn xoá?"}
                        onConfirm={() => deleteRecord(record)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <DeleteOutlined className='delete-btn' />
                    </Popconfirm>
                </Space>
            )
        },
    ];

    const formFields = [
        {
            key: 'id',
            hidden: true
        },
        {
            title: 'Tài khoản',
            key: 'username',
            required: true
        },
        {
            title: 'Tên',
            key: 'name',
            required: true
        },
        {
            title: 'Bộ phận',
            key: 'roles',
            select: {
                mode: 'multiple',
                options: roles
            }
        },
    ]

    function btn_click() {
        fetchData(params)
    }

    const [data, setData] = useState([]);
    const loadListTable = async (params) => {
        setLoading(true)
        const res = await getUsers(params);
        setData(res.map(e => {
            return { ...e, key: e.id }
        }));
        setLoading(false);
    }
    useEffect(() => {
        (async () => {
            loadListTable(params);
            var res = await getUserRoles();
            setRoles(res);
        })()
    }, [])

    const [messageApi, contextHolder] = message.useMessage();

    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Upload file thành công',
        });
    };

    const error = () => {
        messageApi.open({
            type: 'error',
            content: 'Upload file lỗi',
        });
    };

    const onFinish = async (values) => {
        if (values?.date_join_company) values.date_join_company = dayjs(values.date_join_company).format('YYYY-MM-DD');
        if (values?.date_end_trial) values.date_end_trial = dayjs(values.date_end_trial).format('YYYY-MM-DD');
        if (isEdit) {
            const res = await updateUserInfo(editingRecord?.id, values);
            console.log(res);
            if (res) {
                form.resetFields();
                setOpenMdl(false);
                // loadListTable(params);
                fetchData(params);
                setEditingRecord();
            }
        } else {
            const res = await createUserInfo(values);
            console.log(res);
            if (res) {
                form.resetFields();
                setOpenMdl(false);
                // loadListTable(params);
                fetchData(params);
            }
        }
    }

    const deleteRecord = async (record) => {
        const res = await deleteUserInfo(record?.id);
        setListCheck([]);
        fetchData(params);
        // if (listCheck.length > 0) {
        // } else {
        //     message.info('Chưa chọn bản ghi cần xóa')
        // }
    }
    // const editRecord = () => {
    //     setIsEdit(true)
    //     if (listCheck.length !== 1) {
    //         message.info('Chọn 1 bản ghi để chỉnh sửa');
    //     } else {
    //         const result = data.find((record) => record.id === listCheck[0]);
    //         console.log(result?.roles.map(e => e.id));
    //         form.setFieldsValue({ ...result, roles: result?.roles.map(e => e.id) });
    //         setOpenMdl(true);
    //     }
    // }
    const editRecord = (record) => {
        setIsEdit(true);
        if (record?.date_join_company) record.date_join_company = dayjs(record.date_join_company);
        if (record?.date_end_trial) record.date_end_trial = dayjs(record.date_end_trial);
        const fields = { ...record }
        form.setFieldsValue(fields);
        setEditingRecord(fields);
        setOpenMdl(true);
    }
    const insertRecord = () => {
        setIsEdit(false)
        form.resetFields();
        setOpenMdl(true);
    }
    const [loadingExport, setLoadingExport] = useState(false);
    const [loading, setLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportUsers(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setListCheck(selectedRowKeys)
        },
    };

    const fetchData = async (params) => {
        setLoading(true);
        const response = await getUserInfo(params);
        if (response.success) {
            setUserInfo(response.data?.data ?? []);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const onCancel = () => {
        form.resetFields();
        setOpenMdl(false);
    }

    return <>
        {contextHolder}
        <Row style={{ padding: '8px', height: '90vh' }} gutter={[8, 8]}>
            <Col span={3}>
                <Card style={{ height: '100%' }} bodyStyle={{ padding: 0 }}>
                    <Divider>Tìm kiếm</Divider>
                    <div className='mb-3'>
                        <Form style={{ margin: '0 15px' }} layout="vertical">
                            <Form.Item name="id" label="Mã nhân viên" className='mb-3'>
                                <Input allowClear onChange={(e) => setParams({ ...params, id: e.target.value })} placeholder='Nhập mã' />
                            </Form.Item>
                        </Form>
                    </div>
                    <div className='mb-3'>
                        <Form style={{ margin: '0 15px' }} layout="vertical">
                            <Form.Item name="name" label="Họ và tên" className='mb-3'>
                                <Input allowClear onChange={(e) => setParams({ ...params, name: e.target.value })} placeholder='Họ và tên' />
                            </Form.Item>
                        </Form>
                    </div>
                    <div className='mb-3'>
                        <Form style={{ margin: '0 15px' }} layout="vertical">
                            <Form.Item name="date_join_company" label="Ngày vào" className='mb-3'>
                                <DatePicker onChange={(date, dateString) => setParams({ ...params, date_join_company: dateString })} style={{ width: '100%' }} />
                            </Form.Item>
                        </Form>
                    </div>
                    <div className='mb-3'>
                        <Form style={{ margin: '0 15px' }} layout="vertical">
                            <Form.Item name="date_end_trial" label="Ngày KT thử việc" className='mb-3'>
                                <DatePicker onChange={(date, dateString) => setParams({ ...params, date_end_trial: dateString })} style={{ width: '100%' }} />
                            </Form.Item>
                        </Form>
                    </div>
                    <div className='mb-3'>
                        <Form style={{ margin: '0 15px' }} layout="vertical">
                            <Form.Item name="category_1" label="Phân loại 1" className='mb-3'>
                                <Input allowClear onChange={(e) => setParams({ ...params, category_1: e.target.value })} placeholder='Phân loại 1' />
                            </Form.Item>
                        </Form>
                    </div>
                    <div className='mb-3'>
                        <Form style={{ margin: '0 15px' }} layout="vertical">
                            <Form.Item name="category_2" label="Phân loại 2" className='mb-3'>
                                <Input allowClear onChange={(e) => setParams({ ...params, category_2: e.target.value })} placeholder='Phân loại 2' />
                            </Form.Item>
                        </Form>
                    </div>
                    <div className='mb-3'>
                        <Form style={{ margin: '0 15px' }} layout="vertical">
                            <Form.Item name="category_3" label="Phân loại 3" className='mb-3'>
                                <Input allowClear onChange={(e) => setParams({ ...params, category_3: e.target.value })} placeholder='Phân loại 3' />
                            </Form.Item>
                        </Form>
                    </div>
                    <div className='mb-3'>
                        <Form style={{ margin: '0 15px' }} layout="vertical">
                            <Form.Item name="category_4" label="Phân loại 4" className='mb-3'>
                                <Input allowClear onChange={(e) => setParams({ ...params, category_4: e.target.value })} placeholder='Phân loại 4' />
                            </Form.Item>
                        </Form>
                    </div>
                    <div className='mb-3'>
                        <Form style={{ margin: '0 15px' }} layout="vertical">
                            <Form.Item name="category_4" label="Phân loại 4" className='mb-3'>
                                <Input allowClear onChange={(e) => setParams({ ...params, category_5: e.target.value })} placeholder='Phân loại 4' />
                            </Form.Item>
                        </Form>
                    </div>
                    <div style={
                        {
                            padding: '10px',
                            textAlign: 'center'
                        }
                    }
                        layout="vertical">
                        <Button type='primary'
                            style={{ width: '80%' }} onClick={btn_click}>
                            Truy vấn
                        </Button>
                    </div>
                </Card>
            </Col>
            <Col span={21}>
                <Card style={{ height: '100%' }} title="Quản lý nhân sự" extra={
                    <Space>
                        <Upload
                            showUploadList={false}
                            name='file'
                            action={baseURL + "/api/user-info/import"}
                            headers={{
                                authorization: "Bearer " + JSON.parse(localStorage.getItem('authUser'))?.token,
                            }}
                            onChange={(info) => {
                                setLoadingExport(true);
                                if (info.file.status === 'error') {
                                    setLoadingExport(false);
                                    error()
                                } else if (info.file.status === 'done') {
                                    if (info.file.response.success === true) {
                                        // loadListTable(params);
                                        fetchData(params);
                                        success();
                                        setLoadingExport(false);
                                    } else {
                                        // loadListTable(params);
                                        fetchData(params);
                                        message.error(info.file.response.message);
                                        setLoadingExport(false);
                                    }
                                }
                            }}
                        >
                            <Button style={{ marginLeft: '15px' }} type="primary" loading={loadingExport}>
                                Upload Excel
                            </Button>
                        </Upload>
                        <Button type="primary" onClick={exportFile} loading={exportLoading}>Export Excel</Button>
                        {/* <Button type="primary" onClick={editRecord} disabled={listCheck.length <= 0}>Edit</Button> */}
                        <Button type="primary" onClick={insertRecord}>Insert</Button>
                        <Popconfirm
                            title="Xoá bản ghi"
                            description={"Bạn có chắc xoá " + listCheck.length + " bản ghi đã chọn?"}
                            onConfirm={deleteRecord}
                            okText="Có"
                            cancelText="Không"
                            placement="bottomRight"
                        >
                            <Button type="primary" disabled={listCheck.length <= 0}>Delete</Button>
                        </Popconfirm>

                    </Space>
                }>
                    <Spin spinning={loading}>
                        <Table size='small'
                            className="table-mh80"
                            bordered
                            scroll={{ x: '1550px' }}
                            columns={col_detailTable}
                            dataSource={userInfo}
                            // rowSelection={rowSelection}
                            pagination={{
                                current: pagination.page,
                                pageSize: pagination.pageSize,
                                onChange: (page, pageSize) => {
                                    setPagination({ ...pagination, page, pageSize });
                                }
                            }}
                        />
                    </Spin>
                </Card>
            </Col>
        </Row>

        <Modal title={isEdit ? 'Cập nhật' : 'Thêm mới'} open={openMdl} onCancel={onCancel} footer={null} width={800}>
            <Form style={{ margin: '0 15px' }}
                layout="vertical"
                form={form}
                onFinish={onFinish}>
                <Row gutter={5}>
                    <Col span={12}>
                        <Form.Item name={'id'} className='mb-3' label={'Mã nhân viên'} rules={[{ required: true }]}>
                            <Input placeholder="Mã nhân viên" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'name'} className='mb-3' label={'Họ tên'} rules={[{ required: true }]}>
                            <Input placeholder="Họ tên" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'date_join_company'} className='mb-3' label={'Ngày vào'} rules={[{ required: true }]}>
                            <DatePicker showTime={false} allowClear={false} placeholder="Ngày vào" format="DD/MM/YYYY" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'date_end_trial'} className='mb-3' label={'Ngày kết thúc thử việc'} rules={[{ required: false }]}>
                            <DatePicker showTime={false} allowClear={false} placeholder="Ngày kết thúc thử việc" format="DD/MM/YYYY" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'category_1'} className='mb-3' label={'Phân loại 1'} rules={[{ required: false }]}>
                            <Input placeholder="Phân loại 1" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'category_2'} className='mb-3' label={'Phân loại 2'} rules={[{ required: false }]}>
                            <Input placeholder="Phân loại 2" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'category_3'} className='mb-3' label={'Phân loại 3'} rules={[{ required: false }]}>
                            <Input placeholder="Phân loại 3" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'category_4'} className='mb-3' label={'Phân loại 4'} rules={[{ required: false }]}>
                            <Input placeholder="Phân loại 4" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'category_5'} className='mb-3' label={'Phân loại 5'} rules={[{ required: false }]}>
                            <Input placeholder="Phân loại 5" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'note'} className='mb-3' label={'Ghi chú'} rules={[{ required: false }]}>
                            <Input placeholder="Ghi chú" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item className='mb-0 text-end'>
                    <Button type="primary" htmlType='submit'>Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal>

        {/* <Modal title={isEdit ? 'Cập nhật' : 'Thêm mới'} open={openMdl} onCancel={() => setOpenMdl(false)} footer={null} width={800}>
            <Form style={{ margin: '0 15px' }}
                layout="vertical"
                form={form}
                onFinish={onFinish}>
                <Row gutter={[16, 16]}>
                    {formFields.map(e => {
                        if (e.key !== 'select' && e.key !== 'stt') return <Col span={!e.hidden ? 12 : 0}>
                            <Form.Item name={e.key} className='mb-3' label={e.title} hidden={e.hidden} rules={[{ required: e.required }]}>
                                {!e.isTrueFalse ?
                                    e.select ?
                                        <Select mode={e.select.mode} options={e.select.options} />
                                        :
                                        <Input disabled={e.disabled || (isEdit && e.key === 'id')}></Input>
                                    :
                                    <Select>
                                        <Select.Option value={1}>Có</Select.Option>
                                        <Select.Option value={0}>Không</Select.Option>
                                    </Select>
                                }
                            </Form.Item>
                        </Col>
                    })}
                </Row>
                <Form.Item className='mb-0'>
                    <Button type="primary" htmlType='submit' >Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal> */}
    </>
}

export default Users;
