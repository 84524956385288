import { DatePicker, Col, Row, Card, Table, Divider, Button, Form, Input, Select, Upload, message, Space, Modal, Spin, Popconfirm, Tree, InputNumber } from 'antd';
import { baseURL } from '../../../config';
import React, { useState, useEffect } from 'react';
import { exportMachine, getMachine, updateMachine, createMachine, deleteMachine } from '../../../api';
import dayjs from 'dayjs';
import { LoadingOutlined } from '@ant-design/icons';
import { getTreeSelect } from '../../../api/phase2/ui/main';
import { createMachinePriorityOrders, deleteMachinePriorityOrders, deleteManyMachinePriorityOrders, getMachinePriorityOrders, importMachinePriorityOrders, updateMachinePriorityOrders } from '../../../api/phase2/master_data/machine-priority-order';
import { getProducts } from '../../../api/ui/main';
import { getLineList, getMachineList } from '../../../api/phase2/oi/manufacture';

const MachinePriorityOrder = () => {
    document.title = "Quản lý thứ tự ưu tiên máy";
    const [listCheck, setListCheck] = useState([]);
    const [openMdl, setOpenMdl] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm();
    const [params, setParams] = useState({});
    const [pagination, setPagination] = useState({ page: 1, pageSize: 20, total: 0 });
    const columns = [
        { title: 'Thứ tự ưu tiên', dataIndex: 'priority', align: 'center' },
        { title: 'Mã máy ', dataIndex: 'machine_id', align: 'center', fixed: 'left' },
        { title: 'Mã sản phẩm', dataIndex: 'product_id', align: 'center' },
        { title: 'Tên sản phẩm', dataIndex: 'product_name', align: 'center' },
        { title: 'Công đoạn', dataIndex: 'line_name', align: 'center' },
    ];

    function btn_click(page = 1, pageSize = 20) {
        loadListTable({ ...params, page, pageSize })
    }
    const fetchTreeData = async () => {
        const res = await getTreeSelect();
        setListLines(res.data);
    }
    const [products, setProducts] = useState([]);
    const fetchProducts = async () => {
        const res = await getProducts();
        setProducts(res.data.map(e => ({ ...e, value: e.id, label: e.name })));
    }
    const [machines, setMachines] = useState([]);
    const fetchMachines = async () => {
        const res = await getMachineList();
        setMachines(res.data.map(e => ({ ...e, value: e.code, label: e.code })));
    }
    const [lines, setLines] = useState([]);
    const fetchLines = async () => {
        const res = await getLineList();
        setLines(res.data);
    }
    const [data, setData] = useState([]);
    const loadListTable = async (params) => {
        setLoading(true)
        const res = await getMachinePriorityOrders(params);
        setData(res.data.data.map(e => {
            return { ...e, key: e.id }
        }));
        setPagination({ ...pagination, total: res.data.total });
        setLoading(false);
    }
    useEffect(() => {
        fetchTreeData();
        fetchProducts();
        fetchMachines();
        fetchLines();
        btn_click();
    }, []);

    const onFinish = async (values) => {
        console.log(values);
        let res = null;
        if (isEdit) {
            res = await updateMachinePriorityOrders(values.id, values);

        } else {
            res = await createMachinePriorityOrders(values);
        }
        if (res?.success) {
            form.resetFields();
            setOpenMdl(false);
            loadListTable(params);
            setListCheck([]);
        }

    }

    const deleteRecord = async () => {
        if (listCheck.length > 1) {
            const res = await deleteManyMachinePriorityOrders(listCheck);
            if (res.success) {
                setListCheck([]);
                loadListTable(params);
            }
        } else if(listCheck.length === 1){
            const res = await deleteMachinePriorityOrders(listCheck);
            if (res.success) {
                setListCheck([]);
                loadListTable(params);
            }
        } else {
            message.info('Chưa chọn bản ghi cần xóa');
            return;
        }
    }
    const editRecord = () => {
        setIsEdit(true)
        if (listCheck.length !== 1) {
            message.info('Chọn 1 bản ghi để chỉnh sửa');
        } else {
            const result = data.find((record) => record.id === listCheck[0]);
            let available_at = null;
            if (result?.available_at) {
                available_at = dayjs(result.available_at);
            }
            form.setFieldsValue({ ...result, line: result?.line?.name, available_at });
            setOpenMdl(true);
        }
    }
    const insertRecord = () => {
        setIsEdit(false)
        form.resetFields();
        setOpenMdl(true);
    }
    const [loadingExport, setLoadingExport] = useState(false);
    const [loading, setLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportMachine(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const rowSelection = {
        selectedRowKeys: listCheck,
        onChange: (selectedRowKeys, selectedRows) => {
            setListCheck(selectedRowKeys)
        },
    };
    const [formSearch] = Form.useForm();
    const onSearch = (values) => {
        loadListTable(params);
    }
    const [listLines, setListLines] = useState([]);
    const onCheck = (checkedKeys, info) => {
        console.log(checkedKeys, info);

        const selectKeys = {
            line_id: [],
            machine_id: [],
        };
        function traverse(node) {
            switch (node.type) {
                case 'line':
                    selectKeys.line_id.push(node.key?.toString());
                    break;
                case 'machine':
                    selectKeys.machine_id.push(node.key?.toString());
                    break;
                default:
                    break;
            }
        }
        info.checkedNodes.map(traverse)
        setParams({ ...params, ...selectKeys });
        formSearch.setFieldsValue(selectKeys)
    };
    const [loadingUpload, setLoadingUpload] = useState(false);
    return <>
        <Row style={{ padding: '8px', marginRight: 0 }} gutter={[8, 8]}>
            <Col span={3} className='custom-col'>
                <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => formSearch.submit()}>Truy vấn</Button>
                ]}>
                    <Divider>Tìm kiếm</Divider>
                    <Form
                        style={{ margin: '0 8px' }}
                        layout="vertical"
                        form={formSearch}
                        onFinish={onSearch}>
                        <Form.Item className='mb-3'>
                            {
                                listLines.length ?
                                    <Tree
                                        defaultExpandedKeys={[2]}
                                        style={{ maxHeight: 250, overflowY: 'auto' }}
                                        checkable
                                        selectable={false}
                                        onCheck={onCheck}
                                        treeData={listLines}
                                    />
                                    :
                                    <LoadingOutlined />
                            }
                        </Form.Item>
                        <Form.Item label="Tên sản phẩm" className='mb-3' name={'product_name'}>
                            <Input allowClear onChange={(e) => setParams({ ...params, product_name: e.target.value })} placeholder='Nhập tên sản phẩm' />
                        </Form.Item>
                        <Form.Item label="Mã sản phẩm" className='mb-3' name={'product_id'}>
                            <Input allowClear onChange={(e) => setParams({ ...params, product_id: e.target.value })} placeholder='Nhập mã sản phẩm' />
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <Col span={21}>
                <Card style={{ height: '100%' }} styles={{ body: { paddingBottom: 0 } }} title="Thứ tự ưu tiên máy" extra={
                    <Space>
                        <Upload
                            showUploadList={false}
                            customRequest={async ({ file, onSuccess, onError }) => {
                                const formData = new FormData();
                                formData.append('file', file);
                                const res = await importMachinePriorityOrders(formData);
                                if (res.success) {
                                    btn_click()
                                    setLoadingUpload(false);
                                } else {
                                    btn_click()
                                    setLoadingUpload(false);
                                }
                            }}
                        >
                            <Button style={{ marginLeft: '15px' }} type="primary" loading={loadingUpload}>
                                Upload Excel
                            </Button>
                        </Upload>
                        {/* <Button type="primary" onClick={exportFile} loading={exportLoading}>Export Excel</Button> */}
                        <Button type="primary" onClick={editRecord} disabled={listCheck.length <= 0}>Edit</Button>
                        <Button type="primary" onClick={insertRecord}>Insert</Button>
                        <Popconfirm
                            title="Xoá bản ghi"
                            description={"Bạn có chắc xoá " + listCheck.length + " bản ghi đã chọn?"}
                            onConfirm={deleteRecord}
                            okText="Có"
                            cancelText="Không"
                            placement="bottomRight"
                        >
                            <Button type="primary" disabled={listCheck.length <= 0}>Delete</Button>
                        </Popconfirm>
                    </Space>
                }>
                    <Table size='small'
                        loading={loading}
                        bordered
                        pagination={{
                            current: pagination.page,
                            pageSize: pagination.pageSize,
                            total: pagination.total,
                            onChange: (page, pageSize) => btn_click(page, pageSize)
                        }}
                        scroll={
                            {
                                y: 'calc(100vh - 260px)'
                            }
                        }
                        columns={columns}
                        dataSource={data}
                        rowSelection={rowSelection}
                    />
                </Card>
            </Col>
        </Row>
        <Modal title={isEdit ? 'Cập nhật' : 'Thêm mới'} open={openMdl} onCancel={() => setOpenMdl(false)} footer={null} width={800}>
            <Form style={{ margin: '0 15px' }}
                layout="vertical"
                form={form}
                onFinish={onFinish}>
                <Form.Item name="id" hidden></Form.Item>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item name="priority" label="Thứ tự ưu tiên" className='mb-0' rules={[{ required: true }]}>
                            <InputNumber className='w-100' placeholder='Nhập thứ tự ưu tiên' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="machine_id" label="Mã máy" className='mb-0' rules={[{ required: true }]}>
                            <Select options={machines} showSearch optionFilterProp='label' placeholder="Vui lòng chọn" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="product_id" label="Sản phẩm" className='mb-0' rules={[{ required: true }]}>
                            <Select options={products} showSearch optionFilterProp='label' placeholder="Vui lòng chọn" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="line_id" label="Công đoạn" className='mb-0' rules={[{ required: true }]}>
                            <Select options={lines} showSearch optionFilterProp='label' placeholder="Vui lòng chọn" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item className='mt-4'>
                    <Button type="primary" htmlType='submit' >Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default MachinePriorityOrder;
