import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import '../style.scss';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Manufacture1 from './Manufacture1';
import Manufacture2 from './Manufacture2';
import Manufacture3 from './Manufacture3';
import Manufacture4 from './Manufacture4';
import Manufacture5 from './Manufacture5';
import P2Manufacture from './P2Manufacture';
import P2ManufactureSelection from './P2ManufactureSelection'
const Manufacture = (props) => {
    document.title = "Sản xuất";
    const { line } = useParams();
    const history = useHistory();
    useEffect(() => {
        const screen = JSON.parse(localStorage.getItem('screen'));
        if(line){
            localStorage.setItem('screen', JSON.stringify({ ...screen, manufacture: line ?? '' }))
        }else{
            if(!screen?.manufacture){
                history.push('/manufacture/24')
            }else{
                history.push('/manufacture/'+screen?.manufacture);
            }
        }
    }, [line])

    var node = null
    switch (line) {
        case '9':
            node = <Manufacture1 />
            break;
        case '10':
        case '11':
        case '12':
        case '13':
            node = <Manufacture2 />
            break;
        case '15':
            node = <Manufacture3 />
            break;
        case '21':
            node = <Manufacture4 />
            break;
        case '22':
        case '14':
            node = <Manufacture5 />
            break;
        case '24':
        case '25':
        case '26':
        case '27':
            node = <P2Manufacture/>
            break;
        case '29':
            node = <P2ManufactureSelection />
            break;
        default:
            
            break;
    }
    return (
        <React.Fragment>
            {node}
        </React.Fragment>
    );
};

export default withRouter(Manufacture);